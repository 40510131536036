export const removeConsoleLogs = () => {
    // console.log("process.env.NODE_ENV", process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
        // Replace all console.log statements with an empty function in production
        const originalConsoleLog = console.log;
        console.log = () => { };

        // If you also want to remove other console methods, you can do the same for them
        // const originalConsoleWarn = console.warn;
        // console.warn = () => {};

        // const originalConsoleError = console.error;
        // console.error = () => {};

        // Restore the original console.log in development
        // Usage: Call this function in your main file or entry point of the application
        return () => {
            console.log = originalConsoleLog;
            // Restore other console methods if necessary
            // console.warn = originalConsoleWarn;
            // console.error = originalConsoleError;
        };
    } else {
        // If not in production, do nothing
        return () => { };
    }
};
