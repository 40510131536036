import React from "react";
// import Mold from "../images/Amoldi_image (1).png"
import Mold from "../images/3D_1 (1).png"
import logo from "../images/Amoldi App_logo.JPG"
import { Link, useNavigate } from "react-router-dom";
import axios from "../utils/Requests/axios";
import { useState, useContext } from "react";
import { AppStorage } from "../utils/Context/AppContext";
import { showToast } from "../utils/index";
import Request from "../utils/Requests/Request";
import { Button } from "@mui/material";

function Profile() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  const AppContext = useContext(AppStorage);

  const user = AppContext?.userInfo
  return (
    <div className="headLogin">
      <div className="center">
        <div style={{ width: "50%" }}>
          <img src={Mold} alt="dddd" style={{
            textAlign: "center",
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            width: "100%",
            height: "100%",
            // marginLeft: "auto",
            // marginRight: "auto"
          }} />
        </div>
        <div style={{ width: "50%" }} className="formLog">
          <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
            <img src={logo} alt="" style={{ width: "15%" }} />
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', marginLeft: 50, margin: 20 }}>
            <p style={{ color: "rgba(244, 247, 253, 1)" }}>Name : {user.firstName} {user.lastName}</p>
            <p style={{ color: "rgba(244, 247, 253, 1)" }}>PersonalEmail : {user.personalEmail}</p>
            <p style={{ color: "rgba(244, 247, 253, 1)" }}>Personal Mobile No. : {user.personalPhone}</p>
            <p style={{ color: "rgba(244, 247, 253, 1)" }}>businessEmail : {user.businessEmail}</p>
            <p style={{ color: "rgba(244, 247, 253, 1)" }}>business Mobile No. : {user.businessPhone}</p>
            <Button
              onClick={() => navigate("/password-reset")}
            >
              Reset Password
            </Button>
          </div>
          {/* <form onSubmit={login} style={{ marginBottom: "10%" }}>
            <div className="txt_field">
              <input type="text" required onChange={(event) => setEmail(event.target.value)} />
              <span></span>
              <label>Email</label>
            </div>
            <div className="txt_field">
              <input type="password" required onChange={(event) => setPassword(event.target.value)} />
              <span></span>
              <label>Password</label>
            </div>
            <Link to={"/forgot-password"}>
              <div className="pass">Forget Password?</div>
            </Link>
            <input name="submit" type="Submit" value="Login" />
          </form> */}
        </div>
      </div>
    </div>
  );
}

export default Profile;
