import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { faCamera, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import { useDropzone } from 'react-dropzone';
import { AppStorage } from "../utils/Context/AppContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import BuildingStructure from "./BuildingStructure";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from "html2canvas";

function Project({ formData, setFormData, projectId, inspectors, setInspectors, setRadio }) {
  console.log("projectIdprojectId", formData);
  const formRef = useRef(null);
  // const formData = location?.state?.formData ? location?.state?.formData : props.formData
  // const setFormData = location?.state?.setFormData ? location?.state?.setFormData : props.setFormData
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const materialDropdownRef = useRef(null);

  const orderTypesOption = [
    { value: "Full inspection", label: "Full inspection" },
    { value: "Partial inspection", label: "Partial inspection" },
    { value: "Samples only", label: "Samples only" },
    { value: "Post-inspection", label: "Post-inspection" },
    { value: "Other- Protocol", label: "Other- Protocol" },
  ]
  const AppContext = useContext(AppStorage);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [inspectors, setInspectors] = useState([]);
  const [validated, setValidated] = useState(true);
  const [openField, setOpenField] = useState(false);
  const [materialDropdown, setMaterialDropdown] = useState([]);
  const [labFeeDropdown, setLabFeeDropdown] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isManageLabFeeOpen, setManageLabFeeOpen] = useState(false);
  const [image, setImage] = useState();

  // console.log(
  //   "openField", openField
  // );
  const onDrop = (acceptedFiles) => {
    // let dupObj = { ...formData };
    // dupObj.coverPhoto = acceptedFiles[0];
    // setFormData(dupObj);
    const previewData = acceptedFiles;
    setImage(previewData);
  };

  useEffect(() => {
    if (image) {
      handleCoverPhoto(image);
    }
  }, [image]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png', // Accept only image files
    multiple: false, // Allow only one file to be selected
  });
  const handleMaterial = (value) => {
    setFormData({ ...formData, others: value });
    setMaterialDropdown([])
  }
  useEffect(() => {
    getMaterial()
  }, [])

  const removeCoverPhoto = (index) => {
    // const updatedImages = [...uploadedFiles];
    // updatedImages.splice(index, 1);
    const updatedImages = [...formData.coverPhoto];
    formData.coverPhoto.splice(index, 1);
    setImage();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setOpenField(false);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (materialDropdownRef.current && !materialDropdownRef.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setMaterialDropdown([]);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const validateFormData = () => {
    const samplesOnlyValidation =
      formData.projectId === null ||
      formData.coverPhoto === "" ||
      formData.relativeHumidity === "" ||
      formData.geographicLocation === ""

    const otherValidation =
      formData.projectId === null ||
      formData.date === "" ||
      formData.projectScope === "" ||
      formData.projectCost === null ||
      formData.samplingFee === null ||
      // formData.feeDescription === "" ||
      // formData.discountFee === "" ||
      // formData.firstName === "" ||
      // formData.lastName === "" ||
      // formData.email === "" ||
      // formData.siteAddress === "" ||
      // formData.clientComment === "" ||
      // formData.personOnSite === "" ||
      // formData.personOnSiteNumber === "" ||
      // formData.clientNumber === "" ||
      // formData.clientAddress === "" ||
      // formData.clientCategory === "" ||
      formData.buildingType === "" ||
      formData.buildingPurpose === "" ||
      formData.property_size === "" ||
      formData.buildingAge === "" ||
      formData.buildingStructure === "" ||
      formData.buildingMaterialType === "" ||
      formData.others === "" ||
      formData.material === "" ||
      formData.extraLabFee === "" ||
      formData.extraLabFeeDescription === "" ||
      formData.coverPhoto === "" ||
      formData.relativeHumidity === "" ||
      formData.geographicLocation === "" ||
      formData.inspectorName === "" ||
      formData.inspectorLicense === "" ||
      formData.jobSheetNotes === "" ||
      formData.jobInterview === "" ||
      formData.jobSummary === ""
    if (formData.projectScope === "Samples only") {
      console.log("call samples only");
      if (samplesOnlyValidation) {
        setValidated(false);
        return false;
      }
      setValidated(true);
      return true;
    } else {
      console.log("call other", otherValidation);
      if (otherValidation) {
        setValidated(false);
        return false;
      }
      setValidated(true);
      return true;
    }
    // if (
    //   formData.projectId === null ||
    //   formData.date === "" ||
    //   formData.projectScope === "" ||
    //   formData.projectCost === null
    // ) {
    //   setValidated(false);
    //   return false;
    // }
    // setValidated(true);
    // return true;
  };

  // function getSearchedAppointments() {
  //   setLoading(true);

  //   Request.get(`/getPreInspectionData/${projectId}`)
  //     .then((result) => {
  //       setLoading(false);
  //       const preInspectionData = result.data.preInspectionData;
  //       // const preInspectionData = result.data.preInspectionData;
  //       console.log("result", result);
  //       const newFormData = {
  //         projectId: preInspectionData.id,
  //         date: preInspectionData.date,
  //         projectScope: preInspectionData.projectScope,
  //         projectCost: preInspectionData.price,
  //         feeDescription: "",
  //         discountFee: "",
  //         firstName: "",
  //         lastName: "",
  //         email: "",
  //         siteAddress: "",
  //         clientComment: "",
  //         personOnSite: "",
  //         personOnSiteNumber: "",
  //         clientNumber: "",
  //         clientAddress: "",
  //         clientCategory: "",
  //         buildingType: preInspectionData.building_type,
  //         buildingPurpose: preInspectionData.building_purpose,
  //         property_size: preInspectionData.property_size,
  //         buildingAge: "",
  //         buildingStructure: "",
  //         buildingMaterialType: "",
  //         material: "",
  //         others: "",
  //         coverPhoto: "",
  //         relativeHumidity: "",
  //         geographicLocation: preInspectionData.siteLocation,
  //         inspectorName: preInspectionData.inspectorFullName,
  //         inspectorLicense: preInspectionData.userCode,
  //         jobSheetNotes: "",
  //         jobInterview: "",
  //         jobSummary: "",
  //       }
  //       // const newFormData = { ...formData };
  //       // const mappedData = result.data.data.map((item) => ({
  //       //   projectId: item.id,
  //       //   date: item.date,
  //       //   projectScope: item?.quotation?.service_type,
  //       //   projectCost: item?.quotation?.price,
  //       //   firstName: item?.quotation?.firstName,
  //       //   lastName: item?.quotation?.lastName,
  //       //   email: item?.email,
  //       //   siteAddress: item?.siteAddress,
  //       //   clientComment: item?.comments,
  //       //   personOnSite: item?.personOnSite,
  //       //   personOnSiteNumber: item?.personOnSiteNumber,
  //       //   clientNumber: item?.clientPhone,
  //       //   clientAddress: item?.clientAddress,
  //       //   clientCategory: item?.clientCategory,
  //       // }));
  //       // // Assuming you want to set the first item in the array to the formData
  //       // if (mappedData.length > 0) {
  //       //   Object.assign(newFormData, mappedData[0]);
  //       // }
  //       setFormData(newFormData);
  //       // setForceUpdate(false);
  //     })
  //     .catch((err) => {
  //       // setLoading(false);
  //       console.log("Error: ", err);
  //       if (err.response.status === 401) {
  //         showToast("error", "Unauthorized user.");
  //       } else {
  //         showToast("error", "Something went wrong.");
  //       }
  //     });
  // }
  // useEffect(() => {
  //   getSearchedAppointments()
  // }, [])

  const handleNextButton = () => {
    const samplesOnlyValidation =
      formData.projectId === null ||
      formData.coverPhoto === "" ||
      formData.relativeHumidity === "" ||
      formData.geographicLocation === ""

    const otherValidation =
      formData.projectId === null ||
      formData.date === "" ||
      formData.projectScope === "" ||
      formData.projectCost === null ||
      formData.samplingFee === null ||
      // formData.feeDescription === "" ||
      // formData.discountFee === "" ||
      // formData.firstName === "" ||
      // formData.lastName === "" ||
      // formData.email === "" ||
      // formData.siteAddress === "" ||
      // formData.clientComment === "" ||
      // formData.personOnSite === "" ||
      // formData.personOnSiteNumber === "" ||
      // formData.clientNumber === "" ||
      // formData.clientAddress === "" ||
      // formData.clientCategory === "" ||
      formData.buildingType === "" ||
      formData.buildingPurpose === "" ||
      formData.property_size === "" ||
      formData.buildingAge === "" ||
      formData.buildingStructure === "" ||
      formData.buildingMaterialType === "" ||
      formData.others === "" ||
      formData.material === "" ||
      formData.extraLabFee === "" ||
      formData.extraLabFeeDescription === "" ||
      formData.coverPhoto === "" ||
      formData.relativeHumidity === "" ||
      formData.geographicLocation === "" ||
      formData.inspectorName === "" ||
      formData.inspectorLicense === "" ||
      formData.jobSheetNotes === "" ||
      formData.jobInterview === "" ||
      formData.jobSummary === ""
    if (formData.projectScope === "Samples only") {
      if (samplesOnlyValidation) {
        setValidated(false);
        return false;
      }
      return setRadio("Agreement")

    } else {
      if (otherValidation) {
        setValidated(false);
        return false;
      }
      return setRadio("Agreement")
    }
  }

  const handleProjectSubmit = () => {
    const building = formData.structure.map(item => item.buildingStructure).join(",");
    // console.log("building", building);
    console.log("validateFormData", validateFormData());
    if (validateFormData()) {
      let tempData = new FormData();
      tempData.append("projectId", formData.projectId);
      tempData.append("date", formData.date);
      tempData.append("projectScope", formData.projectScope);
      tempData.append("projectCost", formData.projectCost);
      tempData.append("feeDescription", formData.feeDescription);
      tempData.append("discountFee", formData.discountFee);
      // tempData.append("firstName", formData.firstName);
      // tempData.append("lastName", formData.lastName);
      // tempData.append("email", formData.email);
      // tempData.append("siteAddress", formData.siteAddress);
      // tempData.append("clientComment", formData.clientComment);
      // tempData.append("personOnSite", formData.personOnSite);
      // tempData.append("personOnSiteNumber", formData.personOnSiteNumber);
      // tempData.append("clientNumber", formData.clientNumber);
      // tempData.append("clientAddress", formData.clientAddress);
      // tempData.append("clientCategory", formData.clientCategory);
      tempData.append("buildingType", formData.buildingType);
      tempData.append("buildingPurpose", formData.buildingPurpose);
      tempData.append("property_size", formData.property_size);
      tempData.append("buildingAge", formData.buildingAge);
      tempData.append("buildingStructure", formData.structure.map(item => item.buildingStructure).join(","));
      tempData.append("buildingMaterialType", formData.structure.map(item => item.buildingMaterialType).join(","));
      tempData.append("material", formData.structure.map(item => item.others.value).join(","));
      // tempData.append("buildingStructure", formData.buildingStructure);
      // tempData.append("buildingMaterialType", formData.buildingMaterialType);
      tempData.append("extraLabFee", formData.extraLabFee);
      tempData.append("extraLabFeeDescription", formData.extraLabFeeDescription);
      // tempData.append("others", formData.others);
      tempData.append("relativeHumidity", formData.relativeHumidity);
      tempData.append("geographicLocation", formData.geographicLocation);
      tempData.append("inspectorName", formData.inspectorName);
      tempData.append("inspectorLicense", formData.inspectorLicense);
      tempData.append("jobSheetNotes", formData.jobSheetNotes);
      tempData.append("jobInterview", formData.jobInterview);
      tempData.append("jobSummary", formData.jobSummary);

      if (formData.coverPhoto) {
        tempData.append("coverPhoto", formData.coverPhoto);
      }
      Request.post("/createProject", tempData, {
        "Content-Type": "multipart/form-data",
      })
        .then((result) => {
          // console.log("Data: ", result.data);
          if (result.data["alertAboutPrice"]) {
            showToast("error", result.data["alertAboutPrice"]);
          } else {
            showToast("success", result?.data?.message);
            generatePDF(result.data["Project"])
          }
          setForceUpdate(true);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  };

  const handleCoverPhoto = (images) => {

    if (images?.length === 0) {
      return;
    }

    const tempData = new FormData();

    images.forEach((image, index) => {
      tempData.append(`images`, image);
    });

    Request.post("/postImage", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        if (result?.data) {
          const fileNames = result.data.images.map((file) => file.filename);
          let updatedFormData = { ...formData };
          updatedFormData.coverPhoto = fileNames; // Set the preliminaryPhotos to the new fileNames
          setFormData(updatedFormData);
        }
        // showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };

  function getInspectors() {
    setLoading(true);

    Request.get("/getAllInspectors")
      .then((result) => {
        setLoading(false);
        setInspectors(result.data["data"]);
        const data = result.data["data"].find((insp) => insp.id === formData.inspectorName);
        let dupObj = { ...formData };
        // dupObj.inspectorName = selectedInspector.id ? `${selectedInspector.id}` : "";
        dupObj.inspectorLicense = data ? data.userCode : "";
        // debugger
        setFormData(dupObj);

        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    if (forceUpdate) {
      getInspectors()
    }
  }, [forceUpdate]);

  function getMaterial(e) {
    // console.log("eeeeeeee", e);
    setLoading(true);

    Request.get("/getDropdownValues/material")
      .then((result) => {
        setLoading(false);
        // console.log("response", result);
        var data = result.data["values"].map((item, index) => ({
          value: item.value, // Use clientName as the value
          label: item.value
        }));
        setMaterialDropdown(data)
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }


  function addMaterial(e, isRemove) {
    setLoading(true);
    const obj = {
      remove: isRemove,
      dropdownName: "material",
      value: e
    }
    Request.post("/addDropdownValue", obj)
      .then((result) => {
        setLoading(false);
        // console.log("add response", result);
        setOpenField(true);
        setFormData({ ...formData, material: "" })
        // setInspectors(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getInspectors()
      // getMaterial();
    }
  }, [AppContext?.userAuthToken]);
  const handleOpenFieldClick = (event) => {
    // Prevent the event from propagating to the document click listener
    event.stopPropagation();
  };

  const openManageLab = () => {
    setManageLabFeeOpen(!isManageLabFeeOpen);

    // setFormData({ ...formData, extraLabFee: "", extraLabFeeDescription: "" })
  }

  // useEffect(() => {
  //   const selectedInspector = inspectors.find((insp) => insp.id === formData.inspectorName);
  //   console.log("selectedInspector", selectedInspector);
  //   let dupObj = { ...formData };
  //   dupObj.inspectorLicense = selectedInspector ? selectedInspector.userCode : "";
  //   // debugger
  //   setFormData(dupObj);
  // }, [formData.inspectorName])

  const generatePDF = (projectData) => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text('Project Detail', 10, 10);

    let yPos = 20;

    // Loop through the form data object
    Object.entries(projectData).forEach(([key, value]) => {
      // Check if the value is an array
      if (Array.isArray(value)) {
        // If it's an array, iterate over each element
        value.forEach((element, index) => {
          // Render each element's details
          Object.entries(element).forEach(([subKey, subValue]) => {
            // Check if the subValue is an object
            if (typeof subValue === 'object') {
              // If it's an object, render its label and value
              doc.text(`${subKey}: ${subValue.label || subValue.value}`, 10, yPos);
            } else {
              // Render the key and value
              doc.text(`${subKey}: ${subValue}`, 10, yPos);
            }
            yPos += 10;
          });
          yPos += 5; // Add some space between elements
        });
      } else {
        // Render non-array values
        doc.text(`${key}: ${value}`, 10, yPos);
        yPos += 10;
      }
    });

    doc.save('form_data.pdf');
    // const doc = new jsPDF();
    // html2canvas(formRef.current).then(canvas => {
    //   const imgData = canvas.toDataURL('image/png');

    //   const pdfWidth = doc.internal.pageSize.getWidth();
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    //   doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //   doc.save('form_data.pdf');
    // });

    // doc.save('form_data.pdf');
  };


  return (
    <div>
      <form ref={formRef}>
        <Box>
          <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Pre-inspection</h3>
          <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
            a. Project Information
          </p>
          {/* {(project.length > 0 ? project : [{}]).map((appt, index)  => ( */}
          <Grid item xs={12} md={12} lg={12} container>
            <Grid xs={12} md={12} lg={6}>
              <TextField
                placeholder="(Pre-populated)"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.projectId}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.projectId = e.target.value;
                  setFormData(dupObj);
                }}
                disabled
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Project ID:
              </p>
            </Grid>
            <Grid xs={12} md={12} lg={6}>
              <TextField
                placeholder="(Automatically generated)"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.date}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.date = e.target.value;
                  setFormData(dupObj);
                }}
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Date:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.date === null ||
                  formData.date === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter date
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <FormControl sx={{ mr: 1, width: "90%" }}>
                <Select
                  placeholder="Frank Selo Tenant"
                  value={formData.projectScope}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.projectScope = e.target.value;
                    setFormData(dupObj);
                  }}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  {!!orderTypesOption.length &&
                    orderTypesOption.map((item, i) => (
                      <MenuItem value={item.label} key={i}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* <TextField
              placeholder="Samples Only"
              id="outlined-start-adornment"
              sx={{
                width: "90%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={formData.projectScope}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.projectScope = e.target.value;
                setFormData(dupObj);
              }}
            /> */}
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Project Scope:
              </p>
              {!validated &&
                (formData.projectScope === null ||
                  formData.projectScope === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Select project scope
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <TextField
                placeholder="Service Fee: $ X"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.projectCost}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.projectCost = e.target.value;
                  setFormData(dupObj);
                }}
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Project Cost:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.projectCost === null ||
                  formData.projectCost === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter project cost
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <TextField
                placeholder="Sampling Fee"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.samplingFee}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.samplingFee = e.target.value;
                  setFormData(dupObj);
                }}
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Sampling Fee:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.samplingFee === null ||
                  formData.samplingFee === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter sampling fee
                  </div>
                )}
            </Grid>

            {/* <Grid xs={12} md={12} lg={6}>
            <TextField
              placeholder="Fee Description"
              id="outlined-start-adornment"
              sx={{
                width: "90%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <p
              style={{
                marginTop: "6px",
                fontSize: "16px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              (+) add extra or discount fee if authorized by admin only
            </p>
          </Grid> 
        <Grid xs={12} md={12} lg={6}>
            <TextField
              placeholder="$ +X for an extra or $ -X for a discount"
              id="outlined-start-adornment"
              sx={{
                width: "90%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
          </Grid> */}
          </Grid>
          {/* ))} */}
          {/* <p style={{ color: "rgba(106, 96, 169, 1)" }}>
          <span
            style={{
              borderBottom: "2px solid rgba(106, 96, 169, 0.8)",
              fontWeight: "bold",
            }}
          >
            Note{" "}
          </span>
          Once set of two required information is manually entered a user entry
          by the user clicks on (+) above, the 2 files above pop-up to allow
          data. 
        </p>*/}
          {/* <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            backgroundColor: " rgba(46, 105, 255, 1)",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            padding: "unset",
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingRight: "18px",
            paddingLeft: "18px",
            "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
            textTransform: "unset",
            mt: 2,
          }}
        >
          Create Project 
      </Button>*/}
        </Box>

        {/* <Box sx={{ mt: 4 }}>
        <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
          b. Client Information
        </p>
            <Grid item xs={12} md={12} lg={12} container>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.firstName}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.firstName = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  First Name:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.lastName}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.lastName = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Last Name:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.email}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.email = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  Email Address:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.siteAddress}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.siteAddress = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Inspection Site Address:
                </p>
              </Grid>
            </Grid>
      </Box> */}

        {/* <Box sx={{ mt: 4 }}>
        <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
          c. Additional Client Information
        </p>
            <Grid item xs={12} md={12} lg={12} container>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientComment}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientComment = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  Client’s Note or Comment:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.personOnSite}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.personOnSite = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Person on site:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.personOnSiteNumber}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.personOnSiteNumber = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  Person on site phone number:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientNumber}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientNumber = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Client’s phone number:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientAddress}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientAddress = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Client’s Home address:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientCategory}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientCategory = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Client category:
                </p>
              </Grid>
            </Grid>
      </Box> */}

        <Box sx={{ mt: 4 }}>
          <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
            b. Building Information
          </p>
          <Grid item xs={12} md={12} lg={12} container>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <FormControl sx={{ mt: 1, width: "90%" }}>
                <Select
                  displayEmpty
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    color: "rgba(155, 46, 239, 1)",
                  }}
                  value={formData.buildingType}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.buildingType = e.target.value;
                    setFormData(dupObj);
                  }}
                >
                  <MenuItem value={"Residential Buildings"}>Residential Buildings</MenuItem>
                  <MenuItem value={"Non-residential Buildings"}>Non-residential Buildings</MenuItem>
                </Select>
              </FormControl>
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Building Type:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.buildingType === null ||
                  formData.buildingType === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Select building type
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <FormControl sx={{ mt: 1, width: "90%" }}>
                <Select
                  displayEmpty
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    color: "rgba(155, 46, 239, 1)",
                  }}
                  value={formData.buildingPurpose}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.buildingPurpose = e.target.value;
                    setFormData(dupObj);
                  }}
                >
                  <MenuItem value={"Apartment"}>Apartment</MenuItem>
                  <MenuItem value={"Single-family homes"}>Single-family homes</MenuItem>
                  <MenuItem value={"Commercial"}>Commercial</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
              </FormControl>
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Building Purpose:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.buildingPurpose === null ||
                  formData.buildingPurpose === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Select building Purpose
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={12} marginTop={1}>
              <FormControl sx={{ mr: 1, width: "90%" }}>
                <Select
                  value={formData.property_size}
                  displayEmpty
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.property_size = e.target.value;
                    setFormData(dupObj);
                  }}
                  // readOnly={
                  //   informationData.orderType === "Full inspection" ? false : true
                  // }
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  <MenuItem value={null}>
                    <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                      500 SF - 1,000 SF
                    </span>
                  </MenuItem>
                  <MenuItem value={"500-1,000 SF"}>500-1,000 SF</MenuItem>
                  <MenuItem value={"1001-1,500 SF"}>1001-1,500 SF</MenuItem>
                  <MenuItem value={"1,501-2,000 SF"}>1,501-2,000 SF</MenuItem>
                  <MenuItem value={"2,001-2,500 SF"}>2,001-2,500 SF</MenuItem>
                  <MenuItem value={"2,501-3,000 SF"}>2,501-3,000 SF</MenuItem>
                  <MenuItem value={"3,001-3,500 SF"}>3,001-3,500 SF</MenuItem>
                  <MenuItem value={"3,501-4,000 SF"}>3,501-4,000 SF</MenuItem>
                  <MenuItem value={"4,001-4,500 SF"}>4,001-4,500 SF</MenuItem>
                  <MenuItem value={"4,501-5000 SF"}>4,501-5000 SF</MenuItem>
                  <MenuItem value={"5,001-5,500 SF"}>5,001-5,500 SF</MenuItem>
                  <MenuItem value={">5,500"}>{`>`}5,500</MenuItem>
                </Select>
              </FormControl>
              {/* <FormControl sx={{ mt: 1, width: "100%" }}>
              <TextField
                placeholder="500 - 1,000 - (Square feet)"
                id="outlined-start-adornment"
                sx={{
                  width: "95%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.property_size}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.property_size = e.target.value;
                  setFormData(dupObj);
                }}
              />

            </FormControl> */}
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Property Size:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.property_size === null ||
                  formData.property_size === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Select property size
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <TextField
                placeholder="Type your building age"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.buildingAge}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.buildingAge = e.target.value;
                  setFormData(dupObj);
                }}
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Building Age:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.buildingAge === null ||
                  formData.buildingAge === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter building age
                  </div>
                )}
            </Grid>
            {/* <Grid xs={12} md={12} lg={6}>
            <FormControl sx={{ mt: 1, width: "90%" }}>
              <Select
                displayEmpty
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
                value={formData.buildingStructure}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.buildingStructure = e.target.value;
                  setFormData(dupObj);
                }}
              >
                <MenuItem value={"Exterior"}>Exterior</MenuItem>
                <MenuItem value={"Interior"}>Interior</MenuItem>
              </Select>
            </FormControl>
            <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
            >
              Building structure:
            </p>
          </Grid> */}
            {/* <Grid xs={12} md={12} lg={6}>
            <FormControl sx={{ mt: 1, width: "90%" }}>
              <Select
                displayEmpty
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
                value={formData.buildingMaterialType}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.buildingMaterialType = e.target.value;
                  setFormData(dupObj);
                }}
              >
                {console.log("formData.buildingStructure", formData.buildingStructure)}
                {formData.buildingStructure === "Exterior" ?
                  <MenuItem value={"Roof"}>Roof</MenuItem> :
                  <MenuItem value={"Walls"}>Walls</MenuItem>
                }
                {formData.buildingStructure === "Exterior" && <MenuItem value={"Roof"}>Walls</MenuItem>}
              </Select>
            </FormControl>
            <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
            >
              Building material types:
            </p>
          </Grid> */}
            {/* <Grid xs={12} md={12} lg={6}>
            <FormControl sx={{ mt: 1, width: "90%" }}>
              <ReactSelect
                value={formData.others}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.others = e;
                  getMaterial(e)
                  setMenuOpen(false)
                  setFormData(dupObj);
                }}
                options={materialDropdown}
                onInputChange={(inputValue, { action }) => {
                  if (action === 'input-change') {
                    // Set menuIsOpen to true when the user starts typing
                    setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
                  }
                }}
                onBlur={() => setMenuOpen(false)}
                isSearchable={true}
                styles={customStyles}
                placeholder="Material"
                menuIsOpen={isMenuOpen}
              />
            </FormControl>
            <p
              ref={wrapperRef}
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                cursor: "pointer"
                // width:"90%"
              }}
              onClick={(e) => {
                setOpenField(!openField)
                handleOpenFieldClick(e)
              }}
            >
              + Material
            </p>
          </Grid> */}

            {/* {openField &&
            <Grid
              xs={12}
              md={12}
              sx={{ display: "flex", gap: "15px" }}
              onClick={handleOpenFieldClick}
            >
              <Grid xs={12} md={12} lg={6} style={{ width: "100%" }}>
                <TextField
                  placeholder="Add material"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.material}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.material = e.target.value;
                    setFormData(dupObj);
                  }}
                />
              </Grid>
              <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                <Button
                  sx={{
                    border: "1px solid rgba(46, 105, 255, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    borderRadius: "4px",
                    width: "100%",
                    padding: "9px 0px",
                    backgroundColor: "rgba(46, 105, 255, 1)",
                    textTransform: "unset",
                    fontWeight: "unset",
                    "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                  }}
                  onClick={() => addMaterial(formData.material)}
                >
                  Add
                </Button>
              </Grid>
              <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                <Button
                  sx={{
                    border: "1px solid rgba(46, 105, 255, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    borderRadius: "4px",
                    width: "100%",
                    padding: "9px 0px",
                    backgroundColor: "rgba(46, 105, 255, 1)",
                    textTransform: "unset",
                    fontWeight: "unset",
                    "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                  }}
                  onClick={() => addMaterial(formData.material, true)}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>} */}

          </Grid>
          {
            formData.structure.map((buildingData, index) => (
              <>
                <BuildingStructure
                  formData={formData}
                  setFormData={setFormData}
                  buildingData={buildingData}
                  main_index={index}
                  getMaterial={() => getMaterial()}
                  materialDropdown={materialDropdown}
                  addMaterial={(e, isRemove) => addMaterial(e, isRemove)}
                />
                {/* <p
                ref={wrapperRef}
                style={{
                  marginTop: "6px",
                  fontSize: "15px",
                  color: "rgba(155, 46, 239, 1)",
                  cursor: "pointer"
                  // width:"90%"
                }}>
                {`Structure ${index + 1}`}
              </p >
              <Grid item xs={12} md={12} lg={12} container>
                <Grid xs={12} md={12} lg={6}>
                  <FormControl sx={{ mt: 1, width: "90%" }}>
                    <Select
                      displayEmpty
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderRadius: "12px",
                        "& .MuiInputBase-formControl": {
                          borderRadius: "10px",
                          color: "rgba(155, 46, 239, 1) !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(223, 222, 225, 1) !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        color: "rgba(155, 46, 239, 1)",
                      }}
                      value={formData.buildingStructure}
                      onChange={(e) => {
                        let dupObj = { ...formData };
                        dupObj.buildingStructure = e.target.value;
                        setFormData(dupObj);
                      }}
                    >
                      <MenuItem value={"Exterior"}>Exterior</MenuItem>
                      <MenuItem value={"Interior"}>Interior</MenuItem>
                    </Select>
                  </FormControl>
                  <p
                    style={{
                      marginTop: "6px",
                      fontSize: "12px",
                      color: "rgba(155, 46, 239, 1)",
                      // width:"90%"
                    }}
                  >
                    Building structure:
                  </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                  <FormControl sx={{ mt: 1, width: "90%" }}>
                    <Select
                      displayEmpty
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderRadius: "12px",
                        "& .MuiInputBase-formControl": {
                          borderRadius: "10px",
                          color: "rgba(155, 46, 239, 1) !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(223, 222, 225, 1) !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        color: "rgba(155, 46, 239, 1)",
                      }}
                      value={formData.buildingMaterialType}
                      onChange={(e) => {
                        let dupObj = { ...formData };
                        dupObj.buildingMaterialType = e.target.value;
                        setFormData(dupObj);
                      }}
                    >
                      {console.log("formData.buildingStructure", formData.buildingStructure)}
                      {formData.buildingStructure === "Exterior" ?
                        <MenuItem value={"Roof"}>Roof</MenuItem> :
                        <MenuItem value={"Walls"}>Walls</MenuItem>
                      }
                      {formData.buildingStructure === "Exterior" && <MenuItem value={"Roof"}>Walls</MenuItem>}
                    </Select>
                  </FormControl>
                  <p
                    style={{
                      marginTop: "6px",
                      fontSize: "12px",
                      color: "rgba(155, 46, 239, 1)",
                      // width:"90%"
                    }}
                  >
                    Building material types:
                  </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                  <FormControl sx={{ mt: 1, width: "90%" }}>
                    <ReactSelect
                      // arrowRenderer={null}
                      value={formData.others}
                      onChange={(e) => {
                        let dupObj = { ...formData };
                        dupObj.others = e;
                        getMaterial(e)
                        setMenuOpen(false)
                        setFormData(dupObj);
                      }}
                      options={materialDropdown}
                      onInputChange={(inputValue, { action }) => {
                        if (action === 'input-change') {
                          // Set menuIsOpen to true when the user starts typing
                          setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
                        }
                      }}
                      onBlur={() => setMenuOpen(false)}
                      isSearchable={true}
                      styles={customStyles}
                      placeholder="Material"
                      menuIsOpen={isMenuOpen}
                    />
                  </FormControl>
                  <p
                    ref={wrapperRef}
                    style={{
                      marginTop: "6px",
                      fontSize: "12px",
                      color: "rgba(155, 46, 239, 1)",
                      cursor: "pointer"
                      // width:"90%"
                    }}
                    onClick={(e) => {
                      setOpenField(!openField)
                      handleOpenFieldClick(e)
                    }}
                  >
                    + Material
                  </p>
                </Grid>

                {openField &&
                  <Grid
                    xs={12}
                    md={12}
                    sx={{ display: "flex", gap: "15px" }}
                    onClick={handleOpenFieldClick}
                  >
                    <Grid xs={12} md={12} lg={6} style={{ width: "100%" }}>
                      <TextField
                        placeholder="Add material"
                        id="outlined-start-adornment"
                        sx={{
                          width: "90%",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          borderRadius: "12px",
                          "& .MuiInputBase-formControl": {
                            borderRadius: "10px",
                            color: "rgba(155, 46, 239, 1) !important",
                          },
                          "& .MuiInputBase-formControl ::placeholder": {
                            color: "rgba(180, 177, 185, 1) !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(223, 222, 225, 1) !important",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                          },
                        }}
                        value={formData.material}
                        onChange={(e) => {
                          let dupObj = { ...formData };
                          dupObj.material = e.target.value;
                          setFormData(dupObj);
                        }}
                      />
                    </Grid>
                    <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                      <Button
                        sx={{
                          border: "1px solid rgba(46, 105, 255, 1)",
                          color: "rgba(255, 255, 255, 1)",
                          borderRadius: "4px",
                          width: "100%",
                          padding: "9px 0px",
                          backgroundColor: "rgba(46, 105, 255, 1)",
                          textTransform: "unset",
                          fontWeight: "unset",
                          "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                        }}
                        onClick={() => addMaterial(formData.material)}
                      >
                        Add
                      </Button>
                    </Grid>
                    <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                      <Button
                        sx={{
                          border: "1px solid rgba(46, 105, 255, 1)",
                          color: "rgba(255, 255, 255, 1)",
                          borderRadius: "4px",
                          width: "100%",
                          padding: "9px 0px",
                          backgroundColor: "rgba(46, 105, 255, 1)",
                          textTransform: "unset",
                          fontWeight: "unset",
                          "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                        }}
                        onClick={() => addMaterial(formData.material, true)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>}
              </Grid> */}
              </>
            ))
          }
          <Grid xs={12} md={12} lg={12} marginTop={1}>
            <p
              ref={wrapperRef}
              style={{
                marginTop: "6px",
                fontSize: "15px",
                color: "rgba(155, 46, 239, 1)",
                cursor: "pointer"
                // width:"90%"
              }}
              onClick={() => {
                const dupObj = { ...formData }
                formData.structure.length > 2 ?
                  formData.structure.pop() :
                  dupObj.structure.push({
                    buildingStructure: " ",
                    buildingMaterialType: "",
                    material: "",
                    others: ""
                  })
                setFormData(dupObj)
              }}
            >
              {formData.structure.length > 2 ? "- Remove extra structure" : "+ One More Structure"}
            </p>
            <Grid xs={12} md={12} lg={12} >
              <p
                ref={wrapperRef}
                style={{
                  marginTop: "6px",
                  fontSize: "15px",
                  color: "rgba(155, 46, 239, 1)",
                  cursor: "pointer"
                  // width:"90%"
                }}
                onClick={() => openManageLab()}
              >
                {isManageLabFeeOpen ? "-" : "+"} Manage Lab Fee
              </p>
            </Grid>

            {isManageLabFeeOpen &&
              <>
                <Grid
                  xs={12}
                  md={12}
                  lg={6}
                  onClick={handleOpenFieldClick}
                >
                  <FormControl sx={{ mt: 1, width: "100%" }}>
                    <Grid xs={12} md={12} lg={12} style={{ width: "100%" }}>
                      <TextField
                        type="number"
                        placeholder="Add Extra Lab Fee"
                        id="outlined-start-adornment"
                        sx={{
                          width: "90%",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          borderRadius: "12px",
                          "& .MuiInputBase-formControl": {
                            borderRadius: "10px",
                            color: "rgba(155, 46, 239, 1) !important",
                          },
                          "& .MuiInputBase-formControl ::placeholder": {
                            color: "rgba(180, 177, 185, 1) !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(223, 222, 225, 1) !important",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                          },
                        }}
                        value={formData.extraLabFee}
                        onChange={(e) => {
                          let dupObj = { ...formData };
                          dupObj.extraLabFee = e.target.value;
                          setFormData(dupObj);
                        }}
                      />
                    </Grid>

                  </FormControl>
                  <p
                    style={{
                      marginTop: "6px",
                      fontSize: "12px",
                      color: "rgba(155, 46, 239, 1)",
                      // width:"90%"
                    }}
                  >
                    Extra Lab Fee
                  </p>
                  {!validated && formData.projectScope !== "Samples only" &&
                    (formData.extraLabFee === null ||
                      formData.extraLabFee === "") && (
                      <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                        Enter extra lab fee
                      </div>
                    )}
                </Grid>
                <Grid
                  xs={12}
                  md={12}
                  lg={6}
                  // sx={{ display: "flex", gap: "15px" }}
                  onClick={handleOpenFieldClick}
                >
                  <FormControl sx={{ mt: 1, width: "100%" }}>
                    <Grid xs={12} md={12} lg={12} style={{ width: "100%" }}>
                      <TextField
                        placeholder="Add Extra Lab Fee Description"
                        id="outlined-start-adornment"
                        sx={{
                          width: "90%",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          borderRadius: "12px",
                          "& .MuiInputBase-formControl": {
                            borderRadius: "10px",
                            color: "rgba(155, 46, 239, 1) !important",
                          },
                          "& .MuiInputBase-formControl ::placeholder": {
                            color: "rgba(180, 177, 185, 1) !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(223, 222, 225, 1) !important",
                          },
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                          },
                        }}
                        value={formData.extraLabFeeDescription}
                        onChange={(e) => {
                          let dupObj = { ...formData };
                          dupObj.extraLabFeeDescription = e.target.value;
                          // addMaterial(e.target.value)
                          setFormData(dupObj);
                        }}
                      />
                    </Grid>
                  </FormControl>
                  <p
                    style={{
                      marginTop: "6px",
                      fontSize: "12px",
                      color: "rgba(155, 46, 239, 1)",
                      // width:"90%"
                    }}
                  >
                    Extra Lab Fee Description
                  </p>
                  {!validated && formData.projectScope !== "Samples only" &&
                    (formData.extraLabFeeDescription === null ||
                      formData.extraLabFeeDescription === "") && (
                      <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                        Enter extra lab fee description
                      </div>
                    )}
                </Grid>
              </>
            }
          </Grid>

          <p style={{ color: "rgba(106, 96, 169, 1)" }}>
            <span style={{ fontWeight: "bold" }}>Note</span> (+) button allows to
            add new fields
          </p>
          <div>
            {formData?.coverPhoto?.length === 0 ? <div
              {...getRootProps()}
              className="coverPhoto"
              style={{ marginBottom: "15px" }}
            >
              <div className="coverCamera">
                <input {...getInputProps()} />
                <FontAwesomeIcon
                  icon={faCamera}
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                />
              </div>
            </div> :
              formData?.coverPhoto?.map((image, index) => (
                <div
                  key={index}
                  className="coverPhoto"
                  style={{
                    marginBottom: "15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <button
                    onClick={() => removeCoverPhoto(index)}
                    style={{
                      alignSelf: "flex-end",
                      borderRadius: "12px",
                      backgroundColor: "red",
                      border: "red",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
                  </button>
                  {typeof image === "string" ?
                    <img src={`https://amoldi.com/image-storage/${image}`} /> :
                    <img src={URL.createObjectURL(image)} />
                  }
                </div>))}
            <p
              style={{
                marginTop: "6px",
                fontSize: "15px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Cover Photo:
            </p>
          </div>
          {/* <div {...getRootProps()} className="coverPhoto">
            <input {...getInputProps()} />
            {image ? (
              <img src={URL.createObjectURL(image)} alt="Selected" />
            ) : (
              <div className="coverCamera">
                <FontAwesomeIcon icon={faCamera} style={{ color: 'rgba(0, 0, 0, 1)' }} />
              </div>
            )}
          </div> */}

          {/* <p
            style={{
              marginTop: "6px",
              fontSize: "10px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Cover Photo:
          </p> */}
          {!validated &&
            (formData.coverPhoto === null ||
              formData.coverPhoto === "") && (
              <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                Attech cover photo
              </div>
            )}
          <p
            style={{
              marginTop: "6px",
              fontSize: "15px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Required before submitting the completed form.
          </p>
          <Grid item xs={12} md={12} lg={12} container>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <TextField
                // placeholder="Name"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.relativeHumidity}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.relativeHumidity = e.target.value;
                  setFormData(dupObj);
                }}
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Relative Humidity:
              </p>
              {!validated &&
                (formData.relativeHumidity === null ||
                  formData.relativeHumidity === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter relative humidity
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <FormControl sx={{ width: "90%" }}>
                <Select
                  displayEmpty
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    color: "rgba(155, 46, 239, 1)",
                  }}
                  value={formData.geographicLocation}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.geographicLocation = e.target.value;
                    setFormData(dupObj);
                  }}
                >
                  <MenuItem value={"North"}>North</MenuItem>
                  <MenuItem value={"Northwest"}>Northwest</MenuItem>
                  <MenuItem value={"Northeast"}>Northeast</MenuItem>
                  <MenuItem value={"East"}>East</MenuItem>
                  <MenuItem value={"Southeast"}>Southeast</MenuItem>
                  <MenuItem value={"South"}>South</MenuItem>
                  <MenuItem value={"Southwest"}>Southwest</MenuItem>
                  <MenuItem value={"West"}>West</MenuItem>
                </Select>
              </FormControl>
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Geographic Location:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.geographicLocation === null ||
                  formData.geographicLocation === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Select geographic Location
                  </div>
                )}
            </Grid>
          </Grid>
        </Box>

        {/* check */}
        <Box sx={{ mt: 4 }}>
          <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
            c. Inspector Information
          </p>
          <Grid item xs={12} md={12} lg={12} container>
            <Grid xs={12} md={12} lg={6} marginTop={1}>
              <FormControl sx={{ mt: 1, width: "90%" }}>
                <Select
                  displayEmpty
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    color: "rgba(155, 46, 239, 1)",
                  }}
                  value={formData.inspectorName}
                  onChange={(e) => {
                    const selectedInspector = inspectors.find((insp) => insp.id === e.target.value);
                    console.log("selectedInspector", selectedInspector);
                    let dupObj = { ...formData };
                    dupObj.inspectorName = selectedInspector.id ? `${selectedInspector.id}` : "";
                    dupObj.inspectorLicense = selectedInspector ? selectedInspector.userCode : "";
                    // debugger
                    setFormData(dupObj);
                  }}
                >
                  {inspectors &&
                    inspectors.map((insp) => (
                      <MenuItem value={insp.id}>{`${insp?.firstName} ${insp?.lastName}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Inspector Full name
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.inspectorName === null ||
                  formData.inspectorName === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter inspector full name
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={6} mt={1} marginTop={1}>
              <TextField
                placeholder="(Pre-populated based on the inspector name selected above)"
                id="outlined-start-adornment"
                sx={{
                  width: "90%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={formData.inspectorLicense}
              />
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Inspector License# :
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.inspectorLicense === null ||
                  formData.inspectorLicense === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter inspector license
                  </div>
                )}
            </Grid>
            {/* <Grid xs={12} md={12} lg={12}>
            <TextField
              // placeholder="Name"
              value={formData.jobSheetNotes}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.jobSheetNotes = e.target.value
                // debugger
                setFormData(dupObj);
              }}
              id="outlined-start-adornment"
              sx={{
                width: "95%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
            >
              Job Sheet Notes:
            </p>
          </Grid> */}
            <Grid xs={12} md={12} lg={12} marginTop={1}>
              <textarea

                value={formData.jobInterview}
                onChange={(e) => {
                  let dupObj = { ...formData };
                  dupObj.jobInterview = e.target.value
                  // debugger
                  setFormData(dupObj);
                }}
                className="textArea"
                placeholder="Comment"
                rows="6"
                style={{ marginTop: 10, width: "93%" }}
              ></textarea>
              {/* <TextField
              // placeholder="Name"
              value={formData.jobInterview}
              onChange={(e) => {
                let dupObj = { ...formData };
                dupObj.jobInterview = e.target.value
                // debugger
                setFormData(dupObj);
              }}
              id="outlined-start-adornment"
              sx={{
                width: "95%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            /> */}
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Job Interview:
              </p>
              {!validated && formData.projectScope !== "Samples only" &&
                (formData.jobInterview === null ||
                  formData.jobInterview === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter job interview
                  </div>
                )}
            </Grid>
            <Grid xs={12} md={12} lg={12} marginTop={1}>
              <textarea
                value={formData.jobSummary}
                onChange={(e) => {
                  const selectedInspector = inspectors.find((insp) => insp.id === e.target.value);
                  let dupObj = { ...formData };
                  dupObj.jobSummary = e.target.value
                  // debugger
                  setFormData(dupObj);
                }}
                className="textArea"
                placeholder="Comment"
                rows="6"
                style={{ marginTop: 10, width: "93%" }}
              ></textarea>
              {/* <TextField
              // placeholder="Name"
              value={formData.jobSummary}
              onChange={(e) => {
                const selectedInspector = inspectors.find((insp) => insp.id === e.target.value);
                let dupObj = { ...formData };
                dupObj.jobSummary = e.target.value
                // debugger
                setFormData(dupObj);
              }}
              id="outlined-start-adornment"
              sx={{
                width: "95%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            /> */}
              <p
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  // width:"90%"
                }}
              >
                Job Summary* :
              </p>
              {!validated && formData.projectScope !== "Samples Only" &&
                (formData.jobSummary === null ||
                  formData.jobSummary === "") && (
                  <div style={{ color: "red", fontSize: "small", lineHeight: 1 }}>
                    Enter job summary
                  </div>
                )}
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "95%",
              mt: 1,
            }}
          >
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "6px",
                padding: "10px 32px !important",
                backgroundColor: "rgba(46, 105, 255, 1)",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                textTransform: "unset",
              }}
              onClick={handleProjectSubmit}
            // onClick={generatePDF}
            >
              Save
            </Button>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(46, 105, 255, 1)",
                borderRadius: "6px",
                padding: "10px 30px !important",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
                textTransform: "unset",
              }}
              onClick={() => handleNextButton()}
            >
              Next
            </Button>
          </Box>
          <p style={{ color: "rgba(106, 96, 169, 1)" }}>
            <span
              style={{
                fontWeight: "bold",
                borderBottom: "2px solid rgba(106, 96, 169, 0.8)",
              }}
            >
              Note{" "}
            </span>
            this button should disabled if agreement above is not signed yet.
          </p>
        </Box>
      </form>
    </div >
  );
}

export default Project;
