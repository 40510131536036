import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, MenuItem, Select } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";

function Information() {

  const initialStateInformation = {
    clienFullName: "",
    clientEmail: "",
    business_name: "",
    site_loaction: "",
    building_info: "",
    orderType: "",
    property_size: "",
    crawlspace: "",
    crawl_space_Inspection: "",
    orderFee: "",
    samplingFee: "",
    room_count: "",
    quoteworkZone: ""
  };

  const initialStateAppointment = {
    dateAndTime: "",
    client: "",
    client_phone: "",
    person_on_Site: "",
    person_phone: "",
    client_address: "",
    site_address: "",
    client_comment: "",
    workZone: "",
    licenseId: "",
    inspectorName: "",
    personOnSiteOtherType: "",
    attachedDocuments: []
  }
  const maxLength = 10;
  const [personSiteOn, setPersonSiteOn] = useState("");
  const [informationData, setInformationData] = useState(initialStateInformation);
  const [appointmentData, setAppointmentData] = useState(initialStateAppointment);
  const [clienName, setClienName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState("");
  const [workZoneList, setGetWorkZone] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [imagesUrl, setImageUrl] = useState([]);
  const [validation, setValidation] = useState(true);
  const [isOpenCancelAppointmentModel, setCancelAppointmentModel] = useState(false);
  const [reason, setReason] = React.useState("");
  console.log("informationData", informationData);
  console.log("reason", reason);
  console.log("imagesUrl", imagesUrl);
  const personOnSiteOption = [
    { value: "Myself", label: "Myself" },
    { value: "Tenant", label: "Tenant" },
    { value: "Real estate", label: "Real estate" },
    { value: "Other", label: "Other" },
  ]

  const orderTypesOption = [
    { value: "Full inspection", label: "Full inspection" },
    { value: "Partial inspection", label: "Partial inspection" },
    { value: "Samples only", label: "Samples only" },
    { value: "Post-inspection", label: "Post-inspection" },
    { value: "Other- Protocol", label: "Other- Protocol" },
  ]

  const maxImages = 1;
  const onDrop = (acceptedFiles) => {
    // Check if the total number of images doesn't exceed the maximum
    // if (uploadedFiles.length + acceptedFiles.length <= maxImages) {
    //   // Handle the uploaded files here
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    // } else {
    //   alert("You can only upload a maximum of 10 images.");
    // }
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      handleSubmitImages(uploadedFiles);
    }
  }, [uploadedFiles])

  const handleSubmitImages = (uploadedFiles) => {
    // console.log("images before:", uploadedFiles);

    if (uploadedFiles.length === 0) {
      return;
    }

    const tempData = new FormData();

    uploadedFiles.forEach((image, index) => {
      tempData.append(`images`, image);
    });

    Request.post("/postImage", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        // console.log("Data: ", result.data.images);
        if (result?.data) {
          const fileNames = result.data.images.map((file) => file.filename);
          const fileUrl = result.data.images.map((file) => {
            return (
              setImageUrl([...imagesUrl, file.imageurl])
            )
          });
          let updatedExteriorData = { ...appointmentData };
          // setImageUrl(fileUrl)
          updatedExteriorData.attachedDocuments =
            fileNames; // Set the preliminaryPhotos to the new fileNames
          setAppointmentData(updatedExteriorData);

        }
        // showToast("success", result?.data?.message);
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };

  const removeImage = (index) => {
    const updatedImages = [...uploadedFiles];
    updatedImages.splice(index, 1);
    setUploadedFiles(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true, // Accept only image files
  });

  // const remainingSlots = maxImages - uploadedFiles.length;

  function getClienName() {
    setLoading(true);

    Request.get("/getClientNames")
      .then((result) => {
        setLoading(false);
        // console.log("Appointments Dropdown res", result.data["data"]);
        setClienName(result.data["data"]);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function cancelAppointmentCall() {
    setLoading(true);
    const reqBody = {
      reason: reason,
      id: appointmentData?.appointmentId
    }
    Request.post("/cancelAppointments", reqBody)
      .then((res) => {
        setLoading(false);
        showToast("success", res.data.success);
        setReason("");
        resetData();
        // console.log("Appointments Dropdown res", result.data["data"]);
        // setClienName(result.data["data"]);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getAllWorkZones() {
    setLoading(true);

    Request.get("/getWorkZone")
      .then((result) => {
        setLoading(false);
        setGetWorkZone(result.data["data"]);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function resetData() {
    setInformationData({
      clienFullName: "",
      clientEmail: "",
      business_name: "",
      site_loaction: "",
      building_info: "",
      orderType: "",
      property_size: "",
      crawlspace: "",
      crawl_space_Inspection: "",
      orderFee: "",
      samplingFee: "",
      room_count: "",
      quoteworkZone: ""
    })
    setAppointmentData({
      dateAndTime: "",
      client: "",
      client_phone: "",
      person_on_Site: "",
      person_phone: "",
      client_address: "",
      site_address: "",
      client_comment: "",
      workZone: "",
      licenseId: "",
      inspectorName: "",
      personOnSiteOtherType: "",
      attachedDocuments: [],
      appointmentId: null
    })
  }

  const validateData = () => {
    if (
      informationData.clienFullName === "" ||
      informationData.clientEmail === "" ||
      informationData.building_info === "" ||
      informationData.orderType === "" ||
      (informationData.orderType === "Full inspection" &&
        informationData.property_size === null) ||
      informationData.orderType === "" ||
      informationData.crawlspace === "" ||
      informationData.crawl_space_Inspection === "" ||
      informationData.orderFee === "" ||
      informationData.samplingFee === "" ||
      informationData.room_count === "" ||
      informationData.quoteworkZone === ""
    ) {
      setValidation(false);
      return false;
    }
    setValidation(true);
    return true;
  };

  function getQuoteAndAppData() {
    setLoading(true);

    resetData();
    Request.get(`/getQuoteAndAppData/${clientId}`)
      .then((result) => {
        setLoading(false);
        // console.log("getQuoteAndAppData res", result.data["quoteData"]);
        const quoteData = result.data["quoteData"];
        const appointmentData2 = result.data["appointmentData"];
        console.log("appointment Dataaaa", appointmentData);
        console.log("quoteData", appointmentData.attachedDocuments);
        setInformationData({
          clienFullName: quoteData?.clientFullName,
          clientEmail: quoteData?.email,
          business_name: quoteData?.businessName,
          site_loaction: quoteData?.siteLocation,
          building_info: quoteData?.building_type,
          orderType: quoteData?.service_type,

          property_size: quoteData?.property_size,
          crawlspace: quoteData?.crawl_space,
          crawl_space_Inspection: quoteData?.crawl_space_Inspection,
          orderFee: quoteData?.price,
          samplingFee: quoteData?.samplingFee,
          room_count: quoteData?.room_count,
          quoteworkZone: quoteData?.workZoneId
        })
        setAppointmentData({
          dateAndTime: appointmentData2?.date,
          client: appointmentData2?.clientCategory,
          client_phone: appointmentData2?.clientPhone,
          person_on_Site: appointmentData2?.personOnSite,
          person_phone: appointmentData2?.personOnSiteNumber,
          client_address: appointmentData2?.clientAddress,
          site_address: appointmentData2?.siteAddress,
          client_comment: appointmentData2?.comments,
          workzone: appointmentData2?.workZone,
          licenseId: appointmentData2?.userCode,
          inspectorName: appointmentData2?.inspectorFullName,
          personOnSiteOtherType: appointmentData2?.personOnSiteOtherType,
          attachedDocuments: appointmentData2?.attachedDocuments ? appointmentData2?.attachedDocuments : [],
          appointmentId: appointmentData2?.id ? appointmentData2?.id : ""
        })
        setImageUrl(appointmentData2?.attachedDocuments ? appointmentData2?.attachedDocuments : [])
        // appointmentData2?.attachedDocuments ? setUploadedFiles(appointmentData2?.attachedDocuments) : setUploadedFiles([])
        console.log("appointment Dataaaa", appointmentData);
        // setClienName(result.data["data"]);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function updateFromFileManage() {
    setLoading(true);
    const payload = {
      id: clientId,
      email: informationData.clientEmail,
      businessName: informationData.business_name,
      building_type: informationData.building_info,
      service_type: informationData.orderType,
      property_size: informationData.property_size,
      crawl_space: informationData.crawlspace,
      crawl_space_Inspection: informationData.crawl_space_Inspection,
      room_count: informationData.room_count,
      // workZone: appointmentData.workZone,
      workZoneId: informationData.quoteworkZone,
      price: informationData.orderFee,
      samplingFee: informationData.samplingFee,
      clientCategory: appointmentData.client,
      clientPhone: appointmentData.client_phone,
      personOnSite: appointmentData.person_on_Site,
      personOnSiteNumber: appointmentData.person_phone,
      comments: appointmentData.client_comment,
      // userCode: appointmentData.licenseId,
      attachedDocuments: appointmentData?.attachedDocuments?.join(',')
    }
    if (validateData()) {
      if (appointmentData.licenseId) {
        Request.put(`/updateFromFileManage`, payload)
          .then((result) => {
            setLoading(false);
            console.log("result", result);
            showToast("success", result.data.message);
            console.log("appointment Dataaaa", appointmentData);
            resetData();
            setClientId("");
            setClienName("");
            getClienName();
            setUploadedFiles([]);
            // setClienName(result.data["data"]);
          })
          .catch((err) => {
            setLoading(false);
            // console.log("Error: ", err);
            if (err.response.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", "Something went wrong.");
            }
          });
      } else {
        showToast("error", "Please Enter License Id");
      }
    }
  }

  console.log("appointment", appointmentData);
  useEffect(() => {
    getClienName();
    getAllWorkZones();
  }, [])
  console.log("appointment Data", appointmentData);
  const handleClose = () => {
    setCancelAppointmentModel(false);
  };

  return (
    <div style={{ padding: "10px" }}>
      <Dialog
        open={isOpenCancelAppointmentModel}
        fullWidth={true}
        maxWidth='sm'
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData).entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Cancel Appointment</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <textarea
            className="textArea"
            placeholder="Reason"
            rows="6"
            style={{ marginTop: 10, border: "1px solid #000", color: "#000" }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <Button
            sx={{
              border: "1px solid rgba(191, 10, 48, 1)",
              color: "rgba(191, 10, 48, 1)",
              borderRadius: "10px",
              padding: "4px 20px !important",
              "&:hover": { backgroundColor: "rgba(191, 10, 48, 0.1)" },
              textTransform: "unset"
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "4px 20px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
            }}
            disabled={!clientId}
            onClick={cancelAppointmentCall}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      <h3>Search client information by name:</h3>
      <Grid item xs={12} md={4} container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12} md={12} container spacing={2}>
          <Grid item xs={12} md={6} container>
            <FormControl sx={{ mr: 1, width: "100%" }}>
              <Select
                value={clientId}
                displayEmpty
                onChange={(e) => {
                  setClientId(e.target.value);
                }}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 400, // Set your desired max height here
                    },
                  },
                }}
              >
                {!!clienName.length &&
                  clienName.map((item, i) => (
                    <MenuItem value={item.id} key={i}>
                      {`${item.name}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client Name:
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              placeholder="Id"
              id="outlined-start-adornment"
              value={clientId}
              displayEmpty
              onChange={(e) => {
                setClientId(e.target.value);
              }}
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </InputAdornment>
                ),
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Id:
            </span>
            {/* {!validated &&
            (discountData.discount === null ||
              discountData.discount === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter Discount
              </div>
            )} */}
          </Grid>
          {/* <Grid item xs={12} md={6} container>
            <FormControl sx={{ mr: 1, width: "100%" }}>
              <Select
                value={clientId}
                displayEmpty
                onChange={(e) => {
                  setClientId(e.target.value);
                }}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                {!!clienName.length &&
                  clienName.map((item, i) => (
                    <MenuItem value={item.id} key={i}>
                      {item.id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client Id:
            </span>
          </Grid> */}
          <Grid item xs={12} md={4} container>
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                maxHeight: "55px",
                minWidth: "84px",
                backgroundColor: "rgba(44, 181, 44, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              }}
              onClick={() => getQuoteAndAppData()}
            >
              Go
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <h3>Information</h3>
      <p style={{ color: "rgba(21, 6, 45, 1)" }}>
        Quote Confirmation #: ABT352
      </p>
      <Grid item xs={12} md={8} container spacing={2}>

        <Grid item xs={12} md={6}>

          <FormControl sx={{ mr: 1, width: "100%" }}>
            <TextField
              value={informationData.clienFullName}
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.clienFullName = e.target.value;
                setInformationData(dupObj);
              }}
              placeholder="Name"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Client Name:
          </span>

          {!validation &&
            (informationData.clienFullName === null || informationData.clienFullName === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter client name
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={informationData.clientEmail}
            onChange={(e) => {
              let dupObj = { ...informationData };
              dupObj.clientEmail = e.target.value;
              setInformationData(dupObj);
            }}
            placeholder="patrick22@gmail.com"
            id="outlined-start-adornment"
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Client Email Address:
          </span>
          {!validation &&
            (informationData.clientEmail === null || informationData.clientEmail === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter client email
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={informationData.business_name}
            onChange={(e) => {
              let dupObj = { ...informationData };
              dupObj.business_name = e.target.value;
              setInformationData(dupObj);
            }}
            placeholder="Business Name"
            id="outlined-start-adornment"
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Business Name:
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            value={informationData.site_loaction}
            onChange={(e) => {
              let dupObj = { ...informationData };
              dupObj.site_loaction = e.target.value;
              setInformationData(dupObj);
            }}
            placeholder="Houston, TX 77004"
            id="outlined-start-adornment"
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Site Location:
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <TextField
              placeholder="Residential building"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </InputAdornment>
                ),
              }}
            /> */}
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={informationData.building_info}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.building_info = e.target.value;
                setInformationData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Residential Buildings
                </span>
              </MenuItem>
              <MenuItem value={"Residential Buildings"}>Residential Buildings</MenuItem>
              <MenuItem value={"Non-residential Buildings"}>Non-residential Buildings</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Building Type:
          </span>
          {!validation &&
            (informationData.building_info === null || informationData.building_info === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select building type
              </div>
            )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              placeholder="Frank Selo Tenant"
              value={informationData.orderType}
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.orderType = e.target.value;
                setInformationData(dupObj);
                // setOrderType(e.target.value);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              {!!orderTypesOption.length &&
                orderTypesOption.map((item, i) => (
                  <MenuItem value={item.label} key={i}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Order Types:
          </span>
          {!validation &&
            (informationData.orderType === null || informationData.orderType === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select order type
              </div>
            )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={informationData?.property_size}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.property_size = e.target.value;
                setInformationData(dupObj);
              }}
              readOnly={
                informationData.orderType === "Full inspection" ? false : true
              }
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={null}>
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  500 SF - 1,000 SF
                </span>
              </MenuItem>
              <MenuItem value={"500-1,000 SF"}>500-1,000 SF</MenuItem>
              <MenuItem value={"1001-1,500 SF"}>1001-1,500 SF</MenuItem>
              <MenuItem value={"1,501-2,000 SF"}>1,501-2,000 SF</MenuItem>
              <MenuItem value={"2,001-2,500 SF"}>2,001-2,500 SF</MenuItem>
              <MenuItem value={"2,501-3,000 SF"}>2,501-3,000 SF</MenuItem>
              <MenuItem value={"3,001-3,500 SF"}>3,001-3,500 SF</MenuItem>
              <MenuItem value={"3,501-4,000 SF"}>3,501-4,000 SF</MenuItem>
              <MenuItem value={"4,001-4,500 SF"}>4,001-4,500 SF</MenuItem>
              <MenuItem value={"4,501-5000 SF"}>4,501-5000 SF</MenuItem>
              <MenuItem value={"5,001-5,500 SF"}>5,001-5,500 SF</MenuItem>
              <MenuItem value={">5,500"}>{`>`}5,500</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Property Size (applicable to full inspection only):
          </span>
          {!validation &&
            (informationData.property_size === null || informationData.property_size === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select property size
              </div>
            )}
          {/* {!validatedPrice &&
              priceData.service_type === "Full inspection" &&
              (priceData.property_size === "" ||
                priceData.property_size === null) && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Select Property Size
                </div>
              )} */}
        </Grid>
        {/* <Grid item xs={12} md={6} container>
            <TextField
              placeholder="2,500 SF"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </InputAdornment>
                ),
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Property Size:
            </span>
          </Grid> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} md={12} container spacing={2}> */}
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={informationData.crawlspace}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.crawlspace = e.target.value;
                setInformationData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Crawlspace in building ?
          </span>
          {!validation &&
            (informationData.crawlspace === null || informationData.crawlspace === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Crawlspace in building
              </div>
            )}
        </Grid>
        {informationData.orderType === "Other- Protocol" &&
          <Grid item xs={12} md={6}>
            <TextField
              value={informationData.room_count}
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.room_count = e.target.value;
                setInformationData(dupObj);
              }}
              placeholder="Room Count"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Room Count
            </span>
            {!validation &&
              (informationData.crawlspace === null || informationData.crawlspace === "") && (
                <div style={{ color: "red", fontSize: "small" }}>
                  Enter room count
                </div>
              )}
          </Grid>
        }
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={informationData.crawl_space_Inspection}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.crawl_space_Inspection = e.target.value;
                setInformationData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Scope of inspection includes crawlspace ?
          </span>
          {!validation &&
            (informationData.crawl_space_Inspection === null || informationData.crawl_space_Inspection === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Scope of inspection includes crawlspace ?
              </div>
            )}
        </Grid>
        {/* <Grid item xs={12} md={6} container>
            <TextField
              value={informationData.crawl_space_Inspection}
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.crawl_space_Inspection = e.target.value;
                setInformationData(dupObj);
              }}
              placeholder="No"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </InputAdornment>
                ),
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Scope of inspection includes crawlspace ?
            </span>
          </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={informationData.quoteworkZone}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.quoteworkZone = e.target.value;
                setInformationData(dupObj);
              }}
              disabled={informationData.quoteworkZone}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              {!!workZoneList.length &&
                workZoneList.map((item, i) => (
                  <MenuItem value={item.id} key={i}>
                    {item.city}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Work Zone:
          </span>
          {!validation &&
            (informationData.quoteworkZone === null || informationData.quoteworkZone === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Scope of inspection includes crawlspace ?
              </div>
            )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} container spacing={2}>

        <Grid item xs={12} md={12} container sx={{ mt: 2 }}>
          <span style={{ width: "100%", fontWeight: "400" }}>
            Pricing Matrix
          </span>
        </Grid>
        <Grid item xs={12} md={12} container spacing={2}>
          <Grid item xs={12} md={6} container>
            <TextField
              value={informationData.orderFee}
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.orderFee = e.target.value;
                setInformationData(dupObj);
              }}
              disabled={informationData.building_info === "Non-residential Buildings" && informationData.orderType === "Partial inspection" || informationData.property_size === ">5,500" ? false : true}
              placeholder="$ 100"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment:
                  (
                    (informationData.building_info === "Non-residential Buildings" && informationData.orderType === "Partial inspection" || informationData.property_size === ">5,500") &&
                    (<InputAdornment position="end">
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </InputAdornment>)
                  ),
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Order Fee:
            </span>
          </Grid>
          <Grid item xs={12} md={6} container>
            <TextField
              value={informationData.samplingFee}
              onChange={(e) => {
                let dupObj = { ...informationData };
                dupObj.samplingFee = e.target.value;
                setInformationData(dupObj);
              }}
              disabled={informationData.building_info === "Non-residential Buildings" && informationData.orderType === "Partial inspection" || informationData.property_size === ">5,500" ? false : true}
              placeholder="$ 100"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment:
                  (
                    (informationData.building_info === "Non-residential Buildings" && informationData.orderType === "Partial inspection" || informationData.property_size === ">5,500") &&
                    (<InputAdornment position="end">
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </InputAdornment>)
                  ),
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Sampling Fee:
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} container sx={{ mt: 1 }}>
          <span style={{ width: "100%", fontWeight: "400" }}>
            Appointment Confirmation #: X03TQ
          </span>
        </Grid>
        <Grid item xs={12} md={12} container spacing={2}>
          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.dateAndTime}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.dateAndTime = e.target.value;
                setAppointmentData(dupObj);
                console.log("dateAndTime", appointmentData);
              }}
              placeholder="Mon, June 1, 2023 at 9:30am CDT"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </InputAdornment>
                ),
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Date and Time:
            </span>
          </Grid>
          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.client}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.client = e.target.value;
                setAppointmentData(dupObj);
                console.log("client", appointmentData);
              }}
              placeholder="Owner"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client:
            </span>
          </Grid>
          <Grid item xs={12} md={12} container>
            <TextField
              value={appointmentData.client_phone}
              onChange={(e) => {
                const numericOnly = e.target.value.replace(/\D/g, '');

                // Format the phone number
                const formattedNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3');
                let dupObj = { ...appointmentData };
                dupObj.client_phone = formattedNumber;
                setAppointmentData(dupObj);
                console.log("client_phone", appointmentData);
              }}
              placeholder="713 485 0697"
              id="outlined-start-adornment"
              inputProps={{ maxLength }}
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client Phone:
            </span>
          </Grid>
          <Grid item xs={12} md={6} container>
            <FormControl sx={{ mr: 1, width: "100%" }}>
              <Select
                value={appointmentData.person_on_Site}
                onChange={(e) => {
                  let dupObj = { ...appointmentData };
                  dupObj.person_on_Site = e.target.value;
                  setAppointmentData(dupObj);
                  console.log("person_on_Site", appointmentData);
                }}
                placeholder="Frank Selo Tenant"
                // value={personSiteOn}
                // onChange={(e) => {
                //   setPersonSiteOn(e.target.value);
                // }}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                {!!personOnSiteOption.length &&
                  personOnSiteOption.map((item, i) => (
                    <MenuItem value={item.label} key={i}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Person on-site:
            </span>
          </Grid>

          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.person_phone}

              onChange={(e) => {
                let dupObj = { ...appointmentData };

                const numericOnly = e.target.value.replace(/\D/g, '');

                // Format the phone number
                const formattedNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3');
                // const phone = e.target.value === 3 && `${(e.target.value)
                // }`
                console.log("formattedNumber", formattedNumber);
                dupObj.person_phone = formattedNumber;
                setAppointmentData(dupObj);

                console.log("person_phone", appointmentData);
              }}
              placeholder="(281) 203 5603"
              id="outlined-start-adornment"
              inputProps={{ maxLength }}
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Person Phone:
            </span>
          </Grid>
          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.client_address}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.client_address = e.target.value;
                setAppointmentData(dupObj);
                console.log("client_address", appointmentData);
              }}
              placeholder="125 Browning Road Houston, TX 77004"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client’s Address (Business address, if applicable):
            </span>
          </Grid>
          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.site_address}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.site_address = e.target.value;
                setAppointmentData(dupObj);
                console.log("site_address", appointmentData);
              }}
              placeholder="(If different from client’s address)"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Site Address:
            </span>
          </Grid>
          {appointmentData.person_on_Site === "Other" && <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.personOnSiteOtherType}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.personOnSiteOtherType = e.target.value;
                setAppointmentData(dupObj);
                console.log("personOnSiteOtherType", appointmentData);
              }}
              placeholder="Frank Selo Tenant"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Other’s description:
            </span>
          </Grid>}
          <Grid item xs={12} md={12} container>
            <textarea
              value={appointmentData.client_comment}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.client_comment = e.target.value;
                setAppointmentData(dupObj);
                console.log("client_comment", appointmentData);
              }}
              className="textArea"
              placeholder="Comment"
              rows="6"
              style={{ marginTop: 10 }}
            ></textarea>

            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client's comment:
            </span>
          </Grid>
          {/* <Grid xs={12} md={12} container>
            {appointmentData.attachedDocuments.map((image, index) => (
              <div
                key={index}
                className="coverPhoto"
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  onClick={() => removeImage(index)}
                  style={{
                    alignSelf: "flex-end",
                    borderRadius: "12px",
                    backgroundColor: "red",
                    border: "red",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
                </button>
                <img src={`${"https://"}${image}`} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Grid> */}
          {/* <Grid xs={12} md={12} container> */}
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              width: "100%",
              flexWrap: "wrap",
              mt: 1
            }}
          >
            {/* <Grid item xs={12} md={6} container gap={2}> */}
            {imagesUrl.map((image, index) => (
              console.log("imagesUrl's image", image),
              <div
                key={index}
                className="coverPhoto"
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  onClick={() => removeImage(index)}
                  style={{
                    alignSelf: "flex-end",
                    borderRadius: "12px",
                    backgroundColor: "red",
                    border: "red",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
                </button>
                {typeof (image) === "string" && image.includes("https://") ?

                  <img src={image} alt={`Image ${index + 1}`} /> :
                  <img src={`https://${image}`} alt={`image ${index + 1}`} />}
                {/* <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} /> */}
              </div>
            ))}
            {/* </Grid> */}
            {/* <Grid item xs={12} md={6} container> */}
            <div
              className="coverPhoto"
              style={{
                marginBottom: "15px",
                display: "flex",
                // justifyContent: "center",
                flexDirection: "column",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="coverCamera">
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ color: "rgba(0, 0, 0, 1)" }}
                />
              </div>
              Add  More Photo
            </div>
            {/* </Grid> */}
          </Box>
          {/* </Grid> */}
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Client's comment:
          </p>
          {/* <Grid item xs={12} md={12} container>

            <TextField
              type="file"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />

            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client's attench document comment:
            </span>
          </Grid> */}

          {/* <Grid item xs={12} md={12} container>
            <textarea
              className="textArea"
              placeholder="Comment"
              rows="6"
            ></textarea>
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client's comment:
            </span>
          </Grid> */}
          <Grid
            item
            xs={12}
            md={12}
            container
            sx={{ display: "flex", gap: "25px", mt: 2 }}
          >
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "6px",
                padding: "10px 32px !important",
                backgroundColor: "rgba(44, 181, 44, 1)",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
                textTransform: "unset",
              }}
              disabled={!clientId}
              onClick={updateFromFileManage}
            >
              Save
            </Button>
            <Button
              sx={{
                border: "1px solid rgba(191, 10, 48, 1)",
                color: "rgba(191, 10, 48, 1)",
                borderRadius: "10px",
                padding: "4px 20px !important",
                "&:hover": { backgroundColor: "rgba(191, 10, 48, 0.1)" },
                textTransform: "unset"
              }}
              onClick={() => setCancelAppointmentModel(true)}
              disabled={!clientId || !appointmentData?.appointmentId}
            >
              Cancel
            </Button>
          </Grid>

        </Grid>
        <Grid item xs={12} md={12} container sx={{ mt: 1 }}>
          <span style={{ width: "100%", fontWeight: "400" }}>
            Resource Data:
          </span>
        </Grid>
        <Grid item xs={12} md={12} container spacing={2}>
          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.inspectorName}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.inspectorName = e.target.value;
                setAppointmentData(dupObj);
              }}
              disabled
              placeholder="Olas Moau"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Inspector name:
            </span>
          </Grid>
          <Grid item xs={12} md={6} container>
            <TextField
              value={appointmentData.licenseId}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.licenseId = e.target.value;
                setAppointmentData(dupObj);
              }}
              disabled
              placeholder="MAC …."
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Inspector License ID:
            </span>
          </Grid>
          {/* <Grid item xs={12} md={12} container>
            <FormControl sx={{ mr: 1, width: "100%" }}>
              <Select
                value={appointmentData.workZone}
                displayEmpty
                onChange={(e) => {
                  let dupObj = { ...appointmentData };
                  dupObj.workZone = e.target.value;
                  setAppointmentData(dupObj);
                }}
                disabled
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                {!!workZoneList.length &&
                  workZoneList.map((item, i) => (
                    <MenuItem value={item.id} key={i}>
                      {item.city}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Work Zone:
            </span>
          </Grid> */}
          <Grid item xs={12} md={12} container>
            <TextField
              value={appointmentData.workZone}
              onChange={(e) => {
                let dupObj = { ...appointmentData };
                dupObj.workZone = e.target.value;
                setAppointmentData(dupObj);
              }}
              disabled
              placeholder="Houston"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Work Zone:
            </span>
          </Grid>
        </Grid>
      </Grid>
    </div >
  );
}

export default Information;
