import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const SplineChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'category', 
      categories: [1, 5, 10, 15, 20, 25, 30], 
      labels: {
        format: 'MMM', 
      },
    },
    legend: {
        position: 'top',
        horizontalAlign:"right",       
    },
  
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([ 
    {
        name: 'current month',
        data: [31, 40, 28, 51, 42, 109, 100],
        color:"rgba(191, 10, 48, 0.87)"
      },
    {
      name: 'last month',
      data: [11, 32, 45, 32, 34, 52, 41],
      color:"rgba(232, 234, 238, 0.5)"
    },
    
  ]);

  return (
    <div id="chart">
      <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} />
    </div>
  );
};

export default SplineChart;
