import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import SignatureCanvas from "react-signature-canvas";

function AttachmentA({ projectId, setRadio }) {
  const signatureRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [attechMentAData, setAttechmentAData] = useState([]);
  const [isSignatureVisible, setIsSignatureVisible] = useState(false);
  const [attechMentAgreement, setAttechmentAgreement] = useState([]);
  const [samplingFee, setSamplingFee] = useState();
  const [extraLabFee, setExtraLabFee] = useState([]);
  const [validated, setValidated] = useState(true);
  const [totalCost, setTotalCost] = useState(0)

  const validateFormData = () => {
    if (
      signatureRef.current.isEmpty()
    ) {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };

  function getAttechmentA() {
    setLoading(true);
    if (projectId) {
      Request.get(`/getSamplesData/${projectId}`)
        .then((result) => {
          setLoading(false);
          var data = [];
          for (var i = 0; i < result.data["samples"].length; i++) {
            const ele = result.data["samples"][i];
            data.push({ ...ele, isChecked: false })
          }
          setAttechmentAData(data)
          setSamplingFee(result.data["samplingFee"])
          setExtraLabFee(result.data["extraLabFee"])
          // setInspectors(result.data["data"]);
          setForceUpdate(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    } else {
      showToast("error", "Please Select Client Name")
    }
  }

  useState(() => {
    getAttechmentA();
  }, [])

  const checkedSamples = (id) => {
    var checkedData;
    const updateArray = attechMentAData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: !item.isChecked // Update isChecked or any other property
        };
      }
      return item;
    })

    setAttechmentAData(updateArray);
  }

  const checkedAllSamples = (id) => {
    var checkedData;
    const checked = attechMentAData.every(item => item.isChecked === true);
    const updateArray = attechMentAData.map((item) => {
      if (checked) {
        return {
          ...item,
          isChecked: false // Update isChecked or any other property
        }
      } else if (!checked) {
        return {
          ...item,
          isChecked: true
        }
      }
    })
    setAttechmentAData(updateArray);
  }

  useEffect(() => {
    const filterData = attechMentAData.filter(item => item.isChecked === true);
    filterData.length >= 1 ?
      setTotalCost((samplingFee + extraLabFee) * filterData.length) : setTotalCost(0)
  }, [attechMentAData])

  const SubmitAgreement = () => {
    if (signatureRef.current.isEmpty()) {
      showToast("error", "Signature is empty. Please sign before saving.");
      return; // Prevent submission when the signature is empty
    }
    const approvedSamples = attechMentAData
      .filter(item => item.isChecked === true)
      .map(item => ({
        sampleType: item.sampleType,
        sampleId: item.id,
        samplingAgreementSignature: signatureRef.current.toDataURL("image/png"),
        projectId: projectId // Add your new field and its value here
      }));

    setLoading(true);
    if (projectId) {
      Request.post(`/approveSamples`, { approvedSamples: approvedSamples })
        .then((result) => {
          setLoading(false);
          clearSignature()
          showToast("success", result.data.message)
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        })
    } else {
      showToast("error", "Please Select Client Name")
    }
  }

  const openSignature = () => {
    setIsSignatureVisible(!isSignatureVisible);
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const closeSignature = () => {
    setIsSignatureVisible(false);
  };

  return (
    <>
      <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Attachment A:</h3>
      <table className="table" style={{ marginTop: "15px" }}>
        <tr>
          <th className="th">
            Approve All <br />
            <input type="checkbox" onChange={() => checkedAllSamples()} />
          </th>
          <th className="th">Type</th>
          <th className="th">Room</th>
          <th className="th">Location</th>
          <th className="th">Substrate</th>
          <th className="th">Report Photos (Link)</th>
          <th className="th">Cost Estimate</th>
          <th className="th">Sample Standard Fee (US$)</th>
          <th className="th">Extra Lab Fee (US $)</th>
        </tr>
        {/* <tr> */}
        {attechMentAData.map((data, index) => {
          return (
            <tr>
              <td className="td">
                <input type="checkbox"
                  onChange={() => checkedSamples(data.id, data.sampleFee, data.extraLabFee)} checked={data.isChecked} />
              </td>
              <td className="td">{data.sampleType}</td>
              <td className="td">{data.roomType}</td>
              <td className="td">{data.location}</td>
              <td className="td">{data.sampleSubstrate}</td>
              <td className="td">
                <a
                  href=""
                  style={{
                    textDecoration: "underline",
                    color: "rgba(46, 105, 255, 1)",
                  }}
                >
                  View Photos
                </a>
              </td>
              <td className="td">
                <input type="checkbox" />
              </td>
              <td className="td">{samplingFee}</td>
              <td className="td">{extraLabFee}</td>
            </tr>
          )
        })}
        {/* <td className="td">
            <input type="checkbox" />
          </td>
          <td className="td">Control</td>
          <td className="td">Outdoor control sample</td>
          <td className="td">X</td>
          <td className="td">N/A</td>
          <td className="td">
            <a
              href=""
              style={{
                textDecoration: "underline",
                color: "rgba(46, 105, 255, 1)",
              }}
            >
              View Photos
            </a>
          </td>
          <td className="td">
            <input type="checkbox" />
          </td>
          <td className="td">Pre-populated</td>
        </tr> */}

        {/* <tr>
          <td className="td">
            <input type="checkbox" />
          </td>
          <td className="td">Control</td>
          <td className="td">Outdoor control sample</td>
          <td className="td">Y</td>
          <td className="td">N/A</td>
          <td className="td">
            <a
              href=""
              style={{
                textDecoration: "underline",
                color: "rgba(46, 105, 255, 1)",
              }}
            >
              View Photos
            </a>
          </td>
          <td className="td">
            <input type="checkbox" />
          </td>
          <td className="td">Pre-populated</td>
        </tr> */}
        {/* <tr>
          <td className="td">
            <input type="checkbox" />
          </td>
          <td className="td">Control</td>
          <td className="td">Outdoor control sample</td>
          <td className="td">Z</td>
          <td className="td">N/A</td>
          <td className="td">
            <a
              href=""
              style={{
                textDecoration: "underline",
                color: "rgba(46, 105, 255, 1)",
              }}
            >
              View Photos
            </a>
          </td>
          <td className="td">
            <input type="checkbox" />
          </td>
          <td className="td">Pre-populated</td>
        </tr> */}
      </table>
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: "flex", gap: "15px" }}>
          <Button
            sx={{
              border: "1px solid rgba(46, 105, 255, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "4px",
              padding: "7px 35px !important",
              backgroundColor: "rgba(46, 105, 255, 1)",
              "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              textTransform: "unset",
            }}
            onClick={openSignature}
          >
            Sampling Agreement Signature
          </Button>
          {!validated &&
            (signatureRef.current.isEmpty()) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Signature is required
              </div>
            )}
          {/* <p
            style={{
              color: "rgba(46, 105, 255, 1)",
              borderBottom: "1px solid rgba(46, 105, 255, 1)",
            }}
          >
            Submit Attachment A
          </p> */}
        </Box>
        {isSignatureVisible && (
          <div>
            <div style={{ border: "1px solid #000", width: 400, margin: "5px 0px" }}>
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: "sigCanvas",

                }}
              />
            </div>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(46, 105, 255, 1)",
                marginTop: "3px",
                marginRight: "6px",
                borderRadius: "6px",
                padding: "7px 35px !important",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
                textTransform: "unset",
              }}
              onClick={clearSignature}>Clear</Button>
            <Button
              sx={{
                marginTop: 1,
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                padding: "7px 35px !important",
                backgroundColor: "rgba(46, 105, 255, 1)",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                textTransform: "unset",
              }}
              onClick={SubmitAgreement}
            >
              Submit attechment A</Button>
          </div>
        )}
        <p style={{ color: "rgba(46, 105, 255, 1)", textDecoration: "underline", }}>
          <span style={{ fontWeight: 400, fontSize: "18px" }}>
            Calculate Cost:{" "}
          </span>
          {totalCost > 0 && totalCost}
        </p>
        {/* <p
          style={{
            color: "rgba(46, 105, 255, 1)",
            textDecoration: "underline",
          }}
        >
          Calculate Cost
        </p>
        <span>{totalCost}</span> */}
        <p style={{
          color: "rgba(106, 96, 169, 1)",
          lineHeight: "25.2px",
          marginTop: "25px"
        }}>
          <span style={{ fontWeight: "bold", color: "rgba(106, 96, 169, 1)" }}>Note</span> The sampling agreement signature button should display a
          pop-up window to allow the client to sign and hit a “Done” button.
          The “ Calculate cost” provide the estimated total cost as a
          pop-up.
        </p>
        <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            padding: "7px 35px !important",
            backgroundColor: "rgba(46, 105, 255, 1)",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
            textTransform: "unset",
            mt: 3
          }}
          onClick={() => setRadio("COC")}
        >
          Next
        </Button>
      </Box>
    </>
  );
}

export default AttachmentA;
