import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { faCamera, faL, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import { AppStorage } from "../utils/Context/AppContext";
import Callouts from "./Callouts";
import ReactSelect from 'react-select';


function ExteriorRoom({
  getInspection,
  exteriorData,
  setExteriorData,
  main_index,
  singleExteriorData,
  projectId
}) {
  const AppContext = useContext(AppStorage);
  const roomTypeRef = useRef(null);
  const roomTypeDropdownRef = useRef(null);
  const locationeRef = useRef(null);
  const locationDropdownRef = useRef(null);

  const [forceUpdate, setForceUpdate] = useState(false);
  const [images, setImages] = useState([]);
  const [humidityPhoto, setHumidityPhoto] = useState([]);
  const [isRoomopenField, setRoomOpenField] = useState(false);
  const [isOpenLocationField, setLoactionOpenField] = useState(false);
  const [roomTypeDropDown, setRoomTypeDropDown] = useState([]);
  const [locationDropDown, setLocationDropDown] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isRoomTypeOpen, setRoomTypeOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (roomTypeRef.current && !roomTypeRef.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setRoomOpenField(false);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // console.log("singleExteriorData in useEffect", singleExteriorData);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (locationeRef.current && !locationeRef.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setLoactionOpenField(false);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (roomTypeDropDown.current && !roomTypeDropDown.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setRoomTypeDropDown([]);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (locationDropdownRef.current && !locationDropdownRef.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setLocationDropDown([]);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOpenFieldClick = (event) => {
    // Prevent the event from propagating to the document click listener
    event.stopPropagation();
  };

  const onDrop = (selectedFiles) => {
    const previewData = [...images, ...selectedFiles];
    setImages(previewData);
  };

  useEffect(() => {
    if (images?.length > 0) {
      handlePreliminarySubmitImages(images);
    }
  }, [images]);

  useEffect(() => {
    if (humidityPhoto?.length > 0) {
      handleHumilitySubmitImages(humidityPhoto);
    }
  }, [humidityPhoto]);


  const handlePreliminarySubmitImages = (images) => {

    if (images?.length === 0) {
      return;
    }

    const tempData = new FormData();

    images.forEach((image, index) => {
      tempData.append(`images`, image);
    });

    Request.post("/postImage", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        if (result?.data) {
          const fileNames = result.data.images.map((file) => file.filename);
          let updatedExteriorData = [...exteriorData];
          updatedExteriorData[main_index].preliminaryPhotos = [...updatedExteriorData[main_index].preliminaryPhotos, ...fileNames];
          // updatedExteriorData[main_index].preliminaryPhotos = fileNames; // Set the preliminaryPhotos to the new fileNames
          setExteriorData(updatedExteriorData);
          setImages([])
        }
        // showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };

  const handleHumilitySubmitImages = (images) => {

    if (images?.length === 0) {
      return;
    }

    const tempData = new FormData();

    images.forEach((image, index) => {
      tempData.append(`images`, image);
    });

    Request.post("/postImage", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        if (result?.data) {
          const fileNames = result.data.images.map((file) => file.filename);
          let updatedExteriorData = [...exteriorData];
          updatedExteriorData[main_index].humidityPhoto = fileNames; // Set the preliminaryPhotos to the new fileNames
          setExteriorData(updatedExteriorData);
        }
        // showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };

  const handleRoomSubmit = (dataToSend) => {
    // dataToSend.callout.map((item, index) => {
    //   `callOut_reportPhotos_${index}`:
    // })
    // let key = `callOut_reportPhotos_${main_index}`;
    let tempData = {
      projectId: projectId,
      roomType: dataToSend.roomType.value,
      preliminaryPhotos: dataToSend.preliminaryPhotos.join(","),
      humidityPhotos: dataToSend.humidityPhoto.join(","),
      location: dataToSend.location.value,
      relativeHumidity: dataToSend.relativeHumidity,
      inspectionId: dataToSend.inspectionId,
      // [key]: dataToSend.callout.forEach(callout => {
      //   callout.reportPhotos = callout.reportPhotos;
      // }),
      callOuts: dataToSend.callout.map((calloutItem) => ({
        callOutType: calloutItem.callOutType,
        callOutIdName: calloutItem.callOutId.value,
        callOutDescription: calloutItem.callOutDescription,
        callOutSource: calloutItem.callOutSource,
        reportPhotoDescription: calloutItem.reportPhotoDescription,
        moisture: calloutItem.callOutMoisture,
        callOutLocation: calloutItem.callOutLocation,
        referencePhotos: calloutItem.referencePhotos.join(","), // Join referencePhotos array into a comma-separated string
        reportPhotos: calloutItem.reportPhotos.join(","), // Join reportPhotos array into a comma-separated string
        moisturePhotos: calloutItem.moisturePhoto.join(","), // Join reportPhotos array into a comma-separated string
      })),
    };
    if (projectId) {
      Request.post("/createExterior", tempData)
        // "Content-Type": "multipart/form-data",)
        .then((result) => {
          showToast("success", result?.data?.message);
          setForceUpdate(true);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    } else {
      showToast("error", "Please Select Client Name");
    }
  };

  const removePhoto = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });
  const { getRootProps: getRootGalleryProps, getInputProps: getInputGalleryProps } = useDropzone({
    onDrop: (acceptedFile) => {
      setHumidityPhoto([
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        }),
      ]);
    },
    accept: "image/*",
    multiple: true,
  });

  const handleUpdateRoom = (dataToSend) => {
    console.log("dataToSend", dataToSend);
    let tempData = {
      roomId: dataToSend.roomId,
      projectId: projectId,
      roomType: dataToSend.roomType.value,
      preliminaryPhotos: dataToSend.preliminaryPhotos.join(","),
      humidityPhotos: dataToSend.humidityPhoto.join(","),
      location: dataToSend.location.value,
      relativeHumidity: dataToSend.relativeHumidity,
      inspectionId: dataToSend.inspectionId,
      // [key]: dataToSend.callout.forEach(callout => {
      //   callout.reportPhotos = callout.reportPhotos;
      // }),
      callOuts: dataToSend.callout.map((calloutItem) => ({
        callOutId: calloutItem.id,
        callOutType: calloutItem.callOutType,
        callOutIdName: calloutItem.callOutId.value,
        callOutDescription: calloutItem.callOutDescription,
        callOutSource: calloutItem.callOutSource,
        reportPhotoDescription: calloutItem.reportPhotoDescription,
        moisture: calloutItem.callOutMoisture,
        callOutLocation: calloutItem.callOutLocation,
        referencePhotos: calloutItem.referencePhotos.join(","), // Join referencePhotos array into a comma-separated string
        reportPhotos: calloutItem.reportPhotos.join(","), // Join reportPhotos array into a comma-separated string
        moisturePhotos: calloutItem.moisturePhoto.join(","), // Join reportPhotos array into a comma-separated string
      })),
    };
    console.log("tempData", tempData);

    Request.put("/updateExteriorRoom", tempData)
      // "Content-Type": "multipart/form-data",)
      .then((result) => {
        showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };

  function getRoomType(e) {

    Request.get("/getDropdownValues/roomType")
      .then((result) => {
        var data = [];
        // setInspectors(result.data["data"]);
        // for (let i = 0; i < result.data["values"].length; i++) {
        //   const ele = result.data["values"][i];
        //   if (ele.value.includes(e)) {
        //     data.push(ele);
        //   }
        // }

        var data = result.data["values"].map((e, index) => ({
          value: e.value, // Use clientName as the value
          label: e.value
        }));
        const filterData = data.filter((objA) => !exteriorData.some((objB) => objA.value === objB.roomType.value));
        setRoomTypeDropDown(filterData)
        // if (e) {
        //   setRoomTypeDropDown(result.data["values"].filter((ele) => ele.value.toLowerCase().includes(e.toLowerCase())));
        // } else {
        //   setRoomTypeDropDown([])
        // }
        // if (data.length > 0 && e) {
        //   setMaterialDropdown(data)
        // } else if (data.length > 0 && !e) {
        //   data = [];
        //   setMaterialDropdown([])
        // }
        setForceUpdate(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  useEffect(() => {
    getLocation("t");
    getRoomType("t");
  }, [])

  function getLocation(e) {

    Request.get("/getDropdownValues/location")
      .then((result) => {
        var data = [];
        // setInspectors(result.data["data"]);
        // for (let i = 0; i < result.data["values"].length; i++) {
        //   const ele = result.data["values"][i];
        //   if (ele.value.includes(e)) {
        //     data.push(ele);
        //   }
        // }
        var data = result.data["values"].map((e, index) => ({
          value: e.value, // Use clientName as the value
          label: e.value
        }));
        // const filterData = data.filter((objA) => !exteriorData.some((objB) => objA.value === objB.location.value));
        // for (var i = 0; i < result.data["values"].length; i++) {
        //   const ele = result.data["values"][i];
        //   data.push({ label: ele.value, value: ele.value })
        // }
        setLocationDropDown(data)
        // if (e) {
        //   setLocationDropDown(result.data["values"].filter((ele) => ele.value.toLowerCase().includes(e.toLowerCase())));
        // } else {
        //   setLocationDropDown([])
        // }
        // if (data.length > 0 && e) {
        //   setMaterialDropdown(data)
        // } else if (data.length > 0 && !e) {
        //   data = [];
        //   setMaterialDropdown([])
        // }
        setForceUpdate(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function addRoomType(e, isRemove) {
    // setLoading(true);
    const obj = {
      remove: isRemove,
      dropdownName: "roomType",
      value: e,
      // value: e
    }
    Request.post("/addDropdownValue", obj)
      .then((result) => {
        // setLoading(false);
        setRoomOpenField(false);
        getRoomType()
        //   setExteriorData(
        //     [...exteriorData, exteriorData[main_index].addRoomType: ""])
        //   // setInspectors(result.data["data"]);
        //   // setForceUpdate(false);
      })
      .catch((err) => {
        // setLoading(false);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const handleChange = (e) => {
    let dupArr = [...exteriorData];
    dupArr[main_index].location = e;
    getLocation(e)
    setExteriorData(dupArr);
    setMenuOpen(false);
  }

  function addLocation(e, isRemove) {
    // setLoading(true);
    const obj = {
      remove: isRemove,
      dropdownName: "location",
      value: e
    }
    Request.post("/addDropdownValue", obj)
      .then((result) => {
        // setLoading(false);
        setLoactionOpenField(false);
        getLocation();
        // setExteriorData({ ...singleExteriorData, addLocation: "" })
        // setInspectors(result.data["data"]);
        // setForceUpdate(false);
      })
      .catch((err) => {
        // setLoading(false);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // const [uploadedImages, setUploadedImages] = useState([]);
  const maxImages = 1;
  // const remainingSlots = maxImages - uploadedImages.length;
  const removeImage = (index) => {
    const updatedImages = [...singleExteriorData.preliminaryPhotos];
    singleExteriorData.preliminaryPhotos.splice(index, 1);
    setImages(updatedImages)
    // setHumidityPhoto([]);
  };
  const removeHumalityPhoto = (index) => {
    // const updatedImages = [...uploadedFiles];
    // updatedImages.splice(index, 1);
    const updatedImages = [...singleExteriorData.humidityPhoto];
    singleExteriorData.humidityPhoto.splice(index, 1);
    setHumidityPhoto([]);
  };
  const handleRoomType = (value) => {
    let dupArr = [...exteriorData];
    dupArr[main_index].roomType = value;
    setExteriorData(dupArr);
    setRoomTypeDropDown([])
  }
  const handleLocation = (value) => {
    let dupArr = [...exteriorData];
    dupArr[main_index].location = value;
    setExteriorData(dupArr);
    setLocationDropDown([])
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 60,
      // width: "300px",
      borderRadius: "6px",
      border: state.isFocused
        ? "1px solid rgba(155, 46, 239, 1)"
        : "1px solid rgba(223, 222, 225, 1)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(155, 46, 239, 1)",
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }), // Hide the indicator separator
    dropdownIndicator: () => ({ display: 'none' }), // Hide the dropdown indicator
  };

  return (
    <>
      <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Inspection:</h3>
      <Grid xs={12} md={12} lg={12} sx={{ mb: 3 }}>
        <span
          style={{
            marginTop: "6px",
            fontSize: "15px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          A.1. Exterior Room
        </span>
        <FormControl sx={{ mt: 1, width: "90%", position: "relative" }}>
          <ReactSelect
            // arrowRenderer={null}
            value={singleExteriorData?.roomType}
            onChange={(e) => {
              let dupArr = [...exteriorData];
              dupArr[main_index].roomType = e;
              getRoomType(e)
              setRoomTypeOpen(false)
              setExteriorData(dupArr);
            }}
            options={roomTypeDropDown}
            onInputChange={(inputValue, { action }) => {
              if (action === 'input-change') {
                // Set menuIsOpen to true when the user starts typing
                setRoomTypeOpen(true); // Assuming you have a state variable to manage menuIsOpen
              }
            }}
            onBlur={() => setRoomTypeOpen(false)}
            isSearchable={true}
            styles={customStyles}
            placeholder="e.g. Garage"
            menuIsOpen={isRoomTypeOpen}
          />
        </FormControl>
        <p
          ref={roomTypeRef}
          style={{
            marginTop: "6px",
            fontSize: "12px",
            color: "rgba(155, 46, 239, 1)",
            cursor: "pointer"
            // width:"90%"
          }}
          onClick={() => setRoomOpenField(!isRoomopenField)}
        >
          + Room Type:
        </p>
      </Grid>
      {isRoomopenField &&
        <Grid
          xs={12}
          md={12}
          sx={{ display: "flex", gap: "15px", mb: 3 }}

          onClick={handleOpenFieldClick}
        >
          {/* <FormControl sx={{ mt: 1, width: "100%" }}> */}
          <Grid xs={12} md={12} lg={8.5} style={{ width: "100%" }}>
            <TextField
              placeholder="Add Room Type"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={singleExteriorData.addRoomType}
              onChange={(e) => {
                let dupArr = [...exteriorData];
                dupArr[main_index].addRoomType = e.target.value;
                setExteriorData(dupArr);
              }}
            />
          </Grid>
          <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                width: "100%",
                padding: "9px 0px",
                backgroundColor: "rgba(46, 105, 255, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              }}
              onClick={() => addRoomType(singleExteriorData.addRoomType)}
            >
              Add
            </Button>
          </Grid>
          <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                width: "100%",
                padding: "9px 0px",
                backgroundColor: "rgba(46, 105, 255, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              }}
              onClick={() => addRoomType(singleExteriorData.addRoomType, true)}
            >
              Remove
            </Button>
          </Grid>
        </Grid>}
      <div>
        <div
          {...getRootProps()}
          className="coverPhoto"
          style={{ marginBottom: "15px" }}
        >
          <div className="coverCamera">
            <input {...getInputProps()} />
            <FontAwesomeIcon
              icon={faCamera}
              style={{ color: "rgba(0, 0, 0, 1)" }}
            />
          </div>
        </div>
        {singleExteriorData?.preliminaryPhotos?.length > 0 && (
          <div>
            <div
              style={{
                display: "flex",
                margin: "10px",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              {singleExteriorData.preliminaryPhotos.map((photo, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      padding: "2px",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        borderRadius: "12px",
                        color: "white",
                        backgroundColor: "red",
                        border: "red",
                        top: "-8px",
                        right: "-9px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "13px",
                      }}
                      onClick={() => removeImage(index)}
                    >
                      X
                    </button>
                  </div>
                  {typeof photo === "string" ?
                    <img
                      key={index}
                      src={`https://amoldi.com/image-storage/${photo}`}
                      alt={`Preview ${index}`}
                      className="image-preview"
                    /> :
                    <img
                      key={index}
                      src={URL.createObjectURL(photo)}
                      alt={`Preview ${index}`}
                      className="image-preview"
                    />}
                </div>
              ))}
            </div>
          </div>
        )}
        <p
          style={{
            marginTop: "6px",
            fontSize: "15px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Preliminary Photos (*) :
        </p>
      </div>
      {/* <div>
        <div
          {...getRootProps()}
          className="coverPhoto"
          style={{ marginBottom: "15px" }}
        >
          <div className="coverCamera">
            <input {...getInputProps()} />
            <FontAwesomeIcon
              icon={faCamera}
              style={{ color: "rgba(0, 0, 0, 1)" }}
            />
          </div>
        </div>

        {images?.length > 0 && (
          <div>
            <div
              style={{
                display: "flex",
                margin: "10px",
                flexWrap: "wrap",
                gap: "8px",
              }}
            >
              {images.map((photo, index) => (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      padding: "2px",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        borderRadius: "12px",
                        color: "white",
                        backgroundColor: "red",
                        border: "red",
                        top: "-8px",
                        right: "-9px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "13px",
                      }}
                      onClick={() => removeImage(index)}
                    >
                      X
                    </button>
                  </div>
                  {typeof photo === "string" ?
                    <img
                      key={index}
                      src={photo}
                      alt={`Preview ${index}`}
                      className="image-preview"
                    /> :
                    <img
                      key={index}
                      src={URL.createObjectURL(photo)}
                      alt={`Preview ${index}`}
                      className="image-preview"
                    />}
                </div>
              ))}
            </div>
          </div>
        )}
        <p
          style={{
            marginTop: "6px",
            fontSize: "15px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Preliminary Photos (*) :
        </p>
      </div> */}
      <div>
        {singleExteriorData?.humidityPhoto?.length === 0 ? <div
          {...getRootGalleryProps()}
          className="coverPhoto"
          style={{ marginBottom: "15px" }}
        >
          <div className="coverCamera">
            <input {...getInputGalleryProps()} />
            <FontAwesomeIcon
              icon={faCamera}
              style={{ color: "rgba(0, 0, 0, 1)" }}
            />
          </div>
        </div> :
          singleExteriorData?.humidityPhoto?.map((image, index) => (
            <div
              key={index}
              className="coverPhoto"
              style={{
                marginBottom: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <button
                onClick={() => removeHumalityPhoto(index)}
                style={{
                  alignSelf: "flex-end",
                  borderRadius: "12px",
                  backgroundColor: "red",
                  border: "red",
                  fontSize: "13px",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
              </button>
              {typeof image === "string" ?
                <img src={`https://amoldi.com/image-storage/${image}`} /> :
                <img src={URL.createObjectURL(image)} />
              }
            </div>))}
        <p
          style={{
            marginTop: "6px",
            fontSize: "15px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Humidity Photo :
        </p>
      </div>

      <Grid item xs={12} md={12} lg={12} container >
        <Grid xs={12} md={12} lg={6}>
          <FormControl sx={{ width: "95%", }}>
            <ReactSelect
              // arrowRenderer={null}
              value={singleExteriorData?.location}
              onChange={handleChange}
              options={locationDropDown}
              onInputChange={(inputValue, { action }) => {
                if (action === 'input-change') {
                  // Set menuIsOpen to true when the user starts typing
                  setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
                }
              }}
              onBlur={() => setMenuOpen(false)}
              isSearchable={true}
              styles={customStyles}
              placeholder="North"
              menuIsOpen={isMenuOpen}
            />
          </FormControl>


          <p
            ref={locationeRef}
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              cursor: "pointer"
              // width:"90%"
            }}
            onClick={() => setLoactionOpenField(!isOpenLocationField)}
          >
            + Location:
          </p>
        </Grid>
        {isOpenLocationField &&
          <Grid
            xs={12}
            md={12}
            sx={{ display: "flex", gap: "15px", mb: 1, }}
            onClick={handleOpenFieldClick}
          >
            {/* <FormControl sx={{ mt: 1, width: "100%" }}> */}
            <Grid xs={12} md={12} lg={6}>
              <TextField
                placeholder="Add Location"
                id="outlined-start-adornment"
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                value={singleExteriorData.addLocation}
                onChange={(e) => {
                  let dupArr = [...exteriorData];
                  dupArr[main_index].addLocation = e.target.value;
                  setExteriorData(dupArr);
                }}
              />
            </Grid>
            <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
              <Button
                sx={{
                  border: "1px solid rgba(46, 105, 255, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "9px 0px",
                  backgroundColor: "rgba(46, 105, 255, 1)",
                  textTransform: "unset",
                  fontWeight: "unset",
                  "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                }}
                onClick={() => addLocation(singleExteriorData.addLocation)}
              >
                Add
              </Button>
            </Grid>
            <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
              <Button
                sx={{
                  border: "1px solid rgba(46, 105, 255, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "4px",
                  width: "100%",
                  padding: "9px 0px",
                  backgroundColor: "rgba(46, 105, 255, 1)",
                  textTransform: "unset",
                  fontWeight: "unset",
                  "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                }}
                onClick={() => addLocation(singleExteriorData.addLocation, true)}
              >
                Remove
              </Button>
            </Grid>
            {/* </FormControl> */}
            {/* <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
              onClick={() => setOpenField(true)}
            >
              + Material
            </p> */}
          </Grid>}
        <Grid xs={12} md={12} lg={6} >
          <TextField
            // placeholder="Relative Humidity"
            id="outlined-start-adornment"
            sx={{
              width: "95%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            value={singleExteriorData?.relativeHumidity}
            onChange={(e) => {
              let dupArr = [...exteriorData];
              dupArr[main_index].relativeHumidity = e.target.value;
              setExteriorData(dupArr);
            }}
          />
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              // width:"90%"
            }}
          >
            Relative Humidity:
          </p>
        </Grid>
      </Grid>
      {!!singleExteriorData?.callout?.length &&
        singleExteriorData?.callout?.map((singleCallout, i) => (
          <Callouts
            main_index={main_index}
            key={i}
            index={i}
            exteriorData={exteriorData}
            setExteriorData={setExteriorData}
            singleCallout={singleCallout}
          />
        ))}
      <Box sx={{ mt: 3, display: "flex", gap: "15px" }}>
        <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            padding: "7px 35px !important",
            backgroundColor: "rgba(46, 105, 255, 1)",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
            textTransform: "unset",
          }}
          disabled={singleExteriorData?.roomId ? true : false}
          onClick={() => handleRoomSubmit(singleExteriorData)}
        >
          Save
        </Button>
        <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(46, 105, 255, 1)",
            borderRadius: "4px",
            padding: "7px 35px !important",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
            textTransform: "unset",
          }}
          onClick={() => handleUpdateRoom(singleExteriorData)}
          disabled={singleExteriorData?.roomId ? false : true}
        >
          Edit
        </Button>
      </Box>
      <p
        style={{ color: "rgba(106, 96, 169, 1)" }}
        onClick={() => {
          const dupObj = { ...singleExteriorData };

          const dupArr = [...exteriorData];
          dupObj.callout.push({
            callOutType: "",
            callOutIdName: "",
            callOutId: "",
            callOutLocation: "",
            callOutDescription: "",
            callOutSource: "",
            referencePhotos: [],
            reportPhotos: [],
            reportDescription: "",
            callOutMoisture: "",
          });
          // dupArr[main_index] = dupObj;
          setExteriorData(dupArr);
        }}
      >
        (+) Add Callouts
      </p>
    </>
  );
}

export default ExteriorRoom;
