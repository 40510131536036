import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { faCamera, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";

function CalloutMoisture({ main_index, index, exteriorData, setExteriorData, singleCallout }) {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);

    const maxImages = 1;
    const onDrop = (acceptedFiles) => {
        // Check if the total number of images doesn't exceed the maximum
        if (uploadedFiles.length + acceptedFiles.length <= maxImages) {
            // Handle the uploaded files here
            setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
        } else {
            alert("You can only upload a maximum of 10 images.");
        }
    };
    // upload image

    useEffect(() => {
        if (uploadedFiles.length > 0) {
            handleSubmitImages(uploadedFiles);
        }
    }, [uploadedFiles]);

    // useEffect(() => {
    //     singleCallout.callOutMoisture ? setUploadedFiles(singleCallout.callOutMoisture) : setUploadedFiles([]);
    // },[])
    const handleSubmitImages = (uploadedFiles) => {
        console.log("images before:", uploadedFiles);

        if (uploadedFiles.length === 0) {
            return;
        }

        const tempData = new FormData();

        uploadedFiles.forEach((image, index) => {
            tempData.append(`images`, image);
        });

        Request.post("/postImage", tempData, {
            "Content-Type": "multipart/form-data",
        })
            .then((result) => {
                // console.log("Data: ", result.data.images);
                if (result?.data) {
                    const fileNames = result.data.images.map((file) => file.filename);
                    let updatedExteriorData = [...exteriorData];
                    updatedExteriorData[main_index].callout[index].moisturePhoto =
                        [...updatedExteriorData[main_index].callout[index].moisturePhoto, ...fileNames]; // Set the preliminaryPhotos to the new fileNames
                    setExteriorData(updatedExteriorData);
                    setUploadedFiles([])
                }
                // showToast("success", result?.data?.message);
                setForceUpdate(true);
            })
            .catch((err) => {
                // console.log("Error: ", err);
                if (err.response.status === 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    };
    // end upload
    const removeImage = (index) => {
        const updatedImages = [...singleCallout.moisturePhoto];
        singleCallout.moisturePhoto.splice(index, 1);
        setUploadedFiles(updatedImages);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*",
        multiple: true, // Accept only image files
    });
    const remainingSlots = maxImages - singleCallout.moisturePhoto.length;
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                }}
            >
                {singleCallout.moisturePhoto.map((image, index) => (
                    <div
                        key={index}
                        className="coverPhoto"
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <button
                            onClick={() => removeImage(index)}
                            style={{
                                alignSelf: "flex-end",
                                borderRadius: "12px",
                                backgroundColor: "red",
                                border: "red",
                                fontSize: "13px",
                                cursor: "pointer",
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
                        </button>
                        {typeof image === "string" ?
                            <img src={`https://amoldi.com/image-storage/${image}`} alt={`Image ${index + 1}`} /> :
                            <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} />}
                    </div>
                ))}
                {remainingSlots > 0 && (
                    <div
                        className="coverPhoto"
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div className="coverCamera">
                            <FontAwesomeIcon
                                icon={faCamera}
                                style={{ color: "rgba(0, 0, 0, 1)" }}
                            />
                        </div>
                        {/* {remainingSlots > 1 ? `Add ${remainingSlots} More` : `Add 1 More`} */}
                    </div>
                )}
            </Box>
            <p
                style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                }}
            >
                Moisture Photo  (*) :
            </p>
        </>
    );
}

export default CalloutMoisture;
