import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import ReactSelect from 'react-select';
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faL, faTimes } from "@fortawesome/free-solid-svg-icons";

function Samples({ main_index, index, interiorData, setInteriorData, singleSample, sampleIndex }) {
  const SubstratRef = useRef(null);
  const sampleTypeDropdownRef = useRef(null);

  const [isSubstratField, setSubstratField] = useState(false);
  const [substrateDropdown, setSubstrateDropdown] = useState([]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [sampleType, setSampleType] = useState([
    {
      value: "Air sample", label: "Air sample"
    },
    { value: "Surface sample", label: "Surface sample" },
    {
      value: "Cavity sample", label: "Cavity sample"
    }
  ])

  const removeType = () => {
    const interiorValues = interiorData.flatMap(
      (item) =>
        item.callout.flatMap((calloutItem) =>
          calloutItem.samples.map((sample) => sample.value)
        )
    );
    const filteredData = sampleType.filter((item) => !interiorValues.includes(item.value));
    const filterData = sampleType.filter((objA) => !interiorData.some((objB) =>
      objB.callout.some((calloutItem) =>
        calloutItem.samples.some((sample) =>
          sample.sampleType?.value === objA.value
        )
      )
    ));
    // const filterData = sampleType.filter((objA) => !interiorData.some((objB) => objB.callout[calloutIndex].samples[index].sampleType?.value === objA.value));
    setSampleType(filterData)
  }

  useEffect(() => {
    removeType()
  }, [])

  useEffect(() => {
    singleSample.samplePhoto ? setImage(singleSample.samplePhoto) : setImage([])
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (SubstratRef.current && !SubstratRef.current.contains(event.target)) {
        // Clicked outside the wrapper, hide the field
        setSubstratField(false);
      }
    };

    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getSubstrate = (e) => {

    Request.get("/getDropdownValues/substrate")
      .then((result) => {
        var data = result.data["values"].map((e, index) => ({
          value: e.value, // Use clientName as the value
          label: e.value
        }));
        setSubstrateDropdown(data)
        // if (e) {
        //   setSampleTypeDropdown(result.data["values"].filter((ele) => ele.value.toLowerCase().includes(e.toLowerCase())));
        // } else {
        //   setSampleTypeDropdown([])
        // }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const addSampleType = (e, isRemove) => {
    const obj = {
      remove: isRemove,
      dropdownName: "substrate",
      value: e
    }
    Request.post("/addDropdownValue", obj)
      .then((result) => {
        setSubstratField(false);
        getSubstrate()
        // setExteriorData({ ...exteriorData, callOutIdName: "" })
        // setInspectors(result.data["data"]);
        // setForceUpdate(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  useEffect(() => {
    getSubstrate();
  }, [])

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 60,
      // width: "300px",
      borderRadius: "6px",
      border: state.isFocused
        ? "1px solid rgba(155, 46, 239, 1)"
        : "1px solid rgba(223, 222, 225, 1)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(155, 46, 239, 1)",
      },
    }),
    // indicatorSeparator: () => ({ display: 'none' }), // Hide the indicator separator
    // dropdownIndicator: () => ({ display: 'none' }), // Hide the dropdown indicator
  };


  const handleSubstrateOpenFieldClick = (event) => {
    // Prevent the event from propagating to the document click listener
    event.stopPropagation();
  };

  const handleSampleType = (value) => {
    let dupArr = [...interiorData];
    dupArr[main_index].samples[index].sampleType = value;
    setInteriorData(dupArr);
    setSubstrateDropdown([])
  }
  const { getRootProps: getRootGalleryProps, getInputProps: getInputGalleryProps } = useDropzone({
    onDrop: (acceptedFile) => {
      setImage([
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        }),
      ]);
    },
    accept: "image/*",
    multiple: true,
  });

  const removeImage = (index) => {
    // const updatedImages = [...uploadedFiles];
    // updatedImages.splice(index, 1);
    setImage([]);
  };

  const handleSubmitImages = (images) => {

    if (images?.length === 0) {
      return;
    }

    const tempData = new FormData();

    images.forEach((image, index) => {
      tempData.append(`images`, image);
    });

    Request.post("/postImage", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        if (result?.data) {
          const fileNames = result.data.images.map((file) => file.filename);
          let updatedExteriorData = [...interiorData];
          updatedExteriorData[main_index].callout[index].samples[sampleIndex].samplePhoto = fileNames; // Set the preliminaryPhotos to the new fileNames
          setInteriorData(updatedExteriorData);
          setImage([]);
        }
        // showToast("success", result?.data?.message);
        // setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };


  useEffect(() => {
    if (image?.length > 0) {
      handleSubmitImages(image);
    }
  }, [image]);

  return (
    <Grid item xs={12} md={12} lg={12} container sx={{ mt: 3 }}>
      <Grid xs={12} md={12} lg={12}>
        <p
          style={{
            marginTop: "6px",
            fontSize: "15px",
            color: "rgba(155, 46, 239, 1)",
            fontWeight: "bold",
          }}
        >
          Sample:
        </p>
      </Grid>
      <Grid xs={12} md={12} lg={6}>
        <FormControl sx={{ width: "95%" }}>
          {/* <Select
            displayEmpty
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              color: "rgba(155, 46, 239, 1)",
            }}
            value={singleSample?.sampleType}
            onChange={(e) => {
              const dupObj = [...interiorData];
              dupObj[main_index].callout[calloutIndex].samples[index].sampleType = e.target.value;
              setInteriorData(dupObj);
              removeType();
            }}
          >
            {sampleType.map((type, index) => {
              return (
                <MenuItem value={type.value}>{type.value}</MenuItem>
              )
            })}
            {/* <MenuItem value={"Air sample"}>Air sample</MenuItem>
            <MenuItem value={"Surface sample"}>Surface sample</MenuItem>
            <MenuItem value={"Cavity sample"}>Cavity sample</MenuItem> */}
          {/* </Select> */}
          <ReactSelect
            // arrowRenderer={null}
            value={singleSample?.sampleType}
            onChange={(e) => {
              let dupObj = [...interiorData];
              dupObj[main_index].callout[index].samples[sampleIndex].sampleType = e;
              setInteriorData(dupObj);
              // removeType();
            }}
            options={sampleType}
            // onInputChange={(inputValue, { action }) => {
            //   if (action === 'input-change') {
            //     // Set menuIsOpen to true when the user starts typing
            //     setRoomTypeOpen(true); // Assuming you have a state variable to manage menuIsOpen
            //   }
            // }}
            // onBlur={() => setRoomTypeOpen(false)}
            isSearchable={false}
            styles={customStyles}
            placeholder="Room Type"
          // menuIsOpen={isRoomTypeOpen}
          />
        </FormControl>
        <p
          style={{
            marginTop: "6px",
            fontSize: "12px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Sample Types (*) :
        </p>
      </Grid>
      {/* <Grid xs={12} md={12} lg={6}>
        <FormControl sx={{ width: "90%" }}>
          <ReactSelect
            // arrowRenderer={null}
            value={singleSample?.sampleType}
            onChange={(e) => {
              const dupArr = [...interiorData];
              dupArr[main_index].samples[index].sampleType = e;
              getSampleType(e)
              setMenuOpen(false);
              setInteriorData(dupArr);
            }}
            options={sampleTypeDropdown}
            onInputChange={(inputValue, { action }) => {
              if (action === 'input-change') {
                // Set menuIsOpen to true when the user starts typing
                setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
              }
            }}
            onBlur={() => setMenuOpen(false)}
            isSearchable={true}
            styles={customStyles}
            placeholder="Room Type"
            menuIsOpen={isMenuOpen}
          />
        </FormControl>
        <p
          ref={sampleTypeRef}
          style={{
            marginTop: "6px",
            fontSize: "12px",
            color: "rgba(155, 46, 239, 1)",
          }}
          onClick={() => setSampleTypeField(!isSampleTypeField)}
        >
          + Samples Type:
        </p>
      </Grid>
      {
        isSampleTypeField &&
        <Grid
          xs={12}
          md={12}
          sx={{ display: "flex", gap: "15px", marginBottom: 2 }}
          onClick={handleSampleOpenFieldClick}
        >
          <Grid xs={12} md={12} lg={6} style={{ width: "100%" }}>
            <TextField
              placeholder="Add Sample Type"
              id="outlined-start-adornment"
              sx={{
                width: "90%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={singleSample.sampleTypeName}
              onChange={(e) => {
                let dupObj = [...interiorData];
                dupObj[main_index].samples[index].sampleTypeName = e.target.value;
                setInteriorData(dupObj);
              }}
            />
          </Grid>
          <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                width: "100%",
                padding: "9px 0px",
                backgroundColor: "rgba(46, 105, 255, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              }}
              onClick={() => addSampleType(singleSample.sampleTypeName)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      } */}
      <Grid xs={12} md={12} lg={6}>
        <TextField
          placeholder="e.g. Northeast"
          id="outlined-start-adornment"
          sx={{
            width: "95%",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "12px",
            "& .MuiInputBase-formControl": {
              borderRadius: "10px",
              color: "rgba(155, 46, 239, 1) !important",
            },
            "& .MuiInputBase-formControl ::placeholder": {
              color: "rgba(180, 177, 185, 1) !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(223, 222, 225, 1) !important",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(155, 46, 239, 1) !important",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(155, 46, 239, 1) !important",
            },
          }}
          value={singleSample?.location}
          onChange={(e) => {
            const dupArr = [...interiorData];
            dupArr[main_index].callout[index].samples[sampleIndex].location = e.target.value;
            setInteriorData(dupArr);
          }}

        />
        <p
          style={{
            marginTop: "6px",
            fontSize: "12px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Location:
        </p>
      </Grid>
      <Grid xs={12} md={12} lg={6}>
        <FormControl sx={{ width: "95%" }}>
          <Select
            displayEmpty
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              color: "rgba(155, 46, 239, 1)",
            }}
            value={singleSample?.source}
            onChange={(e) => {
              const dupArr = [...interiorData];
              dupArr[main_index].callout[index].samples[sampleIndex].source = e.target.value;
              setInteriorData(dupArr);
            }}
          >
            <MenuItem value={"Plumbing Leak"}>Plumbing Leak</MenuItem>
            <MenuItem value={"Plumbing Leak"}>Plumbing Leak</MenuItem>
          </Select>
        </FormControl>
        <p
          style={{
            marginTop: "6px",
            fontSize: "12px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Source (*) :
        </p>
      </Grid>
      <Grid xs={12} md={12} lg={6}>
        <FormControl sx={{ width: "95%" }}>
          {/* <Select
            displayEmpty
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              color: "rgba(155, 46, 239, 1)",
            }}
            disabled={singleSample.sampleType?.value === "Surface sample"}
            value={singleSample?.substrate}
            onChange={(e) => {
              const dupArr = [...interiorData];
              dupArr[main_index].callout[calloutIndex].samples[index].substrate = e.target.value;
              setInteriorData(dupArr);
            }}
          >
            <MenuItem value={"Miscellaneous"}>Miscellaneous</MenuItem>
            <MenuItem value={"Miscellaneous"}>Miscellaneous</MenuItem>
          </Select> */}
          <ReactSelect
            // arrowRenderer={null}
            value={singleSample?.substrate}
            onChange={(e) => {
              const dupArr = [...interiorData];
              dupArr[main_index].callout[index].samples[sampleIndex].substrate = e;
              getSubstrate(e)
              setMenuOpen(false)
              setInteriorData(dupArr);
            }}
            options={substrateDropdown}
            onInputChange={(inputValue, { action }) => {
              if (action === 'input-change') {
                // Set menuIsOpen to true when the user starts typing
                setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
              }
            }}
            onBlur={() => setMenuOpen(false)}
            isSearchable={true}
            styles={customStyles}
            placeholder="Miscellaneous"
            menuIsOpen={isMenuOpen}
          />
        </FormControl>
        <p
          ref={SubstratRef}
          style={{
            marginTop: "6px",
            fontSize: "12px",
            color: "rgba(155, 46, 239, 1)",
          }}
          onClick={() => setSubstratField(!isSubstratField)}
        >
          + Substrate (*) :
        </p>
      </Grid>
      {isSubstratField &&
        <Grid
          xs={12}
          md={12}
          sx={{ display: "flex", gap: "15px", mb: 1, }}
          onClick={handleSubstrateOpenFieldClick}
        >
          {/* <FormControl sx={{ mt: 1, width: "100%" }}> */}
          <Grid xs={12} md={12} lg={6}>
            <TextField
              placeholder="Add Substrate"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={singleSample.subStrateName}
              onChange={(e) => {
                let dupArr = [...interiorData];
                dupArr[main_index].callout[index].samples[sampleIndex].subStrateName = e.target.value;
                setInteriorData(dupArr);
              }}
            />
          </Grid>
          <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                width: "100%",
                padding: "9px 0px",
                backgroundColor: "rgba(46, 105, 255, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              }}
              onClick={() => addSampleType(singleSample.subStrateName)}
            >
              Add
            </Button>
          </Grid>
          <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
            <Button
              sx={{
                border: "1px solid rgba(46, 105, 255, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                width: "100%",
                padding: "9px 0px",
                backgroundColor: "rgba(46, 105, 255, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              }}
              onClick={() => addSampleType(singleSample.subStrateName, true)}
            >
              Remove
            </Button>
          </Grid>
        </Grid>}
      <Grid xs={12} md={12} lg={12}>
        <div>
          {singleSample.samplePhoto.length === 0 ? <div
            {...getRootGalleryProps()}
            className="coverPhoto"
            style={{ marginBottom: "15px" }}
          >
            <div className="coverCamera">
              <input {...getInputGalleryProps()} />
              <FontAwesomeIcon
                icon={faCamera}
                style={{ color: "rgba(0, 0, 0, 1)" }}
              />
            </div>
          </div> :
            singleSample.samplePhoto.map((image, index) => (
              <div
                key={index}
                className="coverPhoto"
                style={{
                  marginBottom: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  onClick={() => removeImage(index)}
                  style={{
                    alignSelf: "flex-end",
                    borderRadius: "12px",
                    backgroundColor: "red",
                    border: "red",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
                </button>
                {typeof image === "string" ?
                  <img src={`https://amoldi.com/image-storage/${image}`} alt={`Image ${index + 1}`} /> :
                  <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} />}
              </div>))}
          <p
            style={{
              marginTop: "6px",
              fontSize: "15px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Sample Photo :
          </p>
        </div>
      </Grid>
    </Grid >
  );
}

export default Samples;
