import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { faCamera, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import CalloutReport from "./CalloutReport";
import ReactSelect from 'react-select';
import CalloutMoisture from "./CalloutMoisture";
import Samples from "./Samples";

function Callouts({
    main_index,
    index,
    exteriorData,
    setExteriorData,
    singleCallout,
    projectId,
    type,
    calloutIndex
}) {

    const calloutIdRef = useRef(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isCalloutIdField, setCalloutIdField] = useState(false);
    const [calloutIdDropDown, setCalloutIdDropDown] = useState([])
    const maxImages = 10; // Maximum number of allowed images
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calloutIdRef.current && !calloutIdRef.current.contains(event.target)) {
                // Clicked outside the wrapper, hide the field
                setCalloutIdField(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const onDrop = (acceptedFiles) => {
        // Check if the total number of images doesn't exceed the maximum
        if (uploadedImages.length + acceptedFiles.length <= maxImages) {
            // Handle the uploaded files here
            setUploadedImages([...uploadedImages, ...acceptedFiles]);
        } else {
            alert("You can only upload a maximum of 10 images.");
        }
    };

    const handleChange = (e) => {
        let dupArr = [...exteriorData];
        dupArr[main_index].callOutId = e;
        getCalloutId(e)
        setExteriorData(dupArr);
        setMenuOpen(false);
    }

    // upload image
    useEffect(() => {
        if (uploadedImages.length > 0) {
            handleSubmitImages(uploadedImages);
        }
    }, [uploadedImages]);


    const handleSubmitImages = (uploadedImages) => {

        if (uploadedImages.length === 0) {
            return;
        }

        const tempData = new FormData();

        uploadedImages.forEach((image, index) => {
            tempData.append(`images`, image);
        });

        Request.post("/postImage", tempData, {
            "Content-Type": "multipart/form-data",
        })
            .then((result) => {
                if (result?.data) {
                    const fileNames = result.data.images.map((file) => file.filename);
                    let updatedExteriorData = [...exteriorData];
                    updatedExteriorData[main_index].callout[index].referencePhotos =
                        [...updatedExteriorData[main_index].callout[index].referencePhotos, ...fileNames]; // Set the preliminaryPhotos to the new fileNames
                    setExteriorData(updatedExteriorData);
                    setUploadedImages([])
                }

                setForceUpdate(true);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    };
    // end upload
    const removeImage = (index) => {
        const updatedImages = [...singleCallout.referencePhotos];
        singleCallout.referencePhotos.splice(index, 1);
        setUploadedImages(updatedImages);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*",
        multiple: true,
    });

    function getCalloutId(e) {

        Request.get("/getDropdownValues/callOutIdName")
            .then((result) => {
                var data = [];
                // setInspectors(result.data["data"]);
                // for (let i = 0; i < result.data["values"].length; i++) {
                //   const ele = result.data["values"][i];
                //   if (ele.value.includes(e)) {
                //     data.push(ele);
                //   }
                // }

                var data = result.data["values"].map((e, index) => ({
                    value: e.value, // Use clientName as the value
                    label: e.value
                }));
                const filterData = data.filter((objA) => !exteriorData.some((objB) => objA.value === objB.roomType.value));
                setCalloutIdDropDown(filterData)
                // if (e) {
                //   setRoomTypeDropDown(result.data["values"].filter((ele) => ele.value.toLowerCase().includes(e.toLowerCase())));
                // } else {
                //   setRoomTypeDropDown([])
                // }
                // if (data.length > 0 && e) {
                //   setMaterialDropdown(data)
                // } else if (data.length > 0 && !e) {
                //   data = [];
                //   setMaterialDropdown([])
                // }
                setForceUpdate(false);
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    }

    useEffect(() => {
        getCalloutId("e)")
    }, [])
    function addCalloutId(e, isRemove) {
        // setLoading(true);
        const obj = {
            remove: isRemove,
            dropdownName: "callOutIdName",
            value: e,
            // value: e
        }
        Request.post("/addDropdownValue", obj)
            .then((result) => {
                // setLoading(false);
                setCalloutIdField(false);
                getCalloutId()
                //   setExteriorData(
                //     [...exteriorData, exteriorData[main_index].addRoomType: ""])
                //   // setInspectors(result.data["data"]);
                //   // setForceUpdate(false);
            })
            .catch((err) => {
                // setLoading(false);
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 60,
            // width: "300px",
            borderRadius: "6px",
            border: state.isFocused
                ? "1px solid rgba(155, 46, 239, 1)"
                : "1px solid rgba(223, 222, 225, 1)",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid rgba(155, 46, 239, 1)",
            },
        }),
        indicatorSeparator: () => ({ display: 'none' }), // Hide the indicator separator
        dropdownIndicator: () => ({ display: 'none' }), // Hide the dropdown indicator
    };

    const handleOpenFieldClick = (event) => {
        // Prevent the event from propagating to the document click listener
        event.stopPropagation();
    };
    const remainingSlots = maxImages - singleCallout.referencePhotos.length;
    return (
        <>
            <Grid item xs={12} md={12} lg={12} container sx={{ mt: 3 }}>
                <Grid xs={12} md={12} lg={12}>
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "15px",
                            color: "rgba(155, 46, 239, 1)",
                            fontWeight: "bold",
                        }}
                    >
                        Callouts
                    </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <FormControl sx={{ width: "95%" }}>
                        <Select
                            displayEmpty
                            sx={{
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderRadius: "12px",
                                "& .MuiInputBase-formControl": {
                                    borderRadius: "10px",
                                    color: "rgba(155, 46, 239, 1) !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                },
                                color: "rgba(155, 46, 239, 1)",
                            }}
                            value={singleCallout?.callOutType}
                            onChange={(e) => {
                                const dupObj = [...exteriorData];
                                dupObj[main_index].callout[index].callOutType = e.target.value;
                                setExteriorData(dupObj);
                            }}
                        >
                            <MenuItem value={"Preventive Maintence"}>Preventing Maintence</MenuItem>
                            <MenuItem value={"Microbial Sampling"}>Microbial Sampling</MenuItem>
                        </Select>
                    </FormControl>
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                        }}
                    >
                        Callout Types (*) :
                    </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <FormControl sx={{ width: "95%" }}>
                        <ReactSelect
                            // arrowRenderer={null}
                            value={singleCallout?.callOutId}
                            onChange={(e) => {
                                let dupArr = [...exteriorData];
                                dupArr[main_index].callout[index].callOutId = e;
                                getCalloutId(e)
                                setMenuOpen(false)
                                setExteriorData(dupArr);
                            }}
                            options={calloutIdDropDown}
                            onInputChange={(inputValue, { action }) => {
                                if (action === 'input-change') {
                                    // Set menuIsOpen to true when the user starts typing
                                    setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
                                }
                            }}
                            onBlur={() => setMenuOpen(false)}
                            isSearchable={true}
                            styles={customStyles}
                            placeholder="E17- Damaged windows"
                            menuIsOpen={isMenuOpen}
                        />
                    </FormControl>
                    <p
                        ref={calloutIdRef}
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                        }}
                        onClick={() => setCalloutIdField(!isCalloutIdField)}
                    >
                        + Callout IDs and/or Names (*) :
                    </p>
                </Grid>
                {isCalloutIdField &&
                    <Grid
                        xs={12}
                        md={12}
                        sx={{ display: "flex", gap: "15px", mb: 1, }}
                        onClick={handleOpenFieldClick}
                    >
                        {/* <FormControl sx={{ mt: 1, width: "100%" }}> */}
                        <Grid xs={12} md={12} lg={6} width="100%">
                            <TextField
                                placeholder="Add Callout IDs and/or Names"
                                id="outlined-start-adornment"
                                sx={{
                                    width: "100%",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    borderRadius: "12px",
                                    "& .MuiInputBase-formControl": {
                                        borderRadius: "10px",
                                        color: "rgba(155, 46, 239, 1) !important",
                                    },
                                    "& .MuiInputBase-formControl ::placeholder": {
                                        color: "rgba(180, 177, 185, 1) !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                    },
                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                    },
                                }}
                                value={singleCallout.callOutIdName}
                                onChange={(e) => {
                                    let dupArr = [...exteriorData];
                                    dupArr[main_index].callout[index].callOutIdName = e.target.value;
                                    setExteriorData(dupArr);
                                }}
                            />
                        </Grid>
                        <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                            <Button
                                sx={{
                                    border: "1px solid rgba(46, 105, 255, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    borderRadius: "4px",
                                    width: "100%",
                                    padding: "9px 0px",
                                    backgroundColor: "rgba(46, 105, 255, 1)",
                                    textTransform: "unset",
                                    fontWeight: "unset",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                                }}
                                onClick={() => addCalloutId(singleCallout.callOutIdName)}
                            >
                                Add
                            </Button>
                        </Grid>
                        <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }} >
                            <Button
                                sx={{
                                    border: "1px solid rgba(46, 105, 255, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    borderRadius: "4px",
                                    width: "90%",
                                    padding: "9px 0px",
                                    backgroundColor: "rgba(46, 105, 255, 1)",
                                    textTransform: "unset",
                                    fontWeight: "unset",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                                }}
                                onClick={() => addCalloutId(singleCallout.callOutIdName, true)}
                            >
                                Remove
                            </Button>
                        </Grid>
                    </Grid>}
                <Grid xs={12} md={12} lg={6}>
                    <TextField
                        placeholder="Location"
                        id="outlined-start-adornment"
                        sx={{
                            width: "95%",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderRadius: "12px",
                            "& .MuiInputBase-formControl": {
                                borderRadius: "10px",
                                color: "rgba(155, 46, 239, 1) !important",
                            },
                            "& .MuiInputBase-formControl ::placeholder": {
                                color: "rgba(180, 177, 185, 1) !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(223, 222, 225, 1) !important",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(155, 46, 239, 1) !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(155, 46, 239, 1) !important",
                            },
                        }}
                        value={singleCallout.callOutLocation}
                        onChange={(e) => {
                            let dupArr = [...exteriorData];
                            dupArr[main_index].callout[index].callOutLocation = e.target.value;
                            setExteriorData(dupArr);
                        }}
                    />
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                        }}
                    >
                        Location:
                    </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <TextField
                        placeholder="Callout Description"
                        id="outlined-start-adornment"
                        sx={{
                            width: "95%",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderRadius: "12px",
                            "& .MuiInputBase-formControl": {
                                borderRadius: "10px",
                                color: "rgba(155, 46, 239, 1) !important",
                            },
                            "& .MuiInputBase-formControl ::placeholder": {
                                color: "rgba(180, 177, 185, 1) !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(223, 222, 225, 1) !important",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(155, 46, 239, 1) !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(155, 46, 239, 1) !important",
                            },
                        }}
                        value={singleCallout.callOutDescription}
                        onChange={(e) => {
                            let dupArr = [...exteriorData];
                            dupArr[main_index].callout[index].callOutDescription = e.target.value;
                            setExteriorData(dupArr);
                        }}
                    />
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                        }}
                    >
                        Callout Description:
                    </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <TextField
                        placeholder="Callout Source"
                        id="outlined-start-adornment"
                        sx={{
                            width: "95%",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderRadius: "12px",
                            "& .MuiInputBase-formControl": {
                                borderRadius: "10px",
                                color: "rgba(155, 46, 239, 1) !important",
                            },
                            "& .MuiInputBase-formControl ::placeholder": {
                                color: "rgba(180, 177, 185, 1) !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(223, 222, 225, 1) !important",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(155, 46, 239, 1) !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(155, 46, 239, 1) !important",
                            },
                        }}
                        value={singleCallout.callOutSource}
                        onChange={(e) => {
                            let dupArr = [...exteriorData];
                            dupArr[main_index].callout[index].callOutSource = e.target.value;
                            setExteriorData(dupArr);
                        }}
                    />
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                        }}
                    >
                        Callout Source:
                    </p>
                </Grid>
            </Grid>
            <CalloutReport
                main_index={main_index}
                index={index}
                exteriorData={exteriorData}
                setExteriorData={setExteriorData}
                singleCallout={singleCallout}
            />
            <Grid xs={12} md={12} lg={6}>
                <TextField
                    placeholder="Report Photo Description"
                    id="outlined-start-adornment"
                    sx={{
                        width: "95%",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderRadius: "12px",
                        "& .MuiInputBase-formControl": {
                            borderRadius: "10px",
                            color: "rgba(155, 46, 239, 1) !important",
                        },
                        "& .MuiInputBase-formControl ::placeholder": {
                            color: "rgba(180, 177, 185, 1) !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(223, 222, 225, 1) !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                    }}
                    value={singleCallout.reportPhotoDescription}
                    onChange={(e) => {
                        let dupArr = [...exteriorData];
                        dupArr[main_index].callout[index].reportPhotoDescription = e.target.value;
                        setExteriorData(dupArr);
                    }}
                />
                <p
                    style={{
                        marginTop: "6px",
                        fontSize: "12px",
                        color: "rgba(155, 46, 239, 1)",
                    }}
                >
                    Report Photos Description:
                </p>
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    gap: "15px",
                    width: "100%",
                    flexWrap: "wrap",
                    mt: 3,
                }}
            >
                {singleCallout.referencePhotos.map((image, index) => (
                    <div
                        key={index}
                        className="coverPhoto"
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <button
                            onClick={() => removeImage(index)}
                            style={{
                                alignSelf: "flex-end",
                                borderRadius: "12px",
                                backgroundColor: "red",
                                border: "red",
                                fontSize: "13px",
                                cursor: "pointer",
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} style={{ color: "white" }} />
                        </button>
                        {typeof image === "string" ?
                            <img src={`https://amoldi.com/image-storage/${image}`} alt={`Image ${index + 1}`} /> :
                            <img src={URL.createObjectURL(image)} alt={`Image ${index + 1}`} />}
                    </div>
                ))}
                {remainingSlots > 0 && (
                    <div
                        className="coverPhoto"
                        style={{
                            marginBottom: "15px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        <div className="coverCamera">
                            <FontAwesomeIcon
                                icon={faPlus}
                                style={{ color: "rgba(0, 0, 0, 1)" }}
                            />
                        </div>
                        {remainingSlots === 10
                            ? `Upto ${remainingSlots} photos`
                            : `Add ${remainingSlots} More`}
                    </div>
                )}
            </Box>
            <p
                style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                }}
            >
                Reference Photos (Up to 10 photos):
            </p>
            <Grid xs={12} md={12} lg={6}>
                <TextField
                    placeholder="Moisture"
                    id="outlined-start-adornment"
                    sx={{
                        width: "95%",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderRadius: "12px",
                        "& .MuiInputBase-formControl": {
                            borderRadius: "10px",
                            color: "rgba(155, 46, 239, 1) !important",
                        },
                        "& .MuiInputBase-formControl ::placeholder": {
                            color: "rgba(180, 177, 185, 1) !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(223, 222, 225, 1) !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                    }}
                    value={singleCallout.moisture}
                    onChange={(e) => {
                        let dupArr = [...exteriorData];
                        dupArr[main_index].callout[index].moisture = e.target.value;
                        setExteriorData(dupArr);
                    }}
                />
                <p
                    style={{
                        marginTop: "6px",
                        fontSize: "12px",
                        color: "rgba(155, 46, 239, 1)",
                    }}
                >
                    Moisture:
                </p>
            </Grid>
            <CalloutMoisture
                main_index={main_index}
                index={index}
                exteriorData={exteriorData}
                setExteriorData={setExteriorData}
                singleCallout={singleCallout}
            />

            {type === 2 && exteriorData[main_index].callout[index].callOutType !== "Preventive Maintence" &&
                singleCallout?.samples?.map((callout, i) => (
                    <Samples
                        main_index={main_index}
                        key={i}
                        index={index}
                        sampleIndex={i}
                        interiorData={exteriorData}
                        setInteriorData={setExteriorData}
                        singleSample={callout}

                    />
                ))
            }
            {
                type === 2 && exteriorData[main_index].callout[index].callOutType !== "Preventive Maintence" && <p style={{ color: "rgba(106, 96, 169, 1)" }} onClick={() => {
                    const dupObj = { ...singleCallout };
                    const dupArr = [...exteriorData];
                    dupObj.samples.push({
                        sampleType: "",
                        sampleTypeName: "",
                        location: "",
                        source: "",
                        substrate: "",
                        subStrateName: "",
                        samplePhoto: []
                    },);
                    // dupArr[main_index] = dupObj;
                    setExteriorData(dupArr);
                }}>(+) Add Next Samples</p >
            }
        </>
    );
}

export default Callouts;
