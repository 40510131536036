import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, TextField } from "@mui/material";
import wordBlue from "../images/wordBlue.svg";
import wordRed from "../images/wordRed.svg";
import RectangleBlue from "../images/RectangleBlue.png";
import RectangleRed from "../images/RectangleRed.png";
function ClientManagement() {
  return (
    <div style={{ padding: "10px" }}>
      <h3>Search client file by name:</h3>
      <Grid item xs={12} md={6} container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12} md={8} container spacing={2}>
          <Grid item xs={12} md={9} container>
            <TextField
              placeholder="Name"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Client Name:
            </span>
          </Grid>
          <Grid item xs={12} md={3} container>
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                maxHeight: "55px",
                minWidth: "84px",
                backgroundColor: "rgba(44, 181, 44, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              }}
            >
              Go
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <h3>Complete Orders</h3>
      <Grid item xs={12} md={7} container>
        <table className="table">
          <tr>
            <th className="th">File Name</th>
            <th className="th">Status</th>
            <th className="th">Document</th>
          </tr>
          <tr>
            <td className="td">Report</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Attachment A</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Agreement</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Project Data</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Photos</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Receipt</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">COC</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Post-Photos</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>

          <tr>
            <td className="td">Lab Results</td>
            <td className="td">Uploaded</td>
            <td className="td">
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(0, 0, 0, 1)",
                  borderRadius: "2px",
                  padding: "unset",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingRight: "18px",
                  paddingLeft: "18px",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                }}
              >
                Generate
              </Button>
            </td>
          </tr>
        </table>
      </Grid>
      <Grid item xs={12} md={12} container sx={{ mt: 4 }}>
        <span style={{ width: "100%", fontWeight: "400", fontSize:"20px" }}>
          Client’s Folder:
        </span>
      </Grid>
      <Grid item xs={12} md={7} container spacing={4} sx={{ mt: 0.5 }}>
        <Grid item xs={12} md={4} container>
          <div
            style={{
              padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleBlue})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 40, 104, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordBlue} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ width: "100%", marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:"100%"
                }}
              >
                Report (Word document)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(0, 40, 104, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleRed})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(191, 10, 48, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordRed} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:'100% !important',
                }}
              >
               Attachment A (PDF)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(191, 10, 48, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
                display: "flex",
                width:"100%",
                flexDirection: "column",
                alignItems: "center",
                background:`url(${RectangleBlue})`,
                backgroundRepeat:"no-repeat",
                backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 40, 104, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordBlue} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:"100%"
                }}
              >
                Agreement (PDF)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(0, 40, 104, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={4} container>
          <div
            style={{
              padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleBlue})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 40, 104, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordBlue} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:"100%",
                  textAlign:"center"
                }}
              >
                Project Data (PDF)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(0, 40, 104, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleRed})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(191, 10, 48, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordRed} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:'100% !important',
                }}
              >
              Photos (JPG)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(191, 10, 48, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
                display: "flex",
                width:"100%",
                flexDirection: "column",
                alignItems: "center",
                background:`url(${RectangleBlue})`,
                backgroundRepeat:"no-repeat",
                backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 40, 104, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordBlue} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:"100%"
                }}
              >
                Receipt (PDF)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(0, 40, 104, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
              padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleBlue})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 40, 104, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordBlue} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  color: "rgba(180, 177, 185, 1)",
                  width:"100%"
                }}
              >
                COC (PDF)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(0, 40, 104, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleRed})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(191, 10, 48, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordRed} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:'100% !important',
                }}
              >
               Post-Photos (JPG)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(191, 10, 48, 1)",
                  textTransform:"unset"
                }}
              >
                Generated
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
                display: "flex",
                width:"100%",
                flexDirection: "column",
                alignItems: "center",
                background:`url(${RectangleBlue})`,
                backgroundRepeat:"no-repeat",
                backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0, 40, 104, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordBlue} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <span
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  width: "max-content",
                  color: "rgba(180, 177, 185, 1)",
                  width:"100%"
                }}
              >
                Lab Results (PDF)
              </span>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(0, 40, 104, 1)",
                  textTransform:"unset"
                }}
              >
                Attached
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} container>
          <div
            style={{
                padding: "22px",
              display: "flex",
              width:"100%",
              flexDirection: "column",
              alignItems: "center",
              background:`url(${RectangleRed})`,
              backgroundRepeat:"no-repeat",
              backgroundSize:"100% 90%"
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(191, 10, 48, 0.1)",
                display: "flex",
                padding: "10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <img src={wordRed} style={{ width: "16px" }} alt="" />
            </div>
            <div style={{ marginTop:"5px" }}>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  padding: "unset",
                  color: "rgba(191, 10, 48, 1)",
                  textTransform:"unset"
                }}
              >
                Attached Files
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5} container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={4} container>
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "10px",
                padding: "12px!important",
                backgroundColor:"rgba(44, 181, 44, 1)",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
                textTransform:"unset",
                width:'100%'
              }}
            >
              Upload
            </Button>
        </Grid>

        <Grid item xs={12} md={4} container>
        <Button
              sx={{
                border: "1px solid rgba(155, 46, 239, 1)",
                color: "rgba(155, 46, 239, 1)",
                borderRadius: "10px",
                padding: "12px!important",
                "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                textTransform:"unset",
                width:'100%'
              }}
            >
              Archive
            </Button>
        </Grid>

        <Grid item xs={12} md={4} container>
        <Button
              sx={{
                border: "1px solid rgba(0, 0, 0, 1)",
                color: "rgba(0, 0, 0, 1)",
                borderRadius: "10px",
                padding: "12px 40px !important",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                textTransform:"unset",
                width:'100%'
              }}
            >
              Attach
            </Button>
        </Grid>

      </Grid>
    </div>
  );
}

export default ClientManagement;
