import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import { AppStorage } from "../utils/Context/AppContext";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import moment from "moment";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeletePrice from "../modals/DeletePrice";
import { useLocation } from "react-router-dom";
import CalendarManagement from "./CalendarManagement";
import PresetAppointment from "../modals/PresetAppointment";

function Accordion({ items }) {
  const [openIndex, setOpenIndex] = useState(null);
  const [zipCode, setZipCode] = useState("");
  const [itemId, setItemId] = useState(null);

  const toggleAccordion = (Id, index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
      setItemId(Id);
      setZipCode(items[index]?.zipCode || "");
    }
  };
  const handleSubmit = () => {
    let tempData = {
      zipCode: zipCode,
    };
    Request.put(`/updateWorkZone/${itemId}`, tempData)
      .then((result) => {
        showToast("success", "Work Zone Updated.");
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err.response.data.error);
        }
      });
  };
  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>
          <div
            onClick={() => toggleAccordion(item?.id, index)}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              gap: "5px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginRight: "10px",
                color: `${openIndex === index
                  ? "rgba(155, 46, 239, 1)"
                  : "rgba(0, 40, 104, 1)"
                  }`,
              }}
            >
              {openIndex === index ? "-" : "+"}
            </div>
            <div
              style={{
                color: `${openIndex === index
                  ? "rgba(155, 46, 239, 1)"
                  : "rgba(0, 40, 104, 1)"
                  }`,
              }}
            >
              {item?.city}
            </div>
          </div>
          {openIndex === index && (
            <div style={{ marginLeft: "20px" }}>
              <p
                style={{
                  color: "rgba(155, 46, 239, 1)",
                  fontSize: "13px",
                  fontWeight: "300",
                  lineHeight: "24px",
                }}
              >
                &#x2022; {`${"Job occurrences:"} ${item.jobOccurrences} (by default). The number increases
                with inspectors’ work zone preferences as set under “User
                management”`}.
              </p>
              <p
                style={{
                  color: "rgba(155, 46, 239, 1)",
                  fontSize: "13px",
                  fontWeight: "300",
                  lineHeight: "24px",
                }}
              >
                &#x2022; {`Job opportunities: ${item.jobOpportunities} (by default). The number increases
                (decreases) by an increment of 2 each time a new inspector is
                added to (removed from) the system under “User management”)`}.
              </p>
              <div>
                <TextField
                  placeholder="e.g 77002"
                  id="outlined-start-adornment"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  sx={{
                    width: "70%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FontAwesomeIcon
                          onClick={handleSubmit}
                          style={{
                            color: "rgba(44, 181, 44, 1)",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          icon={faSave}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <span
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                  width: "100%",
                }}
              >
                &#x2022; Zip Code
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
function PricingMatrix() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const elem = document.getElementById(location.hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Adjust the delay as needed
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location.pathname, location.hash]);

  const initialStateDiscount = {
    id: null,
    feeType: "",
    discount: "",
    adjustmentType: "",
    adjustmentDescription: "",
    offerType: ""
  };
  const initialStatePrice = {
    workZoneId: null,
    service_type: "",
    property_size: null,
    roomCount: "",
    fee_type: "",
    discount: "",
    adjustment_type: "",
    price: "",
    building_info: ""
  };

  const initialStateZone = {
    city: "",
    zipCode: "",
  };
  const AppContext = useContext(AppStorage);
  const [discountData, setDiscountData] = useState(initialStateDiscount);
  // console.log("discountDatadiscountData", discountData);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointmentsDropdown, setAppointmentsDropdown] = useState([]);
  const [validated, setValidated] = useState(true);
  const [validatedPrice, setValidatedPrice] = useState(true);
  const [validatedZone, setValidatedZone] = useState(true);
  const [validatedWorkZone, setValidatedWorkZone] = useState(true);
  const [priceData, setPriceData] = useState(initialStatePrice);
  // console.log("initialStatePrice", priceData);
  const [workZoneData, setWorkZoneData] = useState(initialStateZone);
  const [addWorkZone, setAddWorkZone] = useState(false);
  const [getWorkZone, setGetWorkZone] = useState([]);
  const [getBlockedDates, setGetBlockedDates] = useState([]);
  const [blockedDate, setBlockedDate] = useState([]);
  const [getReserveDates, setGetReserveDates] = useState([]);
  const [reserveDate, setReserveDate] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [weeks, setWeeks] = useState(generateWeeks(currentMonth));
  const [select930All, setSelect930All] = useState(false);
  const [select200All, setSelect200All] = useState(false);
  const [calendarDates, setCalendarDates] = useState([]); // this is blocked dates [] to submit data
  const [unblockDates, setUnblockDates] = useState([]); // this is unblocked dates [] to submit data
  const [getPrices, setGetPrices] = useState([]);
  const [getEnableWeekend, setGetEnableWeekend] = useState(null);
  const [checked, setChecked] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [delPrice, setDelPrice] = useState("");
  const [openDelModal, setOpenDelModal] = useState(false);
  const [reserveDates, setReserveDates] = useState([]);
  const [unReserveDates, setUnReserveDates] = useState([]);
  const [allSelectedDates, setAllSelectedDates] = useState([]);
  const [paginationTable2, setPaginationTable2] = useState({
    current: 1,
    pageSize: 7,
  });
  const [workZoneId, setWorkZoneId] = useState("");
  const [unavailableDates, setUnavailableDates] = useState([]);
  console.log("weeksweeks", weeks);
  // console.log("reserveDates", reserveDates);
  console.log("getBlockedDates", getBlockedDates);
  // console.log("calendarDates", calendarDates);
  const [openAppointments, setOpenAppointments] = useState(false);
  // console.log("workZoneId", workZoneId);
  // console.log("getReserveDates", getReserveDates);
  const handleEditClick = (priceData) => {
    setEditRowId(priceData.id);
    console.log("priceData", priceData);
    const dupObj = { ...priceData };
    dupObj.workZoneId = priceData?.work_zonesId || null;
    dupObj.service_type = priceData?.service_type || "";
    dupObj.property_size = priceData?.property_size || null;
    dupObj.fee_type = priceData?.fee_type || "";
    dupObj.discount = priceData?.offerType || "";
    dupObj.adjustment_type = priceData?.adjustment_type || "";
    dupObj.price = priceData?.price || "";
    dupObj.roomCount = priceData?.room_count || "";
    dupObj.building_info = priceData?.building_type || "";
    setPriceData(dupObj);
  };

  function generateWeeks(selectedMonth) {
    const weeks = [];
    // Calculate the start date of the calendar (first Sunday of the current month)
    const startDate = selectedMonth.clone().startOf("month").startOf("week");

    // Calculate the last day of the current month
    const lastDayOfMonth = selectedMonth.clone().endOf("month");

    // Calculate the end date of the calendar (four weeks later or until the end of the month)
    const endDate = lastDayOfMonth.clone().endOf("week");

    // Generate the weeks for the calendar
    let currentDate = startDate.clone();
    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        week.push(
          {
            date: currentDate.clone(),
            isChecked: false, // Initialize isChecked to false
            is930Checked: false, // Initialize is930Checked to false
            is200Checked: false,
            isRemove930Checked: false, // Initialize is
            isRemove200Checked: false, // Initialize isRemove
            isRemoveChecked: false, // Initialize
          }
        );
        currentDate.add(1, "day");
      }
      weeks.push(week);
    }
    return weeks;
  }

  const isWeekend = (dateStr) => {
    const dateObj = new Date(dateStr);
    const dayOfWeek = dateObj.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 is Sunday, 6 is Saturday
  };


  function handleSelectAll(time, type) {
    console.log("type", type);
    if (time === "9:30 AM") {
      setSelect930All(!select930All);
    } else {
      setSelect200All(!select200All);
    }

    const updatedWeeks = weeks.map((week) =>
      week.map((date) => (
        console.log("weekDate", date),
        {
          ...date,
          isChecked: date.is930Checked && date.is200Checked ? true : false,
          // is930Checked: time === "9:30 AM" ? true : false,
          is930Checked: date.is930Checked && time === "9:30 PM" ? false : !date.is930Checked && time === "9:30 AM" ? true : date.is930Checked && time === "2:00 PM" && true,
          is200Checked: date.is200Checked && time === "2:00 PM" ? false : !date.is200Checked && time === "2:00 PM" ? true : date.is200Checked && time === "9:30 AM" && true
          // is200Checked: time === "2:00 PM" ? true : false
        }))
    );
    // const updatedWeeks = weeks
    //   .map((week) =>
    //     week
    //       .filter((date) => !isWeekend(date.date))
    //       .map((date) => ({
    //         ...date,
    //         isChecked: date.is930Checked && date.is200Checked ? true : false,
    //         is930Checked:
    //           date.is930Checked && time === "9:30 PM"
    //             ? false
    //             : !date.is930Checked && time === "9:30 AM"
    //               ? true
    //               : date.is930Checked && time === "2:00 PM" && true,
    //         is200Checked:
    //           date.is200Checked && time === "2:00 PM"
    //             ? false
    //             : !date.is200Checked && time === "2:00 PM"
    //               ? true
    //               : date.is200Checked && time === "9:30 AM" && true,
    //       }))
    //   )
    //   .filter((week) => week.length > 0);
    // console.log("filteredWeeks", updatedWeeks);
    // const filteredArray = updatedWeeks.filter(item => item !== false);
    setWeeks(updatedWeeks);
    const updatedCheckboxData = [...calendarDates];
    if (!select930All || !select200All) {
      // When selecting all, add all checkboxes to updatedCheckboxData
      weeks.forEach((week) => {
        week.forEach((date) => {
          if (time === "9:30 AM") {
            updatedCheckboxData.push({
              blocked_dates: date.date.format("MM/DD/YYYY"),
              blocked_time: "9:30 AM",
              workZoneId: workZoneId
            });
          }
          if (time === "2:00 PM") {
            updatedCheckboxData.push({
              blocked_dates: date.date.format("MM/DD/YYYY"),
              blocked_time: "2:00 PM",
              workZoneId: workZoneId
            });
          }
        });
      });
    } else {
      updatedCheckboxData.length = 0;
    }

    const checkArray = getBlockedDates.every((date, index) => date.blocked_dates === updatedCheckboxData[index]?.blocked_dates)
    console.log("checkArray", checkArray);
    console.log(
      "setWeek", updatedCheckboxData, getBlockedDates
    );
    const blockDates = handleSelectAllBlockedDates(updatedCheckboxData);
    const reserveDates = handleSelectAllReservedDates(updatedCheckboxData)
    console.log("blockDatesblockDates", blockDates, reserveDates);
    const resultBlockedtArray = blockDates.map(commonElement => {
      const matchingBlockedDate = getBlockedDates.find(item =>
        item.blocked_dates === commonElement.blocked_dates && commonElement.blocked_time === item.blocked_time && item.is_blocked === true
      );
      return {
        blocked_date: commonElement.blocked_dates,
        blocked_time: commonElement.blocked_time,
        id: matchingBlockedDate.id
      };
    });
    const removeUnavailableDate = updatedCheckboxData.filter(date =>
      !unavailableDates.some(item => item.date === date.blocked_dates && item.time === date.blocked_time)
    )
    // const filteredDates = removeUnavailableDate.filter(entry => !isWeekend(entry.blocked_dates));
    // console.log("removeUnavailableDate", filteredDates);
    const resultReserveArray = reserveDates.map(commonElement => {
      const matchingBlockedDate = getReserveDates.find(item =>
        item.blocked_dates === commonElement.blocked_dates && commonElement.blocked_time === item.blocked_time && item.is_Reserved
      );
      return {
        blocked_date: commonElement.blocked_dates,
        blocked_time: commonElement.blocked_time,
        id: matchingBlockedDate.id
      };
    });
    console.log("removeUnavailableDate", removeUnavailableDate, getEnableWeekend);
    console.log("resultReserveArray", resultReserveArray);
    // setAllSelectedDates(updatedCheckboxData);
    if (getEnableWeekend) {
      setCalendarDates(removeUnavailableDate);
      setUnblockDates(resultBlockedtArray);
      setUnReserveDates(resultReserveArray);
      setReserveDates(removeUnavailableDate);
    } else {
      setCalendarDates(removeUnavailableDate.filter(entry => !isWeekend(entry.blocked_dates)));
      setUnblockDates(resultBlockedtArray.filter(entry => !isWeekend(entry.blocked_dates)));
      setUnReserveDates(resultReserveArray.filter(entry => !isWeekend(entry.blocked_dates)));
      setReserveDates(removeUnavailableDate.filter(entry => !isWeekend(entry.blocked_dates)));
    }
  }

  function handleSelectAllBlockedDates(updatedCheckboxData) {
    return updatedCheckboxData.filter(date =>
      getBlockedDates.some(blockedDate => date.blocked_dates === blockedDate.blocked_dates && date.blocked_time === blockedDate.blocked_time)
    );
  }
  function handleSelectAllReservedDates(updatedCheckboxData) {
    return updatedCheckboxData.filter(date =>
      getReserveDates.some(reserveDate => date.blocked_dates === reserveDate.blocked_dates && date.blocked_time === reserveDate.blocked_time)
    );
  }

  // Function to handle a single row checkbox
  function handleRowCheckbox(weekIndex, time) {
    // console.log("weekIndex", weekIndex);
    const updatedWeeks = [...weeks];
    updatedWeeks[weekIndex] = updatedWeeks[weekIndex].map((date) => (
      console.log("week date", date, time),
      {
        ...date,
        isChecked: date.is930Checked && date.is200Checked ? true : false,
        // is930Checked: time === "9:30 AM" ? true : false,
        is930Checked: date.is930Checked && time === "9:30 PM" ? false : !date.is930Checked && time === "9:30 AM" ? true : date.is930Checked && time === "2:00 PM" && true,
        is200Checked: date.is200Checked && time === "2:00 PM" ? false : !date.is200Checked && time === "2:00 PM" ? true : date.is200Checked && time === "9:30 AM" && true
        // is200Checked: time === "2:00 PM" ? true : false
      }));
    setWeeks(updatedWeeks);

    const updatedCheckboxData = [];

    // Iterate through all weeks and add selected checkboxes to updatedCheckboxData
    updatedWeeks.forEach((week) => {
      week.forEach((date) => {
        if (date.is930Checked) {
          updatedCheckboxData.push({
            blocked_dates: date.date.format("MM/DD/YYYY"),
            blocked_time: "9:30 AM",
            workZoneId: workZoneId
          });
        }
        if (date.is200Checked) {
          updatedCheckboxData.push({
            blocked_dates: date.date.format("MM/DD/YYYY"),
            blocked_time: "2:00 PM",
            workZoneId: workZoneId
          });
        }
      });
    });
    const blockDates = handleSelectAllBlockedDates(updatedCheckboxData);
    const reserveDates = handleSelectAllReservedDates(updatedCheckboxData)
    console.log("blockDatesblockDates", blockDates, reserveDates);
    const resultBlockedtArray = blockDates.map(commonElement => {
      const matchingBlockedDate = getBlockedDates.find(item =>
        item.blocked_dates === commonElement.blocked_dates && commonElement.blocked_time === item.blocked_time && item.is_blocked === true
      );
      return {
        blocked_date: commonElement.blocked_dates,
        blocked_time: commonElement.blocked_time,
        id: matchingBlockedDate.id
      };
    });
    const removeUnavailableDate = updatedCheckboxData.filter(date =>
      !unavailableDates.some(item => item.date === date.blocked_dates && item.time === date.blocked_time)
    )
    console.log("removeUnavailableDate", removeUnavailableDate);
    const resultReserveArray = reserveDates.map(commonElement => {
      const matchingBlockedDate = getReserveDates.find(item =>
        item.blocked_dates === commonElement.blocked_dates && commonElement.blocked_time === item.blocked_time && item.is_Reserved
      );
      return {
        blocked_date: commonElement.blocked_dates,
        blocked_time: commonElement.blocked_time,
        id: matchingBlockedDate.id
      };
    });
    console.log("resulBlockedtArray", resultBlockedtArray);
    console.log("resultReserveArray", resultReserveArray);
    // setAllSelectedDates(updatedCheckboxData);
    if (getEnableWeekend) {
      setCalendarDates(removeUnavailableDate);
      setUnblockDates(resultBlockedtArray);
      setUnReserveDates(resultReserveArray);
      setReserveDates(removeUnavailableDate);
    } else {
      setCalendarDates(removeUnavailableDate.filter(entry => !isWeekend(entry.blocked_dates)));
      setUnblockDates(resultBlockedtArray.filter(entry => !isWeekend(entry.blocked_dates)));
      setUnReserveDates(resultReserveArray.filter(entry => !isWeekend(entry.blocked_dates)));
      setReserveDates(removeUnavailableDate.filter(entry => !isWeekend(entry.blocked_dates)));
    }
    // setCalendarDates(removeUnavailableDate);
    // setUnblockDates(resultBlockedtArray);
    // setUnReserveDates(resultReserveArray);
    // setReserveDates(removeUnavailableDate);
    // setCalendarDates(updatedCheckboxData);
  }

  // Define the days of the week
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  function handleNextMonthClick() {
    const nextMonth = currentMonth.clone().add(1, "month");
    // const nextMonth = currentMonth.clone().add(-1, "month");
    const nextMonthWeeks = generateWeeks(nextMonth);
    setCurrentMonth(nextMonth);
    setWeeks(nextMonthWeeks);
    setSelect930All(false);
    setSelect200All(false);
    setCalendarDates([]);
  }
  function handlePreviousMonthClick() {
    // const nextMonth = currentMonth.clone().add(1, "month");
    const nextMonth = currentMonth.clone().add(-1, "month");
    const nextMonthWeeks = generateWeeks(nextMonth);
    setCurrentMonth(nextMonth);
    setWeeks(nextMonthWeeks);
    setSelect930All(false);
    setSelect200All(false);
    setCalendarDates([]);
  }
  function handleNextMonthClickSecondInstance() {
    const nextMonth = currentMonth.clone().add(1, "month");

    const nextMonthWeeks = generateWeeks(nextMonth);
    setCurrentMonth(nextMonth);
    setWeeks(nextMonthWeeks);
    setSelect930All(false);
    setSelect200All(false);
    setCalendarDates([]);
  }
  // get unblock dates
  // console.log("unBlockedDates", unblockDates);
  // console.log("getBlockedDates", getBlockedDates);
  // console.log("getReserveDates", getReserveDates);
  // console.log("unReserveDates", unReserveDates);


  function handleUnblock(dateString, time) {
    // console.log("unblock", dateString, time);
    // Find the index of the blocked date and time in getBlockedDates
    const indexToRemove = getBlockedDates.findIndex(
      (item) => item.blocked_dates === dateString && item.blocked_time === time
    );
    // console.log("unBlockedDate", indexToRemove);
    if (indexToRemove !== -1) {
      // Remove the blocked date and time from getBlockedDates
      const unblockedDate = getBlockedDates.splice(indexToRemove, 1)[0];
      // console.log("first", unblockedDate);

      // Add the unblocked date and time to unblockDates
      setUnblockDates((prevUnblockDates) => [
        ...prevUnblockDates,
        unblockedDate,
      ]);

      // Update the state with the modified getBlockedDates
      setGetBlockedDates([...getBlockedDates]);
    }
  }

  // get UnReserve Date
  function handleUnReserve(dateString, time) {
    console.log("dateString", dateString, time);
    // Find the index of the blocked date and time in getBlockedDates
    const indexToRemove = getReserveDates.findIndex(
      (item) => {
        // console.log("item", item);
        return (
          item.blocked_dates === dateString && item.blocked_time === time
        )
      }
    );
    // const removeId = getReserveDates.filter(
    //   (item) =>
    //     item.blocked_dates === dateString && item.blocked_time === time
    // )
    // console.log("indexToRemove", removeId);
    // var removeItem = []
    // for (let i = 0; i < removeId.length; i++) {
    //   const el = removeId[i];
    //   // removeItem.push({ "id": el.id })
    //   removeItem = [...removeItem, { id: el.id }];
    // }
    // console.log("removeItem", removeItem);
    if (indexToRemove !== -1) {
      // Remove the blocked date and time from getBlockedDates
      const unReserveDate = getReserveDates.splice(indexToRemove, 1)[0];
      // console.log("first", unblockedDate);
      setUnReserveDates((prevUnReserveDate) => [
        ...prevUnReserveDate,
        unReserveDate,
      ])
      // Add the unblocked date and time to unblockDates
      // setUnReserveDates((prevUnblockDates) => removeItem);

      // Update the state with the modified getBlockedDates
      setGetReserveDates([...getReserveDates]);
    }
  }
  const toggleAddWorkZone = () => {
    setAddWorkZone(!addWorkZone); // Invert the current state value
  };
  useEffect(() => {
    if (forceUpdate) {
      getAppointmentsDropdown();
      getAllWorkZones();
      // getAllBlockedDates();
      // getAllReserveDates();
      getAllPrices();
      // getEnableWeekends();
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getAppointmentsDropdown();
      getAllWorkZones();
      // getAllBlockedDates();
      // getAllReserveDates();
      getAllPrices();
      // getEnableWeekends();
    }
  }, [AppContext?.userAuthToken]);

  function getAppointmentsDropdown() {
    setLoading(true);

    Request.get("/appointmentsDropdown")
      .then((result) => {
        setLoading(false);
        // console.log("Appointments Dropdown res", result.data["data"]);
        setAppointmentsDropdown(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // get enable weekends
  function getEnableWeekends(workZoneID) {
    setLoading(true);
    if (validateWorkZone()) {
      Request.get(`/EnableWeekends/${workZoneID}`)
        .then((result) => {
          setLoading(false);
          console.log("EnableWeekends result", result);
          setGetEnableWeekend(result.data.data.weekendData);
          setChecked(result.data.data.weekendData);
          setForceUpdate(false);
        })
        .catch((err) => {
          setLoading(false);
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  }
  // get All prices
  function getAllPrices() {
    setLoading(true);

    Request.get("/getPrices")
      .then((result) => {
        setLoading(false);
        // console.log("ddddeeder", result.data["data"]);
        setGetPrices(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // validation for discount
  const validateDiscountData = () => {
    if (
      discountData.feeType === "" ||
      discountData.discount === null ||
      discountData.adjustmentType === "" ||
      discountData.adjustmentDescription === ""
    ) {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };
  // validation for price
  const validatePriceData = () => {
    if (
      priceData.workZoneId === null ||
      priceData.service_type === "" ||
      (priceData.service_type === "Other-Protocol" &&
        priceData.roomCount === "") ||
      (priceData.service_type === "Full inspection" &&
        priceData.property_size === null) ||
      priceData.fee_type === "" ||
      priceData.price === ""
    ) {
      setValidatedPrice(false);
      return false;
    }
    setValidatedPrice(true);
    return true;
  };

  const validateWorkZone = () => {
    if (
      workZoneId === null ||
      workZoneId === ""
    ) {
      setValidatedWorkZone(false);
      return false;
    }
    setValidatedWorkZone(true);
    return true;
  };

  // validation for work Zone post
  const validateZoneData = () => {
    if (workZoneData.city === "" || workZoneData.zipCode === "") {
      setValidatedZone(false);
      return false;
    }
    setValidatedZone(true);
    return true;
  };

  // discount api
  const handleSubmitDiscount = () => {
    if (validateDiscountData()) {
      let tempDataDiscount = {
        fee_type: discountData.feeType,
        discount: parseInt(discountData.discount),
        adjustment_type: discountData.adjustmentType,
        offerType: discountData.offerType
      };
      Request.put(`/appointmentsDiscount/${discountData.id}`, tempDataDiscount)
        .then((result) => {
          showToast("success", "Discount Updated.");
          setDiscountData(initialStateDiscount);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  };
  // get work zone api
  function getAllWorkZones() {
    setLoading(true);

    Request.get("/getWorkZone")
      .then((result) => {
        setLoading(false);
        setGetWorkZone(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // price post api
  const handleSubmitPrice = () => {
    if (validatePriceData()) {

      let tempDataPrice = {
        id: editRowId && editRowId,
        work_zonesId: priceData.workZoneId,
        service_type: priceData.service_type,
        property_size: priceData.property_size,
        fee_type: priceData.fee_type,
        offerType: priceData.discount,
        adjustment_type: priceData.adjustment_type,
        price: priceData.price,
        room_count: Number(priceData.roomCount),
        building_type: priceData.building_info
      };
      Request.post("/createPrice", tempDataPrice)
        .then((result) => {
          showToast("success", result.data.message);
          getAllPrices();
          setPriceData(initialStatePrice);
          setEditRowId('')
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  };
  // work zone post api
  const handleSubmitZone = () => {
    if (validateZoneData()) {
      let tempDataPrice = {
        city: workZoneData.city,
        zipCode: workZoneData.zipCode,
      };
      Request.post("/createWorkZone", tempDataPrice)
        .then((result) => {
          showToast("success", result.data.message);
          setWorkZoneData(initialStateZone);
          getAllWorkZones();
          setAddWorkZone(false);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  };
  // post blocked dates
  const handleSubmitDates = () => {
    var data = [];
    for (var i = 0; i < calendarDates.length; i++) {
      const ele = calendarDates[i];
      data.push({ ...ele, workZoneId: workZoneId })
    }
    console.log("datadata", data);
    // console.log("calendarDates", calendarDates);
    let tempData = { calendarDates: data };
    console.log("tempData", tempData);
    if (validateWorkZone()) {
      Request.post("/blockDates", tempData)
        .then((result) => {
          showToast("success", result?.data?.message);
          setCalendarDates([]);
          setUnblockDates([]);
          setUnReserveDates([]);
          setReserveDates([]);
          // setSelect200All(false);
          // setSelect930All(false);
          // console.log("Data: ", result.data);
          getAllBlockedDates(workZoneId);
        })
        .catch((err) => {
          console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", err?.response?.data?.error);
          }
        });
    }
  };

  const handleReserveDates = () => {
    var data = [];
    for (var i = 0; i < reserveDates.length; i++) {
      const ele = reserveDates[i];
      data.push({ ...ele, workZoneId: workZoneId })
    }
    let tempData = { reserveDates: data };
    if (validateWorkZone()) {
      Request.post("/reserveDates", tempData)
        .then((result) => {
          showToast("success", result?.data?.message);
          // console.log("Data: ", result.data);
          setCalendarDates([]);
          setUnblockDates([]);
          setUnReserveDates([]);
          setReserveDates([]);
          getAllReserveDates(workZoneId);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", err?.response?.data?.error);
          }
        });
    }
  };
  // console.log("unblockDates", unblockDates);
  const handleunReserveDates = () => {
    let tempData = { unReserveDates: unReserveDates };
    if (validateWorkZone()) {
      Request.put("/unReserveDates", tempData)
        .then((result) => {
          setForceUpdate(true);
          showToast("success", result?.data?.message);
          const updatedWeeks = weeks.map((week) =>
            week.map((date) => ({
              ...date,
              isChecked: false,
              is930Checked: false, // Update time checkboxes
              is200Checked: false, // Update time checkboxes
            }))
          );
          setWeeks(updatedWeeks);
          setSelect930All(false);
          setSelect200All(false);
          // console.log("Data: ", result.data);
          setCalendarDates([]);
          setUnblockDates([]);
          setUnReserveDates([]);
          setReserveDates([]);
          getAllReserveDates(workZoneId);
        })
        .catch((err) => {
          console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", err?.response?.data?.error);
          }
        });
    }
  };

  const handleWeekends = (value) => {
    // console.log("handleWeekends value", value);
    let tempData = { enableWeekend: value };
    if (validateWorkZone()) {
      Request.post(`/EnableWeekends/${workZoneId}`, tempData)
        .then((result) => {
          showToast("success", result?.data?.message);
          setChecked(result.data.weekendData)
          setGetEnableWeekend(result.data.weekendData);
          // console.log("Data: ", result.data);
          // getEnableWeekends(workZoneId);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", err?.response?.data?.error);
          }
        });
    }
  };
  const toggleSwitch = (e) => {
    setChecked(e.target.checked);
    handleWeekends(e.target.checked);
  };
  // get results
  function getAllBlockedDates(workZoneID) {
    setLoading(true);

    Request.get(`/getBlockedDates/${workZoneID}`)
      .then((result) => {
        setLoading(false);
        // console.log(result.data["data"]);
        // setSelectAll(!selectAll);
        setGetBlockedDates(result.data["blockedDates"]);
        setBlockedDate(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getUnavailableDates(id) {
    console.log("idididid", id);
    setLoading(true);
    if (id !== null) {
      Request.get(`/getUnavailableDates/${id}`)
        .then((result) => {
          setLoading(false);
          // console.log(
          //   "getUnavailableDates result", result
          // );
          setUnavailableDates(result.data["unavailableDates"]);
          // console.log(result.data["data"]);
          // setGetBlockedDates(result.data["data"]);
          getAllBlockedDates(id);
          getAllReserveDates(id)
          setForceUpdate(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  }

  function getAllReserveDates(workZoneId) {
    setLoading(true);

    Request.get(`/getReservedDates/${workZoneId}`)
      .then((result) => {
        setLoading(false);
        // console.log("get All Reserve Date", result.data["data"]);
        // setSelectAll(!selectAll);
        setGetReserveDates(result.data["reservedDates"]);
        setReserveDate(result.data["data"])
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  // unblock dates api
  function unBlockDateId() {
    let tempData = { unBlockDates: unblockDates };
    if (validateWorkZone()) {
      Request.post("/unblockDates", tempData)
        .then((res) => {
          setForceUpdate(true);
          setCalendarDates([]);
          setUnblockDates([]);
          setUnReserveDates([]);
          setReserveDates([]);
          const updatedWeeks = weeks.map((week) =>
            week.map((date) => ({
              ...date,
              isChecked: false,
              is930Checked: false, // Update time checkboxes
              is200Checked: false, // Update time checkboxes
            }))
          );
          setSelect930All(false);
          setSelect200All(false);
          setWeeks(updatedWeeks);
          // console.log("ddd", res.data);
          getAllBlockedDates(workZoneId);
          showToast("success", res?.data?.message);
        })
        .catch((err) => {
          // console.log("Error: ", err);
          if (err.response.status == 401) {
            showToast("error", "Unauthorized user.");
          } else {
            showToast("error", "Something went wrong.");
          }
        });
    }
  }
  // close del modal
  const CloseDelModal = () => {
    setOpenDelModal(false);
  };
  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelPrice(user);
  };
  // function to reset form Advanced Price
  const ResetForm = () => {
    setPriceData(initialStatePrice);
  };
  function deletePrice(price) {
    Request.delete(`/deletePrices/${price["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // on hold appt


  const OpenModal = () => {
    setOpenAppointments(true);
  };
  return (
    <div style={{ padding: "10px" }}>
      <h3>Pricing Matrix:</h3>
      <p style={{ color: "rgba(21, 6, 45, 1)" }}>General</p>
      <Grid item xs={12} md={8} container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={discountData.feeType}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...discountData };
                dupObj.feeType = e.target.value;
                setDiscountData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Sampling fee
                </span>
              </MenuItem>
              <MenuItem value={"Order Fee"}>Order Fee</MenuItem>
              <MenuItem value={"Sampling Fee"}>Sampling Fee</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Fee Type:
          </span>
          {!validated &&
            (discountData.feeType === null || discountData.feeType === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Fee Type
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Price"
            id="outlined-start-adornment"
            value={discountData.discount}
            displayEmpty
            onChange={(e) => {
              let dupObj = { ...discountData };
              dupObj.discount = e.target.value;
              setDiscountData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Price Adjustment:
          </span>
          {!validated &&
            (discountData.discount === null ||
              discountData.discount === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter Discount
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={discountData.adjustmentType}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...discountData };
                dupObj.adjustmentType = e.target.value;
                setDiscountData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={"Percentage"}>Percentage</MenuItem>
              <MenuItem value={"Dollar"}>Dollar</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Adjustment Type:
          </span>
          {!validated &&
            (discountData.adjustmentType === null ||
              discountData.adjustmentType === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Adjustment Type
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Description"
            id="outlined-start-adornment"
            value={discountData.adjustmentDescription}
            displayEmpty
            onChange={(e) => {
              let dupObj = { ...discountData };
              dupObj.adjustmentDescription = e.target.value;
              setDiscountData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </InputAdornment>
              ),
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Description:
          </span>
          {!validated &&
            (discountData.adjustmentDescription === null ||
              discountData.adjustmentDescription === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter Discription
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={discountData.id}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...discountData };
                dupObj.id = e.target.value;
                setDiscountData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              {!!appointmentsDropdown.length &&
                appointmentsDropdown.map((app, i) => (
                  <MenuItem value={app.id} key={i}>
                    {app.clientName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Confirmation Number Type:
          </span>
          {!validated &&
            (discountData.id === null || discountData.id === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Appointment
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Id"
            id="outlined-start-adornment"
            value={discountData.id}
            displayEmpty
            onChange={(e) => {
              let dupObj = { ...discountData };
              dupObj.id = e.target.value;
              setDiscountData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </InputAdornment>
              ),
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Id:
          </span>
          {/* {!validated &&
            (discountData.discount === null ||
              discountData.discount === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter Discount
              </div>
            )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={discountData?.offerType}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...discountData };
                dupObj.offerType = e.target.value;
                setDiscountData(dupObj);
              }}
              placeholder="Extra"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Extra
                </span>
              </MenuItem>
              <MenuItem value={"Extra"}>Extra</MenuItem>
              <MenuItem value={"Discount"}>Discount</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Offer Type:
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          sx={{ display: "flex", gap: "25px", mt: 2 }}
        >
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
            }}
            onClick={handleSubmitDiscount}
          >
            Save
          </Button>
          <Button
            sx={{
              border: "1px solid rgba(155, 46, 239, 1)",
              color: "rgba(155, 46, 239, 1)",
              borderRadius: "6px",
              padding: "10px 30px !important",
              "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
              textTransform: "unset",
            }}
            onClick={() => setDiscountData(initialStateDiscount)}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <p style={{ color: "rgba(21, 6, 45, 1)" }}>Advanced</p>
      <Grid item xs={12} md={8} container spacing={2}>
        <Grid item xs={12} md={6}>

          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData?.workZoneId}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.workZoneId = e.target.value;
                setPriceData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={null}>
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  e.g. Houston
                </span>
              </MenuItem>
              {!!getWorkZone.length &&
                getWorkZone.map((zone, i) => (
                  <MenuItem value={zone.id} key={i}>
                    {zone.city}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Work Zone:
          </span>
          {!validatedPrice &&
            (priceData.workZoneId === null || priceData.workZoneId === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Work Zone
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <TextField
              placeholder="Residential building"
              id="outlined-start-adornment"
              sx={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </InputAdornment>
                ),
              }}
            /> */}
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData.building_info}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.building_info = e.target.value;
                setPriceData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Residential Buildings
                </span>
              </MenuItem>
              <MenuItem value={"Residential Buildings"}>Residential Buildings</MenuItem>
              <MenuItem value={"Non-residential Buildings"}>Non-residential Buildings</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Building Type:
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData?.service_type}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.service_type = e.target.value;
                if (dupObj.service_type !== "Full inspection") {
                  dupObj.property_size = null;
                }
                setPriceData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Full inspection
                </span>
              </MenuItem>
              <MenuItem value={"Full inspection"}>Full inspection</MenuItem>
              <MenuItem value={"Partial inspection"}>
                Partial inspection
              </MenuItem>
              <MenuItem value={"Samples only"}>Samples only</MenuItem>
              <MenuItem value={"Post-inspection"}>Post-inspection</MenuItem>
              <MenuItem value={"Other- Protocol"}>Other-Protocol</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Order Type:
          </span>
          {!validatedPrice &&
            (priceData.service_type === null ||
              priceData.service_type === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Order Type
              </div>
            )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData?.property_size}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.property_size = e.target.value;
                setPriceData(dupObj);
              }}
              readOnly={
                priceData.service_type === "Full inspection" ? false : true
              }
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={null}>
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  500 SF - 1,000 SF
                </span>
              </MenuItem>
              <MenuItem value={"500-1,000 SF"}>500-1,000 SF</MenuItem>
              <MenuItem value={"1001-1,500 SF"}>1001-1,500 SF</MenuItem>
              <MenuItem value={"1,501-2,000 SF"}>1,501-2,000 SF</MenuItem>
              <MenuItem value={"2,001-2,500 SF"}>2,001-2,500 SF</MenuItem>
              <MenuItem value={"2,501-3,000 SF"}>2,501-3,000 SF</MenuItem>
              <MenuItem value={"3,001-3,500 SF"}>3,001-3,500 SF</MenuItem>
              <MenuItem value={"3,501-4,000 SF"}>3,501-4,000 SF</MenuItem>
              <MenuItem value={"4,001-4,500 SF"}>4,001-4,500 SF</MenuItem>
              <MenuItem value={"4,501-5000 SF"}>4,501-5000 SF</MenuItem>
              <MenuItem value={"5,001-5,500 SF"}>5,001-5,500 SF</MenuItem>
              <MenuItem value={">5,500"}>{`>`}5,500</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Property Size (applicable to full inspection only):
          </span>
          {!validatedPrice &&
            priceData.service_type === "Full inspection" &&
            (priceData.property_size === "" ||
              priceData.property_size === null) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Property Size
              </div>
            )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData?.fee_type}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.fee_type = e.target.value;
                setPriceData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Order fee
                </span>
              </MenuItem>
              <MenuItem value={"Order fee"}>Order fee</MenuItem>
              <MenuItem value={"Sample fee"}>Sample fee</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Fee Type:
          </span>
          {!validatedPrice &&
            (priceData.fee_type === null || priceData.fee_type === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Fee Type
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            placeholder="Price"
            id="outlined-start-adornment"
            value={priceData?.price}
            displayEmpty
            onChange={(e) => {
              let dupObj = { ...priceData };
              dupObj.price = e.target.value;
              setPriceData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon icon={faPenToSquare} />
                </InputAdornment>
              ),
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Price Adjustment:
          </span>
          {!validatedPrice &&
            (priceData.price === null || priceData.price === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter Fee Type Price
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData?.adjustment_type}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.adjustment_type = e.target.value;
                setPriceData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Percentage
                </span>
              </MenuItem>
              <MenuItem value={"Percentage"}>Percentage</MenuItem>
              <MenuItem value={"Dollar"}>Dollar</MenuItem>
              <MenuItem value={"Basic Fee"}>Basic Fee</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Adjustment Type:
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={priceData?.discount}
              displayEmpty
              onChange={(e) => {
                let dupObj = { ...priceData };
                dupObj.discount = e.target.value;
                setPriceData(dupObj);
              }}
              placeholder="Extra"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value="">
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  Extra
                </span>
              </MenuItem>
              <MenuItem value={"Extra"}>Extra</MenuItem>
              <MenuItem value={"Discount"}>Discount</MenuItem>
              <MenuItem value={"N/A"}>N/A</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Offer Type:
          </span>
        </Grid>
        {priceData.service_type === "Other- Protocol" && <Grid item xs={12} md={6}>
          <TextField
            placeholder="Room Count"
            id="outlined-start-adornment"
            value={priceData?.roomCount}
            displayEmpty
            onChange={(e) => {
              let dupObj = { ...priceData };
              dupObj.roomCount = e.target.value;
              setPriceData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}

          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Room Count:
          </span>
          {!validatedPrice &&
            (priceData.roomCount === null || priceData.roomCount === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter room count
              </div>
            )}
        </Grid>}
        <Grid
          item
          xs={12}
          md={12}
          container
          sx={{ display: "flex", gap: "25px", mt: 2 }}
        >
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
            }}
            onClick={handleSubmitPrice}
          >
            Save
          </Button>
          <Button
            sx={{
              border: "1px solid rgba(155, 46, 239, 1)",
              color: "rgba(155, 46, 239, 1)",
              borderRadius: "6px",
              padding: "10px 30px !important",
              "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
              textTransform: "unset",
            }}
            onClick={ResetForm}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} md={12} container>
          <h3 style={{ width: "100%" }}>All Prices:</h3>
        </Grid>
        <Grid item xs={12} md={12} container>
          <table className="table">
            <tr>
              <th className="thInspect">Service Type</th>
              <th className="thInspect">Property Size</th>
              <th className="thInspect">Offer Type</th>
              <th className="thInspect">Adjustment Type</th>
              <th className="thInspect">Fee Type</th>
              <th className="thInspect">Price</th>
              <th className="thInspect">Work Zone</th>
              <th className="thInspect">Actions</th>
            </tr>
            {getPrices.map((price) => (
              <tr>
                <td className="tdInspect">{price?.service_type}</td>
                <td className="tdInspect">{price?.property_size}</td>
                <td className="tdInspect">{price?.offerType}</td>
                <td className="tdInspect">{price?.adjustment_type}</td>
                <td className="tdInspect">{price?.fee_type}</td>
                <td className="tdInspect">{price?.price}</td>
                <td className="tdInspect">{price?.work_zone?.city}</td>
                <td className="tdInspect">
                  <EditIcon
                    sx={{
                      cursor: "pointer",
                      color: "rgba(155, 46, 239, 1)",
                      marginRight: "8px",
                    }}
                    onClick={() => handleEditClick(price)}
                  />
                  <DeleteIcon
                    sx={{ cursor: "pointer", color: "rgba(191, 10, 48, 1)" }}
                    onClick={() => OpenDelModal(price)}
                  />
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item xs={12} md={12} container>
          <h3 style={{ width: "100%" }}>Calendar:</h3>
          {/* <Grid item xs={12} md={12}>
            <FormControl sx={{ mr: 1, width: "100%" }}>
              <Select
                value={workZoneId}
                displayEmpty
                onChange={(e) => {
                  setWorkZoneId(e.target.value);
                  getUnavailableDates(e.target.value)
                  // let dupObj = { ...priceData };
                  // dupObj.workZoneId = e.target.value;
                  // setPriceData(dupObj);
                }}
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                <MenuItem value={null}>
                  <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                    e.g. Houston
                  </span>
                </MenuItem>
                {!!getWorkZone.length &&
                  getWorkZone.map((zone, i) => (
                    <MenuItem value={zone.id} key={i}>
                      {zone.city}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Work Zone:
            </span>
          </Grid> */}
          {/* <p style={{ color: "rgba(21, 6, 45, 1)" }}>Calendar Management</p> */}
          <Grid>
            <p style={{ color: "rgba(21,6,45,1)" }}>Calendar Pool: </p>
          </Grid>
          <span
            style={{
              color: "rgba(0, 0, 0, 1)",
              width: "100%",
              fontWeight: "300",
              fontSize: "13px",
            }}
          >
            The calendar can be adjusted per work zone by allowing (or
            preventing) clients to (from) make (making) appointments on specific
            days when permitted by admin.
          </span>
        </Grid>
        <Grid item xs={12} md={12} container>
          <span
            style={{
              color: "rgba(0, 40, 104, 1)",
              width: "100%",
              fontWeight: "400",
              fontSize: "13px",
              cursor: "pointer",
            }}
            onClick={toggleAddWorkZone}
          >
            (+) Add Work Zone: e.g. San Antonio
          </span>
        </Grid>
        {addWorkZone && (
          <>
            {/* // <Box sx={{width:"100%", mt:1}}> */}
            <Grid item xs={12} md={6}>
              <TextField
                placeholder="City"
                id="outlined-start-adornment"
                value={workZoneData.city}
                displayEmpty
                onChange={(e) => {
                  let dupObj = { ...workZoneData };
                  dupObj.city = e.target.value;
                  setWorkZoneData(dupObj);
                }}
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </InputAdornment>
                  ),
                }}
              />
              <span
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Enter City:
              </span>
              {!validatedZone &&
                (workZoneData.city === null || workZoneData.city === "") && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    Enter City
                  </div>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                placeholder="ZipCode"
                id="outlined-start-adornment"
                displayEmpty
                value={workZoneData.zipCode}
                onChange={(e) => {
                  let dupObj = { ...workZoneData };
                  dupObj.zipCode = e.target.value;
                  setWorkZoneData(dupObj);
                }}
                sx={{
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  borderRadius: "12px",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "10px",
                    color: "rgba(155, 46, 239, 1) !important",
                  },
                  "& .MuiInputBase-formControl ::placeholder": {
                    color: "rgba(180, 177, 185, 1) !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(223, 222, 225, 1) !important",
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(155, 46, 239, 1) !important",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </InputAdornment>
                  ),
                }}
              />
              <span
                style={{
                  marginTop: "6px",
                  fontSize: "12px",
                  color: "rgba(155, 46, 239, 1)",
                }}
              >
                Enter Zip Code:
              </span>
              {!validatedZone &&
                (workZoneData.zipCode === null ||
                  workZoneData.zipCode === "") && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    Enter Zip Code
                  </div>
                )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
              <Button
                sx={{
                  border: "1px solid rgba(44, 181, 44, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "6px",
                  padding: "10px 32px !important",
                  backgroundColor: "rgba(44, 181, 44, 1)",
                  "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
                  textTransform: "unset",
                }}
                onClick={handleSubmitZone}
              >
                Save
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12} container>
          <Accordion items={getWorkZone} />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={workZoneId}
              displayEmpty
              onChange={(e) => {
                const updatedWeeks = weeks.map((week) =>
                  week.map((date) => ({
                    ...date,
                    isChecked: false,
                    is930Checked: false, // Update time checkboxes
                    is200Checked: false, // Update time checkboxes
                  }))
                );
                setWeeks(updatedWeeks);
                setWorkZoneId(e.target.value);
                setCalendarDates([]);
                setUnblockDates([]);
                setReserveDates([]);
                setUnReserveDates([]);
                getUnavailableDates(e.target.value)
                // getEnableWeekends(e.target.value)
                const findValue = getWorkZone.find((ele) => ele.id === e.target.value);
                console.log("findIndex: " + findValue);
                setChecked(findValue?.areWeekendsEnabled)
                setGetEnableWeekend(findValue?.areWeekendsEnabled)
                // let dupObj = {...priceData}; 
                // dupObj.workZoneId = e.target.value;
                // setPriceData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={null}>
                <span style={{ color: "rgba(180, 177, 185, 0.3)" }}>
                  e.g. Houston
                </span>
              </MenuItem>
              {!!getWorkZone.length &&
                getWorkZone.map((zone, i) => (
                  <MenuItem value={zone.id} key={i}>
                    {zone.city}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Work Zone:
          </span>
          {!validatedWorkZone &&
            (workZoneId === null || workZoneId === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select work zone
              </div>
            )}
        </Grid>
        <CalendarManagement
          currentMonth={currentMonth}
          handleNextMonthClick={handleNextMonthClick}
          handlePreviousMonthClick={handlePreviousMonthClick}
          checked={checked}
          Switch={Switch}
          toggleSwitch={toggleSwitch}
          select930All={select930All}
          select200All={select200All}
          handleSelectAll={handleSelectAll}
          daysOfWeek={daysOfWeek}
          getEnableWeekend={getEnableWeekend}
          weeks={weeks}
          handleRowCheckbox={handleRowCheckbox}
          getBlockedDates={getBlockedDates}
          appointmentsDropdown={appointmentsDropdown}
          unblockDates={unblockDates}
          handleUnblock={handleUnblock}
          calendarDates={calendarDates}
          setCalendarDates={setCalendarDates}
          setWeeks={setWeeks}
          handleSubmitDates={handleSubmitDates}
          unBlockDateId={unBlockDateId}
          handleReserveDates={handleReserveDates}
          setReserveDates={setReserveDates}
          handleunReserveDates={handleunReserveDates}
          getReserveDates={getReserveDates}
          handleUnReserve={handleUnReserve}
          unReserveDates={unReserveDates}
          unavailableDates={unavailableDates}
          blockDates={blockedDate}
          reserveDate={reserveDate}
          switchDisable={workZoneId === null || workZoneId === ""}
        />
        <Grid item xs={12} md={12} container >
          <p style={{ color: "rgba(21, 6, 45, 1)" }} id="Reservation">
            Calendar Reservation Pool:
          </p>
          <span
            style={{
              color: "rgba(0, 0, 0, 1)",
              width: "100%",
              fontWeight: "300",
              fontSize: "13px",
            }}
          >
            The dates reserved per work zone can be assigned to a particular
            group of clients.
          </span>
        </Grid>
        <Grid item xs={12} md={12} container>
          <Accordion items={getWorkZone} />
        </Grid>
        {/* <CalendarManagement
          presentMonth={currentMonth}
          handleNextMonth={handleNextMonthClickSecondInstance}
          dayOfWeek={daysOfWeek}
          weekM={weeks}
          getBlockedDates={getBlockedDates}
          isSecondInstance={true}
        /> */}
        <CalendarManagement
          presentMonth={currentMonth}
          handleNextMonthClick={handleNextMonthClick}
          handlePreviousMonthClick={handlePreviousMonthClick}
          checked={checked}
          Switch={Switch}
          toggleSwitch={toggleSwitch}
          select930All={select930All}
          select200All={select200All}
          handleSelectAll={handleSelectAll}
          dayOfWeek={daysOfWeek}
          getEnableWeekend={getEnableWeekend}
          weeks={weeks}
          handleRowCheckbox={handleRowCheckbox}
          getBlockedDates={getBlockedDates}
          appointmentsDropdown={appointmentsDropdown}
          unblockDates={unblockDates}
          handleUnblock={handleUnblock}
          calendarDates={calendarDates}
          setCalendarDates={setCalendarDates}
          setWeeks={setWeeks}
          handleSubmitDates={handleSubmitDates}
          unBlockDateId={unBlockDateId}
          handleReserveDates={handleReserveDates}
          setReserveDates={setReserveDates}
          handleunReserveDates={handleunReserveDates}
          getReserveDates={getReserveDates}
          handleUnReserve={handleUnReserve}
          unReserveDates={unReserveDates}
          unavailableDates={unavailableDates}
          isSecondInstance={true}
          onClick={() => validateWorkZone() && OpenModal()}
        />
        {/* <Grid item xs={12} md={12} container>
          <Button
            sx={{
              border: "1px solid rgba(191, 10, 48, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px!important",
              backgroundColor: "rgba(191, 10, 48, 1)",
              "&:hover": { backgroundColor: "rgba(191, 10, 48, 1)" },
              textTransform: "unset",
              width: "35%",
            }}
            onClick={() => validateWorkZone() && OpenModal()}
          >
            Preset an appointment
          </Button>
        </Grid> */}
      </Grid>
      {
        openDelModal && (
          <DeletePrice
            deletePrice={deletePrice}
            currentPrice={delPrice}
            openDelModal={openDelModal}
            setDeleteModal={setOpenDelModal}
          />
        )
      }
      {
        openAppointments && (
          <PresetAppointment
            openAppointments={openAppointments}
            setOpenAppointments={setOpenAppointments}
            getReserveDates={getReserveDates}
            getAllReserveDates={() => getAllReserveDates(workZoneId)}
            workZoneId={workZoneId}
          />
        )
      }
    </div >
  );
}

export default PricingMatrix;
