import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: "https://amoldi.com/",
});

axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log("error: ", error);
    if (error !== null && error?.response?.status === 401){
      window.localStorage.removeItem("p_u_t");
      delete axiosInstance.defaults.headers.common.Authorization;
      window.localStorage.removeItem("userInfo");
      window.location.reload();
    }

    return Promise.reject((error) || 'Something went wrong!');
  }
);

export default axiosInstance;