import React, { useState } from "react";
import logo from "../images/logoDash-removebg.png";
import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../utils/index";
import Request from "../utils/Requests/Request";

function SetPassword() {

  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();

  function ResetPass(e) {
    e.preventDefault()
    const searchParams = new URLSearchParams(location.search);
    let id = searchParams.get("id");
    let token = searchParams.get("token");
    if(!id || !token) {
      let userInfo = localStorage.getItem("userInfo");
      userInfo = JSON.parse(userInfo);
      id = userInfo?.id;
      token = userInfo?.token
    }
    console.log("idid", id);
    Request.post(
      "/setPassword",
      {
        id: id,
        token: token,
        password: password,
        confirm_password: confirmPassword
      },
    )
      .then((result) => {
        // console.log("Data: ", result.data.message);
        showToast("success", result?.data?.message);
        setTimeout(() => {
          navigate('/');
        }, 1500);
      })
      .catch((err) => {
        // console.log("Error: ", err.response.data);
        if (err?.response?.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err.response?.data);
        }
      });
  }
  return (
    <div
      style={{
        background: "rgba(18, 23, 58, 1)",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "33vw",
          background: "rgb(0 2 57)",
          borderRadius: "12px",
          padding: "10px",
          boxShadow: "rgba(191, 10, 48, 0.1) 0px 10px 50px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <img src={logo} alt="" style={{ width: "15%" }} />
        </div>
        <h2 style={{ color: "rgba(244, 247, 253, 1)", textAlign: "center" }}>Create New Password</h2>
        <form action="" method="POST">
          <div className="txt_field" >
            <input type="password"
              onChange={(event) => setPassword(event.target.value)} />
            <span></span>
            <label>Enter Password</label>
          </div>
          <div className="txt_field">
            <input type="password"
              onChange={(event) => setConfirmPassword(event.target.value)} />
            <span></span>
            <label>Confirm Password</label>
          </div>
          <button className="btn-setPass" type="submit" onClick={ResetPass}>Reset Password</button>
        </form>
      </div>
    </div>
  );
}

export default SetPassword;