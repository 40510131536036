import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import Request from "../utils/Requests/Request";
import { showToast } from "../utils";

function ContactManagement() {
  const [jobPosition, setJobPosition] = React.useState(10);
  const [contactOptins, setOptIns] = useState([]);
  const [quoteonHold, setQuoteOnHold] = useState([]);
  const [quoteResults, setQuoteResults] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [cancelOrders, setCancelrders] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [contactListNode, setContactListNode] = useState([]);
  const [contactListTax, setContactListTax] = useState([]);
  const [contactListShard, setContactListShard] = useState([]);
  const [automatedMarket, setAutomatedMarket] = useState([]);
  const [contactListTMSB, setContactLIstTMSB] = useState([]);
  // console.log("contactListNode", contactListNode);
  // console.log("contactListTax", contactListTax);
  // console.log("contactListShard", contactListShard);
  // console.log("AutomatedMarket", automatedMarket);
  // console.log("ContactListTMSB", contactListTMSB);
  const [loading, setLoading] = useState(false);

  // console.log("contactList", contactList);
  const handleChangeJobPosition = (event) => {
    setJobPosition(event.target.value);
  };
  const getAllContactDetail = () => {
    var node = [];
    var tax = [];
    var sharedHost = [];
    var automatedMarket = [];
    var TMSB = [];
    setLoading(true);
    Request.get("/getallContacts").then((result) => {
      // console.log("get all contact", result.data);
      const data = result.data
      setOptIns(data?.OptIn);
      setQuoteOnHold(data?.quotationOnHold);
      setQuoteResults(data?.quotationResult);
      setNewOrders(data?.newOrders);
      setCancelrders(data?.cancelledOrders)
      // setContactList(result.data.data);
      // const contactList = result.data.data
      // for (let i = 0; i < contactList.length; i++) {
      //   const element = contactList[i];
      //   console.log("element", element);
      //   if (element.interests.includes("I'm interested in the node validation")) {
      //     node.push(element)
      //     // setContactListNode([...contactListNode, element])
      //   } if (element.interests.includes("I'm interested in the tax preparation")) {
      //     tax.push(element)
      //     // setContactListTax([...contactListTax, element])
      //   } if (element.interests.includes("I'm interested in the shard hosting")) {
      //     sharedHost.push(element)
      //     // setContactListShard([...contactListShard, element])
      //   } if (element.interests.includes("I'm interested in the Automated Market Maker pool")) {
      //     automatedMarket.push(element)
      //     // setAutomatedMarket([...automatedMarket, element])
      //   } if (element.interests.includes("I'm interested in the TMSB development")) {
      //     TMSB.push(element)
      //     // setContactLIstTMSB([...contactListTMSB, element])
      //   }
      //   setContactListNode(node)
      //   setContactListTax(tax)
      //   setContactListShard(sharedHost)
      //   setAutomatedMarket(automatedMarket)
      //   setContactLIstTMSB(TMSB)
      // }
    })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const getContactDetail = () => {
    var node = [];
    var tax = [];
    var sharedHost = [];
    var automatedMarket = [];
    var TMSB = [];
    setLoading(true);
    Request.get("https://whitepaper.taxblock.network/allUsers").then((result) => {
      // console.log("get all users", result.data.data);
      setContactList(result.data.data);
      const contactList = result.data.data
      for (let i = 0; i < contactList.length; i++) {
        const element = contactList[i];
        // console.log("element", element);
        if (element.interests.includes("I'm interested in the node validation")) {
          node.push(element)
          // setContactListNode([...contactListNode, element])
        } if (element.interests.includes("I'm interested in the tax preparation")) {
          tax.push(element)
          // setContactListTax([...contactListTax, element])
        } if (element.interests.includes("I'm interested in the shard hosting")) {
          sharedHost.push(element)
          // setContactListShard([...contactListShard, element])
        } if (element.interests.includes("I'm interested in the Automated Market Maker pool")) {
          automatedMarket.push(element)
          // setAutomatedMarket([...automatedMarket, element])
        } if (element.interests.includes("I'm interested in the TMSB development")) {
          TMSB.push(element)
          // setContactLIstTMSB([...contactListTMSB, element])
        }
        setContactListNode(node)
        setContactListTax(tax)
        setContactListShard(sharedHost)
        setAutomatedMarket(automatedMarket)
        setContactLIstTMSB(TMSB)
      }
    })
      .catch((err) => {
        setLoading(false);
        console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  useEffect(() => {
    getContactDetail();
    getAllContactDetail();
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <h3 style={{ fontWeight: "600", marginBottom: "unset" }}>Applied Mold Inspection of Texas </h3>
      <span style={{ fontWeight: "500", fontSize: "16px" }}>(Dynamic contact lists based on user journey):</span>
      {/* <p style={{ color: "rgba(21, 6, 45, 1)" }}>Add Users</p> */}
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Opt-ins only.</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Last Name</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {contactOptins.map((item) => {
              // console.log("item", item);
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.lastName}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })
            }

            {/* <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr> */}

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Quote on Hold.</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Last Name</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {quoteonHold.map((item) => {
              // console.log("item", item);
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.lastName}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })
            }

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Quote results only.</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Last Name</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {quoteResults.map((item) => {
              // console.log("item", item);
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.lastName}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })
            }

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per New orders.</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">Name</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {newOrders.map((item) => {
              // console.log("item", item);
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.clientName}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })
            }

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Orders completed.</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Last Name</th>
              <th className="thInspect">Email Address</th>
            </tr>
            <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Orders cancelled.</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">Name</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {cancelOrders.map((item) => {
              // console.log("item", item);
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.clientName}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })
            }

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={12} container sx={{ mt: 3 }}>
          <h3 style={{ fontWeight: "600" }}>Tax & Block Whitepaper (Static contact lists):</h3>
          <h3 style={{ fontWeight: "500", marginBottom: "unset" }}>Search contact group by country: </h3>
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="USA"
            id="outlined-start-adornment"
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Country Names:
          </span>
        </Grid>
        <Grid item xs={12} md={3} container>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "4px",
              maxHeight: "55px",
              minWidth: "84px",
              backgroundColor: "rgba(44, 181, 44, 1)",
              textTransform: "unset",
              fontWeight: "unset",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
            }}
          >
            Go
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per I'm interested in the node validation</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {contactListNode.map((item) => {
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.country}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })}
          </table>

          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per I'm interested in the tax preparation</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {contactListTax.map((item) => {
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.country}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })}
          </table>

          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per I'm interested in the shard hosting</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {contactListShard.map((item) => {
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.country}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })}
          </table>

          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per I'm interested in the Automated Market Maker pool</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {automatedMarket.map((item) => {
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.country}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })}
          </table>

          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per I'm interested in the TMSB development</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {contactListTMSB.map((item) => {
              return (
                <tr>
                  <td className="tdInspect">{item.id}</td>
                  <td className="tdInspect">{item.firstName}</td>
                  <td className="tdInspect">{item.country}</td>
                  <td className="tdInspect">{item.email}</td>
                </tr>
              )
            })}
          </table>


          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Farmer</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {/* <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr> */}

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Tax Professional</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {/* <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr> */}

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Automated Market maker</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {/* <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr> */}

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Validator</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {/* <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr> */}

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Developer</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">Email Address</th>
            </tr>
            {/* <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr> */}

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid>
        {/* <Grid item xs={12} md={12} container sx={{ mt: 3 }}>
          <h3 style={{ fontWeight: "600" }}>Altar of THE LORD (Static contact lists):</h3>
          <h3 style={{ fontWeight: "500", marginBottom: "unset" }}>Search famers country:</h3>
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="USA only"
            id="outlined-start-adornment"
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            State Names:
          </span>
        </Grid>
        <Grid item xs={12} md={3} container>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "4px",
              maxHeight: "55px",
              minWidth: "84px",
              backgroundColor: "rgba(44, 181, 44, 1)",
              textTransform: "unset",
              fontWeight: "unset",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
            }}
          >
            Go
          </Button>
        </Grid>
        <Grid item xs={12} md={10}>
          <p style={{ color: "rgba(21, 6, 45, 1)" }}>Contact List per Farmer</p>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">ID</th>
              <th className="thInspect">First Name</th>
              <th className="thInspect">Country</th>
              <th className="thInspect">State (USA only)</th>
              <th className="thInspect">Email Address</th>
            </tr>
            <tr>
              <td className="tdInspect">1</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">2</td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>

          </table>
          <Button
            sx={{
              border: "1px solid rgba(44, 181, 44, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "6px",
              padding: "10px 32px !important",
              backgroundColor: "rgba(44, 181, 44, 1)",
              "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              textTransform: "unset",
              mt: 3
            }}
          >
            Export
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default ContactManagement;
