import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import { AppStorage } from "../utils/Context/AppContext";
import SignatureCanvas from "react-signature-canvas";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";

function COC({ getInspection }) {
  const AppContext = useContext(AppStorage);

  const initialStateCOC = {
    labName: "",
    generates: "",
    labReference: "",
    date: "",
    trackingNumber: "",
    documents: "",
    inspectorId: getInspection,
  };
  const signatureRef = useRef(null);

  const [cocData, setCocData] = useState(initialStateCOC);
  const [files, setFiles] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isSignatureVisible, setIsSignatureVisible] = useState(false);

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
    }
  };

  const openSignature = () => {
    setIsSignatureVisible(true);
  };
  const onDrop = (selectedFiles) => {
    const previewFiles = [...files, ...selectedFiles];
    setFiles(previewFiles);
  };
  useEffect(() => {
    if (files?.length > 0) {
      handleSubmitFiles(files);
    }
  }, [files]);

  const handleSubmitFiles = (files) => {

    if (files?.length === 0) {
      return;
    }

    const tempData = new FormData();

    files.forEach((file, index) => {
      tempData.append(`files`, file);
    });

    Request.post("/postFiles", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        if (result?.data) {
          const fileNames = result.data.files.map((file) => file.filename);
          const updatedCOCData = { ...cocData };
          updatedCOCData.documents = fileNames; // Set the preliminaryPhotos to the new fileNames
          setCocData(updatedCOCData);
        }
        showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [".pdf", ".csv"], // Accept only image files
    multiple: true,
  });

  const handleCOCSubmit = () => {
    if (!signatureRef.current || signatureRef.current.isEmpty()) {
      showToast("error", "Signature is empty. Please sign before saving.");
      return; // Prevent submission when the signature is empty
    }
    let tempData = {
      labName: cocData.labName,
      inspectorSign: signatureRef.current.toDataURL("image/png"),
      generates: cocData.generates,
      labReference: cocData.labReference,
      date: cocData.date,
      trackingNumber: cocData.trackingNumber,
      documents: cocData.documents.join(","),
      inspectorId: cocData.inspectorId,
    };
    Request.post("/createCoc", tempData)
      .then((result) => {
        showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };
  return (
    <>
      <Box>
        <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>COC Relinquishment:</h3>
        {/* <div style={{ display: "flex", gap: "12px" }}>
          <p style={{ color: "rgba(106, 96, 169, 1)" }}>Approved Samples</p>
          <p
            style={{
              color: "rgba(106, 96, 169, 1)",
              textDecoration: "underline",
            }}
          >
            View COC Report
          </p>
        </div> */}
        {/* <table className="table" style={{ marginTop: "15px" }}>
          <tr>
            <th className="th"></th>
            <th className="th">eCOC</th>
            <th className="th">Lab Code</th>
            <th className="th">Type</th>
            <th className="th">Location</th>
          </tr>
          <tr>
            <td className="td">
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "rgba(46, 105, 255, 1)",
                }}
              >
                Edit Now
              </a>
            </td>
            <td className="td">
              <input type="checkbox" />
            </td>
            <td className="td">OS01 (Pre-populated)</td>
            <td className="td">Control (Pre-populated)</td>
            <td className="td">Outdoor Control Sample (Pre-populated)</td>
          </tr>

          <tr>
            <td className="td">
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "rgba(46, 105, 255, 1)",
                }}
              >
                Edit Now
              </a>
            </td>
            <td className="td">
              <input type="checkbox" />
            </td>
            <td className="td"></td>
            <td className="td"></td>
            <td className="td"></td>
          </tr>

          <tr>
            <td className="td">
              <a
                href=""
                style={{
                  textDecoration: "underline",
                  color: "rgba(46, 105, 255, 1)",
                }}
              >
                Edit Now
              </a>
            </td>
            <td className="td">
              <input type="checkbox" />
            </td>
            <td className="td"></td>
            <td className="td"></td>
            <td className="td"></td>
          </tr>
        </table> */}
        {/* <p style={{ color: "rgba(106, 96, 169, 1)", lineHeight: "25.5px" }}>
          Data entry per sample upon a click on edit button above (Design
          instructions only): e.g. Outdoor Control Sample: OS01
        </p> */}

        {/* <table className="table" style={{ marginTop: "15px" }}>
          <tr>
            <th className="th">Cassette Serial Number</th>
            <th className="th"></th>
            <th className="th"></th>
          </tr>
          <tr>
            <td className="td">Turn Around Time Codes</td>
            <td className="td">STD- Standard (Default)</td>
            <td className="td"></td>
          </tr>
          <tr>
            <td className="td">Sample Media Type</td>
            <td className="td">DE- Direct Exam</td>
            <td className="td"></td>
          </tr>

          <tr>
            <td className="td"></td>
            <td className="td"></td>
            <td className="td"></td>
          </tr>
        </table> */}
        {/* <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            padding: "7px 35px !important",
            backgroundColor: "rgba(46, 105, 255, 1)",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
            textTransform: "unset",
            mt: 3,
          }}
        >
          Done
        </Button> */}
      </Box>
      <Grid item xs={12} md={12} lg={12} container sx={{ mt: 3 }}>
        <Grid xs={12} md={12} lg={6}>
          <FormControl sx={{ width: "95%" }}>
            <Select
              displayEmpty
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
              value={cocData.labName}
              onChange={(e) => {
                let dupObj = { ...cocData };
                dupObj.labName = e.target.value;
                setCocData(dupObj);
              }}
            >
              <MenuItem value={"LAB-A"}>LAB-A</MenuItem>
              <MenuItem value={"LAB-B"}>LAB-B</MenuItem>
            </Select>
          </FormControl>
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Lab Name:
          </p>
        </Grid>
        <Grid xs={12} md={12} lg={4}>
          <Button
            sx={{
              border: "1px solid rgba(46, 105, 255, 1)",
              color: "rgba(255, 255, 255, 1)",
              borderRadius: "4px",
              padding: "15px 35px !important",
              backgroundColor: "rgba(46, 105, 255, 1)",
              "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              textTransform: "unset",
            }}
            onClick={openSignature}
          >
            Inspector Signature Done
          </Button>
          {isSignatureVisible && (
            <div>
              <div style={{ border: "1px solid #000", width: 400, margin: "5px 0px" }}>
                <SignatureCanvas
                  ref={signatureRef}
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: "sigCanvas",

                  }}
                />
              </div>
              <Button
                sx={{
                  border: "1px solid rgba(46, 105, 255, 1)",
                  color: "rgba(46, 105, 255, 1)",
                  marginTop: "3px",
                  marginRight: "6px",
                  borderRadius: "6px",
                  padding: "7px 35px !important",
                  "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
                  textTransform: "unset",
                }}
                onClick={clearSignature}>Clear</Button>
            </div>
          )}
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <TextField
            placeholder="Name"
            id="outlined-start-adornment"
            sx={{
              width: "95%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            value={cocData.generates}
            onChange={(e) => {
              let dupObj = { ...cocData };
              dupObj.generates = e.target.value;
              setCocData(dupObj);
            }}
          />
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              // width:"90%"
            }}
          >
            This generates:
          </p>
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <TextField
            placeholder="Name"
            id="outlined-start-adornment"
            sx={{
              width: "95%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            value={cocData.labReference}
            onChange={(e) => {
              let dupObj = { ...cocData };
              dupObj.labReference = e.target.value;
              setCocData(dupObj);
            }}
          />
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              // width:"90%"
            }}
          >
            Lab reference:
          </p>
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <TextField
            placeholder="Name"
            id="outlined-start-adornment"
            sx={{
              width: "95%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            value={cocData.date}
            onChange={(e) => {
              let dupObj = { ...cocData };
              dupObj.date = e.target.value;
              setCocData(dupObj);
            }}
          />
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              // width:"90%"
            }}
          >
            Date:
          </p>
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <p
            style={{
              marginTop: "16px",
              fontSize: "15px",
              color: "rgba(155, 46, 239, 1)",
              textDecoration: "underline",
            }}
          >
            Reset COC
          </p>
        </Grid>
      </Grid>
      <Box>
        <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>
          Shipping info if applicable:
        </h3>
        <Grid xs={12} md={12} lg={12}>
          <TextField
            placeholder="(Scan bar code or enter the tracking number manually):"
            id="outlined-start-adornment"
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            value={cocData.trackingNumber}
            onChange={(e) => {
              let dupObj = { ...cocData };
              dupObj.trackingNumber = e.target.value;
              setCocData(dupObj);
            }}
          />
          <p
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              // width:"90%"
            }}
          >
            Tracking number:
          </p>
        </Grid>
        <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(255, 255, 255, 1)",
            marginTop: "15px",
            borderRadius: "4px",
            padding: "10px 35px !important",
            backgroundColor: "rgba(46, 105, 255, 1)",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
            textTransform: "unset",
            mb: 3,
          }}
        >
          Save
        </Button>
        <div {...getRootProps()} className="coverPhoto" style={{ marginBottom: "15px" }}>
          <input {...getInputProps()} />
          <div className="coverCamera">
            <FontAwesomeIcon
              icon={faFileCirclePlus}
              style={{ color: "rgba(0, 0, 0, 1)" }}
            />
          </div>
        </div>
        {files.length > 0 && (
          <div>
            <ul>
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}
        <p
          style={{
            marginTop: "6px",
            fontSize: "15px",
            color: "rgba(155, 46, 239, 1)",
          }}
        >
          Attach documents:
        </p>
        <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(255, 255, 255, 1)",
            marginTop: "15px",
            borderRadius: "4px",
            padding: "10px 35px !important",
            backgroundColor: "rgba(46, 105, 255, 1)",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
            textTransform: "unset",
            mt: 3,
          }}
          onClick={handleCOCSubmit}
        >
          Save
        </Button>
      </Box>
    </>
  );
}

export default COC;
