import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import { useDropzone } from 'react-dropzone';
import { AppStorage } from "../utils/Context/AppContext";
import { useLocation, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import NavBar from "./NavBar";
function InspectionProject(props) {
    const location = useLocation()
    const params = useParams();
    const projectId = params?.projectId
    // const formData = location?.state?.formData ? location?.state?.formData : props.formData
    // const setFormData = location?.state?.setFormData ? location?.state?.setFormData : props.setFormData

    const wrapperRef = useRef(null);
    const materialDropdownRef = useRef(null);
    const initialStateProject = {
        projectId: null,
        date: "",
        projectScope: "",
        projectCost: null,
        feeDescription: "",
        discountFee: "",
        firstName: "",
        lastName: "",
        email: "",
        siteAddress: "",
        clientComment: "",
        personOnSite: "",
        personOnSiteNumber: "",
        clientNumber: "",
        clientAddress: "",
        clientCategory: "",
        buildingType: "",
        buildingPurpose: "",
        property_size: "",
        buildingAge: "",
        buildingStructure: "",
        buildingMaterialType: "",
        others: "",
        coverPhoto: "",
        relativeHumidity: "",
        geographicLocation: "",
        inspectorName: "",
        inspectorLicense: "",
        jobSheetNotes: "",
        jobInterview: "",
        jobSummary: "",
    };
    const [formData, setFormData] = useState(initialStateProject);

    const AppContext = useContext(AppStorage);

    const [forceUpdate, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inspectors, setInspectors] = useState([]);
    const [validated, setValidated] = useState(true);
    const [openField, setOpenField] = useState(false);
    const [materialDropdown, setMaterialDropdown] = useState([]);
    const [isMenuOpen, setMenuOpen] = useState(false);

    console.log(
        "openField", openField
    );
    const onDrop = (acceptedFiles) => {
        let dupObj = { ...formData };
        dupObj.coverPhoto = acceptedFiles[0];
        setFormData(dupObj);
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*', // Accept only image files
        multiple: false, // Allow only one file to be selected
    });
    const handleMaterial = (value) => {
        setFormData({ ...formData, others: value });
        setMaterialDropdown([])
    }
    useEffect(() => {
        getMaterial()
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // Clicked outside the wrapper, hide the field
                setOpenField(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (materialDropdownRef.current && !materialDropdownRef.current.contains(event.target)) {
                // Clicked outside the wrapper, hide the field
                setMaterialDropdown([]);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const validateFormData = () => {
        if (
            formData.projectId === null ||
            formData.date === "" ||
            formData.projectScope === "" ||
            formData.projectCost === null
        ) {
            setValidated(false);
            return false;
        }
        setValidated(true);
        return true;
    };

    function getSearchedAppointments() {
        setLoading(true);

        Request.get(`/getPreInspectionData/${projectId}`)
            .then((result) => {
                setLoading(false);
                const preInspectionData = result.data.preInspectionData;
                // const preInspectionData = result.data.preInspectionData;
                console.log("result", result);
                const newFormData = {
                    projectId: preInspectionData.projectId,
                    date: preInspectionData.date,
                    projectScope: preInspectionData.projectScope,
                    projectCost: preInspectionData.projectCost,
                    feeDescription: preInspectionData.feeDescription,
                    discountFee: preInspectionData.discountFee,
                    firstName: "",
                    lastName: "",
                    email: "",
                    siteAddress: "",
                    clientComment: "",
                    personOnSite: "",
                    personOnSiteNumber: "",
                    clientNumber: "",
                    clientAddress: "",
                    clientCategory: "",
                    buildingType: preInspectionData.buildingType,
                    buildingPurpose: preInspectionData.buildingPurpose,
                    property_size: preInspectionData.property_size,
                    buildingAge: preInspectionData.buildingAge,
                    buildingStructure: preInspectionData.buildingStructure,
                    buildingMaterialType: preInspectionData.buildingMaterialType,
                    material: "",
                    others: preInspectionData.material,
                    coverPhoto: "",
                    relativeHumidity: preInspectionData.relativeHumidity,
                    geographicLocation: preInspectionData.geographicLocation,
                    inspectorName: preInspectionData.inspectorName,
                    inspectorLicense: preInspectionData.inspectorLicense,
                    jobSheetNotes: preInspectionData.jobSheetNotes,
                    jobInterview: preInspectionData.jobInterview,
                    jobSummary: preInspectionData.jobSummary,
                }
                // const newFormData = { ...formData };
                // const mappedData = result.data.data.map((item) => ({
                //   projectId: item.id,
                //   date: item.date,
                //   projectScope: item?.quotation?.service_type,
                //   projectCost: item?.quotation?.price,
                //   firstName: item?.quotation?.firstName,
                //   lastName: item?.quotation?.lastName,
                //   email: item?.email,
                //   siteAddress: item?.siteAddress,
                //   clientComment: item?.comments,
                //   personOnSite: item?.personOnSite,
                //   personOnSiteNumber: item?.personOnSiteNumber,
                //   clientNumber: item?.clientPhone,
                //   clientAddress: item?.clientAddress,
                //   clientCategory: item?.clientCategory,
                // }));
                // // Assuming you want to set the first item in the array to the formData
                // if (mappedData.length > 0) {
                //   Object.assign(newFormData, mappedData[0]);
                // }
                setFormData(newFormData);
                // setForceUpdate(false);
            })
            .catch((err) => {
                // setLoading(false);
                console.log("Error: ", err);
                if (err.response.status === 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    }
    useEffect(() => {
        getSearchedAppointments()
    }, [])

    const handleProjectSubmit = () => {
        if (validateFormData()) {
            let tempData = new FormData();
            tempData.append("projectId", formData.projectId);
            tempData.append("date", formData.date);
            tempData.append("projectScope", formData.projectScope);
            tempData.append("projectCost", formData.projectCost);
            tempData.append("feeDescription", formData.feeDescription);
            tempData.append("discountFee", formData.discountFee);
            tempData.append("firstName", formData.firstName);
            tempData.append("lastName", formData.lastName);
            tempData.append("email", formData.email);
            tempData.append("siteAddress", formData.siteAddress);
            tempData.append("clientComment", formData.clientComment);
            tempData.append("personOnSite", formData.personOnSite);
            tempData.append("personOnSiteNumber", formData.personOnSiteNumber);
            tempData.append("clientNumber", formData.clientNumber);
            tempData.append("clientAddress", formData.clientAddress);
            tempData.append("clientCategory", formData.clientCategory);
            tempData.append("buildingType", formData.buildingType);
            tempData.append("buildingPurpose", formData.buildingPurpose);
            tempData.append("property_size", formData.property_size);
            tempData.append("buildingAge", formData.buildingAge);
            tempData.append("buildingStructure", formData.buildingStructure);
            tempData.append("buildingMaterialType", formData.buildingMaterialType);
            tempData.append("others", formData.others);
            tempData.append("relativeHumidity", formData.relativeHumidity);
            tempData.append("geographicLocation", formData.geographicLocation);
            tempData.append("inspectorName", formData.inspectorName);
            tempData.append("inspectorLicense", formData.inspectorLicense);
            tempData.append("jobSheetNotes", formData.jobSheetNotes);
            tempData.append("jobInterview", formData.jobInterview);
            tempData.append("jobSummary", formData.jobSummary);

            if (formData.coverPhoto) {
                tempData.append("coverPhoto", formData.coverPhoto);
            }
            Request.post("/createProject", tempData, {
                "Content-Type": "multipart/form-data",
            })
                .then((result) => {
                    // console.log("Data: ", result.data);
                    showToast("success", result?.data?.message);
                    setForceUpdate(true);
                })
                .catch((err) => {
                    // console.log("Error: ", err);
                    if (err.response.status == 401) {
                        showToast("error", "Unauthorized user.");
                    } else {
                        showToast("error", "Something went wrong.");
                    }
                });
        }
    };

    function getInspectors() {
        setLoading(true);

        Request.get("/getAllInspectors")
            .then((result) => {
                setLoading(false);
                setInspectors(result.data["data"]);
                setForceUpdate(false);
            })
            .catch((err) => {
                setLoading(false);
                // console.log("Error: ", err);
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    }
    useEffect(() => {
        if (forceUpdate) {
            getInspectors()
        }
    }, [forceUpdate]);

    function getMaterial(e) {
        console.log("eeeeeeee", e);
        setLoading(true);

        Request.get("/getDropdownValues/material")
            .then((result) => {
                setLoading(false);
                console.log("response", result);
                var data = result.data["values"].map((item, index) => ({
                    value: item.value, // Use clientName as the value
                    label: item.value
                }));
                setMaterialDropdown(data)
                // setInspectors(result.data["data"]);
                // for (let i = 0; i < result.data["values"].length; i++) {
                //   const ele = result.data["values"][i];
                //   if (ele.value.includes(e)) {
                //     data.push(ele);
                //   }
                // }
                // if (e) {
                //     setMaterialDropdown(result.data["values"].filter((ele) => ele.value.toLowerCase().includes(e.toLowerCase())));
                // } else {
                //     setMaterialDropdown([])
                // }
                // if (data.length > 0 && e) {
                //   setMaterialDropdown(data)
                // } else if (data.length > 0 && !e) {
                //   data = [];
                //   setMaterialDropdown([])
                // }
                setForceUpdate(false);
            })
            .catch((err) => {
                setLoading(false);
                // console.log("Error: ", err);
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    }

    function addMaterial(e) {
        setLoading(true);
        const obj = {
            dropdownName: "material",
            value: e
        }
        Request.post("/addDropdownValue", obj)
            .then((result) => {
                setLoading(false);
                console.log("add response", result);
                setOpenField(false);
                setFormData({ ...formData, material: "" })
                // setInspectors(result.data["data"]);
                setForceUpdate(false);
            })
            .catch((err) => {
                setLoading(false);
                // console.log("Error: ", err);
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    }
    useEffect(() => {

        if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
            getInspectors()
            // getMaterial();
        }
    }, [AppContext?.userAuthToken]);
    const handleOpenFieldClick = (event) => {
        // Prevent the event from propagating to the document click listener
        event.stopPropagation();
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 60,
            // width: "300px",
            borderRadius: "6px",
            border: state.isFocused
                ? "1px solid rgba(155, 46, 239, 1)"
                : "1px solid rgba(223, 222, 225, 1)",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid rgba(155, 46, 239, 1)",
            },
        }),
        indicatorSeparator: () => ({ display: 'none' }), // Hide the indicator separator
        dropdownIndicator: () => ({ display: 'none' }), // Hide the dropdown indicator
    };

    return (

        <div style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
            <Container
                maxWidth="lg"
                sx={{ pt: 3, pb: 5, backgroundColor: "rgba(255, 255, 255, 1)" }}
            >
                <NavBar />
                <Grid
                    item
                    xs={12}
                    md={8}
                    lg={11}
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Grid xs={11} md={12} lg={10.5}>
                        <>
                            <Box>
                                <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Pre-inspection</h3>
                                <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
                                    a. Project Information
                                </p>
                                {/* {(project.length > 0 ? project : [{}]).map((appt, index)  => ( */}
                                <Grid item xs={12} md={12} lg={12} container>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="Name"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.projectId}
                                            onChange={(e) => {
                                                let dupObj = { ...formData };
                                                dupObj.projectId = e.target.value;
                                                setFormData(dupObj);
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            Project ID:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="(Automatically generated)"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.date}
                                            onChange={(e) => {
                                                let dupObj = { ...formData };
                                                dupObj.date = e.target.value;
                                                setFormData(dupObj);
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Date:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="Samples Only"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.projectScope}
                                            onChange={(e) => {
                                                let dupObj = { ...formData };
                                                dupObj.projectScope = e.target.value;
                                                setFormData(dupObj);
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            Project Scope:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="Service Fee: $ X"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.projectCost}
                                            onChange={(e) => {
                                                let dupObj = { ...formData };
                                                dupObj.projectCost = e.target.value;
                                                setFormData(dupObj);
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            Project Cost:
                                        </p>
                                    </Grid>

                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="Fee Description"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "16px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            (+) add extra or discount fee if authorized by admin only
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="$ +X for an extra or $ -X for a discount"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {/* ))} */}
                                <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                                    <span
                                        style={{
                                            borderBottom: "2px solid rgba(106, 96, 169, 0.8)",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Note{" "}
                                    </span>
                                    Once set of two required information is manually entered a user entry
                                    by the user clicks on (+) above, the 2 files above pop-up to allow
                                    data.
                                </p>
                                <Button
                                    sx={{
                                        border: "1px solid rgba(46, 105, 255, 1)",
                                        backgroundColor: " rgba(46, 105, 255, 1)",
                                        color: "rgba(255, 255, 255, 1)",
                                        borderRadius: "4px",
                                        padding: "unset",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                        paddingRight: "18px",
                                        paddingLeft: "18px",
                                        "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                                        textTransform: "unset",
                                        mt: 2,
                                    }}
                                >
                                    Create Project
                                </Button>
                            </Box>

                            {/* <Box sx={{ mt: 4 }}>
        <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
          b. Client Information
        </p>
            <Grid item xs={12} md={12} lg={12} container>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.firstName}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.firstName = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  First Name:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.lastName}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.lastName = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Last Name:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.email}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.email = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  Email Address:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.siteAddress}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.siteAddress = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Inspection Site Address:
                </p>
              </Grid>
            </Grid>
      </Box> */}

                            {/* <Box sx={{ mt: 4 }}>
        <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
          c. Additional Client Information
        </p>
            <Grid item xs={12} md={12} lg={12} container>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientComment}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientComment = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  Client’s Note or Comment:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.personOnSite}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.personOnSite = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Person on site:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.personOnSiteNumber}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.personOnSiteNumber = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                  }}
                >
                  Person on site phone number:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientNumber}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientNumber = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Client’s phone number:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientAddress}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientAddress = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Client’s Home address:
                </p>
              </Grid>
              <Grid xs={12} md={12} lg={6}>
                <TextField
                  placeholder="Name"
                  id="outlined-start-adornment"
                  sx={{
                    width: "90%",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    borderRadius: "12px",
                    "& .MuiInputBase-formControl": {
                      borderRadius: "10px",
                      color: "rgba(155, 46, 239, 1) !important",
                    },
                    "& .MuiInputBase-formControl ::placeholder": {
                      color: "rgba(180, 177, 185, 1) !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(223, 222, 225, 1) !important",
                    },
                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(155, 46, 239, 1) !important",
                    },
                  }}
                  value={formData.clientCategory}
                  onChange={(e) => {
                    let dupObj = { ...formData };
                    dupObj.clientCategory = e.target.value;
                    setFormData(dupObj);
                  }}
                />
                <p
                  style={{
                    marginTop: "6px",
                    fontSize: "12px",
                    color: "rgba(155, 46, 239, 1)",
                    // width:"90%"
                  }}
                >
                  Client category:
                </p>
              </Grid>
            </Grid>
      </Box> */}

                            <Box sx={{ mt: 4 }}>
                                <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
                                    b. Building Information
                                </p>
                                <Grid item xs={12} md={12} lg={12} container>
                                    <Grid xs={12} md={12} lg={6}>
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            <Select
                                                displayEmpty
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    color: "rgba(155, 46, 239, 1)",
                                                }}
                                                value={formData.buildingType}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.buildingType = e.target.value;
                                                    setFormData(dupObj);
                                                }}
                                            >
                                                <MenuItem value={"Residential Buildings"}>Residential Buildings</MenuItem>
                                                <MenuItem value={"Non-residential Buildings"}>Non-residential Buildings</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Building Type:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            <Select
                                                displayEmpty
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    color: "rgba(155, 46, 239, 1)",
                                                }}
                                                value={formData.buildingPurpose}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.buildingPurpose = e.target.value;
                                                    setFormData(dupObj);
                                                }}
                                            >
                                                <MenuItem value={"Apartment"}>Apartment</MenuItem>
                                                <MenuItem value={"Single-family homes"}>Single-family homes</MenuItem>
                                                <MenuItem value={"Commercial"}>Commercial</MenuItem>
                                                <MenuItem value={"Others"}>Others</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Building Purpose:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={12}>
                                        <FormControl sx={{ mt: 1, width: "100%" }}>
                                            <TextField
                                                placeholder="Type your building age"
                                                id="outlined-start-adornment"
                                                sx={{
                                                    width: "95%",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiInputBase-formControl ::placeholder": {
                                                        color: "rgba(180, 177, 185, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                }}
                                                value={formData.property_size}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.property_size = e.target.value;
                                                    setFormData(dupObj);
                                                }}
                                            />

                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Property Size:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="Type your building age"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.buildingAge}
                                            onChange={(e) => {
                                                let dupObj = { ...formData };
                                                dupObj.buildingAge = e.target.value;
                                                setFormData(dupObj);
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            Building Age:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            <Select
                                                displayEmpty
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    color: "rgba(155, 46, 239, 1)",
                                                }}
                                                value={formData.buildingStructure}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.buildingStructure = e.target.value;
                                                    setFormData(dupObj);
                                                }}
                                            >
                                                <MenuItem value={"Exterior"}>Exterior</MenuItem>
                                                <MenuItem value={"Interior"}>Interior</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Building structure:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            <Select
                                                displayEmpty
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    color: "rgba(155, 46, 239, 1)",
                                                }}
                                                value={formData.buildingMaterialType}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.buildingMaterialType = e.target.value;
                                                    setFormData(dupObj);
                                                }}
                                            >
                                                <MenuItem value={"Roof"}>Roof</MenuItem>
                                                <MenuItem value={"Walls"}>Walls</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Building material types:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6} >
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            {/* <TextField
                                                ref={materialDropdownRef}
                                                style={{}}
                                                placeholder="Name"
                                                onFocus={(e) => getMaterial(e.target.value)}
                                                id="outlined-start-adornment"
                                                sx={{
                                                    // width: "90%",
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiInputBase-formControl ::placeholder": {
                                                        color: "rgba(180, 177, 185, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                }}
                                                value={formData.others}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.others = e.target.value;
                                                    getMaterial(e.target.value)
                                                    setFormData(dupObj);
                                                }}
                                            />
                                            {/* {formData.others.length > 0 && */}
                                            {/* <div
                                                style={{
                                                    position: "absolute",
                                                    backgroundColor: "white",
                                                    top: 50,
                                                    width: "100%",
                                                    borderBottomLeftRadius: "10px",
                                                    borderBottomRightRadius: "10px",
                                                    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
                                                    transition: "opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                                    display: formData.others.length > 0 ? "block" : "none"
                                                }}>
                                                {materialDropdown.map((item, index) => {
                                                    return (
                                                        <div style={{ margin: 10 }} key={index} onClick={() => handleMaterial(item.value)}>
                                                            {item.value}
                                                            <Divider className="diviDer" />
                                                        </div>
                                                    )
                                                })}
                                            </div> */}                                             <ReactSelect
                                                // arrowRenderer={null}
                                                value={formData.others}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.others = e;
                                                    getMaterial(e)
                                                    setMenuOpen(false)
                                                    setFormData(dupObj);
                                                }}
                                                options={materialDropdown}
                                                onInputChange={(inputValue, { action }) => {
                                                    if (action === 'input-change') {
                                                        // Set menuIsOpen to true when the user starts typing
                                                        setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
                                                    }
                                                }}
                                                onBlur={() => setMenuOpen(false)}
                                                isSearchable={true}
                                                styles={customStyles}
                                                placeholder="Material"
                                                menuIsOpen={isMenuOpen}
                                            />
                                        </FormControl>
                                        <p
                                            ref={wrapperRef}
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                cursor: "pointer"
                                                // width:"90%"
                                            }}
                                            onClick={() => setOpenField(!openField)}
                                        >
                                            + Material
                                        </p>
                                    </Grid>

                                    {openField &&
                                        <Grid
                                            xs={12}
                                            md={12}
                                            sx={{ display: "flex", gap: "15px" }}
                                            onClick={handleOpenFieldClick}
                                        >
                                            {/* <FormControl sx={{ mt: 1, width: "100%" }}> */}
                                            <Grid xs={12} md={12} lg={6} style={{ width: "100%" }}>
                                                <TextField
                                                    placeholder="Add material"
                                                    id="outlined-start-adornment"
                                                    sx={{
                                                        width: "90%",
                                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                                        borderRadius: "12px",
                                                        "& .MuiInputBase-formControl": {
                                                            borderRadius: "10px",
                                                            color: "rgba(155, 46, 239, 1) !important",
                                                        },
                                                        "& .MuiInputBase-formControl ::placeholder": {
                                                            color: "rgba(180, 177, 185, 1) !important",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "rgba(223, 222, 225, 1) !important",
                                                        },
                                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "rgba(155, 46, 239, 1) !important",
                                                        },
                                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "rgba(155, 46, 239, 1) !important",
                                                        },
                                                    }}
                                                    value={formData.material}
                                                    onChange={(e) => {
                                                        let dupObj = { ...formData };
                                                        dupObj.material = e.target.value;
                                                        // addMaterial(e.target.value)
                                                        setFormData(dupObj);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                                                <Button
                                                    sx={{
                                                        border: "1px solid rgba(46, 105, 255, 1)",
                                                        color: "rgba(255, 255, 255, 1)",
                                                        borderRadius: "4px",
                                                        width: "100%",
                                                        padding: "9px 0px",
                                                        backgroundColor: "rgba(46, 105, 255, 1)",
                                                        textTransform: "unset",
                                                        fontWeight: "unset",
                                                        "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                                                    }}
                                                    onClick={() => addMaterial(formData.material)}
                                                >
                                                    Add
                                                </Button>
                                            </Grid>
                                            {/* </FormControl> */}
                                            {/* <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
              onClick={() => setOpenField(true)}
            >
              + Material
            </p> */}
                                        </Grid>}
                                </Grid>

                                <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                                    <span style={{ fontWeight: "bold" }}>Note</span> (+) button allows to
                                    add new fields
                                </p>
                                <div {...getRootProps()} className="coverPhoto">
                                    <input {...getInputProps()} />
                                    {formData.coverPhoto ? (
                                        <img src={URL.createObjectURL(formData.coverPhoto)} alt="Selected" />
                                    ) : (
                                        <div className="coverCamera">
                                            <FontAwesomeIcon icon={faCamera} style={{ color: 'rgba(0, 0, 0, 1)' }} />
                                        </div>
                                    )}
                                </div>

                                <p
                                    style={{
                                        marginTop: "6px",
                                        fontSize: "10px",
                                        color: "rgba(155, 46, 239, 1)",
                                    }}
                                >
                                    Cover Photo:
                                </p>
                                <p
                                    style={{
                                        marginTop: "6px",
                                        fontSize: "15px",
                                        color: "rgba(155, 46, 239, 1)",
                                    }}
                                >
                                    Required before submitting the completed form.
                                </p>
                                <Grid item xs={12} md={12} lg={12} container>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="Name"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.relativeHumidity}
                                            onChange={(e) => {
                                                let dupObj = { ...formData };
                                                dupObj.relativeHumidity = e.target.value;
                                                setFormData(dupObj);
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Relative Humidity:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            <Select
                                                displayEmpty
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    color: "rgba(155, 46, 239, 1)",
                                                }}
                                                value={formData.geographicLocation}
                                                onChange={(e) => {
                                                    let dupObj = { ...formData };
                                                    dupObj.geographicLocation = e.target.value;
                                                    setFormData(dupObj);
                                                }}
                                            >
                                                <MenuItem value={"Northwest"}>Northwest</MenuItem>
                                                <MenuItem value={"Northeast"}>Northeast</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            Geographic Location:
                                        </p>
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* check */}
                            <Box sx={{ mt: 4 }}>
                                <p style={{ color: "rgba(106, 96, 169, 1)", fontWeight: "500" }}>
                                    c. Inspector Information
                                </p>
                                <Grid item xs={12} md={12} lg={12} container>
                                    <Grid xs={12} md={12} lg={6}>
                                        <FormControl sx={{ mt: 1, width: "90%" }}>
                                            <Select
                                                displayEmpty
                                                sx={{
                                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                                    borderRadius: "12px",
                                                    "& .MuiInputBase-formControl": {
                                                        borderRadius: "10px",
                                                        color: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                                    },
                                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                                    },
                                                    color: "rgba(155, 46, 239, 1)",
                                                }}
                                                value={formData.inspectorLicense}
                                                onChange={(e) => {
                                                    const selectedInspector = inspectors.find((insp) => insp.id === e.target.value);
                                                    let dupObj = { ...formData };
                                                    dupObj.inspectorName = selectedInspector ? `${selectedInspector.firstName} ${selectedInspector.lastName}` : "";
                                                    dupObj.inspectorLicense = selectedInspector ? selectedInspector.id : "";
                                                    // debugger
                                                    setFormData(dupObj);
                                                }}
                                            >
                                                {inspectors &&
                                                    inspectors.map((insp) => (
                                                        <MenuItem value={insp.id}>{`${insp?.firstName} ${insp?.lastName}`}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Inspector Full name
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={6}>
                                        <TextField
                                            placeholder="(Pre-populated based on the inspector name selected above)"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "90%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                            value={formData.inspectorLicense}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                            }}
                                        >
                                            Inspector License# :
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={12}>
                                        <TextField
                                            placeholder="Name"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "95%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Job Sheet Notes:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={12}>
                                        <TextField
                                            placeholder="Name"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "95%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Job Interview:
                                        </p>
                                    </Grid>
                                    <Grid xs={12} md={12} lg={12}>
                                        <TextField
                                            placeholder="Name"
                                            id="outlined-start-adornment"
                                            sx={{
                                                width: "95%",
                                                backgroundColor: "rgba(255, 255, 255, 1)",
                                                borderRadius: "12px",
                                                "& .MuiInputBase-formControl": {
                                                    borderRadius: "10px",
                                                    color: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "& .MuiInputBase-formControl ::placeholder": {
                                                    color: "rgba(180, 177, 185, 1) !important",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                                },
                                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                                },
                                            }}
                                        />
                                        <p
                                            style={{
                                                marginTop: "6px",
                                                fontSize: "12px",
                                                color: "rgba(155, 46, 239, 1)",
                                                // width:"90%"
                                            }}
                                        >
                                            Job Summary* :
                                        </p>
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "95%",
                                        mt: 1,
                                    }}
                                >
                                    <Button
                                        sx={{
                                            border: "1px solid rgba(46, 105, 255, 1)",
                                            color: "rgba(255, 255, 255, 1)",
                                            borderRadius: "6px",
                                            padding: "10px 32px !important",
                                            backgroundColor: "rgba(46, 105, 255, 1)",
                                            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                                            textTransform: "unset",
                                        }}
                                        onClick={handleProjectSubmit}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        sx={{
                                            border: "1px solid rgba(46, 105, 255, 1)",
                                            color: "rgba(46, 105, 255, 1)",
                                            borderRadius: "6px",
                                            padding: "10px 30px !important",
                                            "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
                                            textTransform: "unset",
                                        }}
                                    >
                                        Next
                                    </Button>
                                </Box>
                                <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            borderBottom: "2px solid rgba(106, 96, 169, 0.8)",
                                        }}
                                    >
                                        Note{" "}
                                    </span>
                                    this button should disabled if agreement above is not signed yet.
                                </p>
                            </Box>
                        </>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default InspectionProject;
