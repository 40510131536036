import { useState, useEffect} from "react";
import { Link, useLocation  } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  faUserSecret,
  faUsers,
  faUser,
  faCog,
  faUserTie,
  faHome,
  faFolderGear,
  faTable,
  faAddressBook,
  faFileText,
  faSignOut
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AppStorage } from "../utils/Context/AppContext";
import axios from "../utils/Requests/axios";


function SideBar({open}) {
  const AppContext = useContext(AppStorage);
  const [navItems, setNavItems] = useState([]);
  const location = useLocation();

  const links = [
    
    {
      title: "Home",
      path: "/home",
      access: ["Super Admin", "Admin", "Inspector"],
      icon: faHome,
    },
    {
      title: "Orders",
      path: "/order",
      access: ["Super Admin", "Admin", "Inspector"],
      icon: faTable,
    },
    {
      title: "File Management",
      path: "/fileManagement",
      access: ["Super Admin", "Admin", "Inspector"],
      icon: faFileText,
    },
    {
      title: "Clients",
      path: "/clientManagement",
      access: ["Super Admin", "Admin", "Inspector"],
      icon: faUsers,  
    },
    {
      title: "Inspectors",
      path: "/inspectorManagement",
      access: ["Super Admin", "Admin", "Inspector"],
      icon: faUserSecret,
    },
    {
      title: "Users",
      path: "/userManagement",
      access: ["Super Admin", "Admin"],
      icon: faUser,
    },
    {
      title: "Settings",
      path: "/pricingMatrix",
      access: ["Super Admin"],
      icon: faCog,
    },
    {
      title: "Manage Contacts",
      path: "/contactManagement",
      access: ["Super Admin", "Admin"],
      icon: faAddressBook,
    },
    
  ];
  useEffect(() => {
    if (AppContext?.userInfo) {
      if (AppContext?.userInfo.jobPosition == "Inspector") {
        setNavItems(links.filter((elem) => elem.access.includes("Inspector")));
      } else if (AppContext?.userInfo.jobPosition == "Admin") {
        setNavItems(links.filter((elem) => elem.access.includes("Admin")));
      } else if (AppContext?.userInfo.jobPosition == "Super Admin") {
        setNavItems(links.filter((elem) => elem.access.includes("Super Admin")))
      } else {
        setNavItems(links);
      }
    }
  }, [AppContext?.userInfo]);


 function logout() {
  AppContext?.setUserToken("");
  localStorage.removeItem("p_u_t");
  localStorage.removeItem("userInfo");
  delete axios.defaults.headers.common.Authorization;
  window.location.reload()
}
  return (
    <>
      {navItems.map((link, i) => (
        <ListItem key={i} disablePadding sx={{ display: "block", mb: 0.5}} className={`sideBarList ${location.pathname === link.path ? 'sideBarListActive' : ''}`}>
          <Link to={link.path} key={link.title}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className={`btnSidebar ${location.pathname === link.path ? 'btnSidebarActive' : ''}`}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 4 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon className={`sidebar-icon ${location.pathname === link.path ? 'sidebar-iconActive' : ''}`} icon={link.icon} />
              </ListItemIcon>
              <ListItemText
                primary={link.title}
                sx={{ opacity: open ? 1 : 0}}
              />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
      <ListItem disablePadding sx={{ display: "block", mb: 1}} className="sideBarList" onClick={logout}>
      <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className='btnSidebar'
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 4 : "auto",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon className="sidebar-icon" icon={faSignOut} />
              </ListItemIcon>
              <ListItemText
                primary="Log-Out"
                sx={{ opacity: open ? 1 : 0, color:"rgba(198, 203, 213, 1)"}}
              />
            </ListItemButton>
            </ListItem>
    </>
  );
}

export default SideBar;
