import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import ExteriorRoom from "./ExteriorRoom";
import InteriorRooms from "./InteriorRooms";
import { AppStorage } from "../utils/Context/AppContext";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";

function Inspection({ getInspection, projectId, setRadio }) {
  const AppContext = useContext(AppStorage);

  const initialState = {
    addRoomType: "",
    roomType: "",
    preliminaryPhotos: [],
    humidityPhoto: [],
    location: "",
    addLocation: "",
    relativeHumidity: "",
    inspectionId: getInspection,
    callout: [
      {
        callOutType: "",
        callOutIdName: "",
        callOutId: "",
        callOutLocation: "",
        callOutDescription: "",
        callOutSource: "",
        referencePhotos: [],
        reportPhotos: [],
        reportDescription: "",
        callOutMoisture: "",
        moisturePhoto: [],
        moisture: ""
      },
    ],
  };
  const initialStateInterior = {
    addRoomType: "",
    roomType: "",
    preliminaryPhotos: [],
    humidityPhoto: [],
    location: "",
    addLocation: "",
    relativeHumidity: "",
    inspectionId: getInspection,
    callout: [
      {
        callOutType: "",
        callOutIdName: "",
        callOutId: "",
        callOutLocation: "",
        callOutDescription: "",
        callOutSource: "",
        referencePhotos: [],
        reportPhotos: [],
        reportDescription: "",
        callOutMoisture: "",
        moisturePhoto: [],
        moisture: "",
        samples: [
          {
            sampleType: "",
            sampleTypeName: "",
            location: "",
            source: "",
            substrate: "",
            subStrateName: "",
            samplePhoto: []
          },
        ],
      },
    ],
    // samples: [
    //   {
    //     sampleType: "",
    //     sampleTypeName: "",
    //     location: "",
    //     source: "",
    //     substrate: "",
    //     subStrateName: ""
    //   },
    // ],
  };
  const [exteriorData, setExteriorData] = useState([initialState]);
  const [interiorData, setInteriorData] = useState([initialStateInterior]);
  console.log("exteriorData", exteriorData);
  // useEffect(() => {
  //   if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
  //     getRooms();
  //   }
  // }, [AppContext?.userAuthToken]);

  const addRoom = () => {
    setExteriorData([...exteriorData, initialState]);
  };
  const addInteriorRoom = () => {
    setInteriorData([...interiorData, initialStateInterior]);
  };
  // const addInteriorRoom = () => {
  //   const newRoom = (
  //     <InteriorRooms key={interiorRooms.length} getInspection={getInspection} />
  //   );
  //   setInteriorRooms([...interiorRooms, newRoom]);
  // };
  function getRooms() {
    Request.get(`/getEditRooms/${projectId}`)
      .then((result) => {
        if (result.data) {
          const dupArr = [];
          const interiorDupArr = []
          result.data["exteriorData"].forEach((item) => {
            dupArr.push({
              roomId: item.room?.id,
              roomType: { label: item?.room?.roomType, value: item?.room?.roomType } || "",
              preliminaryPhotos: item.room.preliminaryPhotos ? item.room.preliminaryPhotos.split(',') : [],
              humidityPhoto: item.room.humidityPhoto ? item.room.humidityPhoto.split(',') : [],
              location: { label: item?.room?.location, value: item?.room?.location } || "",
              relativeHumidity: item?.room?.relativeHumidity || "",
              callout: item?.room?.callOuts.map((callOut, index) => {
                return {
                  id: callOut.id,
                  callOutType: callOut.callOutType || "",
                  callOutIdName: callOut.callOutIdName || "",
                  callOutId: { label: callOut.id, value: callOut.id } || "",
                  callOutLocation: callOut.callOutLocation || "",
                  callOutDescription: callOut.callOutDescription || "",
                  callOutSource: callOut.callOutSource || "",
                  referencePhotos: callOut.referencePhotos ? callOut.referencePhotos.split(',') : [],
                  reportPhotos: callOut.reportPhotos ? callOut.reportPhotos.split(',') : [],
                  reportPhotoDescription: callOut.reportPhotoDescription || "",
                  callOutMoisture: callOut.moisture || "",
                  moisturePhoto: callOut.moisturePhoto ? callOut.moisturePhoto.split(',') : [],
                  moisture: callOut.moisture || ""
                }
              })
              // preliminaryPhotos:
              //   item?.room?.preliminaryPhotosUrls && item?.room?.preliminaryPhotos?.split(",") 
            })
          })
          result.data["interiorData"].forEach((item) => {
            interiorDupArr.push({
              roomId: item?.room?.id,
              roomType: { label: item?.room?.roomType, value: item?.room?.roomType } || "",
              preliminaryPhotos: item.room.preliminaryPhotos ? item.room.preliminaryPhotos.split(',') : [],
              humidityPhoto: item.room.humidityPhoto ? item.room.humidityPhoto.split(',') : [],
              location: { label: item?.room?.location, value: item?.room?.location } || "",
              relativeHumidity: item?.room?.relativeHumidity || "",
              callout: item?.room?.callOuts.map((callOut, index) => {
                return {
                  id: callOut.callOut.id,
                  callOutType: callOut.callOut.callOutType || "",
                  callOutIdName: callOut.callOut.callOutIdName || "",
                  callOutId: { label: callOut.callOut.id, value: callOut.callOut.id } || "",
                  callOutLocation: callOut.callOut.callOutLocation || "",
                  callOutDescription: callOut.callOut.callOutDescription || "",
                  callOutSource: callOut.callOut.callOutSource || "",
                  referencePhotos: callOut.callOut.referencePhotos ? callOut.callOut.referencePhotos.split(',') : [],
                  reportPhotos: callOut.callOut.reportPhotos ? callOut.callOut.reportPhotos.split(',') : [],
                  reportPhotoDescription: callOut.callOut.reportPhotoDescription || "",
                  callOutMoisture: callOut.callOut.moisture || "",
                  moisturePhoto: callOut.callOut.moisturePhoto ? callOut.callOut.moisturePhoto.split(',') : [],
                  moisture: callOut.moisture || "",
                  samples: callOut.callOut.samples.map((sample, index) => {
                    return {
                      sampleId: sample.id,
                      sampleType: { label: sample.sampleType, value: sample.sampleType },
                      location: sample.location,
                      source: sample.source,
                      substrate: sample.substrate,
                      samplePhoto: sample.samplePhoto ? sample.samplePhoto : []
                    }
                  })
                }
              })
              // preliminaryPhotos:
              //   item?.room?.preliminaryPhotosUrls && item?.room?.preliminaryPhotos?.split(",") 
            })
          })
          dupArr.length > 0 ? setExteriorData(dupArr) : setExteriorData(exteriorData)
          interiorDupArr.length > 0 ? setInteriorData(interiorDupArr) : setInteriorData(interiorData)
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err?.response?.data.message);
        }
      });
  }

  useEffect(() => {
    getRooms()
  }, [])

  return (
    <>
      {/* {exteriorRooms.map((room, index) => room)} */}
      {exteriorData.map((singleExteriorData, main_index) => (
        <ExteriorRoom
          key={main_index}
          main_index={main_index}
          singleExteriorData={singleExteriorData}
          getInspection={getInspection}
          exteriorData={exteriorData}
          setExteriorData={setExteriorData}
          projectId={projectId}
        />
      ))}
      <p onClick={addRoom} style={{ color: "rgba(106, 96, 169, 1)" }}>
        (+) Add next exterior room when applicable before moving to Interior
        Room section.
      </p>

      {/* {interiorRooms.map((room, index) => room)} */}
      {interiorData.map((singleInteriorData, main_index) => (
        <InteriorRooms
          key={main_index}
          main_index={main_index}
          singleInteriorData={singleInteriorData}
          getInspection={getInspection}
          interiorData={interiorData}
          setInteriorData={setInteriorData}
          projectId={projectId}

        />
      ))}
      <p onClick={addInteriorRoom} style={{ color: "rgba(106, 96, 169, 1)" }}>
        (+) Add next rooms.
      </p>
      <Button
        sx={{
          border: "1px solid rgba(46, 105, 255, 1)",
          color: "rgba(255, 255, 255, 1)",
          borderRadius: "4px",
          marginTop: "15px",
          padding: "7px 35px !important",
          backgroundColor: "rgba(46, 105, 255, 1)",
          "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
          textTransform: "unset",
        }}
        onClick={() => { setRadio("AttachmentA") }}
      >
        Next
      </Button>
    </>
  );
}

export default Inspection;
