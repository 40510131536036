import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  faBasketShopping,
  faArrowRight,
  faEllipsisVertical,
  faCaretRight,
  faDollar,
  faSuitcaseRolling,
  faCartPlus,
  faCoins
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import partialInspect from "../images/partialInspect.png";
import samples from "../images/samples.png";
import postInspect from "../images/postInspect.png";
import Houston from "../images/Houston.png";
import SanAntonio from "../images/SanAntonio.png";
import Austin from "../images/Austin.png";
import Dallas from "../images/Dallas.png";
import ApexCharts from "react-apexcharts";
import SplineChart from "./SplineChart";
import { AppStorage } from "../utils/Context/AppContext";
import Request from "../utils/Requests/Request";
import { showToast } from "../utils";

const ApexChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: ["rgba(241, 241, 243, 1)"],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
        borderRadius: 8,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        ["Sat"],
        ["Sun"],
        ["Mon"],
        ["Tue"],
        ["Wed"],
        ["Thurs"],
        ["Fri"],
      ],
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      data: [21, 22, 10, 28, 16, 21, 13],
    },
  ]);

  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </div>
  );
};

function Home() {
  const AppContext = useContext(AppStorage);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const [month, setMonth] = React.useState(10);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newOrders, setNewOrders] = useState([]);
  const [contractors, setContractor] = useState([]);
  const [cancelOrders, setCancelOrders] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [profitableMarketData, setProfitableMarketData] = useState([]);
  // console.log("profitableMarketData", profitableMarketData);
  const handleChange = (event) => {
    setMonth(event.target.value);
  };
  useEffect(() => {
    if (forceUpdate) {
      if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
        if (userInfo?.jobPosition === "Super Admin" || userInfo?.jobPosition === "Admin") {
          getMessages()
        }
      }
      getNewOrders()
      getCancelAppointmentCount()
      getRecentAppointments()
      getProfitableMarketData()
      getTotalContractors()
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      if (userInfo?.jobPosition === "Super Admin" || userInfo?.jobPosition === "Admin") {
        getMessages()
      }
      getNewOrders()
      getCancelAppointmentCount()
      getRecentAppointments()
      getProfitableMarketData()
      getTotalContractors()
    }
  }, [AppContext?.userAuthToken]);

  function getTotalContractors() {
    setLoading(true);
    Request.get(`/getAllContractors `)
      .then((result) => {
        setLoading(false);
        setContractor(result.data);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getNewOrders() {
    setLoading(true);
    Request.get(`/NewAppointmentCount`)
      .then((result) => {
        setLoading(false);
        setNewOrders(result.data);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getMessages() {
    setLoading(true);
    Request.get(`/getMessages`)
      .then((result) => {
        setLoading(false);
        console.log("message Log", result.data);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getCancelAppointmentCount() {
    setLoading(true);
    Request.get(`/CancelAppointmentCount`)
      .then((result) => {
        setLoading(false);
        setCancelOrders(result.data);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getRecentAppointments() {
    setLoading(true);
    Request.get(`/recentAppointment`)
      .then((result) => {
        setLoading(false);
        // console.log("sss", result.data);
        setRecentOrders(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getProfitableMarketData() {
    setLoading(true);
    Request.get(`/profitableMarketData`)
      .then((result) => {
        setLoading(false);
        // console.log("profitable market work zone", result.data);
        setProfitableMarketData(result.data["profitableMarketData"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  // useEffect(() => {
  //   getProfitableMarketData();
  // }, []);

  return (
    <Grid item xs={12} md={12} container spacing={2} sx={{ pt: 2 }}>
      <Grid item xs={12} sm={12} md={6.5} container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card
            style={{
              background:
                "linear-gradient(180deg, #002868 0%, #2BC3AB 156.58%)",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ paddingBottom: "0.4rem !important" }}>
              <Typography
                sx={{ fontSize: 18 }}
                color="rgba(198, 203, 213, 1)"
                gutterBottom
              >
                <span
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(5, 122, 142, 1)",
                    borderRadius: "4px",
                    color: "white",
                    marginRight: "0.75rem",
                  }}
                >
                  <FontAwesomeIcon icon={faBasketShopping} />
                </span>
                Total Sales
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "white", marginTop: "1em" }}
              >
                $42,442.00
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(198, 203, 213, 1)", marginTop: "1em" }}
              >
                {/* <span
                  style={{
                    fontSize: "smaller",
                    marginRight: "1.5rem",
                    color: "white",
                    fontWeight: "bold"
                  }}
                >
                  Payment (PY) : 0
                </span> */}
                <span style={{
                  fontSize: "smaller",
                  marginRight: "1.5rem",
                  color: "white",
                  fontWeight: "bold"
                }}>INV : 0</span>
                <span style={{
                  fontSize: "smaller",
                  marginRight: "1.5rem",
                  color: "white",
                  fontWeight: "bold"
                }}>RF : 0</span>
                {/* + 3.21% vs last month */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={6}>
          <Card
            style={{
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ paddingBottom: "0.4rem !important" }}>
              <Typography
                sx={{ fontSize: 18 }}
                color="rgba(191, 10, 48, 1)"
                gutterBottom
              >
                <span
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: "0.05rem solid rgba(191, 10, 48, 1)",
                    borderRadius: "4px",
                    color: "rgba(191, 10, 48, 1)",
                    marginRight: "0.75rem",
                  }}
                >
                  <FontAwesomeIcon icon={faUser} />
                </span>
                Total Contractors
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "rgba(0, 0, 0, 1)", marginTop: "1em" }}
              >
                {contractors?.totalCount}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 40, 104, 1)", marginTop: "1em" }}
              >
                <span
                  style={{
                    padding: "3px 5px",
                    backgroundColor: "rgba(0, 40, 104, 1)",
                    borderRadius: "12px",
                    fontSize: "smaller",
                    marginRight: "0.5rem",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ transform: "rotate(-45deg)" }}
                  />
                </span>
                <span style={{
                  fontSize: "smaller",
                  marginRight: "1.5rem",
                  color: "rgba(0, 40, 104, 1)",
                  fontWeight: "bold"
                }}>18.34%</span>
                + 3.21% vs last month
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6}>
          <Card
            style={{
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ paddingBottom: "0.4rem !important" }}>
              <Typography
                sx={{ fontSize: 18 }}
                color="rgba(191, 10, 48, 1)"
                gutterBottom
              >
                <span
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: "0.05rem solid rgba(191, 10, 48, 1)",
                    borderRadius: "4px",
                    color: "rgba(191, 10, 48, 1)",
                    marginRight: "0.75rem",
                  }}
                >
                  <FontAwesomeIcon icon={faDollar} />
                </span>
                New Orders
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "rgba(0, 0, 0, 1)", marginTop: "1em" }}
              >
                {newOrders?.totalCount}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 40, 104, 1)", marginTop: "1em" }}
              >
                <span
                  style={{
                    padding: "3px 5px",
                    backgroundColor: "rgba(0, 40, 104, 1)",
                    borderRadius: "12px",
                    fontSize: "smaller",
                    marginRight: "1.5rem",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ transform: "rotate(-45deg)" }}
                  />
                </span>
                + 3.21% vs last month
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={6}>
          <Card
            style={{
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ paddingBottom: "0.4rem !important" }}>
              <Typography
                sx={{ fontSize: 18 }}
                color="rgba(191, 10, 48, 1)"
                gutterBottom
              >
                <span
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: "0.05rem solid rgba(191, 10, 48, 1)",
                    borderRadius: "4px",
                    color: "rgba(191, 10, 48, 1)",
                    marginRight: "0.75rem",
                  }}
                >
                  <FontAwesomeIcon icon={faCartPlus} />
                </span>
                Orders Completed
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "rgba(0, 0, 0, 1)", marginTop: "1em" }}
              >
                $42,442.00
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 40, 104, 1)", marginTop: "1em" }}
              >
                <span
                  style={{
                    padding: "3px 5px",
                    backgroundColor: "rgba(0, 40, 104, 1)",
                    borderRadius: "12px",
                    fontSize: "smaller",
                    marginRight: "1.5rem",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ transform: "rotate(-45deg)" }}
                  />
                </span>
                + 3.21% vs last month
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={6}>
          <Card
            style={{
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ paddingBottom: "0.4rem !important" }}>
              <Typography
                sx={{ fontSize: 18 }}
                color="rgba(191, 10, 48, 1)"
                gutterBottom
              >
                <span
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: "0.05rem solid rgba(191, 10, 48, 1)",
                    borderRadius: "4px",
                    color: "rgba(191, 10, 48, 1)",
                    marginRight: "0.75rem",
                  }}
                >
                  <FontAwesomeIcon icon={faSuitcaseRolling} />
                </span>
                Orders Cancelled
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "rgba(0, 0, 0, 1)", marginTop: "1em" }}
              >
                {cancelOrders.totalCount}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 40, 104, 1)", marginTop: "1em" }}
              >
                <span
                  style={{
                    padding: "3px 5px",
                    backgroundColor: "rgba(0, 40, 104, 1)",
                    borderRadius: "12px",
                    fontSize: "smaller",
                    marginRight: "1.5rem",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ transform: "rotate(-45deg)" }}
                  />
                </span>
                + 3.21% vs last month
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6} md={6}>
          <Card
            style={{
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
            }}
          >
            <CardContent sx={{ paddingBottom: "0.4rem !important" }}>
              <Typography
                sx={{ fontSize: 18 }}
                color="rgba(191, 10, 48, 1)"
                gutterBottom
              >
                <span
                  style={{
                    padding: "8px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    border: "0.05rem solid rgba(191, 10, 48, 1)",
                    borderRadius: "4px",
                    color: "rgba(191, 10, 48, 1)",
                    marginRight: "0.75rem",
                  }}
                >
                  <FontAwesomeIcon icon={faCoins} />
                </span>
                Sample Collected
              </Typography>
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "rgba(0, 0, 0, 1)", marginTop: "1em" }}
              >
                $42,442.00
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(0, 40, 104, 1)", marginTop: "1em" }}
              >
                <span
                  style={{
                    padding: "3px 5px",
                    backgroundColor: "rgba(0, 40, 104, 1)",
                    borderRadius: "15px",
                    fontSize: "smaller",
                    marginRight: "1.5rem",
                    color: "rgba(255, 255, 255, 1)",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ transform: "rotate(-45deg)" }}
                  />
                </span>
                + 3.21% vs last month
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4.5} container>
        <Box
          sx={{
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 1)",
            padding: "16px",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              Profitable Markets
              <span
                style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
              >
                20 Profitable Items
              </span>
            </Typography>
            <div style={{ alignSelf: "center" }}>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                style={{ fontSize: "20px", color: "rgba(178, 174, 185, 1)" }}
              />
            </div>
          </Box>
          <Divider className="diviDer" />
          <Box
            sx={{
              // padding: "16px 18px",
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              gap: "10px"
            }}
          >
            <div style={{ color: "rgba(0, 40, 104, 1)" }}>
              EN
            </div>
            <div className="vertical-hr"></div>
            <div style={{ color: "rgba(0, 40, 104, 1)" }}>
              SP
            </div>
            <div className="vertical-hr"></div>
            <div style={{ color: "rgba(155, 46, 239, 1)" }}>
              ALL
            </div>
          </Box>
          {profitableMarketData.map((item, index) => {
            return (
              <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(243, 241, 246, 1)",
                      borderRadius: "50%",
                      padding: "18px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={Houston} alt="" style={{ objectFit: "contain" }} />
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.workZone}
                      <span
                        style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                      >
                        Lorem Ipsum is simply
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        // padding: "16px 18px",.
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "13px"
                      }}
                    >
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        {item.enCount}
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        {item.spCount}
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                        {item.totalCount}
                      </div>
                    </Box>
                    {/*   </Box>
                 </Box>
              <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(243, 241, 246, 1)",
                      borderRadius: "50%",
                      padding: "18px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={Austin} alt="" style={{ objectFit: "contain" }} />
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      Austin
                      <span
                        style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                      >
                        Lorem Ipsum is simply
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        // padding: "16px 18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "13px"
                      }}
                    >
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        10
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        10
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                        10
                      </div>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(243, 241, 246, 1)",
                      borderRadius: "50%",
                      padding: "18px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={SanAntonio} alt="" style={{ objectFit: "contain" }} />
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      San Antonio
                      <span
                        style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                      >
                        Lorem Ipsum is simply
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        // padding: "16px 18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "13px"
                      }}
                    >
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        10
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        10
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                        10
                      </div>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(243, 241, 246, 1)",
                      borderRadius: "50%",
                      padding: "18px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img src={Dallas} alt="" style={{ objectFit: "contain" }} />
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontWeight: "bold",
                      }}
                    >
                      Dallas
                      <span
                        style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                      >
                        Lorem Ipsum is simply
                      </span>
                    </Typography>
                    <Box
                      sx={{
                        // padding: "16px 18px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "13px"
                      }}
                    >
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        10
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                        10
                      </div>
                      <div className="vertical-hr"></div>
                      <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                        10
                      </div>
                    </Box> */}
                  </Box>
                </Box>
              </div>
            )
          })}
          {/* <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
              <div
                style={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={Houston} alt="" style={{ objectFit: "contain" }} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  Houston
                  <span
                    style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                  >
                    Lorem Ipsum is simply
                  </span>
                </Typography>
                <Box
                  sx={{
                    // padding: "16px 18px",.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "13px"
                  }}
                >
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                    10
                  </div>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
              <div
                style={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={Austin} alt="" style={{ objectFit: "contain" }} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  Austin
                  <span
                    style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                  >
                    Lorem Ipsum is simply
                  </span>
                </Typography>
                <Box
                  sx={{
                    // padding: "16px 18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "13px"
                  }}
                >
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                    10
                  </div>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
              <div
                style={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={SanAntonio} alt="" style={{ objectFit: "contain" }} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  San Antonio
                  <span
                    style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                  >
                    Lorem Ipsum is simply
                  </span>
                </Typography>
                <Box
                  sx={{
                    // padding: "16px 18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "13px"
                  }}
                >
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                    10
                  </div>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "15px", gap: "10px" }}>
              <div
                style={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "18px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={Dallas} alt="" style={{ objectFit: "contain" }} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  Dallas
                  <span
                    style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                  >
                    Lorem Ipsum is simply
                  </span>
                </Typography>
                <Box
                  sx={{
                    // padding: "16px 18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "13px"
                  }}
                >
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(0, 40, 104, 1)" }}>
                    10
                  </div>
                  <div className="vertical-hr"></div>
                  <div style={{ color: "rgba(155, 46, 239, 1)" }}>
                    10
                  </div>
                </Box>
              </Box>
            </Box>
          </div> */}
        </Box>
      </Grid >
      <Grid item xs={12} sm={12} md={8} container>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 1)",
            padding: "16px",
            borderRadius: "10px"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Sales Statistics</Typography>
            <div style={{ alignSelf: "center" }}>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                style={{ fontSize: "20px", color: "rgba(178, 174, 185, 1)" }}
              />
            </div>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                color: "rgba(191, 10, 48, 1)",
              }}
            >
              18.34%
              <span style={{ color: "rgba(191, 10, 48, 1)", fontSize: "12px" }}>
                from prev.month
              </span>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
              }}
            >
              12,246
              <span
                style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
              >
                Completed
              </span>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
              }}
            >
              13,856
              <span
                style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
              >
                New Orders
              </span>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
              }}
            >
              13,529
              <span
                style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
              >
                Pending
              </span>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
              }}
            >
              10,869
              <span
                style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
              >
                Cancelled
              </span>
            </Typography>
          </Box>
          <ApexChart />
        </Box>
      </Grid>
      {/* spline area chart */}
      <Grid item xs={12} sm={12} md={6.5} >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 1)",
            padding: "16px",
            borderRadius: "10px"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              Monthly Revenue
              <span
                style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
              >
                See insights on how your revenue has grown & changed over time
              </span>
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ mr: 1, minWidth: 120 }}>
                <Select
                  value={month}
                  onChange={handleChange}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value={10}>Current Month</MenuItem>
                  <MenuItem value={20}>Last Month</MenuItem>
                </Select>
              </FormControl>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                style={{ fontSize: "20px", color: "rgba(178, 174, 185, 1)" }}
              />
            </div>
          </Box>
          <SplineChart />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={5.5} >
        <Box
          sx={{
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 1)",
            padding: "16px",
            width: "100%",
            border: "2px solid rgba(33, 147, 233, 1)"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", padding: "8px 0px" }}>
            <Typography
              variant="h5"
            >
              Recent Orders
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ mr: 1, minWidth: 120 }}>
                <Select
                  value={month}
                  onChange={handleChange}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value={10}>Current Month</MenuItem>
                  <MenuItem value={20}>Last Month</MenuItem>
                </Select>
              </FormControl>
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                style={{ fontSize: "20px", color: "rgba(178, 174, 185, 1)" }}
              />
            </div>
          </Box>
          <Divider className="diviDer" />
          {!!recentOrders.length && recentOrders.map((order) => (
            <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
              <div
                style={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "22px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={Houston} alt="" style={{ objectFit: "contain" }} />
              </div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontWeight: "bold",
                  }}
                >
                  {order?.clientName}
                  <span
                    style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                  >
                    {!order?.clientCity || !order?.clientIso ? "" : `${order?.clientCity},${order.clientIso}`}
                  </span>
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                  <span style={{ color: "rgba(168, 146, 22, 1)" }}>{order?.date.split(' ')[1]}</span>
                  <Box
                    sx={{
                      backgroundColor: "rgba(243, 241, 246, 1)",
                      borderRadius: "50%",
                      height: "70%",
                      padding: "16px 18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      style={{ color: "rgba(5, 122, 142, 1)", fontSize: "smaller" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}


          {/* <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
            <div
              style={{
                backgroundColor: "rgba(243, 241, 246, 1)",
                borderRadius: "50%",
                padding: "22px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={partialInspect}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "bold",
                }}
              >
                Partial Inspection
                <span
                  style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                >
                  Alpine Drive (Beverly Hills)
                </span>
              </Typography>
              <Box sx={{display:"flex", alignItems:"center", gap:"1rem"}}>
              <span style={{color:"rgba(168, 146, 22, 1)"}}>19-06-2023</span>
              <Box
                sx={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  height: "70%",
                  padding: "16px 18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ color: "rgba(5, 122, 142, 1)", fontSize:"smaller" }}
                />
              </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
            <div
              style={{
                backgroundColor: "rgba(243, 241, 246, 1)",
                borderRadius: "50%",
                padding: "22px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={samples} alt="" style={{ objectFit: "contain" }} />
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "bold",
                }}
              >
                Samples Only
                <span
                  style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                >
                  South La Cienega Boulevard
                </span>
              </Typography>
              <Box sx={{display:"flex", alignItems:"center", gap:"1rem"}}>
              <span style={{color:"rgba(168, 146, 22, 1)"}}>19-06-2023</span>
              <Box
                sx={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  height: "70%",
                  padding: "16px 18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ color: "rgba(5, 122, 142, 1)", fontSize:"smaller" }}
                />
              </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
            <div
              style={{
                backgroundColor: "rgba(243, 241, 246, 1)",
                borderRadius: "50%",
                padding: "22px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={postInspect} alt="" style={{ objectFit: "contain" }} />
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "bold",
                }}
              >
                Post-Inspection
                <span
                  style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                >
                  Woods Avenue (East LA)
                </span>
              </Typography>
              <Box sx={{display:"flex", alignItems:"center", gap:"1rem"}}>
              <span style={{color:"rgba(168, 146, 22, 1)"}}>19-06-2023</span>
              <Box
                sx={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  height: "70%",
                  padding: "16px 18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ color: "rgba(5, 122, 142, 1)", fontSize:"smaller" }}
                />
              </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", marginTop: "10px", gap: "10px" }}>
            <div
              style={{
                backgroundColor: "rgba(243, 241, 246, 1)",
                borderRadius: "50%",
                padding: "22px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={Houston} alt="" style={{ objectFit: "contain" }} />
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "bold",
                }}
              >
                Protocol
                <span
                  style={{ color: "rgba(178, 174, 185, 1)", fontSize: "12px" }}
                >
                  20 Profitable Items
                </span>
              </Typography>
              <Box sx={{display:"flex", alignItems:"center", gap:"1rem"}}>
              <span style={{color:"rgba(168, 146, 22, 1)"}}>19-06-2023</span>
              <Box
                sx={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  height: "70%",
                  padding: "16px 18px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ color: "rgba(5, 122, 142, 1)", fontSize:"smaller" }}
                />
              </Box>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Grid>
    </Grid >
  );
}

export default Home;
