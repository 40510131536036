import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  faCamera,
  faCreditCard,
  faFileCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import { useDropzone } from 'react-dropzone';

function Receipt({ getInspection, projectId }) {
  const initialState = {
    paymentMethod: "",
    amountPaid: "",
    balance: "",
    squareReferenceNumber: "",
    document: "",
    inspectionId: getInspection,
  };
  const [receipt, setReceipt] = useState(initialState);
  const [receiptData, setReceiptData] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  // const [payment, setPayment] = useState("");

  const SubmitReceipt = () => {
    let tempData = new FormData();
    tempData.append("paymentMethod", receipt.paymentMethod);
    tempData.append("amountPaid", receipt.amountPaid);
    tempData.append("balance", receipt.balance);
    tempData.append("projectCost", receipt.projectCost);
    tempData.append("squareReferenceNumber", receipt.squareReferenceNumber);
    tempData.append("inspectionId", receipt.inspectionId);
    tempData.append("document", receipt.document);
    Request.post("/createReceipt", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        debugger
        showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };

  const getReciptData = () => {
    Request.get(`/getReceiptData/${projectId}`).
      then((result) => {
        setReceiptData(result.data["data"])
      })
      .catch((err) => {
        console.log("error", err);
      })
  }

  useEffect(() => {
    getReciptData();
  }, [])

  const onDrop = (acceptedFiles) => {
    let dupObj = { ...receipt };
    dupObj.document = acceptedFiles[0];
    setReceipt(dupObj);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.pdf', '.csv'], // Accept only image files
    multiple: false, // Allow only one file to be selected
  });
  const handleUploadButtonClick = () => {
    // Trigger the file input click event when the "Upload Document" button is clicked
    const input = document.getElementById("fileInput");
    if (input) {
      input.click();
    }
  };
  return (
    <>
      <Box>
        <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Receipt:</h3>
        <table className="table" style={{ marginTop: "15px" }}>
          <tr>
            <th className="th">Items</th>
            <th className="th">Basic Fee (US $)</th>
            <th className="th">Lab Extra Fee(US $)</th>
            <th className="th">Extra (US $)</th>
            <th className="th">Discount</th>
            <th className="th">Quantity</th>
            <th className="th">Total</th>
          </tr>
          {receiptData.map((item, index) => {
            return (
              <tr>
                <td className="td">{item.item}</td>
                <td className="td">{item.basicFee}</td>
                <td className="td">{item.extraLabFee}</td>
                <td className="td">{item.extra}</td>
                <td className="td">{item.discount}</td>
                <td className="td">{item.quantity}</td>
                <td className="td">{item.total}</td>
              </tr>
            )
          })}
          {/* <tr>
            <td className="td">Air</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
          </tr>

          <tr>
            <td className="td">Surface</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
          </tr>

          <tr>
            <td className="td">Other</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
            <td className="td">Pre-populated</td>
          </tr> */}
        </table>
        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Total Cost:
          </span>{" "}
          Automatic result
        </p>
      </Box>
      <Box sx={{ mt: 5 }}>
        <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Payment Method:</h3>
        <Box
          sx={{
            display: "flex",
            gap: "15px",
            width: "100%",
            mt: 3,
          }}
        >
          <div
            className="coverPhoto"
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: "unset",
              padding: "30px 0px",
              gap: "5px",
              border:
                receipt.paymentMethod === "Credit card"
                  ? "1px solid rgba(46, 105, 255, 1)"
                  : "1px solid rgba(46, 105, 255, 0.14)",
            }}
            value="Credit card"
            onClick={() => {
              let dupObj = { ...receipt };
              dupObj.paymentMethod = "Credit card";
              setReceipt(dupObj);
            }}
          >
            <div className="coverCamera">
              <FontAwesomeIcon
                icon={faCreditCard}
                style={{
                  color:
                    receipt.paymentMethod === "Credit card"
                      ? "rgba(46, 105, 255, 1)"
                      : "rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
            Credit card
          </div>
          <div
            className="coverPhoto"
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: "unset",
              padding: "30px 0px",
              gap: "5px",
              border:
                receipt.paymentMethod === "Invoice"
                  ? "1px solid rgba(46, 105, 255, 1)"
                  : "1px solid rgba(46, 105, 255, 0.14)",
            }}
            value="Invoice"
            onClick={() => {
              let dupObj = { ...receipt };
              dupObj.paymentMethod = "Invoice";
              setReceipt(dupObj);
            }}
          >
            <div className="coverCamera">
              <FontAwesomeIcon
                icon={faFileCirclePlus}
                style={{
                  color:
                    receipt.paymentMethod === "Invoice"
                      ? "rgba(46, 105, 255, 1)"
                      : "rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
            Invoice
          </div>
          <div
            className="coverPhoto"
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: "unset",
              padding: "30px 0px",
              gap: "5px",
              border:
                receipt.paymentMethod === "Check"
                  ? "1px solid rgba(46, 105, 255, 1)"
                  : "1px solid rgba(46, 105, 255, 0.14)",
            }}
            value="Check"
            onClick={() => {
              let dupObj = { ...receipt };
              dupObj.paymentMethod = "Check";
              setReceipt(dupObj);
            }}
          >
            <div className="coverCamera">
              <FontAwesomeIcon
                icon={faFileCirclePlus}
                style={{
                  color:
                    receipt.paymentMethod === "Check"
                      ? "rgba(46, 105, 255, 1)"
                      : "rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
            Check
          </div>
          <div
            className="coverPhoto"
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: "unset",
              padding: "30px 0px",
              gap: "5px",
              border:
                receipt.paymentMethod === "Cash"
                  ? "1px solid rgba(46, 105, 255, 1)"
                  : "1px solid rgba(46, 105, 255, 0.14)",
            }}
            value="Cash"
            onClick={() => {
              let dupObj = { ...receipt };
              dupObj.paymentMethod = "Cash";
              setReceipt(dupObj);
            }}
          >
            <div className="coverCamera">
              <FontAwesomeIcon
                icon={faFileCirclePlus}
                style={{
                  color:
                    receipt.paymentMethod === "Cash"
                      ? "rgba(46, 105, 255, 1)"
                      : "rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
            Cash
          </div>
        </Box>
        <Grid item xs={12} md={12} lg={12} container sx={{ mt: 3, mb: 3 }}>
          <Grid xs={12} md={12} lg={6}>
            <TextField
              placeholder="Type amount here"
              id="outlined-start-adornment"
              sx={{
                width: "95%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={receipt.amountPaid}
              onChange={(e) => {
                let dupObj = { ...receipt };
                dupObj.amountPaid = e.target.value;
                setReceipt(dupObj);
              }}
            />
            <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
            >
              Amount paid:
            </p>
          </Grid>
          <Grid xs={12} md={12} lg={6}>
            <TextField
              placeholder="Your account balance"
              id="outlined-start-adornment"
              sx={{
                width: "95%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={receipt.balance}
              onChange={(e) => {
                let dupObj = { ...receipt };
                dupObj.balance = e.target.value;
                setReceipt(dupObj);
              }}
            />
            <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
            >
              Balance:
            </p>
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <TextField
              placeholder="Type Square reference number"
              id="outlined-start-adornment"
              sx={{
                width: "98%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiInputBase-formControl ::placeholder": {
                  color: "rgba(180, 177, 185, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
              }}
              value={receipt.squareReferenceNumber}
              onChange={(e) => {
                let dupObj = { ...receipt };
                dupObj.squareReferenceNumber = e.target.value;
                setReceipt(dupObj);
              }}
            />
            <p
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
                // width:"90%"
              }}
            >
              Square reference number:
            </p>
          </Grid>
        </Grid>
        <div {...getRootProps()} className="coverPhoto" style={{ marginBottom: "15px" }}>
          <input {...getInputProps()} style={{ display: "none" }} id="fileInput" />
          {receipt.document ?
            <p style={{ fontSize: "x-small", color: "red" }}>{receipt.document.name}</p>
            :
            <div className="coverCamera">
              <FontAwesomeIcon
                icon={faCamera}
                style={{ color: "rgba(0, 0, 0, 1)" }}
              />
            </div>
          }
        </div>
        <Box sx={{ mb: 5 }}>
          <Button
            sx={{
              border: "1px solid rgba(46, 105, 255, 1)",
              color: "rgba(255, 255, 255, 1)",
              marginTop: "15px",
              borderRadius: "4px",
              padding: "10px 35px !important",
              backgroundColor: "rgba(46, 105, 255, 1)",
              "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
              textTransform: "unset",
              mt: 3,
            }}
            onClick={handleUploadButtonClick}
          >
            Upload Document
          </Button>
        </Box>
        <Button
          sx={{
            border: "1px solid rgba(46, 105, 255, 1)",
            color: "rgba(46, 105, 255, 1)",
            borderRadius: "4px",
            padding: "7px 35px !important",
            "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
            textTransform: "unset",
          }}
          onClick={SubmitReceipt}
        >
          Done
        </Button>
        <p
          style={{
            color: "rgba(106, 96, 169, 1)",
            lineHeight: "25.2px",
            marginTop: "25px",
          }}
        >
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Note
          </span>{" "}
          The attachment A, COC relinquishment and receipt should be separated
          from one another.
        </p>
      </Box>
    </>
  );
}

export default Receipt;
