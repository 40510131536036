import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
} from "@mui/material";
import NavBar from "../components/NavBar";
import Project from "../components/Project";
import Agreement from "../components/Agreement";
import Inspection from "../components/Inspection";
import AttachmentA from "../components/AttachmentA";
import COC from "../components/COC";
import Receipt from "../components/Receipt";
import { AppStorage } from "../utils/Context/AppContext";
import Request from "../utils/Requests/Request";
import { showToast } from "../utils";
import ReactSelect from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import UploadFilesInspection from "../components/UploadFilesInspection";

function JobDispatch() {
  const AppContext = useContext(AppStorage);
  // const { id } = useParams();

  const initialStateProject = {
    projectId: null,
    date: "",
    projectScope: "",
    projectCost: null,
    samplingFee: null,
    feeDescription: "",
    discountFee: "",
    firstName: "",
    lastName: "",
    email: "",
    siteAddress: "",
    clientComment: "",
    personOnSite: "",
    personOnSiteNumber: "",
    clientNumber: "",
    clientAddress: "",
    clientCategory: "",
    buildingType: "",
    buildingPurpose: "",
    property_size: "",
    buildingAge: "",
    buildingStructure: "",
    buildingMaterialType: "",
    others: "",
    material: "",
    extraLabFee: "",
    extraLabFeeDescription: "",
    coverPhoto: "",
    relativeHumidity: "",
    geographicLocation: "",
    inspectorName: "",
    inspectorLicense: "",
    jobSheetNotes: "",
    jobInterview: "",
    jobSummary: "",
    structure: [
      {
        buildingStructure: " ",
        buildingMaterialType: "",
        material: "",
        others: ""
      }
    ],
    jobSheetNotes: "",
    jobInterview: "",
    jobSummary: "",
  };
  const initialStateInspection = {
    workZone: "",
    pendingFiles: [],
    uploadFiles: [],
    // id: id,
    id: 1
  };
  const navigate = useNavigate();
  const params = useParams();
  // console.log("params", params);
  const [inspectors, setInspectors] = useState([]);
  const [inspectionData, setInspectionData] = useState(initialStateInspection);
  const [formData, setFormData] = useState(initialStateProject);
  const [radio, setRadio] = useState("Pre-inspection");
  const [workZoneSelect, setWorkZoneSelect] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({ value: params?.id, label: params?.id });
  const [searchResults, setSearchResults] = useState([]);
  const [getInspection, setGetInspection] = useState([])
  const [pendingFiles, setPendingFiles] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [workZone, setWorkZone] = useState([]);
  console.log("uploadFiles", uploadFiles);
  console.log("search", search);
  const handleChangeRadio = (e) => {
    setRadio(e);
  };
  useEffect(() => {
    if (forceUpdate) {
      fetchData();
      getInspectionForm();
      getInspectors();
      getAllWorkZones();
    }
  }, [forceUpdate]);


  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      fetchData();
      getInspectionForm();
      getInspectors();
      getAllWorkZones();
    }
  }, [AppContext?.userAuthToken]);

  const fetchData = async () => {
    try {
      setLoading(true);
      // Use your custom Request class to make the GET request
      const response = await Request.get("/getClientNamesDrop");
      const data = response.data.data.map((item) => ({
        value: item.id, // Use clientName as the value
        label: `${item.clientName} (${item.date})`, // Display both clientName and date in the label
      }));
      setSearchResults(data);
      if (params?.id) {
        const filter = data.find((e) => e.value === Number(params.id))
        // console.log("filter: ", filter);
        setSearch(filter)
        getAppointments()
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  function getSearchedAppointments(searchItem) {
    setLoading(true);

    // Request.get(`/getPreInspectionData/${params?.id ? params?.id : searchItem}`)
    Request.get(`/getPreInspectionData/${search.value}`)
      .then((result) => {

        setLoading(false);
        const preInspectionData = result.data.preInspectionData;
        // console.log("inspectionData", inspectors);
        // const licenceId = inspectors.find((insp) => insp.id === Number(preInspectionData.inspectorLicense));
        // console.log("licenceId", licenceId, preInspectionData.inspectorLicense);
        // const preInspectionData = result.data.preInspectionData;
        // console.log("result", result);
        const newFormData = {
          projectId: preInspectionData.projectId,
          date: preInspectionData.date,
          projectScope: preInspectionData.projectScope,
          projectCost: preInspectionData.projectCost,
          samplingFee: preInspectionData.samplingFee,
          feeDescription: preInspectionData.feeDescription,
          discountFee: preInspectionData.discountFee,
          // firstName: "",
          // lastName: "",
          // email: "",
          // siteAddress: "",
          // clientComment: "",
          // personOnSite: "",
          // personOnSiteNumber: "",
          // clientNumber: "",
          // clientAddress: "",
          // clientCategory: "",
          buildingType: preInspectionData.buildingType,
          buildingPurpose: preInspectionData.buildingPurpose,
          property_size: preInspectionData.property_size,
          buildingAge: preInspectionData.buildingAge,
          extraLabFee: preInspectionData.extraLabFee,
          extraLabFeeDescription: preInspectionData.extraLabFeeDescription,
          // structure: preInspectionData.buildingStructureData,
          structure: preInspectionData.buildingStructureData.length > 1 ? preInspectionData.buildingStructureData.map((item) => (
            {
              buildingStructure: item.buildingStructure,
              buildingMaterialType: item.buildingMaterialType,
              material: "",
              others: { value: item.material, label: item.material }
            }
          )) : [
            {
              buildingStructure: "",
              buildingMaterialType: "",
              material: "",
              others: ""
            }],
          coverPhoto: "",
          relativeHumidity: preInspectionData.relativeHumidity,
          geographicLocation: preInspectionData.geographicLocation,
          inspectorName: preInspectionData.inspectorId,
          inspectorLicense: preInspectionData.inspectorLicense,
          // inspectorLicense: preInspectionData.inspectorLicense,
          jobSheetNotes: preInspectionData.jobSheetNotes,
          jobInterview: preInspectionData.jobInterview,
          jobSummary: preInspectionData.jobSummary,
        }
        // const newFormData = { ...formData };
        // const mappedData = result.data.data.map((item) => ({
        //   projectId: item.id,
        //   date: item.date,
        //   projectScope: item?.quotation?.service_type,
        //   projectCost: item?.quotation?.price,
        //   firstName: item?.quotation?.firstName,
        //   lastName: item?.quotation?.lastName,
        //   email: item?.email,
        //   siteAddress: item?.siteAddress,
        //   clientComment: item?.comments,
        //   personOnSite: item?.personOnSite,
        //   personOnSiteNumber: item?.personOnSiteNumber,
        //   clientNumber: item?.clientPhone,
        //   clientAddress: item?.clientAddress,
        //   clientCategory: item?.clientCategory,
        // }));
        // // Assuming you want to set the first item in the array to the formData
        // if (mappedData.length > 0) {
        //   Object.assign(newFormData, mappedData[0]);
        // }
        setFormData(newFormData);
        // setForceUpdate(false);
      })
      .catch((err) => {
        // setLoading(false);
        console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getInspectors() {
    setLoading(true);

    Request.get("/getAllInspectors")
      .then((result) => {
        setLoading(false);
        setInspectors(result.data["data"]);
        // const data = result.data["data"].find((insp) => insp.id === formData.inspectorName);
        // let dupObj = { ...formData };
        // // dupObj.inspectorName = selectedInspector.id ? `${selectedInspector.id}` : "";
        // dupObj.inspectorLicense = data ? data.userCode : "";
        // // debugger
        // setFormData(dupObj);

        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function saveUploadFile() {
    if (!search.value) {
      showToast("error", "Please select client");
      return; // Prevent submission when the signature is empty
    }
    setLoading(true);
    // let tempData = new FormData();
    // tempData.append("projectId", search.value)
    // tempData.append("files", uploadFiles.map((item, index) => item.path).join(","))
    const obj = {
      projectId: search.value,
      files: uploadFiles.map((item, index) => item.path).join(","),
    }
    Request.post("/post-files-save", obj)
      .then((result) => {
        setLoading(false);
        console.log("upload file result", result);
        showToast("success", result?.data?.message);
        // setInspectors(result.data["data"]);
        // const data = result.data["data"].find((insp) => insp.id === formData.inspectorName);
        // let dupObj = { ...formData };
        // // dupObj.inspectorName = selectedInspector.id ? `${selectedInspector.id}` : "";
        // dupObj.inspectorLicense = data ? data.userCode : "";
        // // debugger
        // setFormData(dupObj);

        // setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const handleSelectChange = (e) => {
    setSearch(e);
  };
  const getAppointments = () => {
    getSearchedAppointments(search?.value);
  };

  function getInspectionForm() {
    Request.get(
      `/getInspectionApp/1`,
    )
      .then((result) => {
        // console.log("shagd", result?.data?.pendingFiles);
        setPendingFiles(result?.data?.pendingFiles.split(","))
        setGetInspection(result?.data?.id);
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err?.response?.data.message);
          navigate("/")

        }
      });
  }
  // file upload

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 60,
      // width: "300px",
      borderRadius: "6px",
      border: state.isFocused
        ? "1px solid rgba(155, 46, 239, 1)"
        : "1px solid rgba(223, 222, 225, 1)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(155, 46, 239, 1)",
      },
    }),
  };
  const handleInspectionSubmit = () => {
    // if (inspectionData.uploadFiles?.length === 0) {
    //   return showToast("error", "Please upload files");
    // }
    let tempData = {
      workZone: inspectionData.roomType,
      uploadFiles: inspectionData.uploadFiles.join(","),
      pendingFiles: inspectionData.pendingFiles.join(","),
      id: inspectionData.id,
    }
    //  console.log("tempData", tempData);
    Request.post("/createInspection", tempData)
      // "Content-Type": "multipart/form-data",)
      .then((result) => {
        //  console.log("Data: ", result.data);
        showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        //  console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };
  function getAllWorkZones() {
    setLoading(true);

    Request.get("/getWorkZone")
      .then((result) => {
        setLoading(false);
        setWorkZone(result.data["data"]);
        setWorkZoneSelect(result.data["data"][0].city)
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const removePendingFiles = (index) => {
    const updatedFiles = [...pendingFiles];
    updatedFiles.splice(index, 1);
    setPendingFiles(updatedFiles);
  };
  const removeUploadFiles = (index) => {
    const updatedFiles = [...uploadFiles];
    updatedFiles.splice(index, 1);
    setUploadFiles(updatedFiles);
  };
  return (
    <div style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
      <Container
        maxWidth="lg"
        sx={{ pt: 3, pb: 5, backgroundColor: "rgba(255, 255, 255, 1)" }}
      >
        <NavBar />
        <Grid
          item
          xs={12}
          md={8}
          lg={11}
          container
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid xs={11} md={12} lg={10.5}>
            <h3>Work Zone:</h3>
            {/* <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "18px",
                marginTop: "15px",
              }}
            >
              <div style={{ display: "flex", gap: "18px", marginTop: "15px" }}>
                {!!workZone.length &&
                  workZone.map((zone, i) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "fit-content",
                        gap: "8px",
                        cursor: "pointer"
                      }}
                      onClick={() => setWorkZoneSelect(zone?.city)}
                      key={i}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          border: `1px solid ${workZoneSelect === zone?.city ? "rgba(191, 10, 48, 1)" : "transparent"
                            }`,
                          padding: "3px",
                        }}
                      >
                        <div
                          style={{
                            width: "7px",
                            height: "7px",
                            backgroundColor: workZoneSelect === zone?.city ? "rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)",
                            borderRadius: "50%",
                            border: workZoneSelect === zone?.city ? "1px solid rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)",
                          }}
                        ></div>
                      </div>
                      <span style={{ color: workZoneSelect === zone?.city ? "rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)" }}>{zone?.city}</span>
                    </div>
                  ))}
              </div>
            </div> */}
            {/* <Box sx={{ display: "flex", flexWrap: "wrap", gap: "18px", mt: 5 }}>
              {!!workZone.length &&
                workZone.map((zone, i) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "fit-content",
                      gap: "8px",
                      cursor: "pointer",
                    }}
                    value="Pre-inspection"
                    onClick={() => setWorkZoneSelect(zone?.city)}
                  >
                    <div
                      style={{
                        borderRadius: "50%",
                        border: `1px solid ${workZoneSelect === zone?.city ? "rgba(191, 10, 48, 1)" : "transparent"
                          }`,
                        padding: "3px",
                      }}
                    >
                      <div
                        style={{
                          width: "7px",
                          height: "7px",
                          backgroundColor: workZoneSelect === zone?.city ? "rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)",
                          borderRadius: "50%",
                          border: workZoneSelect === zone?.city ? "1px solid rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)",
                        }}
                      ></div>
                    </div>
                    <span style={{ color: workZoneSelect === zone?.city ? "rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)" }}>{zone?.city}</span>
                  </div>
                ))}
            </Box>
            <Box
              sx={{
                width: "100%",
                mt: 4,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  border: "1px solid rgba(18, 17, 17, 0.5)",
                  borderBottom: "unset",
                }}
              >
                <Button
                  sx={{
                    border: "1px solid rgba(46, 105, 255, 1)",
                    backgroundColor: " rgba(46, 105, 255, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    borderRadius: "4px",
                    padding: "unset",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingRight: "18px",
                    paddingLeft: "18px",
                    "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                    textTransform: "unset",
                  }}
                  onClick={handleInspectionSubmit}
                >
                  Record Files
                </Button>
                <UploadFilesInspection inspectionData={inspectionData}
                  setInspectionData={setInspectionData} files={pendingFiles} setFiles={setPendingFiles}
                  flag={true} />

              </Box> */}
            {/* <table className="table">
              <tr>
                <th className="th">Name</th>
                <th className="th">Type</th>
                <th className="th">Size</th>
                <th className="th">File ID</th>
                <th className="th">Project ID</th>
              </tr>
              {!!pendingFiles.length && pendingFiles.map((file, index) => (
                <tr key={index}>
                  <td className="td">{file.name}
                    <button
                      style={{
                        borderRadius: "12px",
                        color: "white",
                        backgroundColor: "red",
                        border: "red",
                      }}
                      onClick={() => removePendingFiles(index)}
                    >
                      <span> X </span>
                    </button></td>
                  <td className="td">{file.type}</td>
                  <td className="td">{file.size} bytes</td>
                  <td className="td">File ID</td>
                  <td className="td">Project ID</td>
                </tr>
              ))}
            </table>
          </Box> */}

            <Box
              sx={{
                width: "100%",
                mt: 4,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  border: "1px solid rgba(18, 17, 17, 0.5)",
                  borderBottom: "unset",
                }}
              >
                <Button
                  sx={{
                    border: "1px solid rgba(46, 105, 255, 1)",
                    backgroundColor: " rgba(46, 105, 255, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    borderRadius: "4px",
                    padding: "unset",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingRight: "18px",
                    paddingLeft: "18px",
                    "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                    textTransform: "unset",
                  }}
                  onClick={() => saveUploadFile()}
                >
                  Upload Files
                </Button>
                <UploadFilesInspection inspectionData={inspectionData}
                  setInspectionData={setInspectionData} files={uploadFiles} setFiles={setUploadFiles}
                  flag={false} />
              </Box>
              <table className="table">
                <tr>
                  <th className="th">Name</th>
                  <th className="th">Type</th>
                  <th className="th">Size</th>
                  <th className="th">File ID</th>
                  <th className="th">Project ID</th>
                </tr>
                {uploadFiles.map((file, index) => (
                  <tr key={index}>
                    <td className="td">{file.name}
                      <button
                        style={{
                          borderRadius: "12px",
                          color: "white",
                          backgroundColor: "red",
                          border: "red",
                        }}
                        onClick={() => removeUploadFiles(index)}
                      >
                        <span> X </span>
                      </button></td>
                    <td className="td">{file.type}</td>
                    <td className="td">{file.size} bytes</td>
                    <td className="td">File ID</td>
                    <td className="td">Project ID</td>
                  </tr>
                ))}
              </table>
            </Box>

            <Box sx={{ mt: 6, width: "100%" }}>
              <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Search project by client:{" "}
                </span>
                First name, last name, company’s name and/or date of
                appointment.
              </p>
              <Grid
                xs={12}
                md={8}
                container
                spacing={2}
                sx={{ mb: 5 }}
              >
                <Grid item xs={12} md={8} container spacing={2}>
                  <Grid item xs={12} md={9} container>
                    <FormControl sx={{ mr: 1, width: "90%" }}>
                      <ReactSelect
                        value={search}
                        onChange={handleSelectChange}
                        options={searchResults}
                        isSearchable={true}
                        styles={customStyles}
                        placeholder="Search by name or date..."
                        isLoading={loading}
                      />
                      <span
                        style={{
                          marginTop: "6px",
                          fontSize: "12px",
                          color: "rgba(155, 46, 239, 1)",
                        }}
                      >
                        Search:
                      </span>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} container>
                    <Button
                      sx={{
                        border: "1px solid rgba(46, 105, 255, 1)",
                        color: "rgba(255, 255, 255, 1)",
                        borderRadius: "4px",
                        borderRadius: "4px",
                        maxHeight: "55px",
                        minWidth: "84px",
                        backgroundColor: "rgba(46, 105, 255, 1)",
                        textTransform: "unset",
                        fontWeight: "unset",
                        "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                      }}
                      onClick={getAppointments}
                    >
                      Go
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "18px", mt: 5 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "fit-content",
                  gap: "8px",
                  cursor: "pointer",
                }}
                value="Pre-inspection"
                onClick={() => handleChangeRadio("Pre-inspection")}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border:
                      radio === "Pre-inspection"
                        ? " 1px solid rgba(191, 10, 48, 1)"
                        : "transparent",
                    padding: "3px",
                  }}
                >
                  <div
                    style={{
                      width: "7px",
                      height: "7px",
                      backgroundColor:
                        radio === "Pre-inspection"
                          ? "rgba(191, 10, 48, 1)"
                          : "rgba(0, 40, 104, 1)",
                      borderRadius: "50%",
                      border:
                        radio === "Pre-inspection"
                          ? "1px solid rgba(191, 10, 48, 1)"
                          : "1px solid rgba(0, 40, 104, 1)",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    color:
                      radio === "Pre-inspection"
                        ? "rgba(191, 10, 48, 1)"
                        : "rgba(0, 40, 104, 1)",
                  }}
                >
                  Pre-inspection
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "fit-content",
                  gap: "8px",
                  cursor: "pointer",
                }}
                value="Agreement"
                onClick={() => handleChangeRadio("Agreement")}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border:
                      radio === "Agreement"
                        ? "1px solid rgba(191, 10, 48, 1)"
                        : "transparent",
                    padding: "3px",
                  }}
                >
                  <div
                    style={{
                      width: "7px",
                      height: "7px",
                      backgroundColor:
                        radio === "Agreement"
                          ? "rgba(191, 10, 48, 1)"
                          : "rgba(0, 40, 104, 1)",
                      borderRadius: "50%",
                      border:
                        radio === "Agreement"
                          ? "1px solid rgba(191, 10, 48, 1)"
                          : "1px solid rgba(0, 40, 104, 1)",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    color:
                      radio === "Agreement"
                        ? "rgba(191, 10, 48, 1)"
                        : "rgba(0, 40, 104, 1)",
                  }}
                >
                  Agreement
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "fit-content",
                  gap: "8px",
                  cursor: "pointer",
                }}
                value="Inspection"
                onClick={() => handleChangeRadio("Inspection")}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border:
                      radio === "Inspection"
                        ? "1px solid rgba(191, 10, 48, 1)"
                        : "transparent",
                    padding: "3px",
                  }}
                >
                  <div
                    style={{
                      width: "7px",
                      height: "7px",
                      backgroundColor:
                        radio === "Inspection"
                          ? "rgba(191, 10, 48, 1)"
                          : "rgba(0, 40, 104, 1)",
                      borderRadius: "50%",
                      border:
                        radio === "Inspection"
                          ? "1px solid rgba(191, 10, 48, 1)"
                          : "1px solid rgba(0, 40, 104, 1)",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    color:
                      radio === "Inspection"
                        ? "rgba(191, 10, 48, 1)"
                        : "rgba(0, 40, 104, 1)",
                  }}
                >
                  Inspection
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "fit-content",
                  gap: "8px",
                  cursor: "pointer",
                }}
                value="AttachmentA"
                onClick={() => handleChangeRadio("AttachmentA")}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border:
                      radio === "AttachmentA"
                        ? "1px solid rgba(191, 10, 48, 1)"
                        : "transparent",
                    padding: "3px",
                  }}
                >
                  <div
                    style={{
                      width: "7px",
                      height: "7px",
                      backgroundColor:
                        radio === "AttachmentA"
                          ? "rgba(191, 10, 48, 1)"
                          : "rgba(0, 40, 104, 1)",
                      borderRadius: "50%",
                      border:
                        radio === "AttachmentA"
                          ? "1px solid rgba(191, 10, 48, 1)"
                          : "1px solid rgba(0, 40, 104, 1)",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    color:
                      radio === "AttachmentA"
                        ? "rgba(191, 10, 48, 1)"
                        : "rgba(0, 40, 104, 1)",
                  }}
                >
                  Attachment A
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "fit-content",
                  gap: "8px",
                  cursor: "pointer",
                }}
                value="COC"
                onClick={() => handleChangeRadio("COC")}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border:
                      radio === "COC"
                        ? "1px solid rgba(191, 10, 48, 1)"
                        : "transparent",
                    padding: "3px",
                  }}
                >
                  <div
                    style={{
                      width: "7px",
                      height: "7px",
                      backgroundColor:
                        radio === "COC"
                          ? "rgba(191, 10, 48, 1)"
                          : "rgba(0, 40, 104, 1)",
                      borderRadius: "50%",
                      border:
                        radio === "COC"
                          ? "1px solid rgba(191, 10, 48, 1)"
                          : "1px solid rgba(0, 40, 104, 1)",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    color:
                      radio === "COC"
                        ? "rgba(191, 10, 48, 1)"
                        : "rgba(0, 40, 104, 1)",
                  }}
                >
                  COC
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "fit-content",
                  gap: "8px",
                  cursor: "pointer",
                }}
                value="Receipt"
                onClick={() => handleChangeRadio("Receipt")}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border:
                      radio === "Receipt"
                        ? "1px solid rgba(191, 10, 48, 1)"
                        : "transparent",
                    padding: "3px",
                  }}
                >
                  <div
                    style={{
                      width: "7px",
                      height: "7px",
                      backgroundColor:
                        radio === "Receipt"
                          ? "rgba(191, 10, 48, 1)"
                          : "rgba(0, 40, 104, 1)",
                      borderRadius: "50%",
                      border:
                        radio === "Receipt"
                          ? "1px solid rgba(191, 10, 48, 1)"
                          : "1px solid rgba(0, 40, 104, 1)",
                    }}
                  ></div>
                </div>
                <span
                  style={{
                    color:
                      radio === "Receipt"
                        ? "rgba(191, 10, 48, 1)"
                        : "rgba(0, 40, 104, 1)",
                  }}
                >
                  Receipt
                </span>
              </div>
            </Box>
            {radio === "Pre-inspection" && (
              <div id="project">
                <Project
                  formData={formData}
                  setFormData={setFormData}
                  projectId={search.value}
                  inspectors={inspectors}
                  setInspectors={setInspectors}
                  radio={radio}
                  setRadio={setRadio}
                />
              </div>
            )}
            {radio === "Agreement" && <Agreement getInspection={getInspection} projectId={search.value} setRadio={setRadio} />}
            {radio === "Inspection" && <Inspection getInspection={getInspection} projectId={search.value} setRadio={setRadio} />}
            {radio === "AttachmentA" && <AttachmentA projectId={search.value} setRadio={setRadio} />}
            {radio === "COC" && <COC getInspection={getInspection} projectId={search.value} setRadio={setRadio} />}
            {radio === "Receipt" && <Receipt getInspection={getInspection} projectId={search.value} />}
          </Grid>
        </Grid>
      </Container>
    </div >
  );
}

export default JobDispatch;
