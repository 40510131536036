import { Box, Button, Container, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import ReactSelect from 'react-select';
import Request from '../utils/Requests/Request'
import { useNavigate } from 'react-router-dom'
import InspectionHomeData from '../components/InspectionHomeData';

const InspectionHome = () => {
    const navigate = useNavigate();
    const initialStateProject = {
        projectId: null,
        date: "",
        projectScope: "",
        projectCost: null,
        feeDescription: "",
        discountFee: "",
        firstName: "",
        lastName: "",
        email: "",
        siteAddress: "",
        clientComment: "",
        personOnSite: "",
        personOnSiteNumber: "",
        clientNumber: "",
        clientAddress: "",
        clientCategory: "",
        buildingType: "",
        buildingPurpose: "",
        property_size: "",
        buildingAge: "",
        buildingStructure: "",
        buildingMaterialType: "",
        others: "",
        coverPhoto: "",
        relativeHumidity: "",
        geographicLocation: "",
        inspectorName: "",
        inspectorLicense: "",
        jobSheetNotes: "",
        jobInterview: "",
        jobSummary: "",
    };
    const [formData, setFormData] = useState(initialStateProject);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [jobData, setJobData] = useState({})
    const [data, setData] = useState([{

    }]);
    console.log("jobData", data);
    console.log("search", search, id, searchResults);
    const fetchData = async (clientName) => {
        try {
            setLoading(true);
            // Use your custom Request class to make the GET request
            const response = await Request.get(`/getClientDataByName/All`);
            setData(response.data.data)
            // const data = response.data.data.map((item) => ({
            //     value: item.id, // Use clientName as the value
            //     label: `${item.name}`, // Display both clientName and date in the label
            // }));
            // setSearchResults(data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    const getClientName = async () => {
        try {
            setLoading(true);
            // Use your custom Request class to make the GET request
            const response = await Request.get("/getClientNamesDrop");
            // setData(response.data.data)
            const data = response.data.clientNames.map((item) => ({
                value: item, // Use clientName as the value
                label: `${item}`, // Display both clientName and date in the label
            }));
            data.push({ value: "All", label: "All" });
            setSearchResults(data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        // getClientName();
        fetchData()
    }, [])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 60,
            width: "300px",
            borderRadius: "6px",
            border: state.isFocused
                ? "1px solid rgba(155, 46, 239, 1)"
                : "1px solid rgba(223, 222, 225, 1)",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid rgba(155, 46, 239, 1)",
            },
        }),
    };

    // const jobDetail = (e) => {
    //     console.log("e", e);
    //     // setSearch(e);
    //     for (var i = 0; i < data.length; i++) {
    //         const ele = data[i];
    //         if (e === ele.id) {
    //             setJobData(ele);
    //             return;
    //         }
    //     }
    //     // setJobData()
    // };

    const handleChange = (e) => {
        console.log(
            "eee", e
        );
        setSearch(e);
        setId(e.value)
        fetchData(e.value);
        // jobDetail(e.value);
    }


    const updateFormData = (newData) => {
        setFormData(newData);
    }

    return (
        <div style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>

            <Container
                maxWidth="lg"
                sx={{ pt: 3, pb: 5, backgroundColor: "rgba(255, 255, 255, 1)" }}
            >
                <NavBar />
                <Box
                    sx={{
                        width: "100%",
                        mt: 4,
                    }}
                >
                    <Grid item xs={12} md={8} container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ mr: 1, width: "100%" }}>
                                {/* <Select
                                    value={search}
                                    displayEmpty
                                    onChange={(e) => {
                                        // let dupObj = { ...discountData };
                                        // dupObj.search = e.target.value;
                                        setSearch(e.target.value);
                                        jobDetail(e.target.value)
                                    }}
                                    sx={{
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        borderRadius: "12px",
                                        "& .MuiInputBase-formControl": {
                                            borderRadius: "10px",
                                            color: "rgba(155, 46, 239, 1) !important",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(223, 222, 225, 1) !important",
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(155, 46, 239, 1) !important",
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(155, 46, 239, 1) !important",
                                        },
                                        color: "rgba(155, 46, 239, 1)",
                                    }}
                                >
                                    {!!data.length &&
                                        data.map((app, i) => (
                                            <MenuItem value={app.id} key={i}>
                                                {app.name}
                                            </MenuItem>
                                        ))}
                                </Select> */}
                                {/* <ReactSelect
                                    value={search}
                                    onChange={handleChange}
                                    options={searchResults}
                                    isSearchable={true}
                                    styles={customStyles}
                                    placeholder="Search by name..."
                                    isLoading={loading}
                                />
                                <span
                                    style={{
                                        marginTop: "6px",
                                        fontSize: "12px",
                                        color: "rgba(155, 46, 239, 1)",
                                    }}
                                >
                                    Client Name:
                                </span> */}
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <FormControl sx={{ mr: 1, width: "100%" }}>
                                <TextField
                                    placeholder="Id"
                                    id="outlined-start-adornment"
                                    value={id}
                                    displayEmpty
                                    onChange={(e) => {
                                        setId(e.target.value);
                                        const searchValue = searchResults.find((ele) => ele.value === Number(e.target.value))
                                        console.log(
                                            "searchValue", searchValue
                                        );
                                        setSearch(searchValue)
                                        jobDetail(Number(e.target.value))
                                    }}
                                    sx={{
                                        width: "100%",
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        borderRadius: "12px",
                                        "& .MuiInputBase-formControl": {
                                            borderRadius: "10px",
                                            color: "rgba(155, 46, 239, 1) !important",
                                        },
                                        "& .MuiInputBase-formControl ::placeholder": {
                                            color: "rgba(180, 177, 185, 1) !important",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(223, 222, 225, 1) !important",
                                        },
                                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(155, 46, 239, 1) !important",
                                        },
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "rgba(155, 46, 239, 1) !important",
                                        },
                                    }}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <FontAwesomeIcon icon={faPenToSquare} />
                                //         </InputAdornment>
                                //     ),
                                // }}
                                />
                                <span
                                    style={{
                                        marginTop: "6px",
                                        fontSize: "12px",
                                        color: "rgba(155, 46, 239, 1)",
                                    }}
                                >
                                    Client Id:
                                </span>
                            </FormControl>
                        </Grid> */}
                    </Grid>

                    {/* <Box sx={{ mt: 6, width: "100%" }}>
                        <ReactSelect
                            value={search}
                            onChange={handleChange}
                            options={searchResults}
                            isSearchable={true}
                            styles={customStyles}
                            placeholder="Search by name..."
                            isLoading={loading}
                        />
                    </Box> */}
                    {/* <Box sx={{ mt: 6, width: "100%" }}>
                        <ReactSelect
                            value={id}
                            onChange={handleChange}
                            options={searchResults}
                            isSearchable={true}
                            styles={customStyles}
                            placeholder="Search by name or date..."
                            isLoading={loading}
                        />
                    </Box> */}
                    {data.map((jobData, index) => {
                        return (
                            <InspectionHomeData
                                jobData={jobData}
                                search={search}
                            />
                            // <>
                            //     <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>{jobData?.name}</h3>
                            //     <h5 style={{ color: "rgba(106, 96, 169, 1)" }}>{jobData?.id}</h5>

                            //     <Box
                            //         sx={{
                            //             p: 2,
                            //             border: "1px solid #12111180",
                            //             display: "flex",
                            //             flexDirection: "column"
                            //             // borderBottom: "unset",
                            //         }}
                            //     >
                            //         <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            //             <span style={{ fontWeight: 400, fontSize: "18px" }}>
                            //                 Date and Time:{" "}
                            //             </span>
                            //             {jobData?.date}
                            //         </p>
                            //         <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            //             <span style={{ fontWeight: 400, fontSize: "18px" }}>
                            //                 Inspection Site Address:{" "}
                            //             </span>
                            //             {jobData?.siteAddress}
                            //         </p>
                            //         <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            //             <span style={{ fontWeight: 400, fontSize: "18px" }}>
                            //                 Resource (Inspector Name):{" "}
                            //             </span>
                            //             {jobData?.inspectorName}
                            //         </p>
                            //     </Box>
                            //     <Grid item xs={12} md={8} container spacing={2}>
                            //         <Grid item >
                            //             <Button
                            //                 sx={{
                            //                     border: "1px solid rgba(46, 105, 255, 1)",
                            //                     backgroundColor: " rgba(46, 105, 255, 1)",
                            //                     color: "rgba(255, 255, 255, 1)",
                            //                     borderRadius: "4px",
                            //                     padding: "unset",
                            //                     paddingTop: "6px",
                            //                     paddingBottom: "6px",
                            //                     paddingRight: "18px",
                            //                     paddingLeft: "18px",
                            //                     "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                            //                     textTransform: "unset",
                            //                     mt: 2,
                            //                 }}
                            //                 onClick={() => handleCreateProject(jobData.id)}
                            //             >
                            //                 Create Project
                            //             </Button>
                            //         </Grid>
                            //         <Grid item xs={6} md={6}>
                            //             <p
                            //                 style={{
                            //                     color: " rgba(46, 105, 255, 1)",
                            //                     textDecoration: " underline",
                            //                     textDecorationColor: "rgba(46, 105, 255, 1)",
                            //                     borderRadius: "4px",
                            //                     padding: "unset",
                            //                     paddingTop: "6px",
                            //                     paddingRight: "18px",
                            //                     paddingLeft: "18px",
                            //                     "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                            //                     textTransform: "unset",
                            //                     mt: 2,
                            //                 }}
                            //             >View Job Sheet</p>
                            //         </Grid>
                            //     </Grid>
                            // </>
                        )
                    })}
                    {/* <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>{jobData?.name}</h3>
                    <Box
                        sx={{
                            p: 2,
                            border: "1px solid #12111180",
                            display: "flex",
                            flexDirection: "column"
                            // borderBottom: "unset",
                        }}
                    >
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Date and Time:{" "}
                            </span>
                            {jobData?.date}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Inspection Site Address:{" "}
                            </span>
                            {jobData?.siteAddress}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Resource (Inspector Name):{" "}
                            </span>
                            {jobData?.inspectorName}
                        </p>
                    </Box>
                    <Grid item xs={12} md={8} container spacing={2}>
                        <Grid item >
                            <Button
                                sx={{
                                    border: "1px solid rgba(46, 105, 255, 1)",
                                    backgroundColor: " rgba(46, 105, 255, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    borderRadius: "4px",
                                    padding: "unset",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingRight: "18px",
                                    paddingLeft: "18px",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                                    textTransform: "unset",
                                    mt: 2,
                                }}
                                onClick={() => handleCreateProject()}
                            >
                                Create Project
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <p
                                style={{
                                    color: " rgba(46, 105, 255, 1)",
                                    textDecoration: " underline",
                                    textDecorationColor: "rgba(46, 105, 255, 1)",
                                    borderRadius: "4px",
                                    padding: "unset",
                                    paddingTop: "6px",
                                    paddingRight: "18px",
                                    paddingLeft: "18px",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                                    textTransform: "unset",
                                    mt: 2,
                                }}
                            >View Job Sheet</p>
                        </Grid>
                    </Grid> */}
                    {/* <h3 style={{ color: "rgba(106, 96, 169, 1)" }}>Job ID: 2 (Similar to the appointment confirmation number)</h3>
                    <Box
                        sx={{
                            p: 2,
                            border: "1px solid #12111180",
                            display: "flex",
                            flexDirection: "column"
                            // borderBottom: "unset",
                        }}
                    >
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Date and Time:{" "}
                            </span>
                            First name, last name, company’s name and/or date of
                            appointment.
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Inspection Site Address:{" "}
                            </span>
                            First name, last name, company’s name and/or date of
                            appointment.
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Resource (Inspector Name):{" "}
                            </span>
                            First name, last name, company’s name and/or date of
                            appointment.
                        </p>
                    </Box> */}
                </Box>
            </Container>
        </div >
    )
}

export default InspectionHome