import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";

function UploadFilesInspection({ inspectionData, setInspectionData, files, setFiles, flag }) {
  const [forceUpdate, setForceUpdate] = useState(false);

  const onDrop = (selectedFiles) => {
    const previewFiles = [...files, ...selectedFiles];
    setFiles(previewFiles);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: [".pdf", ".csv"], // Accept only image files
    multiple: true,
  });

  useEffect(() => {
    if (files?.length > 0) {
      handleSubmitFiles(files);
    }
  }, [files]);

  const handleSubmitFiles = (files) => {
    // console.log("files before:", files);

    if (files?.length === 0) {
      return;
    }

    const tempData = new FormData();

    files.forEach((file, index) => {
      tempData.append(`files`, file);
    });

    Request.post("/postFiles", tempData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        // console.log("Data: ", result.data.files);
        if (result?.data) {
          const fileNames = result.data.files.map((file) => file.filename);
          const updatedInspectionData = { ...inspectionData };
          flag === true ? updatedInspectionData.pendingFiles = fileNames
            : updatedInspectionData.uploadFiles = fileNames; // Set the preliminaryPhotos to the new fileNames
          setInspectionData(updatedInspectionData);
        }
        // showToast("success", result?.data?.message);
        setForceUpdate(true);
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };
  return (
    <>
      <p style={{ color: "rgba(18, 17, 17, 0.5)" }} {...getRootProps()}>
        {flag === true ? "Pending Files" : "Upload Files"} ({files.length})
      </p>
      <input {...getInputProps()} />
    </>
  );
}

export default UploadFilesInspection;
