import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Grid, Select } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { AppStorage } from "../utils/Context/AppContext";
import Request from "../utils/Requests/Request";
import { showToast } from "../utils";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";

function Orders() {
  const navigate = useNavigate();
  const AppContext = useContext(AppStorage);
  const [selectedInspectorId, setSelectedInspectorId] = useState({});
  const [inspectors, setInspectors] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [onHoldOrders, setOnHoldOrders] = useState([]);
  const [appointmentsCancelled, setAppointmentsCancelled] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationTable1, setPaginationTable1] = useState({
    current: 1,
    pageSize: 7,
  });
  const [paginationTable2, setPaginationTable2] = useState({
    current: 1,
    pageSize: 7,
  });
  const [paginationTable3, setPaginationTable3] = useState({
    current: 1,
    pageSize: 7,
  });
  // console.log("onHoldOrders", onHoldOrders);
  // console.log("appointments", appointments);
  const handleChangeTable1 = (event, value) => {
    console.log("pagination Change count", value);
    let dupPagination = { ...paginationTable1 };
    dupPagination.current = value;
    setPaginationTable1(dupPagination);
    getAppointments(value);
  };

  const handleChangeTable2 = (event, value) => {
    let dupPagination = { ...paginationTable2 };
    dupPagination.current = value;
    setPaginationTable2(dupPagination);
    getOnHoldQuotes(value);
  };
  const handleChangeTable3 = (event, value) => {
    let dupPagination = { ...paginationTable3 };
    dupPagination.current = value;
    setPaginationTable3(dupPagination);
    getCancelledAppointments(value);
  };

  useEffect(() => {
    if (forceUpdate) {
      getInspectors();
      getAppointments(paginationTable1.current);
      getOnHoldQuotes(paginationTable2.current);
      getCancelledAppointments(paginationTable3.current);
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getInspectors();
      getAppointments(paginationTable1.current);
      getOnHoldQuotes(paginationTable2.current);
      getCancelledAppointments(paginationTable3.current);
    }
  }, [AppContext?.userAuthToken]);

  function getInspectors() {
    setLoading(true);

    Request.get("/getAllInspectors")
      .then((result) => {
        setLoading(false);
        setInspectors(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  function getAppointments(value) {
    setLoading(true);

    Request.get(`/allAppointments/${value}`)
      .then((result) => {
        setLoading(false);
        setAppointments(result.data["data"]);
        if (result.data["data"].length > 0) {
          let dupObj = { ...selectedInspectorId };
          result.data?.data.forEach((item) => {
            if (item?.inspectorId) {
              dupObj[item.id] = item?.inspectorId;
            }
          });
          setSelectedInspectorId(dupObj);
        }
        console.log("paginationTable1: " + paginationTable1.current);
        setForceUpdate(false);
        setPaginationTable1({
          ...paginationTable1,
          current: value,
          total: result.data["totalCount"],
        });
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function getOnHoldQuotes(value) {
    setLoading(true);
    Request.get(`/quotesOnHold/${value}`)
      .then((result) => {
        setLoading(false);
        setOnHoldOrders(result.data["data"]);
        setForceUpdate(false);
        setPaginationTable2({
          ...paginationTable2,
          current: value,
          total: result.data["totalCount"],
        });
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function getCancelledAppointments(value) {
    setLoading(true);
    Request.get(`/allCancelledAppointments/${value}`)
      .then((result) => {
        setLoading(false);
        setAppointmentsCancelled(result.data["data"]);
        setForceUpdate(false);
        setPaginationTable3({
          ...paginationTable3,
          current: value,
          total: result.data["totalCount"],
        });
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function sendResultToHold(id) {
    // console.log("sendResultToHoldid", id);
    setLoading(true);
    Request.put(`/sendResultToHold/${id}`)
      .then((result) => {
        // console.log("result", result.data.message);
        setLoading(false);
        showToast("success", result.data.message);
        getOnHoldQuotes(paginationTable2.current)
        // setAppointmentsCancelled(result.data["data"]);
        // setForceUpdate(false);
        // setPaginationTable3({
        //   ...paginationTable3,
        //   total: result.data["totalCount"],
        // });
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const columnsNewOrders = [
    { field: "id", headerName: "ID", width: 20 },
    {
      field: "locale",
      headerName: "Version",
      width: 90,
      editable: true,
      renderCell: (text) => {
        return <>{text?.value === "es" ? "Spanish" : "English"}</>;
      },
    },
    {
      field: "clientName",
      headerName: "Client’s Name",
      width: 120,
      editable: true,
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(191, 10, 48, 1)" }}>{text?.value}</div>
        );
      },
    },
    {
      field: "date",
      headerName: "Appointment Date",
      width: 140,
      editable: true,
      renderCell: (text) => {
        return <div>{text?.value.split(" ")[1]}</div>;
      },
    },
    {
      field: "siteCity",
      headerName: "Site City",
      width: 90,
      editable: true,
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(0, 40, 104, 1)" }}>{text?.value}</div>
        );
      },
    },
    {
      field: "quotation",
      headerName: "Order Type",
      width: 140,
      renderCell: (text) => {
        return <>{text.value?.service_type}</>;
      },
      editable: true,
    },
    {
      field: "InspectorsName",
      headerName: "Inspector’s Name",
      renderCell: (text) => {

        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              "& fieldset": {
                borderColor: "transparent !important",
                "& legend": {
                  "& span": {
                    color: "transparent !important",
                  },
                },
              },
              "& svg": { fill: "rgba(191, 10, 48, 1)" },
            }}
            value={selectedInspectorId?.[text?.id] || null}
            onChange={(e) => {
              let dupObj = { ...selectedInspectorId };
              dupObj[text?.id] = e.target.value;
              setSelectedInspectorId(dupObj);

            }}
          >
            {inspectors.map((inspector) => (
              <MenuItem key={inspector?.id} value={inspector?.id}>
                {inspector?.firstName}
              </MenuItem>
            ))}
          </Select>
        );
      },
      width: 120,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dbUserCode",
      headerName: "Inspector’s ID",
      width: 100,
      editable: true,
      renderCell: (text) => {
        // console.log("text: " + text.dbUserCode);
        return (
          <>
            {text.value || ""}
          </>);
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 90,
      editable: true,
      renderCell: (text) => {
        return <>{text?.value}</>;
      },
    },
    {
      field: "updatedAt",
      headerName: "Date & Time",
      width: 200,
      editable: true,
      renderCell: (text) => {
        // console.log("text", text);
        return <>{moment(text?.value).format('llll') || ""}</>;
      },
    },
  ];

  const OrdersOnHold = [
    {
      field: "locale",
      headerName: "Version",
      width: 90,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return <>{text?.value === "es" ? "Spanish" : "English"}</>;
      },
    },
    {
      field: "firstName",
      headerName: "Client’s Name",
      width: 120,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(191, 10, 48, 1)" }}>
            {`${text?.value} ${text?.row.lastName}`}
          </div>
        );
      },
    },
    {
      field: "dateOfAppointment",
      headerName: "Date of Appointment",
      type: "number",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return <>{text.value}</>;
      },
    },
    {
      field: "city",
      headerName: "Site City",
      type: "number",
      width: 110,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(191, 10, 48, 1)" }}>{text?.value}</div>
        );
      },
    },
    {
      field: "service_type",
      headerName: "Order Type",
      width: 140,
      editable: true,
    },
    {
      field: "calendar_spot",
      headerName: "Calendar Spot",
      type: "number",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return (
          <div><Button onClick={text?.value === "No reservation" ? () => navigate("/pricingMatrix#Reservation") : () => navigate("/order")} sx={{ color: "rgba(0, 40, 104, 1)" }}>{text?.value}</Button></div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      width: 90,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "Quote",
      type: "number",
      width: 100,
      editable: true,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.dateOfAppointment,
      renderCell: (params) => {
        const id = params.row.id
        const dateOfAppointment = params.value;
        console.log("params: " + dateOfAppointment);
        return <Button
          disabled={!dateOfAppointment}
          onClick={() => sendResultToHold(id)}
        >
          SEND</Button>;
      },
    },
  ];

  const OrdersCancelled = [
    {
      field: "locale",
      headerName: "Version",
      width: 90,
      editable: true,
      renderCell: (text) => {
        return <>{text?.value === "es" ? "Spanish" : "English"}</>;
      },
    },
    {
      field: "clientName",
      headerName: "Client’s Name",
      width: 120,
      editable: true,
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(191, 10, 48, 1)" }}>{text?.value}</div>
        );
      },
    },
    {
      field: "date",
      headerName: "Appointment Date",
      width: 140,
      editable: true,
      renderCell: (text) => {
        return <div>{text?.value.split(" ")[1]}</div>;
      },
    },
    {
      field: "siteCity",
      headerName: "Site City",
      width: 90,
      editable: true,
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(0, 40, 104, 1)" }}>{text?.value}</div>
        );
      },
    },
    {
      field: "quotation",
      headerName: "Order Type",
      width: 140,
      renderCell: (text) => {
        return <>{text.value?.service_type}</>;
      },
      editable: true,
    },
    {
      field: "is_cancelled",
      headerName: "Status",
      width: 90,
      editable: true,
      renderCell: (text) => {
        return <>{text?.value === true ? "Cancelled" : ""}</>;
      },
    },
  ];

  const handleAssignInspector = () => {
    if (selectedRows.length === 0) {
      // console.log('No rows are selected.');
      showToast("success", 'No rows are selected.');
      return; // Exit the function early
    }
    // console.log("dddd", Object.keys(selectedInspectorId));
    // console.log("fff", selectedRows);

    // Check if selectedAppointmentIds match the appointmentIds
    const areAllIdsMatching = selectedRows.some((id) => Object.keys(selectedInspectorId).includes(id.toString()));


    if (!areAllIdsMatching) {
      // console.log('Not all selected row IDs match appointment IDs.');
      showToast("error", 'Please select row and assign inspector');
      return; // Exit the function early
    }
    let tempData = {
      inspectorId: selectedInspectorId,
      appointmentIds: Object.keys(selectedInspectorId),
    };
    Request.put(`/assignInspector`, tempData)
      .then((result) => {
        showToast("success", result?.data?.message);
        getAppointments(paginationTable1.current);
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  };


  return (
    <Grid item xs={12} md={12} container spacing={2} sx={{ pt: 2 }}>
      <Grid item xs={12} sm={12} md={12} container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 1)",
              padding: "1px 16px 10px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontWeight: "500" }}>New Orders</h2>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <Button
                  sx={{
                    border: "1px solid rgba(44, 181, 44, 1)",
                    color: "rgba(44, 181, 44, 1)",
                    borderRadius: "4px",
                    padding: "6px 20px !important",
                    "&:hover": { backgroundColor: "rgba(44, 181, 44, 0.1)" },
                    textTransform: "unset",
                  }}
                >
                  Dispatch
                </Button>

                <Button
                  sx={{
                    border: "1px solid rgba(155, 46, 239, 1)",
                    color: "rgba(155, 46, 239, 1)",
                    borderRadius: "4px",
                    padding: "6px 20px !important",
                    "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                    textTransform: "unset",
                  }}
                  onClick={handleAssignInspector}
                >
                  Update
                </Button>
              </Box>
            </Box>
            <DataGrid
              rows={appointments}
              columns={columnsNewOrders}
              sx={{
                "& .MuiDataGrid-cell": {
                  border: 1,
                  borderColor: "rgba(223, 222, 225, 1)",
                },
                "& .MuiDataGrid-columnSeparator": {
                  color: "rgba(223, 222, 225, 1)",
                  visibility: "unset",
                },
                "& .css-e84y1v-MuiDataGrid-root": {
                  borderStyle: "unset",
                },
                marginTop: "7px",
              }}
              hideFooter
              checkboxSelection
              disableColumnMenu
              disableSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                setSelectedRows(ids); // Store the selected IDs
                // console.log('Selected IDs:', ids);
              }}
              {...appointments}
            />
            <Stack spacing={2} marginTop="5px">
              {console.log("paginationTable1.current", paginationTable1.current)}
              <Pagination
                count={Math.ceil(paginationTable1.total / 7)}
                page={paginationTable1.current}
                onChange={handleChangeTable1}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 1)",
              padding: "1px 16px 10px",
              borderRadius: "10px",
            }}
          >
            <h2 style={{ fontWeight: "500" }}>Orders on Hold</h2>
            <DataGrid
              rows={onHoldOrders}
              columns={OrdersOnHold}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: 5,
              //     },
              //   },
              // }}
              sx={{
                "& .MuiDataGrid-cell": {
                  border: 1,
                  borderColor: "rgba(223, 222, 225, 1)",
                },
                "& .MuiDataGrid-columnSeparator": {
                  color: "rgba(223, 222, 225, 1)",
                  visibility: "unset",
                },
                "& .css-e84y1v-MuiDataGrid-root": {
                  borderStyle: "unset",
                },
                marginTop: "25px",
              }}
              hideFooter
              // pageSizeOptions={[5]}
              disableColumnMenu
            />
            <Stack spacing={2} marginTop="5px">
              <Pagination
                count={Math.ceil(paginationTable2.total / 7)}
                page={paginationTable2.current}
                onChange={handleChangeTable2}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} container spacing={2} sx={{ mt: 1 }}>
        {/* <Grid item xs={12} md={6}>
    <Box sx={{background:"rgba(255, 255, 255, 1)", padding:"1px 16px 10px", borderRadius:"10px" }}>
      <h2 style={{fontWeight:"500"}}>Orders Completed</h2>
      <DataGrid
        rows={rowsC}
        columns={OrdersCompleted}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
        "& .MuiDataGrid-cell": {
          border: 1,
          borderColor:"rgba(223, 222, 225, 1)",
        },
        "& .MuiDataGrid-columnSeparator":{
          color:"rgba(223, 222, 225, 1)",
          visibility:"unset"
        },
        "& .css-e84y1v-MuiDataGrid-root":{
          borderStyle:"unset"
        },
        marginTop:"25px"
       
      }}
      hideFooter
        pageSizeOptions={[5]}
        // checkboxSelection
        disableColumnMenu
      />
      <Stack spacing={2} marginTop="5px">
            <Pagination
              count={Math.ceil(2 / 7)}
              page={1}
              onChange={handleChange}
            />
          </Stack>
    </Box>
    </Grid> */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              background: "rgba(255, 255, 255, 1)",
              padding: "1px 16px 10px",
              borderRadius: "10px",
            }}
          >
            <h2 style={{ fontWeight: "500" }}>Orders Cancelled</h2>
            <DataGrid
              rows={appointmentsCancelled}
              columns={OrdersCancelled}
              // initialState={{
              //   pagination: {
              //     paginationModel: {
              //       pageSize: 5,
              //     },
              //   },
              // }}
              sx={{
                "& .MuiDataGrid-cell": {
                  border: 1,
                  borderColor: "rgba(223, 222, 225, 1)",
                },
                "& .MuiDataGrid-columnSeparator": {
                  color: "rgba(223, 222, 225, 1)",
                  visibility: "unset",
                },
                "& .css-e84y1v-MuiDataGrid-root": {
                  borderStyle: "unset",
                },
                marginTop: "25px",
              }}
              hideFooter
              pageSizeOptions={[5]}
              disableColumnMenu
            />
            <Stack spacing={2} marginTop="5px">
              <Pagination
                count={Math.ceil(paginationTable3.total / 7)}
                page={paginationTable3.current}
                onChange={handleChangeTable3}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Orders;
