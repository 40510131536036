import React, { useContext, useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Request from "../utils/Requests/Request";
import { showToast } from "../utils";
import { AppStorage } from "../utils/Context/AppContext";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";


function InspectorManagement() {
  const AppContext = useContext(AppStorage);
  const navigate = useNavigate();
  const [radio, setRadio] = useState("");
  const [alignmentStatistics, setAlignmentStatistics] = useState("currentMonth");
  const [alignmentFeedback, setAlignmentFeedback] = useState("currentMonth");
  const [getWorkZone, setGetWorkZone] = useState([])
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointmentByZone, setAppointmentByZone] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [inspectors, setInspectors] = useState([]);
  const [inspectorId, setInspectorId] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChangeStatistics = (event, statistics) => {
    setAlignmentStatistics(statistics);
  };
  const handleChangeFeedback = (event, feedBack) => {
    setAlignmentFeedback(feedBack);
  };


  // get work zones
  function getAllWorkZones() {
    setLoading(true);

    Request.get("/getWorkZone")
      .then((result) => {
        setLoading(false);
        setGetWorkZone(result.data["data"]);
        setRadio(result.data["data"][0].city)
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err?.response?.status == 401) {
          showToast("error", "Unauthorized user.");
        }
      });
  }
  function getAppointmentByWorkZones(search) {
    setLoading(true);
    Request.get(`/appointmentsByZone/${radio}?search=${search || ""}`)
      .then((result) => {
        setLoading(false);
        // console.log(result.data["data"]);
        setAppointmentByZone(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err?.response?.status === 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    if (forceUpdate) {
      getAllWorkZones()
      getInspectors()
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getAllWorkZones()
      getInspectors()
    }
  }, [AppContext?.userAuthToken]);

  useEffect(() => {
    if (radio) {
      getAppointmentByWorkZones(inspectorId?.value);
    }
  }, [radio]);
  // get all inspectors
  function getInspectors() {
    setLoading(true);

    Request.get("/getAllInspectors")
      .then((result) => {
        setLoading(false);
        setInspectors(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err?.response?.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }

  const handleSelectChange = (selectedOption) => {
    setInspectorId(selectedOption);
    setIsButtonDisabled(selectedOption === null);
    // console.log('Selected Inspector:', selectedOption);
  };

  const handleInspectorClick = () => {
    getAppointmentByWorkZones(inspectorId?.value);
  }
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 50,
      width: "300px",
      borderRadius: "6px",
      border: state.isFocused
        ? "2px solid rgba(223, 222, 225, 1)"
        : "2px solid rgba(223, 222, 225, 1)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(223, 222, 225, 1)",
      },
    }),
  };
  return (
    <div style={{ padding: "10px" }}>
      <h3>Search inspector by name:</h3>
      <Grid item xs={12} md={8} container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12} md={6} container spacing={2}>
          <Grid item xs={12} md={9} container>
            <ReactSelect
              placeholder={
                <div style={{ color: "rgba(180, 177, 185, 0.45)" }}>
                  Name
                </div>
              }
              styles={customStyles}
              options={inspectors.map(inspector => ({
                label: inspector?.firstName,
                value: inspector?.id
              }))}
              value={inspectorId}
              onChange={handleSelectChange}
              isClearable
              disabled={isButtonDisabled}
            />
            <span
              style={{
                marginTop: "6px",
                fontSize: "12px",
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              Inspector Names:
            </span>
          </Grid>
          <Grid item xs={12} md={3} container>
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "4px",
                maxHeight: "50px",
                minWidth: "84px",
                backgroundColor: "rgba(44, 181, 44, 1)",
                textTransform: "unset",
                fontWeight: "unset",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
              }}
              onClick={handleInspectorClick}
            >
              Go
            </Button>
          </Grid>
        </Grid>
        {/* Job Appointment */}
        <Grid item xs={12} md={12} container sx={{ mt: 3 }}>
          <span style={{ width: "100%", fontWeight: "500" }}>
            Job Appointment per Work Zone:
          </span>
          <div style={{ display: "flex", gap: "18px", marginTop: "15px" }}>
            {!!getWorkZone.length &&
              getWorkZone.map((zone, i) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "fit-content",
                    gap: "8px",
                    cursor: "pointer"
                  }}
                  onClick={() => setRadio(zone?.city)}
                  key={i}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      border: `1px solid ${radio === zone?.city ? "rgba(191, 10, 48, 1)" : "transparent"
                        }`,
                      padding: "3px",
                    }}
                  >
                    <div
                      style={{
                        width: "7px",
                        height: "7px",
                        backgroundColor: radio === zone?.city ? "rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)",
                        borderRadius: "50%",
                        border: radio === zone?.city ? "1px solid rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)",
                      }}
                    ></div>
                  </div>
                  <span style={{ color: radio === zone?.city ? "rgba(191, 10, 48, 1)" : "rgba(0, 40, 104, 1)" }}>{zone?.city}</span>
                </div>
              ))}
          </div>

          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">Day</th>
              <th className="thInspect">Date</th>
              <th className="thInspect">Time (CDT)</th>
              <th className="thInspect">Client’s Name</th>
              <th className="thInspect">Site Address</th>
              <th className="thInspect">Job Number</th>
              <th className="thInspect">Version</th>
            </tr>
            {!!appointmentByZone.length &&
              appointmentByZone.map((app, i) => (
                <tr key={i}>
                  <td className="tdInspect">{app?.date.split(' ')[0]}</td>
                  <td className="tdInspect">{app?.date.split(' ')[1]}</td>
                  <td className="tdInspect">{`${app?.date.split(' ')[2]} ${app?.date.split(' ')[3]}`}</td>
                  <td className="tdInspect">{app?.clientName}</td>
                  <td className="tdInspect">{`${app?.siteAddress},${app?.siteCity},${app?.siteState}`}</td>
                  <td className="tdInspect">{i + 1}</td>
                  <td className="tdInspect">{app?.locale === "es" ? "Spanish" : "English"}</td>
                </tr>
              ))}
          </table>
        </Grid>
        <Grid item xs={12} md={11} container sx={{ mt: 3 }}>
          <span style={{ width: "100%", fontWeight: "500" }}>Statistics:</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "fit-content",
              gap: "8px",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                border: "1px solid rgba(191, 10, 48, 1)",
                padding: "3px",
              }}
            >
              <div
                style={{
                  width: "7px",
                  height: "7px",
                  backgroundColor: "rgba(191, 10, 48, 1)",
                  borderRadius: "50%",
                  border: "1px solid rgba(191, 10, 48, 1)",
                }}
              ></div>
            </div>
            <span style={{ color: "rgba(191, 10, 48, 1)" }}>
              Work Performance
            </span>
          </div>
          <div style={{ width: "100%", marginTop: "15px", }}>
            <ToggleButtonGroup
              value={alignmentStatistics}
              exclusive
              onChange={handleChangeStatistics}
              aria-label="Platform"
              sx={{

                "& .Mui-selected": {
                  backgroundColor: "rgba(44, 181, 44, 1) !important",
                  color: "rgba(255, 255, 255, 1) !important",
                  border: "1px solid rgba(44, 181, 44, 1) !important",
                },
                "& .MuiToggleButton-root": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  color: "rgba(155, 46, 239, 1)",
                  border: "1px solid rgba(255, 255, 255, 1) "
                },
              }}
            >
              <ToggleButton value="currentMonth">Current Month</ToggleButton>
              <ToggleButton value="lastMonth">Last Month</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">Inspector Code</th>
              <th className="thInspect">Completed Orders</th>
              <th className="thInspect">Samples Collected</th>
              <th className="thInspect">Sampling Average</th>
              <th className="thInspect">Target Sampling Average</th>
              <th className="thInspect">Bonus (US $)</th>
            </tr>
            <tr>
              <td className="tdInspect">MNF01</td>
              <td className="tdInspect">4</td>
              <td className="tdInspect">12</td>
              <td className="tdInspect"></td>
              <td className="tdInspect">5</td>
              <td className="tdInspect"></td>
            </tr>

            <tr>
              <td className="tdInspect">ABGH34</td>
              <td className="tdInspect">8</td>
              <td className="tdInspect">18</td>
              <td className="tdInspect"></td>
              <td className="tdInspect">5</td>
              <td className="tdInspect"></td>
            </tr>
          </table>
        </Grid>
        <Grid item xs={12} md={8} container sx={{ mt: 3 }}>
          <span style={{ width: "100%", fontWeight: "500" }}>Client Feedback:</span>
          <div style={{ width: "100%", marginTop: "15px", }}>
            <ToggleButtonGroup
              value={alignmentFeedback}
              exclusive
              onChange={handleChangeFeedback}
              aria-label="Platform"
              sx={{

                "& .Mui-selected": {
                  backgroundColor: "rgba(44, 181, 44, 1) !important",
                  color: "rgba(255, 255, 255, 1) !important",
                  border: "1px solid rgba(44, 181, 44, 1) !important",
                },
                "& .MuiToggleButton-root": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  color: "rgba(155, 46, 239, 1)",
                  border: "1px solid rgba(255, 255, 255, 1) "
                },
              }}
            >
              <ToggleButton value="currentMonth">Current Month</ToggleButton>
              <ToggleButton value="lastMonth">Last Month</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">Inspector Code</th>
              <th className="thInspect">Survey Respondent</th>
              <th className="thInspect">Rating</th>
            </tr>
            <tr>
              <td className="tdInspect">MNF01</td>
              <td className="tdInspect">4</td>
              <td className="tdInspect">4.5</td>
            </tr>

            <tr>
              <td className="tdInspect">ABGH34</td>
              <td className="tdInspect">1</td>
              <td className="tdInspect">4.1</td>
            </tr>
          </table>
        </Grid>

      </Grid>
      <Grid item xs={12} md={3} container>
        <Button
          sx={{
            border: "1px solid rgba(44, 181, 44, 1)",
            color: "rgba(255, 255, 255, 1)",
            borderRadius: "4px",
            maxHeight: "50px",
            minWidth: "84px",
            backgroundColor: "rgba(44, 181, 44, 1)",
            textTransform: "unset",
            fontWeight: "unset",
          "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
          }}
          onClick={() => navigate('/jobDispatch')}
        >
          Inspection App
        </Button>
      </Grid>
    </div>
  );
}

export default InspectorManagement;
