import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    TextField,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

function Agreement({ getInspection, projectId, setRadio }) {
    const inputInitialsARef = useRef(null);
    const inputInitialsBRef = useRef(null);
    const initialState = {
        initialsA: "",
        initialsB: "",
        inspectionId: getInspection
    };
    const signatureRef = useRef(null);
    const [forceUpdate, setForceUpdate] = useState(false)
    const [agreement, setAgreement] = useState(initialState);
    const [agreementFile, setAgreementFile] = useState();
    const [isGenerateVisibleInput, setGenerateVisibleInput] = useState(false);
    const [link, setLink] = useState("");

    console.log("agreementFile", agreement);


    console.log("inputInitialsARef", inputInitialsARef.current);

    // const getAgreement = () => {
    //     Request.get(`/getAgreement/${projectId}`).
    //         then((response) => {
    //             setAgreementFile(response.data);
    //             replaceInitialsA()
    //         })
    //         .catch((error) => console.log("error", error))
    // }

    var inputInitialsA = document.createElement('input');
    inputInitialsARef.current = inputInitialsA;
    inputInitialsA.setAttribute('type', 'text');
    inputInitialsA.setAttribute('id', 'inputInitialsA');
    const inputElement = document.getElementById('inputInitialsA');
    console.log("inputElement", inputElement?.value);

    const generateLink = () => {
        Request.get(`/generateLink/${projectId}`)
            // "Content-Type": "multipart/form-data",)
            .then((result) => {
                showToast("success", result?.data?.message);
                setForceUpdate(true);
                setGenerateVisibleInput(true);
                setLink(result.data["agreementLink"])
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    };
    const SubmitAgreement = (initialsA, initialsB, signature) => {
        console.log("inputInitialsARef", initialsA, signature);
        if (!signature) {
            showToast("error", "Signature is empty. Please sign before saving.");
            return; // Prevent submission when the signature is empty
        }
        let tempData = {
            initialsA: initialsA,
            // inspectionId: agreement.inspectionId,
            signature: signature,
            initialsB: initialsB,
            projectId: projectId
        }
        console.log(
            "tempData: ", tempData
        );
        Request.post("/createAgreement", tempData)
            // "Content-Type": "multipart/form-data",)
            .then((result) => {
                showToast("success", result?.data?.message);
                setForceUpdate(true);
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    showToast("error", "Unauthorized user.");
                } else {
                    showToast("error", "Something went wrong.");
                }
            });
    };

    const getAgreement = () => {
        Request.get(`/getAgreement/${projectId}`).
            then((response) => {
                return response.data;
            })
            .then(htmlContent => {
                const inputInitialsA = document.createElement('canvas');
                inputInitialsA.setAttribute('id', 'inputInitialsA');
                inputInitialsA.setAttribute('width', '400px');
                inputInitialsA.setAttribute('height', '30px');
                inputInitialsA.style.border = '1px solid black';
                const inputInitialsAClearButton = document.createElement('button');

                const inputInitialsB = document.createElement('canvas');
                inputInitialsB.setAttribute('id', 'inputInitialsB');
                inputInitialsB.setAttribute('width', '400px');
                inputInitialsB.setAttribute('height', '30px');
                inputInitialsB.style.border = '1px solid black';
                const inputInitialsBClearButton = document.createElement('button');

                const clientSignatureCanvas = document.createElement('canvas');
                clientSignatureCanvas.setAttribute('id', 'clientSignatureCanvas');
                clientSignatureCanvas.setAttribute('width', '400px');
                clientSignatureCanvas.setAttribute('height', '200px');
                clientSignatureCanvas.style.border = '1px solid black';
                const clientSignatureClearButton = document.createElement('button');


                // Replace the placeholder with the canvas element
                htmlContent = htmlContent.replace('{{initialsA}}', inputInitialsA.outerHTML);
                htmlContent = htmlContent.replace('{{initialsB}}', inputInitialsB.outerHTML);
                htmlContent = htmlContent.replace('{{clientSignature}}', clientSignatureCanvas.outerHTML);
                const iframe = document.getElementById('myIframe');
                const doc = iframe.contentDocument || iframe.contentWindow.document;
                doc.open();
                doc.write(htmlContent);
                doc.close();
                function clearCanvas(canvasId) {
                    const canvas = doc.getElementById(canvasId);
                    const context = canvas.getContext('2d');
                    context.clearRect(0, 0, canvas.width, canvas.height);
                }
                function initializeSignatureDrawing(canvasId, clearButtonId) {
                    console.log("canvasId", canvasId);
                    var canvas = doc.getElementById(canvasId);
                    console.log("canvas", canvas);
                    var context = canvas.getContext('2d');
                    var isDrawing = false;
                    // const clearButton = doc.createElement('button');
                    // clearButton.setAttribute('id', clearButtonId);
                    // clearButton.textContent = 'Clear Signature';

                    // clearButton.addEventListener('click', () => {
                    //     console.log("Clear Signature button clicked");
                    //     clearCanvas(canvasId);
                    // });

                    // Append the Clear Signature button to the document
                    // doc.body.appendChild(clearButton);
                    let lastX = 0;
                    let lastY = 0;
                    canvas.addEventListener('mousedown', startDrawing);
                    canvas.addEventListener('mousemove', drawLine);
                    canvas.addEventListener('mouseup', endDrawing);
                    canvas.addEventListener('mouseout', endDrawing);
                    canvas.addEventListener('touchstart', startDrawing);
                    canvas.addEventListener('touchmove', drawLine);
                    canvas.addEventListener('touchend', endDrawing);

                    function startDrawing(e) {
                        isDrawing = true;
                        resetPath(e);
                    }

                    function drawLine(e) {
                        if (isDrawing) {
                            draw(e);
                        }
                    }

                    function draw(e) {
                        console.log("sgdfg");
                        const rect = canvas.getBoundingClientRect();
                        const x = e.clientX - rect.left;
                        const y = e.clientY - rect.top;

                        context.lineWidth = 2;
                        context.lineCap = 'round';
                        context.strokeStyle = '#000'; // Set your desired stroke color

                        context.lineTo(x, y);
                        context.stroke();
                    }

                    function resetPath(e) {
                        const rect = canvas.getBoundingClientRect();
                        const x = e.clientX - rect.left;
                        const y = e.clientY - rect.top;

                        context.beginPath();
                        context.moveTo(x, y);
                    }

                    function endDrawing() {
                        isDrawing = false;
                    }
                    function getCoordinates(e) {
                        // code
                    }

                    // const clearButton = document.createElement('button');
                    // clearButton.setAttribute('id', clearButtonId);
                    // clearButton.textContent = 'Clear Signature';

                    // clearButton.addEventListener('click', () => {
                    //     clearCanvas(canvasId);
                    // });
                }
                const clearButton = doc.createElement('button');
                clearButton.setAttribute('id', 'clearSignatureButton');
                clearButton.textContent = 'Clear Signature';
                clearButton.addEventListener('click', () => {
                    clearCanvas('inputInitialsA');
                    clearCanvas('inputInitialsB');
                    clearCanvas('clientSignatureCanvas');
                });
                doc.body.appendChild(clearButton);


                initializeSignatureDrawing("inputInitialsA", "inputInitialsAClearButton");
                initializeSignatureDrawing("inputInitialsB", "inputInitialsBClearButton");
                initializeSignatureDrawing("clientSignatureCanvas", "clientSignatureClearButton");

                const submitButton = document.getElementById('myBtn');
                submitButton.addEventListener('click', function () {

                    const canvasContentA = doc.getElementById('inputInitialsA');
                    const canvasContentB = doc.getElementById('inputInitialsB');
                    const canvasContent = doc.getElementById('clientSignatureCanvas');
                    SubmitAgreement(canvasContentA.toDataURL(), canvasContentB.toDataURL(), canvasContent.toDataURL())
                    console.log("canvasContent.toDataURL()", canvasContentA.toDataURL());
                });
            })
            .catch(error => console.error('Error fetching HTML:', error));
    }


    useEffect(() => {
        console.log("useEffect triggered");
        getAgreement();
    }, [])

    const handleInputChange = (event) => {
        // Update the state with the new input value
        setAgreement({ ...agreement, initialsA: event.target.value });
    };

    const generatePDF = (projectData) => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text('Project Detail', 10, 10);

        let yPos = 20;

        // Loop through the form data object
        Object.entries(projectData).forEach(([key, value]) => {
            // Check if the value is an array
            if (Array.isArray(value)) {
                // If it's an array, iterate over each element
                value.forEach((element, index) => {
                    // Render each element's details
                    Object.entries(element).forEach(([subKey, subValue]) => {
                        // Check if the subValue is an object
                        if (typeof subValue === 'object') {
                            // If it's an object, render its label and value
                            doc.text(`${subKey}: ${subValue.label || subValue.value}`, 10, yPos);
                        } else {
                            // Render the key and value
                            doc.text(`${subKey}: ${subValue}`, 10, yPos);
                        }
                        yPos += 10;
                    });
                    yPos += 5; // Add some space between elements
                });
            } else {
                // Render non-array values
                doc.text(`${key}: ${value}`, 10, yPos);
                yPos += 10;
            }
        });

        doc.save('form_data.pdf');
        // const doc = new jsPDF();
        // html2canvas(formRef.current).then(canvas => {
        //   const imgData = canvas.toDataURL('image/png');

        //   const pdfWidth = doc.internal.pageSize.getWidth();
        //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

        //   doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        //   doc.save('form_data.pdf');
        // });

        // doc.save('form_data.pdf');
    };

    return (
        <>
            <Box>
                <p style={{ color: "rgba(106, 96, 169, 1)", lineHeight: "30px" }}>
                    <span
                        style={{
                            fontWeight: "bold",
                            borderBottom: "2px solid rgba(106, 96, 169, 0.8)",
                            fontSize: "20px",
                        }}
                    >
                        Agreement{" "}
                    </span>

                    (Textual contents will be uploaded to fill out section 1 to 3):
                </p>
            </Box>
            <Box sx={{ p: 3, border: "1px solid rgba(18, 17, 17, 0.5)", mt: 3 }}>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    {/* <div dangerouslySetInnerHTML={{ __html: agreementFile }} /> */}
                    <iframe id="myIframe" width="100%" height="1000px" frameBorder="0"></iframe>
                    {/* <input
                        type="text"
                        id="inputInitialsA"
                        value={agreement.initialsA}
                        onChange={handleInputChange}
                    /> */}
                    <Button
                        sx={{
                            border: "1px solid rgba(46, 105, 255, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            borderRadius: "4px",
                            padding: "7px 45px !important",
                            backgroundColor: "rgba(46, 105, 255, 1)",
                            "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                            textTransform: "unset",
                        }}
                        id="myBtn"
                    // onClick={getAgreement}
                    >
                        Submit
                    </Button>
                </div>
            </Box>
            <Box sx={{ mt: 3, display: "flex", gap: "15px" }}>
                <Button
                    sx={{
                        border: "1px solid rgba(46, 105, 255, 1)",
                        color: "rgba(255, 255, 255, 1)",
                        borderRadius: "4px",
                        padding: "7px 45px !important",
                        backgroundColor: "rgba(46, 105, 255, 1)",
                        "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                        textTransform: "unset",
                    }}
                    onClick={() => setRadio("Inspection")}
                >
                    Next
                </Button>
                <Button
                    sx={{
                        border: "1px solid rgba(46, 105, 255, 1)",
                        color: "rgba(46, 105, 255, 1)",
                        borderRadius: "4px",
                        padding: "7px 45px !important",
                        "&:hover": { backgroundColor: "rgba(46, 105, 255, 0.1)" },
                        textTransform: "unset",
                    }}
                    onClick={() => generateLink()}
                >
                    Generate link
                </Button>
            </Box>
            {isGenerateVisibleInput && (
                <TextField
                    placeholder="Initials"
                    id="outlined-start-adornment"
                    sx={{
                        mt: 1,
                        width: "100%",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        borderRadius: "12px",
                        "& .MuiInputBase-formControl": {
                            borderRadius: "10px",
                            color: "rgba(155, 46, 239, 1) !important",
                        },
                        "& .MuiInputBase-formControl ::placeholder": {
                            color: "rgba(180, 177, 185, 1) !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(223, 222, 225, 1) !important",
                        },
                        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(155, 46, 239, 1) !important",
                        },
                    }}
                    value={link}
                    disabled
                />
            )}
            <p style={{ color: "rgba(106, 96, 169, 1)", lineHeight: "30px" }}>
                <span
                    style={{
                        fontWeight: "bold",
                        borderBottom: "2px solid rgba(106, 96, 169, 0.8)",
                    }}
                >
                    Note{" "}
                </span>
                Generate link (The link can be copy and paste to send it to the
                designated client for signature approval if the client can't make it
                on-site).
            </p>
        </>
    );
}

export default Agreement;
