import { useState, useContext, useEffect } from "react";
import React from "react";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { AppStorage } from "../utils/Context/AppContext";
import { showToast } from "../utils/index";
import Request from "../utils/Requests/Request";
import ReactSelect from "react-select";
import DeleteModal from "../modals/DeleteModal";
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { ToastContainer } from "react-toastify";

function UserManagement() {
  const AppContext = useContext(AppStorage);
  const initialState = {
    // workZoneId: null,
    workZoneId: [],
    firstName: "",
    userName: "",
    password: "",
    lastName: "",
    jobPosition: "",
    jobTitle: "",
    permissions: [],
    personalPhone: "",
    personalEmail: "",
    businessName: "",
    businessEmail: "",
    businessPhone: "",
    userCode: "",
    businessAddress: ""
  };
  const [delUser, setDelUser] = useState("");
  const [users, setUsers] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [formData, setFormData] = useState(initialState);
  const [validated, setValidated] = useState(true);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [getWorkZone, setGetWorkZone] = useState([]);
  const [workZoneOption, setWorkZoneOption] = useState([]);

  console.log(
    "formData", formData
  );
  const OpenDelModal = (user) => {
    setOpenDelModal(true);
    setDelUser(user);
  };
  const CloseDelModal = () => {
    setOpenDelModal(false);
  };
  const handleEditClick = (userData) => {
    const data = getWorkZone.filter((item) => {
      // return (
      var zone = []
      if (userData?.work_zonesId?.includes(JSON.stringify(item.id))) {
        return { value: item.id, label: item.city }
      }
      // return zone
      // )
    })
    // const data = userData?.work_zonesId?.split(",")?.map((item) => {
    //   console.log("item", item);
    //   var zone
    //   for (let i = 0; i < getWorkZone.length; i++) {
    //     const element = getWorkZone[i];
    //     if (item === JSON.stringify(element.id)) {
    //       zone = element
    //     }
    //     console.log("zone", zone);
    //   }
    //   return zone
    // })
    // const data = userData?.work_zonesId?.split(",")?.map((item) => {
    //   console.log("item", item);
    //   const zone = getWorkZone.find((e) => item === JSON.stringify(e.id))
    //   console.log("zone", zone);
    //   return { value: item, label: zone.city }
    // })
    // console.log("data", data, userData?.work_zonesId);
    setEditRowId(userData.id);
    // console.log(userData);
    const dupObj = { ...formData };
    dupObj.firstName = userData?.firstName || "";
    dupObj.lastName = userData?.lastName || "";
    dupObj.userName = userData?.username || "";
    dupObj.password = userData?.password || "";
    dupObj.jobTitle = userData?.jobTitle || "";
    dupObj.jobPosition = userData?.jobPosition || "";
    dupObj.permissions =
      userData?.permissions?.split(",")?.map((item) => {
        return { value: item, label: item };
      }) || [];
    dupObj.personalPhone = userData?.personalPhone || "";
    dupObj.personalEmail = userData?.personalEmail || "";
    dupObj.businessEmail = userData?.businessEmail || "";
    dupObj.businessName = userData?.businessName || "";
    dupObj.businessPhone = userData?.businessPhone || "";
    dupObj.businessAddress = userData?.businessAddress || "";
    dupObj.workZoneId = data.map((item) => {
      return { value: item.id, label: item.city }
    });
    // dupObj.workZoneId = data || [];
    // dupObj.workZoneId = userData?.work_zonesId || null;
    // dupObj.workZoneId =
    //   userData?.work_zonesId?.split(",")?.map((item) => {
    //     return { value: item, label: item };
    //     // { value: item, label: item };
    //   }) || [];
    dupObj.userCode = userData?.userCode || "";
    setFormData(dupObj);
  };

  useEffect(() => {
    if (forceUpdate) {
      getData();
      getAllWorkZones()
    }
  }, [forceUpdate]);
  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getData();
      getAllWorkZones()
    }
  }, [AppContext?.userAuthToken]);

  function getData() {
    setLoading(true);

    Request.get("/getAllUsers")
      .then((result) => {
        setLoading(false);
        setUsers(result.data["data"]);
        // console.log(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err?.response?.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // post api

  const validateFormData = () => {
    const vatNumberPattern = /^3.{13}3$/;
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.userName === "" ||
      editRowId && formData.password === "" ||
      formData.jobPosition === "" ||
      formData.permissions === "" ||
      // formData.personalPhone === "" ||
      !formData.personalPhone.match(/^\d{10}$/) ||
      formData.personalEmail === "" ||
      formData.businessEmail === "" ||
      !formData.businessPhone.match(/^\d{10}$/) === "" ||
      formData.jobTitle === "" ||
      formData.jobPosition === "Inspector" && formData.workZoneId.length === 0 ||
      formData.jobPosition === "Inspector" && formData.userCode === "" ||
      !formData.businessEmail.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      ) ||
      !formData.personalEmail.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      )
    ) {
      setValidated(false);
      return false;
    }
    setValidated(true);
    return true;
  };
  const handleSubmit = () => {
    if (validateFormData()) {
      let tempData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        username: formData.userName,
        password: !editRowId && formData.password,
        jobPosition: formData.jobPosition,
        jobTitle: formData.jobTitle,
        permissions: formData.permissions.map((item) => item.value)?.toString(),
        personalPhone: formData.personalPhone,
        personalEmail: formData.personalEmail,
        businessEmail: formData.businessEmail,
        businessName: formData.businessName,
        businessPhone: formData.businessPhone,
        businessAddress: formData.businessAddress,
        work_zonesId: formData.jobPosition === "Inspector" && formData.workZoneId.map((item) => item.value)?.toString(),
        userCode: formData.userCode,
      };
      if (editRowId === null) {
        Request.post("/createUser", tempData)
          .then((result) => {
            showToast("success", "New User Added.");
            setFormData(initialState)
            getData();
            // console.log("Data: ", result.data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            if (err?.response?.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", err.response.data.error);
            }
          });
      } else {
        let editTempData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          username: formData.userName,
          // password: !editRowId && formData.password,
          jobPosition: formData.jobPosition,
          jobTitle: formData.jobTitle,
          permissions: formData.permissions.map((item) => item.value)?.toString(),
          personalPhone: formData.personalPhone,
          personalEmail: formData.personalEmail,
          businessEmail: formData.businessEmail,
          businessName: formData.businessName,
          businessPhone: formData.businessPhone,
          work_zonesId: formData.jobPosition === "Inspector" && formData.workZoneId.map((item) => item.value)?.toString(),
          userCode: formData.userCode,
          businessAddress: formData.businessAddress,
          id: editRowId
        };
        // tempData.id = editRowId;
        Request.put("/updateUser", editTempData)
          .then((result) => {
            showToast("success", "User Updated.");
            setFormData(initialState)
            setEditRowId(null)
            getData();
          })
          .catch((err) => {
            // console.log("Error: ", err);
            if (err?.response?.status == 401) {
              showToast("error", "Unauthorized user.");
            } else {
              showToast("error", err.response.data.error);
            }
          });
      }
    }
  };
  const maxLength = 10;
  const handleSelectChange = (selectedValues) => {
    let dupObj = { ...formData };
    dupObj.permissions = selectedValues;
    setFormData(dupObj);
  };
  const handleWorkZone = (selectedValues) => {
    // console.log("handleWorkZone", selectedValues);
    let dupObj = { ...formData };
    dupObj.workZoneId = selectedValues;
    setFormData(dupObj);
  }
  const handleSelectTitle = (selectedValues) => {
    let dupObj = { ...formData };
    dupObj.jobTitle = selectedValues;
    setFormData(dupObj);
  };
  const options = [
    { value: "File Management", label: "File Management" },
    { value: "Client Management", label: "Client Management" },
    { value: "Settings", label: "Settings" },
    { value: "Inspector Management", label: "Inspector Management" },
    { value: "User Management", label: "User Management" },
    { value: "Contact Management", label: "Contact Management" },
    { value: "Order Management", label: "Order Management" },
  ];
  const optionsJobTitle = [
    { value: " General Manager", label: " General Manager" },
    { value: "Assistant General Manager", label: "Assistant General Manager" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Administrative Assistant,", label: "Administrative Assistant," },
    { value: "Mold Assessment Consultant", label: "Mold Assessment Consultant" },
    { value: "Consultant", label: "Consultant" },
  ];

  const optionSelectContarct = [
    { value: "Business Owner", label: "Business Owner" },
    { value: "Recruiter", label: "Recruiter" },
  ]

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 56,
      borderRadius: "12px",
      border: state.isFocused
        ? "2px solid rgba(155, 46, 239, 1)"
        : "1px solid rgba(223, 222, 225, 1)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid rgba(155, 46, 239, 1)",
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: ' rgba(155, 46, 239, 1)'
    })
  };

  function deleteUser(user) {
    Request.delete(`/delUser/${user["id"]}`)
      .then(() => {
        setForceUpdate(true);
        CloseDelModal();
        showToast("success", "Deleted Successfully.");
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err?.response?.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  function getAllWorkZones() {
    setLoading(true);

    Request.get("/getWorkZone")
      .then((result) => {
        setLoading(false);
        // setGetWorkZone(result.data["data"]);
        var setWorkZone = [];
        const workZoneData = result.data.data;
        setGetWorkZone(workZoneData)
        for (var i = 0; i < workZoneData.length; i++) {
          const element = workZoneData[i];
          // element.label = element.city
          // element.value = element.id
          setWorkZone.push({ label: element.city, value: element.id });
        }
        // console.log("workZoneData", setWorkZone);
        setWorkZoneOption(setWorkZone)
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err?.response?.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  return (
    <div style={{ padding: "10px" }}>
      <ToastContainer />
      <p style={{ color: "rgba(21, 6, 45, 1)" }}>{editRowId ? "Edit User" : "Add Users"}</p>
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="First Name"
            id="outlined-start-adornment"
            value={formData.firstName}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.firstName = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            First Name:
          </span>
          {!validated &&
            (formData.firstName === null || formData.firstName === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                First Name is required
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Last Name"
            id="outlined-start-adornment"
            value={formData.lastName}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.lastName = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%",
            }}
          >
            Last Name:
          </span>
          {!validated &&
            (formData.lastName === null || formData.lastName === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Last Name is required
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Username"
            id="outlined-start-adornment"
            value={formData.userName}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.userName = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Username:
          </span>
          {!validated &&
            (formData.userName === null || formData.userName === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Username is required
              </div>
            )}
        </Grid>
        {!editRowId && <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Password"
            id="outlined-start-adornment"
            value={formData.password}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.password = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Password:
          </span>
          {!validated &&
            (formData.password === null || formData.password === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Password is required
              </div>
            )}
        </Grid>}
        <Grid item xs={12} md={6}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            <Select
              value={formData.jobPosition}
              displayEmpty
              onChange={(e) => {
                const dupObj = { ...formData };
                dupObj.jobPosition = e.target.value;
                if (dupObj.jobPosition !== "Inspector") {
                  dupObj.workZoneId = null;
                  dupObj.userCode = null;
                }
                setFormData(dupObj);
              }}
              sx={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "12px",
                "& .MuiInputBase-formControl": {
                  borderRadius: "10px",
                  color: "rgba(155, 46, 239, 1) !important",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(223, 222, 225, 1) !important",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(155, 46, 239, 1) !important",
                },
                color: "rgba(155, 46, 239, 1)",
              }}
            >
              <MenuItem value={"Admin"}>Admin</MenuItem>
              <MenuItem value={"Inspector"}>Inspector</MenuItem>
              <MenuItem value={"Contractor"}>Contractor</MenuItem>
            </Select>
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Job Position:
          </span>
          {!validated &&
            (formData.jobPosition === null || formData.jobPosition === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Select Job Position
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <ReactSelect
            placeholder={
              <div style={{ color: "rgba(180, 177, 185, 0.45)" }}>
                Job Title
              </div>
            }
            styles={customStyles}
            options={
              formData.jobPosition === "Contractor" ?
                optionSelectContarct :
                optionsJobTitle
            }
            value={
              formData.jobPosition === "Contractor" ?
                optionSelectContarct.find((option) => option.value === formData.jobTitle) :
                optionsJobTitle.find((option) => option.value === formData.jobTitle)
            }
            onChange={(selectedOption) => handleSelectTitle(selectedOption.value)}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Job Title:
          </span>
        </Grid>

        <Grid item xs={12} md={12}>
          <ReactSelect
            isMulti
            placeholder={
              <div style={{ color: "rgba(180, 177, 185, 0.45)" }}>
                Permissions
              </div>
            }
            styles={customStyles}
            options={options}
            value={formData.permissions}
            onChange={handleSelectChange}
          />
          {/* </FormControl> */}
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
            }}
          >
            Permissions:
          </span>
          {!validated &&
            (formData.permissions === null ||
              formData.permissions === "") && (
              <div style={{ color: "red", fontSize: "small", width: "100%" }}>
                Select Permissions
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Personal Phone"

            id="outlined-start-adornment"
            value={formData.personalPhone}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.personalPhone = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            inputProps={{ maxLength: 10 }}
          // inputComponent="input" // Use the default input element
          />

          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Personal Phone:
          </span>
          {/* {!validated &&
            (formData.personalPhone === null ||
              formData.personalPhone === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter Phone Number
              </div>
            )} */}
          {!validated &&
            (formData.personalPhone === null ||
              formData.personalPhone === "" || !formData.personalPhone.match(/^\d{10}$/)) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Invalid Phone Number
              </div>
            )}
        </Grid>

        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Personal Email"
            id="outlined-start-adornment"
            value={formData.personalEmail}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.personalEmail = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Personal Email:
          </span>
          {!validated &&
            !formData.personalEmail.match(
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Invalid email
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Business Name"
            id="outlined-start-adornment"
            value={formData.businessName}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.businessName = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Business Name:
          </span>
          {/* {!validated &&
            !formData.businessName && (
              <div style={{ color: "red", fontSize: "small" }}>
                Please enter business name
              </div>
            )} */}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Business Email"
            id="outlined-start-adornment"
            value={formData.businessEmail}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.businessEmail = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Business Email:
          </span>
          {!validated &&
            !formData.businessEmail.match(
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Invalid email
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Business Phone Number"
            id="outlined-start-adornment"
            value={formData.businessPhone}
            onChange={(e) => {
              let dupObj = { ...formData };
              const numericOnly = e.target.value.replace(/\D/g, '');

              // Format the phone number
              const formattedNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3');
              dupObj.businessPhone = formattedNumber;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            inputProps={{ maxLength }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Business Phone Number:
          </span>
          {!validated &&
            (formData.businessPhone === null ||
              formData.businessPhone === "" || !formData.businessPhone.match(/^\d{10}$/)) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Invalid Phone Number
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            placeholder="Business Phone Number"
            id="outlined-start-adornment"
            value={formData.businessAddress}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.businessAddress = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
            inputProps={{ maxLength: 10 }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Business Address:
          </span>
          {!validated &&
            (formData.businessAddress === null ||
              formData.businessAddress === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter business address
              </div>
            )}
        </Grid>
        {/* {console.log("formData.jobPosition", formData.jobPosition)} */}
        <Grid item xs={12} md={12} sx={{ display: formData.jobPosition !== "Inspector" ? "none" : "block" }}>
          <FormControl sx={{ mr: 1, width: "100%" }}>
            {/* {console.log("getWorkZone", getWorkZone)} */}
            <ReactSelect
              isMulti
              placeholder={
                <div style={{ color: "rgba(180, 177, 185, 0.45)" }}>
                  Work Zones
                </div>
              }
              styles={customStyles}
              options={workZoneOption}
              value={formData.workZoneId}
              onChange={handleWorkZone}
            />
          </FormControl>
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            Work zone preferences (a set of work zones can be selected at once
            using the control key or unselected):
          </span>
          {!validated &&
            (formData.workZoneId === null || formData.workZoneId === "" || formData.workZoneId.length === 0) && (
              <div style={{ color: "red", fontSize: "small" }}>
                Work Zone is required
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={12} container sx={{ display: formData.jobPosition !== "Inspector" ? "none" : "block" }}>
          <TextField
            placeholder="23657"
            id="outlined-start-adornment"
            value={formData.userCode}
            onChange={(e) => {
              let dupObj = { ...formData };
              dupObj.userCode = e.target.value;
              setFormData(dupObj);
            }}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderRadius: "12px",
              "& .MuiInputBase-formControl": {
                borderRadius: "10px",
                color: "rgba(155, 46, 239, 1) !important",
              },
              "& .MuiInputBase-formControl ::placeholder": {
                color: "rgba(180, 177, 185, 1) !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(223, 222, 225, 1) !important",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(155, 46, 239, 1) !important",
              },
            }}
          />
          <span
            style={{
              marginTop: "6px",
              fontSize: "12px",
              color: "rgba(155, 46, 239, 1)",
              width: "100%"
            }}
          >
            License ID or certificate ID if license is not required by the State
          </span>
          {!validated &&
            (formData.userCode === null || formData.userCode === "") && (
              <div style={{ color: "red", fontSize: "small" }}>
                Enter User Code
              </div>
            )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          container
          sx={{ display: "flex", gap: "25px", mt: 2 }}
        >
          {editRowId === null ? (
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "6px",
                padding: "10px 32px !important",
                backgroundColor: "rgba(44, 181, 44, 1)",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 1)" },
                textTransform: "unset",
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          ) : (
            <Button
              sx={{
                border: "1px solid rgba(155, 46, 239, 1)",
                color: "rgba(155, 46, 239, 1)",
                borderRadius: "6px",
                padding: "10px 30px !important",
                "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                textTransform: "unset",
              }}
              onClick={handleSubmit}
            >
              Edit
            </Button>
          )
          }
        </Grid>
        <Grid item xs={12} md={11} container sx={{ mt: 3 }}>
          <span style={{ width: "100%", fontWeight: "500" }}>
            Remove Users:
          </span>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">User code</th>
              {/* <th className="thInspect">Inspector license ID</th> */}
              <th className="thInspect">First Name</th>
              <th className="thInspect">Last Name</th>
              <th className="thInspect">Job Position</th>
              <th className="thInspect">Actions</th>
            </tr>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="tdInspect">{user.id}</td>
                <td className="tdInspect">{user.firstName}</td>
                <td className="tdInspect">{user.lastName}</td>
                <td className="tdInspect">{user.jobPosition}</td>
                <td className="tdInspect">
                  <EditIcon
                    sx={{
                      cursor: "pointer",
                      color: "rgba(155, 46, 239, 1)",
                      marginRight: "8px",
                    }}
                    onClick={() => handleEditClick(user)}
                  />
                  <DeleteIcon
                    sx={{ cursor: "pointer", color: "rgba(191, 10, 48, 1)" }}
                    onClick={() => OpenDelModal(user)}
                  />
                </td>
              </tr>
            ))}
          </table>
        </Grid>
        <Grid item xs={12} md={11} container sx={{ mt: 3 }}>
          <span style={{ width: "100%", fontWeight: "500" }}>
            Account Activity
          </span>
          <table className="table" style={{ marginTop: "15px" }}>
            <tr>
              <th className="thInspect">Username</th>
              <th className="thInspect">Date</th>
              <th className="thInspect">Time</th>
              <th className="thInspect">Activity</th>
            </tr>
            <tr>
              <td className="tdInspect">Partric York</td>
              <td className="tdInspect">06/23/2023</td>
              <td className="tdInspect">10:30 AM</td>
              <td className="tdInspect">N/A</td>
            </tr>

            <tr>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
              <td className="tdInspect"></td>
            </tr>
          </table>
        </Grid>
      </Grid>
      {openDelModal && (
        <DeleteModal
          del={deleteUser}
          currentUser={delUser}
          openDelModal={openDelModal}
          setDeleteModal={setOpenDelModal}
        />
      )}

    </div>
  );
}

export default UserManagement;
