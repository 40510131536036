import React, { useState, useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { AppStorage } from "../utils/Context/AppContext";
import { showToast } from "../utils";
import Request from "../utils/Requests/Request";

function PresetAppointment({ openAppointments, setOpenAppointments, getReserveDates, getAllReserveDates, workZoneId }) {
  console.log("getReserveDates", getReserveDates);
  const AppContext = useContext(AppStorage);
  const [getBlockedDates, setGetBlockedDates] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationTable2, setPaginationTable2] = useState({
    current: 1,
    pageSize: 7,
  });
  const [onHoldOrders, setOnHoldOrders] = useState([]);
  const [presetDates, setPresetDates] = useState([]);
  const [reservedDates, setReservedDates] = useState([]);
  const [presetDate, setPresetDate] = useState([]);
  console.log("reservedDates", reservedDates);
  console.log("presetDates", presetDates);
  console.log("presetDate", presetDate);
  useEffect(() => {
    // for (var i = 0; i < getReserveDates.length; i++) {
    //   const dateString = getReserveDates[i].blocked_dates;
    //   const dateParts = dateString.split('/');
    //   const date = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}T00:00:00.000Z`);
    //   const dayNumber = date.getUTCDay();
    //   const daysOfWeek = [
    //     "Sun",
    //     "Mon",
    //     "Tue",
    //     "Wed",
    //     "Thur",
    //     "Fri",
    //     "Sat"];
    //   setReservedDates({ ...getReserveDates[i], day: daysOfWeek[dayNumber] })
    //   console.log("daysOfWeek[dayNumber]", daysOfWeek[dayNumber]);
    // }
    if (forceUpdate) {
      getOnHoldQuotes();
      getAllReserveDates();
      // getAllBlockedDates();
    }
  }, [forceUpdate]);

  useEffect(() => {
    if (AppContext?.userAuthToken || AppContext?.userAuthToken !== "") {
      getOnHoldQuotes();
      getAllReserveDates();
      // getAllBlockedDates();
    }
  }, [AppContext?.userAuthToken]);

  function getOnHoldQuotes() {
    setLoading(true);
    Request.get(`/quotesOnHold/${paginationTable2?.current}`)
      .then((result) => {
        setLoading(false);
        setOnHoldOrders(result.data["data"]);
        setForceUpdate(false);
        setPaginationTable2({
          ...paginationTable2,
          total: result.data["totalCount"],
        });
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  useEffect(() => {
    getPresetDropdown()
  }, [])

  function getPresetDropdown() {
    setLoading(true);
    Request.get(`/getPresetDropdown/${workZoneId}`)
      .then((result) => {
        setLoading(false);
        console.log("get preset dropdown result: " + result.data["dates"]);
        setPresetDates(result.data["dates"]);
        // setOnHoldOrders(result.data["data"]);
        // setForceUpdate(false);
        // setPaginationTable2({
        //   ...paginationTable2,
        //   total: result.data["totalCount"],
        // });
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  // get results
  function getAllBlockedDates() {
    setLoading(true);

    Request.get("/getBlockedDates")
      .then((result) => {
        setLoading(false);
        // console.log(result.data["data"]);
        setGetBlockedDates(result.data["data"]);
        setForceUpdate(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  const OrdersOnHold = [
    {
      field: "locale",
      headerName: "Version",
      width: 90,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return <>{text?.value === "es" ? "Spanish" : "English"}</>;
      },
    },
    {
      field: "firstName",
      headerName: "Client’s Name",
      width: 120,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(191, 10, 48, 1)" }}>
            {`${text?.value} ${text?.row.lastName}`}
          </div>
        );
      },
    },
    {
      field: "dateOfAppointment",
      headerName: "Date of Appointment",
      type: "number",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.dateOfAppointment,
      renderCell: (text) => {
        const service_type = text.row.service_type


        console.log("service_type", service_type)
        return (
          service_type === "Other- Protocol" ? "N/A" :
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{
                "& fieldset": {
                  borderColor: "transparent !important",
                  "& legend": {
                    "& span": {
                      color: "transparent !important",
                    },
                  },
                },
                "& svg": { fill: "rgba(191, 10, 48, 1)" },
              }}
              onChange={(e) => {
                const createPresetDate = [];
                // const dateString = e.target.value;
                // const date = new Date(dateString);
                // const dateParts = dateString.split('/');
                // const date = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}T00:00:00.000Z`);
                // const dayNumber = date.getUTCDay();
                // const daysOfWeek = [
                //   "Sun",
                //   "Mon",
                //   "Tue",
                //   "Wed",
                //   "Thu",
                //   "Fri",
                //   "Sat"
                // ];
                // console.log("daysOfWeek[dayNumber]", daysOfWeek[dayNumber]);
                // console.log("craetePresetDate", text);
                if (e.target.value) {
                  createPresetDate.push({
                    date: e.target.value,
                    quoteId: text?.id,
                    identifier: text?.row?.identifier
                  });
                }
                setPresetDate(createPresetDate)
                console.log("createPresetDate", createPresetDate);

              }}
            >
              {
                presetDates.map((item, index) => {
                  console.log(
                    "itemitem", item
                  );
                  return (
                    <MenuItem key={item}
                      value={item}>
                      {item}
                    </MenuItem>
                  )
                })
              }
              {/* {getReserveDates
              .filter((date) => date.is_Reserved === true)
              .map((date) => (
                <MenuItem key={date?.id}
                  value={`${date?.blocked_dates} ${date?.blocked_time}`}>
                  {`${date?.blocked_dates} ${date?.blocked_time}`}
                </MenuItem>
              ))} */}
            </Select >
        );
      },
    },
    {
      field: "city",
      headerName: "Site City",
      type: "number",
      width: 110,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return (
          <div style={{ color: "rgba(191, 10, 48, 1)" }}>{text?.value}</div>
        );
      },
    },
    {
      field: "service_type",
      headerName: "Order Type",
      width: 140,
      editable: true,
    },
    {
      field: "calendar_spot",
      headerName: "Calendar Spot",
      type: "number",
      width: 150,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (text) => {
        return <div>{text?.value}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      type: "number",
      width: 90,
      editable: true,
      headerAlign: "center",
      align: "center",
    },
  ];
  const handleChangeTable2 = (event, value) => {
    let dupPagination = { ...paginationTable2 };
    dupPagination.current = value;
    setPaginationTable2(dupPagination);
    getOnHoldQuotes(value);
  };
  const handlePresetDates = () => {
    let tempData = { presetDates: presetDate };
    Request.post("/presetAppointments", tempData)
      .then((result) => {
        showToast("success", result?.data?.message);
        // console.log("Data: ", result.data);
        getAllReserveDates()
        // getAllBlockedDates();
      })
      .catch((err) => {
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", err?.response?.data?.error);
        }
      });
  };

  return (
    <Dialog
      open={openAppointments}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DataGrid
        rows={onHoldOrders}
        columns={OrdersOnHold}
        // initialState={{
        //   pagination: {
        //     paginationModel: {
        //       pageSize: 10,
        //     },
        //   },
        // }}
        sx={{
          "& .MuiDataGrid-cell": {
            border: 1,
            borderColor: "rgba(223, 222, 225, 1)",
          },
          "& .MuiDataGrid-columnSeparator": {
            color: "rgba(223, 222, 225, 1)",
            visibility: "unset",
          },
          "& .css-e84y1v-MuiDataGrid-root": {
            borderStyle: "unset",
          },
          marginTop: "25px",
        }}
        hideFooter
        // pageSizeOptions={[5]}
        disableColumnMenu
      />
      <Stack spacing={2} marginTop="5px">
        <Pagination
          count={Math.ceil(paginationTable2.total / 7)}
          page={paginationTable2.current}
          onChange={handleChangeTable2}
        />
      </Stack>
      <DialogActions>
        <Button onClick={() => setOpenAppointments(false)}>Cancel</Button>
        <Button onClick={handlePresetDates}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PresetAppointment;
