import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, Tooltip } from "@mui/material";
import moment from "moment";

function CalendarManagement({
  currentMonth,
  handleNextMonthClick,
  handlePreviousMonthClick,
  handleNextMonth,
  checked,
  Switch,
  toggleSwitch,
  select930All,
  select200All,
  handleSelectAll,
  daysOfWeek,
  getEnableWeekend,
  weeks,
  handleRowCheckbox,
  getBlockedDates,
  appointmentsDropdown,
  unblockDates,
  handleUnblock,
  calendarDates,
  setCalendarDates,
  setWeeks,
  handleSubmitDates,
  unBlockDateId,
  handleReserveDates,
  setReserveDates,
  isSecondInstance = false,
  presentMonth,
  dayOfWeek,
  weekM,
  handleunReserveDates,
  getReserveDates,
  handleUnReserve,
  unReserveDates,
  unavailableDates,
  blockDates,
  reserveDate,
  onClick,
  switchDisable
}) {
  console.log("blockDates", blockDates);
  console.log("getBlockedDatesgetBlockedDates", getBlockedDates);

  const handleRemoveDate = (e, date, weekIndex, dayIndex, time) => {
    console.log("call handleRemoveDate", date, time);
    if (
      getBlockedDates.some(
        (item) =>
          item.blocked_dates ===
          date.date.format("MM/DD/YYYY") &&
          item.blocked_time === time
      )
    ) {
      // Call handleUnblock when the red checkbox is clicked
      handleUnblock(
        date.date.format("MM/DD/YYYY"),
        time
      );
    } else if (
      getReserveDates.some(
        (item) => {
          // console.log("item", item);
          return (
            item.blocked_dates ===
            date.date.format("MM/DD/YYYY") &&
            item.blocked_time === time
          )
        })) {
      // console.log("unReserve Date");
      handleUnReserve(
        date.date.format("MM/DD/YYYY"),
        time
      );
    }
  }
  const handleDates = (e, date, weekIndex, dayIndex, time) => {
    // console.log("date", time);
    // if (
    //   getBlockedDates.some(
    //     (item) =>
    //       item.blocked_dates ===
    //       date.date.format("MM/DD/YYYY") &&
    //       item.blocked_time === time
    //   )
    // ) {
    //   // Call handleUnblock when the red checkbox is clicked
    //   handleUnblock(
    //     date.date.format("MM/DD/YYYY"),
    //     time
    //   );
    // } else if (
    //   getReserveDates.some(
    //     (item) => {
    //       // console.log("item", item);
    //       return (
    //         item.blocked_dates ===
    //         date.date.format("MM/DD/YYYY") &&
    //         item.blocked_time === time
    //       )
    //     })) {
    //   // console.log("unReserve Date");
    //   handleUnReserve(
    //     date.date.format("MM/DD/YYYY"),
    //     time
    //   );
    // }

    // else {
    const updatedCheckboxData = [...calendarDates];
    if (e.target.checked) {
      const date = moment(e.target.value.split("|#|")[1], "MM/DD/YYYY")
      const formattedDate = date.format('MM/DD/YYYY');
      // console.log("e.target.value.split", formattedDate);
      updatedCheckboxData.push({
        blocked_dates: formattedDate,
        blocked_time: e.target.value.split("|#|")[0],
      });
    } else {
      const indexToDelete =
        updatedCheckboxData.findIndex(
          (item) =>
            item.blocked_dates ===
            e.target.value.split("|#|")[1]
        );
      updatedCheckboxData.splice(indexToDelete, 1);
    }

    setCalendarDates(updatedCheckboxData);
    setReserveDates(updatedCheckboxData);
    // console.log(updatedCheckboxData);
    // }
    const updatedWeeks = [...weeks];
    if (time === "2:00 PM") {
      updatedWeeks[weekIndex][dayIndex].is200Checked =
        !date.is200Checked;
      setWeeks(updatedWeeks);
    } else {
      updatedWeeks[weekIndex][dayIndex].is930Checked =
        !date.is930Checked;
      setWeeks(updatedWeeks);
    }
  }
  console.log("weekM", weekM);

  // const handleSelectAllDates = (weekM) => {
  //   const blockDates = getBlockedDates.filter((date) => {
  //     console.log("week<", weekM);
  //     weekM.every((array) => array.every((dates) => dates.includes(date.blocked_dates)))
  //   })
  //   console.log("blockDatesblockDates", blockDates);
  //   // if (
  //   //   getBlockedDates.filter
  //   // )
  // }

  return (
    <>
      <Grid item xs={12} md={12} container>
        {isSecondInstance ? (
          <>
            <p
              style={{
                color: "rgba(0, 0, 0, 1)",
                width: "100%",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {presentMonth.format("MMMM YYYY")}
            </p>
            <span
              style={{
                color: "rgba(44, 181, 44, 1)",
                fontSize: "13px",
                fontWeight: "300",
                lineHeight: "24px",
                cursor: "pointer",
                marginRight: 30
              }}
              onClick={handlePreviousMonthClick}
            >
              Previous month
            </span>
            <span
              style={{
                color: "rgba(44, 181, 44, 1)",
                fontSize: "13px",
                fontWeight: "300",
                lineHeight: "24px",
                cursor: "pointer",
              }}
              onClick={handleNextMonthClick}
            >
              Next month
            </span>
          </>
        ) : (
          <>
            <p
              style={{
                color: "rgba(0, 0, 0, 1)",
                width: "100%",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {currentMonth.format("MMMM YYYY")}
            </p>
            <span
              style={{
                color: "rgba(44, 181, 44, 1)",
                fontSize: "13px",
                fontWeight: "300",
                lineHeight: "24px",
                cursor: "pointer",
                marginRight: 30
              }}
              onClick={handlePreviousMonthClick}
            >
              Previous month
            </span>
            <span
              style={{
                color: "rgba(44, 181, 44, 1)",
                fontSize: "13px",
                fontWeight: "300",
                lineHeight: "24px",
                cursor: "pointer",
              }}
              onClick={handleNextMonthClick}
            >
              Next month
            </span>
          </>
        )}
      </Grid>

      <Grid item xs={12} md={12} container>
        {isSecondInstance ? null : (
          <div>
            {checked ? "Disable Weekends" : "Enable Weekends"}
            <Switch
              checked={checked}
              onChange={(e) => toggleSwitch(e)}
              name="enableWeekends"
              disabled={switchDisable}
            />
          </div>
        )}
        <table className="table">
          <tr>
            <th className="thInspect">
              All <br />
              <Grid>
                <input
                  type="checkbox"
                  checked={select930All}
                  // onChange={() => handleSelectAllDates(weekM)}
                  onChange={() => handleSelectAll("9:30 AM")}
                  className="red-checkbox"
                />
                <span>9:30 AM</span>
              </Grid>
              <Grid>
                <input
                  type="checkbox"
                  checked={select200All}
                  // onChange={() => handleSelectAllDates(weekM)}
                  onChange={() => handleSelectAll("2:00 PM")}
                  className="red-checkbox"
                />
                <span>2:00 PM</span>
              </Grid>
            </th>
            {isSecondInstance
              ? dayOfWeek.map((day, dayIndex) => (
                getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
                  <th className="thInspect" key={day}>
                    {day}
                  </th>
                ) : null
              ))
              :
              daysOfWeek.map((day, dayIndex) =>
                getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
                  <th className="thInspect" key={day}>
                    {day}
                  </th>
                ) : null
              )
            }
          </tr>
          {isSecondInstance ?
            (
              // weekM.map((week, weekIndex) => (
              //   <tr key={weekIndex}>
              //     <td className="tdInspect">
              //       <input
              //         type="checkbox"
              //         className="red-checkbox"
              //         checked={week.every((date) => date.isChecked)}
              //       />
              //     </td>
              //     {week.map((date, dayIndex) =>
              //       <td className="tdInspect" key={dayIndex}>
              //         {date.date.format("D")}
              //         <div>
              //           <input
              //             type="checkbox"
              //             style={{
              //               accentColor: getBlockedDates &&
              //                 getBlockedDates.some(
              //                   (item) =>
              //                     item.blocked_dates ===
              //                     date.date.format("MM/DD/YYYY") &&
              //                     item.blocked_time === "9:30 AM" &&
              //                     item.is_Reserved === true
              //                 )
              //                 ? "rgba(155, 46, 239, 1)"
              //                 : "hsl(212.47deg 100% 50%)",
              //             }}
              //             value={`9:30 AM|#|${date.date.format("MM/DD/YYYY")}`}
              //             checked={
              //               getBlockedDates.some(
              //                 (item) =>
              //                   item.blocked_dates ===
              //                   date.date.format("MM/DD/YYYY") &&
              //                   item.blocked_time === "9:30 AM" &&
              //                   item.is_Reserved === true
              //               )
              //             }
              //           />
              //           9:30 AM
              //           <br />
              //           <input
              //             type="checkbox"
              //             style={{
              //               accentColor: getBlockedDates && getBlockedDates.some(
              //                 (item) =>
              //                   item.blocked_dates ===
              //                   date.date.format("MM/DD/YYYY") &&
              //                   item.blocked_time === "2:00 PM" &&
              //                   item.is_Reserved === true
              //               )
              //                 ? "#e60000"
              //                 : "hsl(212.47deg 100% 50%)",
              //             }}
              //             checked={
              //               getBlockedDates.some(
              //                 (item) =>
              //                   item.blocked_dates ===
              //                   date.date.format("MM/DD/YYYY") &&
              //                   item.blocked_time === "2:00 PM" &&
              //                   item.is_Reserved === true &&
              //                   item.is_blocked === true || false || null
              //               )
              //             }
              //             value={`2:00 PM|#|${date.date.format("MM/DD/YYYY")}`}
              //           />
              //           2:00 PM
              //         </div>
              //       </td>
              //     )}
              //   </tr>
              // ))
              weeks.map((week, weekIndex) => (
                <tr key={weekIndex}>
                  <td className="tdInspect">
                    <Grid>
                      <input
                        type="checkbox"
                        className="red-checkbox"
                        checked={week.every((date) => date.is930Checked)}
                        onChange={() => handleRowCheckbox(weekIndex, "9:30 AM")}
                      />
                      <span>9:30 AM</span>
                    </Grid>
                    <Grid>
                      <input
                        type="checkbox"
                        className="red-checkbox"
                        checked={week.every((date) => date.is200Checked)}
                        onChange={() => handleRowCheckbox(weekIndex, "2:00 PM")}
                      />
                      <span>2:30 AM</span>
                    </Grid>
                  </td>
                  {week.map((date, dayIndex) => {
                    // console.log("datedatedatedate", date)
                    return (
                      getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
                        <td className="tdInspect" key={dayIndex}>
                          {date.date.format("D")}
                          <div>
                            <Tooltip title={getBlockedDates.some(
                              (item) =>
                                item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                item?.blocked_time === "9:30 AM"
                            )
                              ? `${getBlockedDates.find(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "9:30 AM"
                              ).jobsCount} slots blocked`
                              : getReserveDates.some(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "9:30 AM"
                              )
                                ? `${getReserveDates.find(
                                  (item) =>
                                    item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "9:30 AM"
                                ).jobsCount} slots reserved`
                                : ""}>
                              <input
                                type="checkbox"
                                // style={{
                                //   accentColor: unblockDates && unblockDates.some(
                                //     (unblockItem) =>
                                //       unblockItem?.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unblockItem?.blocked_time?.includes("9:30 AM")
                                //   )
                                //     ? "green"
                                //     : getBlockedDates.some(
                                //       (item) =>
                                //         item?.blocked_dates ===
                                //         date.date.format("MM/DD/YYYY") &&
                                //         item?.blocked_time === "9:30 AM" &&
                                //         item?.is_Reserved === false
                                //     )
                                //       ? "#e60000"
                                //       : getBlockedDates.some(
                                //         (item) =>
                                //           item.blocked_dates ===
                                //           date.date.format("MM/DD/YYYY") &&
                                //           item?.blocked_time === "9:30 AM" &&
                                //           item?.is_Reserved === true
                                //       )
                                //         ? "rgba(155, 46, 239, 1)"
                                //         : appointmentsDropdown.some(
                                //           (data) =>
                                //             data.date
                                //               .split(" ")[1]
                                //               ?.includes(date.date.format("MM/DD/YYYY")) &&
                                //             data.date.split(" ")[2]?.includes("9:30")
                                //         )
                                //           ? "rgba(155, 46, 239, 1)"
                                //           : "hsl(212.47deg 100% 50%)",
                                // }}
                                style={{
                                  accentColor: unblockDates && unblockDates.some(
                                    (unblockItem) =>
                                      unblockItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unblockItem?.blocked_time?.includes("9:30 AM")
                                  )
                                    ? "green" :
                                    getBlockedDates.some(
                                      (item) =>
                                        item?.blocked_dates ===
                                        date.date.format("MM/DD/YYYY") &&
                                        item?.blocked_time === "9:30 AM"
                                    )
                                      ? "#e60000" :
                                      getReserveDates.some(
                                        (item) =>
                                          item?.blocked_dates ===
                                          date.date.format("MM/DD/YYYY") &&
                                          item?.blocked_time === "9:30 AM"
                                      )
                                        ? "rgba(155, 46, 239, 1)" :
                                        unReserveDates.some(
                                          (unReserveItem) =>
                                            unReserveItem?.blocked_dates?.includes(
                                              date.date.format("MM/DD/YYYY")
                                            ) && unReserveItem?.blocked_time?.includes("9:30 AM")
                                        ) && "rgba(155,46,239,1)" ||
                                        unavailableDates.some(
                                          (unavailableItem) =>
                                            unavailableItem?.date?.includes(
                                              date.date.format("MM/DD/YYYY")
                                            ) && unavailableItem?.time?.includes("9:30 AM")
                                        ) && "#e60000"

                                }}
                                value={`9:30 AM|#|${date.date.format("MM/DD/YYYY")}`}
                                checked={
                                  date.is930Checked ||
                                  getBlockedDates.some(
                                    (item) =>
                                      item?.blocked_dates ===
                                      date.date.format("MM/DD/YYYY") &&
                                      item?.blocked_time === "9:30 AM"
                                  ) ||
                                  unblockDates.some(
                                    (unblockItem) =>
                                      unblockItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unblockItem?.blocked_time?.includes("9:30 AM")
                                  ) ||
                                  unReserveDates.some(
                                    (unReserveItem) =>
                                      unReserveItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unReserveItem?.blocked_time?.includes("9:30 AM")
                                  ) ||
                                  // appointmentsDropdown.some(
                                  //   (data) =>
                                  //     data.date
                                  //       .split(" ")[1]
                                  //       ?.includes(date.date.format("MM/DD/YYYY")) &&
                                  //     data.date.split(" ")[2]?.includes("9:30")
                                  // ) ||
                                  getReserveDates.some(
                                    (item) =>
                                      item?.blocked_dates ===
                                      date.date.format("MM/DD/YYYY") &&
                                      item?.blocked_time === "9:30 AM"
                                  ) ||
                                  unavailableDates.some(
                                    (unavailableItem) =>
                                      unavailableItem?.date?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unavailableItem?.time?.includes("9:30 AM")
                                  )
                                }
                                onChange={(e) => handleRemoveDate(e, date, weekIndex, dayIndex, "9:30 AM")}
                              // onChange={(e) => {
                              //   if (
                              //     getBlockedDates.some(
                              //       (item) =>
                              //         item.blocked_dates ===
                              //         date.date.format("MM/DD/YYYY") &&
                              //         item.blocked_time === "9:30 AM" &&
                              //         item.is_Reserved === false
                              //     )
                              //   ) {
                              //     // Call handleUnblock when the red checkbox is clicked
                              //     handleUnblock(
                              //       date.date.format("MM/DD/YYYY"),
                              //       "9:30 AM"
                              //     );
                              //   } else {
                              //     const updatedCheckboxData = [...calendarDates];
                              //     if (e.target.checked) {
                              //       updatedCheckboxData.push({
                              //         blocked_dates: e.target.value.split("|#|")[1],
                              //         blocked_time: e.target.value.split("|#|")[0],
                              //       });
                              //     } else {
                              //       const indexToDelete =
                              //         updatedCheckboxData.findIndex(
                              //           (item) =>
                              //             item.blocked_dates ===
                              //             e.target.value.split("|#|")[1]
                              //         );
                              //       updatedCheckboxData.splice(indexToDelete, 1);
                              //     }

                              //     setCalendarDates(updatedCheckboxData);
                              //     setReserveDates(updatedCheckboxData);
                              //     console.log(updatedCheckboxData);
                              //   }
                              //   const updatedWeeks = [...weeks];
                              //   updatedWeeks[weekIndex][dayIndex].is930Checked =
                              //     !date.is930Checked;
                              //   setWeeks(updatedWeeks);
                              // }}
                              />
                              9:30 AM
                            </Tooltip>
                            <br />
                            <Tooltip title={getBlockedDates.some(
                              (item) =>
                                item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                item?.blocked_time === "2:00 PM"
                            )
                              ? `${getBlockedDates.find(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "2:00 PM"
                              ).jobsCount} slots blocked`
                              : getReserveDates.some(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "2:00 PM"
                              )
                                ? `${getReserveDates.find(
                                  (item) =>
                                    item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "2:00 PM"
                                ).jobsCount} slots reserved`
                                : ""}>
                              <input
                                type="checkbox"
                                style={{
                                  accentColor: unblockDates && unblockDates.some(
                                    (unblockItem) =>
                                      unblockItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unblockItem?.blocked_time?.includes("2:00 PM")
                                  )
                                    ? "green" :
                                    getBlockedDates.some(
                                      (item) =>
                                        item?.blocked_dates ===
                                        date.date.format("MM/DD/YYYY") &&
                                        item?.blocked_time === "2:00 PM"
                                    )
                                      ? "#e60000" :
                                      getReserveDates.some(
                                        (item) =>
                                          item?.blocked_dates ===
                                          date.date.format("MM/DD/YYYY") &&
                                          item?.blocked_time === "2:00 PM"
                                      )
                                        ? "rgba(155, 46, 239, 1)" :
                                        unReserveDates.some(
                                          (unReserveItem) =>
                                            unReserveItem?.blocked_dates?.includes(
                                              date.date.format("MM/DD/YYYY")
                                            ) && unReserveItem?.blocked_time?.includes("9:30 AM")
                                        ) && "rgba(155,46,239,1)"
                                  // unavailableDates.some(
                                  //   (unavailableItem) =>
                                  //     unavailableItem?.date?.includes(
                                  //       date.date.format("MM/DD/YYYY")
                                  //     ) && unavailableItem?.time?.includes("2:00 PM")
                                  // ) && "#e60000"
                                }}
                                // style={{
                                //   accentColor: unblockDates && unblockDates.some(
                                //     (unblockItem) =>
                                //       unblockItem?.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unblockItem?.blocked_time?.includes("2:00 PM")
                                //   )
                                //     ? "green"
                                //     : getBlockedDates.some(
                                //       (item) =>
                                //         item.blocked_dates ===
                                //         date.date.format("MM/DD/YYYY") &&
                                //         item.blocked_time === "2:00 PM" &&
                                //         item.is_Reserved === false
                                //     )
                                //       ? "#e60000"
                                //       : getBlockedDates.some(
                                //         (item) =>
                                //           item.blocked_dates ===
                                //           date.date.format("MM/DD/YYYY") &&
                                //           item.blocked_time === "2:00 PM" &&
                                //           item.is_Reserved === true
                                //       )
                                //         ? "rgba(155, 46, 239, 1)"
                                //         : appointmentsDropdown.some(
                                //           (data) =>
                                //             data.date
                                //               .split(" ")[1]
                                //               ?.includes(date.date.format("MM/DD/YYYY")) &&
                                //             data.date.split(" ")[2]?.includes("2:00")
                                //         )
                                //           ? "rgba(155, 46, 239, 1)"
                                //           : "hsl(212.47deg 100% 50%)",
                                // }}
                                // checked={
                                //   date.is200Checked ||
                                //   getBlockedDates.some(
                                //     (item) =>
                                //       item.blocked_dates ===
                                //       date.date.format("MM/DD/YYYY") &&
                                //       item.blocked_time === "2:00 PM"
                                //   ) ||
                                //   unblockDates.some(
                                //     (unblockItem) =>
                                //       unblockItem.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unblockItem.blocked_time?.includes("2:00 PM")
                                //   ) ||
                                //   appointmentsDropdown.some(
                                //     (data) =>
                                //       data.date
                                //         .split(" ")[1]
                                //         ?.includes(date.date.format("MM/DD/YYYY")) &&
                                //       data.date.split(" ")[2]?.includes("2:00")
                                //   )
                                // }

                                checked={
                                  date.is200Checked ||
                                  getBlockedDates.some(
                                    (item) =>
                                      item?.blocked_dates ===
                                      date.date.format("MM/DD/YYYY") &&
                                      item?.blocked_time === "2:00 PM"
                                  ) ||
                                  unblockDates.some(
                                    (unblockItem) =>
                                      unblockItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unblockItem?.blocked_time?.includes("2:00 PM")
                                  ) ||
                                  // appointmentsDropdown.some(
                                  //   (data) =>
                                  //     data.date
                                  //       .split(" ")[1]
                                  //       ?.includes(date.date.format("MM/DD/YYYY")) &&
                                  //     data.date.split(" ")[2]?.includes("9:30")
                                  // ) ||
                                  getReserveDates.some(
                                    (item) =>
                                      item?.blocked_dates ===
                                      date.date.format("MM/DD/YYYY") &&
                                      item?.blocked_time === "2:00 PM"
                                  ) ||
                                  unReserveDates.some(
                                    (unReserveItem) =>
                                      unReserveItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unReserveItem?.blocked_time?.includes("2:00 PM")
                                  ) ||
                                  unavailableDates.some(
                                    (unavailableItem) =>
                                      unavailableItem?.date?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unavailableItem?.time?.includes("2:00 PM")
                                  )
                                }
                                value={`2:00 PM|#|${date.date.format("MM/DD/YYYY")}`}
                                onChange={(e) => handleRemoveDate(e, date, weekIndex, dayIndex, "2:00 PM")}

                              // onChange={(e) => {
                              //   if (
                              //     getBlockedDates.some(
                              //       (item) =>
                              //         item.blocked_dates ===
                              //         date.date.format("MM/DD/YYYY") &&
                              //         item.blocked_time === "2:00 PM" &&
                              //         item.is_Reserved === false
                              //     ) ||
                              //     getBlockedDates.some(
                              //       (item) =>
                              //         item.blocked_dates ===
                              //         date.date.format("MM/DD/YYYY") &&
                              //         item.blocked_time === "2:00 PM" &&
                              //         item.is_Reserved === true
                              //     )
                              //   ) {
                              //     // Call handleUnblock when the red checkbox is clicked
                              //     handleUnblock(
                              //       date.date.format("MM/DD/YYYY"),
                              //       "2:00 PM"
                              //     );
                              //   } else {
                              //     const updatedCheckboxData = [...calendarDates];
                              //     if (e.target.checked) {
                              //       updatedCheckboxData.push({
                              //         blocked_dates: e.target.value.split("|#|")[1],
                              //         blocked_time: e.target.value.split("|#|")[0],
                              //       });
                              //     } else {
                              //       const indexToDelete =
                              //         updatedCheckboxData.findIndex(
                              //           (item) =>
                              //             item.blocked_dates ===
                              //             e.target.value.split("|#|")[1]
                              //         );
                              //       updatedCheckboxData.splice(indexToDelete, 1);
                              //     }

                              //     setCalendarDates(updatedCheckboxData);
                              //   }
                              //   const updatedWeeks = [...weeks];
                              //   updatedWeeks[weekIndex][dayIndex].is200Checked =
                              //     !date.is200Checked;
                              //   setWeeks(updatedWeeks);
                              // }}
                              />
                              2:00 PM
                            </Tooltip>
                          </div>
                        </td>
                      ) : null
                    )
                  }
                  )}
                </tr>
              ))
            ) : (
              weeks.map((week, weekIndex) => (
                <tr key={weekIndex}>
                  <td className="tdInspect">
                    <Grid>
                      <input
                        type="checkbox"
                        className="red-checkbox"
                        checked={week.every((date) => date.is930Checked)}
                        onChange={() => handleRowCheckbox(weekIndex, "9:30 AM")}
                      />
                      <span>9:30 AM</span>
                    </Grid>
                    <Grid>
                      <input
                        type="checkbox"
                        className="red-checkbox"
                        checked={week.every((date) => date.is200Checked)}
                        onChange={() => handleRowCheckbox(weekIndex, "2:00 PM")}
                      />
                      <span>2:30 AM</span>
                    </Grid>
                  </td>
                  {week.map((date, dayIndex) => {
                    console.log("datedatedatedate", date)
                    return (
                      getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
                        <td className="tdInspect" key={dayIndex}>
                          {date.date.format("D")}
                          <div>
                            <Tooltip title={blockDates.some(
                              (item) =>
                                item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                item?.blocked_time === "9:30 AM"
                            )
                              ? `${blockDates.find(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "9:30 AM"
                              ).jobsCount} slots blocked`
                              : reserveDate.some(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "9:30 AM"
                              )
                                ? `${reserveDate.find(
                                  (item) =>
                                    item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "9:30 AM"
                                ).jobsCount} slots reserved`
                                : ""}>
                              <input
                                type="checkbox"
                                style={{
                                  accentColor: unblockDates && unblockDates.some(
                                    (unblockItem) =>
                                      unblockItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unblockItem?.blocked_time?.includes("9:30 AM")
                                  )
                                    ? "green" :
                                    blockDates.some(
                                      (item) =>
                                        item?.blocked_dates ===
                                        date.date.format("MM/DD/YYYY") &&
                                        item?.blocked_time === "9:30 AM"
                                    )
                                      ? "#e60000" :
                                      reserveDate.some(
                                        (item) =>
                                          item?.blocked_dates ===
                                          date.date.format("MM/DD/YYYY") &&
                                          item?.blocked_time === "9:30 AM"
                                      )
                                        ? "rgba(155, 46, 239, 1)" :
                                        unReserveDates.some(
                                          (unReserveItem) =>
                                            unReserveItem?.blocked_dates?.includes(
                                              date.date.format("MM/DD/YYYY")
                                            ) && unReserveItem?.blocked_time?.includes("9:30 AM")
                                        ) && "rgba(155,46,239,1)"
                                  // unavailableDates.some(
                                  //   (unavailableItem) =>
                                  //     unavailableItem?.date?.includes(
                                  //       date.date.format("MM/DD/YYYY")
                                  //     ) && unavailableItem?.time?.includes("9:30 AM")
                                  // ) && "#e60000"

                                }}
                                value={`9:30 AM|#|${date.date.format("MM/DD/YYYY")}`}
                                // disabled={blockDates?.some((item) =>
                                //   item?.blocked_dates ===
                                //   date.date.format("MM/DD/YYYY") &&
                                //   item?.blocked_time === "9:30 AM")
                                // }
                                checked={
                                  date.is930Checked ||
                                  blockDates?.some((item) =>
                                    item?.blocked_dates ===
                                    date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "9:30 AM") ||
                                  reserveDate?.some((item) =>
                                    item?.blocked_dates ===
                                    date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "9:30 AM") ||
                                  unavailableDates.some(
                                    (unavailableItem) =>
                                      unavailableItem?.date?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unavailableItem?.time?.includes("9:30 AM")
                                  )
                                }
                                // checked={
                                //   date.is930Checked ||
                                //   getBlockedDates.some(
                                //     (item) =>
                                //       item?.blocked_dates ===
                                //       date.date.format("MM/DD/YYYY") &&
                                //       item?.blocked_time === "9:30 AM"
                                //   ) ||
                                //   unblockDates.some(
                                //     (unblockItem) =>
                                //       unblockItem?.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unblockItem?.blocked_time?.includes("9:30 AM")
                                //   ) ||
                                //   unReserveDates.some(
                                //     (unReserveItem) =>
                                //       unReserveItem?.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unReserveItem?.blocked_time?.includes("9:30 AM")
                                //   ) ||
                                //   // appointmentsDropdown.some(
                                //   //   (data) =>
                                //   //     data.date
                                //   //       .split(" ")[1]
                                //   //       ?.includes(date.date.format("MM/DD/YYYY")) &&
                                //   //     data.date.split(" ")[2]?.includes("9:30")
                                //   // ) ||
                                //   getReserveDates.some(
                                //     (item) =>
                                //       item?.blocked_dates ===
                                //       date.date.format("MM/DD/YYYY") &&
                                //       item?.blocked_time === "9:30 AM"
                                //   ) ||
                                //   unavailableDates.some(
                                //     (unavailableItem) =>
                                //       unavailableItem?.date?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unavailableItem?.time?.includes("9:30 AM")
                                //   )
                                // }
                                onChange={(e) => handleDates(e, date, weekIndex, dayIndex, "9:30 AM")}
                              />
                              9:30 AM
                            </Tooltip>
                            <br />
                            <Tooltip title={blockDates.some(
                              (item) =>
                                item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                item?.blocked_time === "2:00 PM"
                            )
                              ? `${blockDates.find(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "2:00 PM"
                              ).jobsCount} slots blocked`
                              : reserveDate.some(
                                (item) =>
                                  item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                  item?.blocked_time === "2:00 PM"
                              )
                                ? `${reserveDate.find(
                                  (item) =>
                                    item?.blocked_dates === date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "2:00 PM"
                                ).jobsCount} slots reserved`
                                : ""}>
                              <input
                                type="checkbox"
                                style={{
                                  accentColor: unblockDates && unblockDates.some(
                                    (unblockItem) =>
                                      unblockItem?.blocked_dates?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unblockItem?.blocked_time?.includes("2:00 PM")
                                  )
                                    ? "green" :
                                    blockDates.some(
                                      (item) =>
                                        item?.blocked_dates ===
                                        date.date.format("MM/DD/YYYY") &&
                                        item?.blocked_time === "2:00 PM"
                                    )
                                      ? "#e60000" :
                                      reserveDate.some(
                                        (item) =>
                                          item?.blocked_dates ===
                                          date.date.format("MM/DD/YYYY") &&
                                          item?.blocked_time === "2:00 PM"
                                      )
                                        ? "rgba(155, 46, 239, 1)" :
                                        unReserveDates.some(
                                          (unReserveItem) =>
                                            unReserveItem?.blocked_dates?.includes(
                                              date.date.format("MM/DD/YYYY")
                                            ) && unReserveItem?.blocked_time?.includes("9:30 AM")
                                        ) && "rgba(155,46,239,1)"
                                  // unavailableDates.some(
                                  //   (unavailableItem) =>
                                  //     unavailableItem?.date?.includes(
                                  //       date.date.format("MM/DD/YYYY")
                                  //     ) && unavailableItem?.time?.includes("2:00 PM")
                                  // ) && "#e60000"
                                }}
                                checked={
                                  date.is200Checked ||
                                  blockDates?.some((item) =>
                                    item?.blocked_dates ===
                                    date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "2:00 PM") ||
                                  reserveDate?.some((item) =>
                                    item?.blocked_dates ===
                                    date.date.format("MM/DD/YYYY") &&
                                    item?.blocked_time === "2:00 PM") ||
                                  unavailableDates.some(
                                    (unavailableItem) =>
                                      unavailableItem?.date?.includes(
                                        date.date.format("MM/DD/YYYY")
                                      ) && unavailableItem?.time?.includes("2:00 PM")
                                  )
                                }
                                // checked={
                                //   date.is200Checked ||
                                //   getBlockedDates.some(
                                //     (item) =>
                                //       item?.blocked_dates ===
                                //       date.date.format("MM/DD/YYYY") &&
                                //       item?.blocked_time === "2:00 PM"
                                //   ) ||
                                //   unblockDates.some(
                                //     (unblockItem) =>
                                //       unblockItem?.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unblockItem?.blocked_time?.includes("2:00 PM")
                                //   ) ||
                                //   // appointmentsDropdown.some(
                                //   //   (data) =>
                                //   //     data.date
                                //   //       .split(" ")[1]
                                //   //       ?.includes(date.date.format("MM/DD/YYYY")) &&
                                //   //     data.date.split(" ")[2]?.includes("9:30")
                                //   // ) ||
                                //   getReserveDates.some(
                                //     (item) =>
                                //       item?.blocked_dates ===
                                //       date.date.format("MM/DD/YYYY") &&
                                //       item?.blocked_time === "2:00 PM"
                                //   ) ||
                                //   unReserveDates.some(
                                //     (unReserveItem) =>
                                //       unReserveItem?.blocked_dates?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unReserveItem?.blocked_time?.includes("2:00 PM")
                                //   ) ||
                                //   unavailableDates.some(
                                //     (unavailableItem) =>
                                //       unavailableItem?.date?.includes(
                                //         date.date.format("MM/DD/YYYY")
                                //       ) && unavailableItem?.time?.includes("2:00 PM")
                                //   )
                                // }
                                value={`2:00 PM|#|${date.date.format("MM/DD/YYYY")}`}
                                onChange={(e) => handleDates(e, date, weekIndex, dayIndex, "2:00 PM")}

                              // onChange={(e) => {
                              //   if (
                              //     getBlockedDates.some(
                              //       (item) =>
                              //         item.blocked_dates ===
                              //         date.date.format("MM/DD/YYYY") &&
                              //         item.blocked_time === "2:00 PM" &&
                              //         item.is_Reserved === false
                              //     ) ||
                              //     getBlockedDates.some(
                              //       (item) =>
                              //         item.blocked_dates ===
                              //         date.date.format("MM/DD/YYYY") &&
                              //         item.blocked_time === "2:00 PM" &&
                              //         item.is_Reserved === true
                              //     )
                              //   ) {
                              //     // Call handleUnblock when the red checkbox is clicked
                              //     handleUnblock(
                              //       date.date.format("MM/DD/YYYY"),
                              //       "2:00 PM"
                              //     );
                              //   } else {
                              //     const updatedCheckboxData = [...calendarDates];
                              //     if (e.target.checked) {
                              //       updatedCheckboxData.push({
                              //         blocked_dates: e.target.value.split("|#|")[1],
                              //         blocked_time: e.target.value.split("|#|")[0],
                              //       });
                              //     } else {
                              //       const indexToDelete =
                              //         updatedCheckboxData.findIndex(
                              //           (item) =>
                              //             item.blocked_dates ===
                              //             e.target.value.split("|#|")[1]
                              //         );
                              //       updatedCheckboxData.splice(indexToDelete, 1);
                              //     }

                              //     setCalendarDates(updatedCheckboxData);
                              //   }
                              //   const updatedWeeks = [...weeks];
                              //   updatedWeeks[weekIndex][dayIndex].is200Checked =
                              //     !date.is200Checked;
                              //   setWeeks(updatedWeeks);
                              // }}
                              />
                              2:00 PM
                            </Tooltip>
                          </div>
                        </td>
                      ) : null
                    )
                  }
                  )}
                </tr>
              ))
            )}
        </table>
      </Grid>
      {isSecondInstance ?
        <Grid item xs={12} md={12} container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={3} container>
            <Button
              sx={{
                border: "1px solid rgba(191, 10, 48, 1)",
                color: "rgba(255, 255, 255, 1)",
                borderRadius: "6px",
                padding: "10px!important",
                backgroundColor: "rgba(191, 10, 48, 1)",
                "&:hover": { backgroundColor: "rgba(191, 10, 48, 1)" },
                textTransform: "unset",
                width: "100%",
              }}
              onClick={() => onClick()}
            >
              Preset an appointment
            </Button>
          </Grid>
          <Grid item xs={12} md={2} container>
            <Button
              sx={{
                border: "1px solid rgba(44, 181, 44, 1)",
                color: "rgba(44, 181, 44, 1)",
                borderRadius: "6px",
                padding: "10px!important",
                "&:hover": { backgroundColor: "rgba(44, 181, 44, 0.1)" },
                textTransform: "unset",
                width: "100%",
              }}
              onClick={unBlockDateId}
            >
              Unblock
            </Button>
          </Grid>
          <Grid item xs={12} md={2} container>
            <Button
              sx={{
                border: "1px solid rgba(155, 46, 239, 1)",
                color: "rgba(155, 46, 239, 1)",
                borderRadius: "6px",
                padding: "10px!important",
                "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                textTransform: "unset",
                width: "100%",
              }}
              onClick={handleunReserveDates}
            >
              UnReserve
            </Button>
          </Grid>
        </Grid>
        : (
          <Grid item xs={12} md={12} container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={2} container>
              <Button
                sx={{
                  border: "1px solid rgba(191, 10, 48, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "6px",
                  padding: "10px!important",
                  backgroundColor: "rgba(191, 10, 48, 1)",
                  "&:hover": { backgroundColor: "rgba(191, 10, 48, 1)" },
                  textTransform: "unset",
                  width: "100%",
                }}
                onClick={handleSubmitDates}
              >
                Block
              </Button>

            </Grid>

            {/* <Grid item xs={12} md={2} container>
              <Button
                sx={{
                  border: "1px solid rgba(44, 181, 44, 1)",
                  color: "rgba(44, 181, 44, 1)",
                  borderRadius: "6px",
                  padding: "10px!important",
                  "&:hover": { backgroundColor: "rgba(44, 181, 44, 0.1)" },
                  textTransform: "unset",
                  width: "100%",
                }}
                onClick={unBlockDateId}
              >
                Unblock
              </Button>
            </Grid> */}

            <Grid item xs={12} md={2} container>
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(255, 255, 255, 1)",
                  borderRadius: "6px",
                  padding: "10px!important",
                  backgroundColor: "rgba(155, 46, 239, 1)",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 1)" },
                  textTransform: "unset",
                  width: "100%",
                }}
                onClick={handleReserveDates}
              >
                Reserve
              </Button>
            </Grid>

            {/* <Grid item xs={12} md={2} container>
              <Button
                sx={{
                  border: "1px solid rgba(155, 46, 239, 1)",
                  color: "rgba(155, 46, 239, 1)",
                  borderRadius: "6px",
                  padding: "10px!important",
                  "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
                  textTransform: "unset",
                  width: "100%",
                }}
                onClick={handleunReserveDates}
              >
                UnReserve
              </Button>
            </Grid> */}
          </Grid>
        )}
    </>
  );
}

export default CalendarManagement;

// export default CalendarManagement;

// import React from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { Button, Grid } from "@mui/material";
// import moment from "moment";

// function CalendarManagement({
//   currentMonth,
//   handleNextMonthClick,
//   handleNextMonth,
//   checked,
//   Switch,
//   toggleSwitch,
//   select930All,
//   select200All,
//   handleSelectAll,
//   daysOfWeek,
//   getEnableWeekend,
//   weeks,
//   handleRowCheckbox,
//   getBlockedDates,
//   appointmentsDropdown,
//   unblockDates,
//   handleUnblock,
//   calendarDates,
//   setCalendarDates,
//   setWeeks,
//   handleSubmitDates,
//   unBlockDateId,
//   handleReserveDates,
//   setReserveDates,
//   isSecondInstance = false,
//   presentMonth,
//   dayOfWeek,
//   weekM,
//   handleunReserveDates,
//   getReserveDates,
//   handleUnReserve,
//   unReserveDates,
//   unavailableDates,
//   blockDates,
//   reserveDate,
//   onClick
// }) {
//   console.log("blockDates", blockDates);
//   console.log("getBlockedDatesgetBlockedDates", getBlockedDates);

//   const handleRemoveDate = (e, date, weekIndex, dayIndex, time) => {
//     console.log("call handleRemoveDate", date, time);
//     if (
//       getBlockedDates.some(
//         (item) =>
//           item.blocked_dates ===
//           date.date.format("MM/DD/YYYY") &&
//           item.blocked_time === time
//       )
//     ) {
//       // Call handleUnblock when the red checkbox is clicked
//       handleUnblock(
//         date.date.format("MM/DD/YYYY"),
//         time
//       );
//     } else if (
//       getReserveDates.some(
//         (item) => {
//           // console.log("item", item);
//           return (
//             item.blocked_dates ===
//             date.date.format("MM/DD/YYYY") &&
//             item.blocked_time === time
//           )
//         })) {
//       // console.log("unReserve Date");
//       handleUnReserve(
//         date.date.format("MM/DD/YYYY"),
//         time
//       );
//     }
//   }
//   const handleDates = (e, date, weekIndex, dayIndex, time) => {
//     // console.log("date", time);
//     // if (
//     //   getBlockedDates.some(
//     //     (item) =>
//     //       item.blocked_dates ===
//     //       date.date.format("MM/DD/YYYY") &&
//     //       item.blocked_time === time
//     //   )
//     // ) {
//     //   // Call handleUnblock when the red checkbox is clicked
//     //   handleUnblock(
//     //     date.date.format("MM/DD/YYYY"),
//     //     time
//     //   );
//     // } else if (
//     //   getReserveDates.some(
//     //     (item) => {
//     //       // console.log("item", item);
//     //       return (
//     //         item.blocked_dates ===
//     //         date.date.format("MM/DD/YYYY") &&
//     //         item.blocked_time === time
//     //       )
//     //     })) {
//     //   // console.log("unReserve Date");
//     //   handleUnReserve(
//     //     date.date.format("MM/DD/YYYY"),
//     //     time
//     //   );
//     // }

//     // else {
//     const updatedCheckboxData = [...calendarDates];
//     if (e.target.checked) {
//       const date = moment(e.target.value.split("|#|")[1], "MM/DD/YYYY")
//       const formattedDate = date.format('MM/DD/YYYY');
//       // console.log("e.target.value.split", formattedDate);
//       updatedCheckboxData.push({
//         blocked_dates: formattedDate,
//         blocked_time: e.target.value.split("|#|")[0],
//       });
//     } else {
//       const indexToDelete =
//         updatedCheckboxData.findIndex(
//           (item) =>
//             item.blocked_dates ===
//             e.target.value.split("|#|")[1]
//         );
//       updatedCheckboxData.splice(indexToDelete, 1);
//     }

//     setCalendarDates(updatedCheckboxData);
//     setReserveDates(updatedCheckboxData);
//     // console.log(updatedCheckboxData);
//     // }
//     const updatedWeeks = [...weeks];
//     if (time === "2:00 PM") {
//       updatedWeeks[weekIndex][dayIndex].is200Checked =
//         !date.is200Checked;
//       setWeeks(updatedWeeks);
//     } else {
//       updatedWeeks[weekIndex][dayIndex].is930Checked =
//         !date.is930Checked;
//       setWeeks(updatedWeeks);
//     }
//   }
//   console.log("weekM", weekM);

//   // const handleSelectAllDates = (weekM) => {
//   //   const blockDates = getBlockedDates.filter((date) => {
//   //     console.log("week<", weekM);
//   //     weekM.every((array) => array.every((dates) => dates.includes(date.blocked_dates)))
//   //   })
//   //   console.log("blockDatesblockDates", blockDates);
//   //   // if (
//   //   //   getBlockedDates.filter
//   //   // )
//   // }

//   return (
//     <>
//       <Grid item xs={12} md={12} container>
//         {isSecondInstance ? (
//           <>
//             <p
//               style={{
//                 color: "rgba(0, 0, 0, 1)",
//                 width: "100%",
//                 fontWeight: "500",
//                 fontSize: "16px",
//               }}
//             >
//               {presentMonth.format("MMMM YYYY")}
//             </p>
//             <span
//               style={{
//                 color: "rgba(44, 181, 44, 1)",
//                 fontSize: "13px",
//                 fontWeight: "300",
//                 lineHeight: "24px",
//                 cursor: "pointer",
//               }}
//               onClick={handleNextMonth}
//             >
//               Next month
//             </span>
//           </>
//         ) : (
//           <>
//             <p
//               style={{
//                 color: "rgba(0, 0, 0, 1)",
//                 width: "100%",
//                 fontWeight: "500",
//                 fontSize: "16px",
//               }}
//             >
//               {currentMonth.format("MMMM YYYY")}
//             </p>
//             <span
//               style={{
//                 color: "rgba(44, 181, 44, 1)",
//                 fontSize: "13px",
//                 fontWeight: "300",
//                 lineHeight: "24px",
//                 cursor: "pointer",
//               }}
//               onClick={handleNextMonthClick}
//             >
//               Next month
//             </span>
//           </>
//         )}
//       </Grid>

//       <Grid item xs={12} md={12} container>
//         {isSecondInstance ? null : (
//           <div>
//             {checked ? "Disable Weekends" : "Enable Weekends"}
//             <Switch
//               checked={checked}
//               onChange={(e) => toggleSwitch(e)}
//               name="enableWeekends"
//             />
//           </div>
//         )}
//         {isSecondInstance ?
//           <table className="table">
//             <tr>
//               <th className="thInspect">
//                 All <br />
//                 <Grid>
//                   <input
//                     type="checkbox"
//                     checked={select930All}
//                     // onChange={() => handleSelectAllDates(weekM)}
//                     onChange={() => handleSelectAll("9:30 AM","1")}
//                     className="red-checkbox"
//                   />
//                   <span>9:30 AM</span>
//                 </Grid>
//                 <Grid>
//                   <input
//                     type="checkbox"
//                     checked={select200All}
//                     // onChange={() => handleSelectAllDates(weekM)}
//                     onChange={() => handleSelectAll("2:00 PM","1")}
//                     className="red-checkbox"
//                   />
//                   <span>2:00 PM</span>
//                 </Grid>
//               </th>
//               {isSecondInstance
//                 ? dayOfWeek.map((day, dayIndex) => (
//                   <th className="thInspect" key={day}>
//                     {day}
//                   </th>
//                 ))
//                 :
//                 daysOfWeek.map((day, dayIndex) =>
//                   getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
//                     <th className="thInspect" key={day}>
//                       {day}
//                     </th>
//                   ) : null
//                 )
//               }
//             </tr>
//             {/* // weekM.map((week, weekIndex) => (
//               //   <tr key={weekIndex}>
//               //     <td className="tdInspect">
//               //       <input
//               //         type="checkbox"
//               //         className="red-checkbox"
//               //         checked={week.every((date) => date.isChecked)}
//               //       />
//               //     </td>
//               //     {week.map((date, dayIndex) =>
//               //       <td className="tdInspect" key={dayIndex}>
//               //         {date.date.format("D")}
//               //         <div>
//               //           <input
//               //             type="checkbox"
//               //             style={{
//               //               accentColor: getBlockedDates &&
//               //                 getBlockedDates.some(
//               //                   (item) =>
//               //                     item.blocked_dates ===
//               //                     date.date.format("MM/DD/YYYY") &&
//               //                     item.blocked_time === "9:30 AM" &&
//               //                     item.is_Reserved === true
//               //                 )
//               //                 ? "rgba(155, 46, 239, 1)"
//               //                 : "hsl(212.47deg 100% 50%)",
//               //             }}
//               //             value={`9:30 AM|#|${date.date.format("MM/DD/YYYY")}`}
//               //             checked={
//               //               getBlockedDates.some(
//               //                 (item) =>
//               //                   item.blocked_dates ===
//               //                   date.date.format("MM/DD/YYYY") &&
//               //                   item.blocked_time === "9:30 AM" &&
//               //                   item.is_Reserved === true
//               //               )
//               //             }
//               //           />
//               //           9:30 AM
//               //           <br />
//               //           <input
//               //             type="checkbox"
//               //             style={{
//               //               accentColor: getBlockedDates && getBlockedDates.some(
//               //                 (item) =>
//               //                   item.blocked_dates ===
//               //                   date.date.format("MM/DD/YYYY") &&
//               //                   item.blocked_time === "2:00 PM" &&
//               //                   item.is_Reserved === true
//               //               )
//               //                 ? "#e60000"
//               //                 : "hsl(212.47deg 100% 50%)",
//               //             }}
//               //             checked={
//               //               getBlockedDates.some(
//               //                 (item) =>
//               //                   item.blocked_dates ===
//               //                   date.date.format("MM/DD/YYYY") &&
//               //                   item.blocked_time === "2:00 PM" &&
//               //                   item.is_Reserved === true &&
//               //                   item.is_blocked === true || false || null
//               //               )
//               //             }
//               //             value={`2:00 PM|#|${date.date.format("MM/DD/YYYY")}`}
//               //           />
//               //           2:00 PM
//               //         </div>
//               //       </td>
//               //     )}
//               //   </tr>
//               // )) */}
//             {weeks.map((week, weekIndex) => (
//               <tr key={weekIndex}>
//                 <td className="tdInspect">
//                   <Grid>
//                     <input
//                       type="checkbox"
//                       className="red-checkbox"
//                       checked={week.every((date) => date.is930Checked)}
//                       onChange={() => handleRowCheckbox(weekIndex, "9:30 AM")}
//                     />
//                     <span>9:30 AM</span>
//                   </Grid>
//                   <Grid>
//                     <input
//                       type="checkbox"
//                       className="red-checkbox"
//                       checked={week.every((date) => date.is200Checked)}
//                       onChange={() => handleRowCheckbox(weekIndex, "2:00 PM")}
//                     />
//                     <span>2:30 AM</span>
//                   </Grid>
//                 </td>
//                 {week.map((date, dayIndex) => {
//                   // console.log("datedatedatedate", date)
//                   return (
//                     getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
//                       <td className="tdInspect" key={dayIndex}>
//                         {date.date.format("D")}
//                         <div>
//                           <input
//                             type="checkbox"
//                             // style={{
//                             //   accentColor: unblockDates && unblockDates.some(
//                             //     (unblockItem) =>
//                             //       unblockItem?.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unblockItem?.blocked_time?.includes("9:30 AM")
//                             //   )
//                             //     ? "green"
//                             //     : getBlockedDates.some(
//                             //       (item) =>
//                             //         item?.blocked_dates ===
//                             //         date.date.format("MM/DD/YYYY") &&
//                             //         item?.blocked_time === "9:30 AM" &&
//                             //         item?.is_Reserved === false
//                             //     )
//                             //       ? "#e60000"
//                             //       : getBlockedDates.some(
//                             //         (item) =>
//                             //           item.blocked_dates ===
//                             //           date.date.format("MM/DD/YYYY") &&
//                             //           item?.blocked_time === "9:30 AM" &&
//                             //           item?.is_Reserved === true
//                             //       )
//                             //         ? "rgba(155, 46, 239, 1)"
//                             //         : appointmentsDropdown.some(
//                             //           (data) =>
//                             //             data.date
//                             //               .split(" ")[1]
//                             //               ?.includes(date.date.format("MM/DD/YYYY")) &&
//                             //             data.date.split(" ")[2]?.includes("9:30")
//                             //         )
//                             //           ? "rgba(155, 46, 239, 1)"
//                             //           : "hsl(212.47deg 100% 50%)",
//                             // }}
//                             style={{
//                               accentColor: unblockDates && unblockDates.some(
//                                 (unblockItem) =>
//                                   unblockItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unblockItem?.blocked_time?.includes("9:30 AM")
//                               )
//                                 ? "green" :
//                                 getBlockedDates.some(
//                                   (item) =>
//                                     item?.blocked_dates ===
//                                     date.date.format("MM/DD/YYYY") &&
//                                     item?.blocked_time === "9:30 AM"
//                                 )
//                                   ? "#e60000" :
//                                   getReserveDates.some(
//                                     (item) =>
//                                       item?.blocked_dates ===
//                                       date.date.format("MM/DD/YYYY") &&
//                                       item?.blocked_time === "9:30 AM"
//                                   )
//                                     ? "rgba(155, 46, 239, 1)" :
//                                     unReserveDates.some(
//                                       (unReserveItem) =>
//                                         unReserveItem?.blocked_dates?.includes(
//                                           date.date.format("MM/DD/YYYY")
//                                         ) && unReserveItem?.blocked_time?.includes("9:30 AM")
//                                     ) && "rgba(155,46,239,1)"
//                               // unavailableDates.some(
//                               //   (unavailableItem) =>
//                               //     unavailableItem?.date?.includes(
//                               //       date.date.format("MM/DD/YYYY")
//                               //     ) && unavailableItem?.time?.includes("9:30 AM")
//                               // ) && "#e60000"

//                             }}
//                             value={`9:30 AM|#|${date.date.format("MM/DD/YYYY")}`}
//                             checked={
//                               date.is930Checked ||
//                               getBlockedDates.some(
//                                 (item) =>
//                                   item?.blocked_dates ===
//                                   date.date.format("MM/DD/YYYY") &&
//                                   item?.blocked_time === "9:30 AM"
//                               ) ||
//                               unblockDates.some(
//                                 (unblockItem) =>
//                                   unblockItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unblockItem?.blocked_time?.includes("9:30 AM")
//                               ) ||
//                               unReserveDates.some(
//                                 (unReserveItem) =>
//                                   unReserveItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unReserveItem?.blocked_time?.includes("9:30 AM")
//                               ) ||
//                               // appointmentsDropdown.some(
//                               //   (data) =>
//                               //     data.date
//                               //       .split(" ")[1]
//                               //       ?.includes(date.date.format("MM/DD/YYYY")) &&
//                               //     data.date.split(" ")[2]?.includes("9:30")
//                               // ) ||
//                               getReserveDates.some(
//                                 (item) =>
//                                   item?.blocked_dates ===
//                                   date.date.format("MM/DD/YYYY") &&
//                                   item?.blocked_time === "9:30 AM"
//                               ) ||
//                               unavailableDates.some(
//                                 (unavailableItem) =>
//                                   unavailableItem?.date?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unavailableItem?.time?.includes("9:30 AM")
//                               )
//                             }
//                             onChange={(e) => handleRemoveDate(e, date, weekIndex, dayIndex, "9:30 AM")}
//                           // onChange={(e) => {
//                           //   if (
//                           //     getBlockedDates.some(
//                           //       (item) =>
//                           //         item.blocked_dates ===
//                           //         date.date.format("MM/DD/YYYY") &&
//                           //         item.blocked_time === "9:30 AM" &&
//                           //         item.is_Reserved === false
//                           //     )
//                           //   ) {
//                           //     // Call handleUnblock when the red checkbox is clicked
//                           //     handleUnblock(
//                           //       date.date.format("MM/DD/YYYY"),
//                           //       "9:30 AM"
//                           //     );
//                           //   } else {
//                           //     const updatedCheckboxData = [...calendarDates];
//                           //     if (e.target.checked) {
//                           //       updatedCheckboxData.push({
//                           //         blocked_dates: e.target.value.split("|#|")[1],
//                           //         blocked_time: e.target.value.split("|#|")[0],
//                           //       });
//                           //     } else {
//                           //       const indexToDelete =
//                           //         updatedCheckboxData.findIndex(
//                           //           (item) =>
//                           //             item.blocked_dates ===
//                           //             e.target.value.split("|#|")[1]
//                           //         );
//                           //       updatedCheckboxData.splice(indexToDelete, 1);
//                           //     }

//                           //     setCalendarDates(updatedCheckboxData);
//                           //     setReserveDates(updatedCheckboxData);
//                           //     console.log(updatedCheckboxData);
//                           //   }
//                           //   const updatedWeeks = [...weeks];
//                           //   updatedWeeks[weekIndex][dayIndex].is930Checked =
//                           //     !date.is930Checked;
//                           //   setWeeks(updatedWeeks);
//                           // }}
//                           />
//                           9:30 AM
//                           <br />
//                           <input
//                             type="checkbox"
//                             style={{
//                               accentColor: unblockDates && unblockDates.some(
//                                 (unblockItem) =>
//                                   unblockItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unblockItem?.blocked_time?.includes("2:00 PM")
//                               )
//                                 ? "green" :
//                                 getBlockedDates.some(
//                                   (item) =>
//                                     item?.blocked_dates ===
//                                     date.date.format("MM/DD/YYYY") &&
//                                     item?.blocked_time === "2:00 PM"
//                                 )
//                                   ? "#e60000" :
//                                   getReserveDates.some(
//                                     (item) =>
//                                       item?.blocked_dates ===
//                                       date.date.format("MM/DD/YYYY") &&
//                                       item?.blocked_time === "2:00 PM"
//                                   )
//                                     ? "rgba(155, 46, 239, 1)" :
//                                     unReserveDates.some(
//                                       (unReserveItem) =>
//                                         unReserveItem?.blocked_dates?.includes(
//                                           date.date.format("MM/DD/YYYY")
//                                         ) && unReserveItem?.blocked_time?.includes("9:30 AM")
//                                     ) && "rgba(155,46,239,1)"
//                               // unavailableDates.some(
//                               //   (unavailableItem) =>
//                               //     unavailableItem?.date?.includes(
//                               //       date.date.format("MM/DD/YYYY")
//                               //     ) && unavailableItem?.time?.includes("2:00 PM")
//                               // ) && "#e60000"
//                             }}
//                             // style={{
//                             //   accentColor: unblockDates && unblockDates.some(
//                             //     (unblockItem) =>
//                             //       unblockItem?.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unblockItem?.blocked_time?.includes("2:00 PM")
//                             //   )
//                             //     ? "green"
//                             //     : getBlockedDates.some(
//                             //       (item) =>
//                             //         item.blocked_dates ===
//                             //         date.date.format("MM/DD/YYYY") &&
//                             //         item.blocked_time === "2:00 PM" &&
//                             //         item.is_Reserved === false
//                             //     )
//                             //       ? "#e60000"
//                             //       : getBlockedDates.some(
//                             //         (item) =>
//                             //           item.blocked_dates ===
//                             //           date.date.format("MM/DD/YYYY") &&
//                             //           item.blocked_time === "2:00 PM" &&
//                             //           item.is_Reserved === true
//                             //       )
//                             //         ? "rgba(155, 46, 239, 1)"
//                             //         : appointmentsDropdown.some(
//                             //           (data) =>
//                             //             data.date
//                             //               .split(" ")[1]
//                             //               ?.includes(date.date.format("MM/DD/YYYY")) &&
//                             //             data.date.split(" ")[2]?.includes("2:00")
//                             //         )
//                             //           ? "rgba(155, 46, 239, 1)"
//                             //           : "hsl(212.47deg 100% 50%)",
//                             // }}
//                             // checked={
//                             //   date.is200Checked ||
//                             //   getBlockedDates.some(
//                             //     (item) =>
//                             //       item.blocked_dates ===
//                             //       date.date.format("MM/DD/YYYY") &&
//                             //       item.blocked_time === "2:00 PM"
//                             //   ) ||
//                             //   unblockDates.some(
//                             //     (unblockItem) =>
//                             //       unblockItem.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unblockItem.blocked_time?.includes("2:00 PM")
//                             //   ) ||
//                             //   appointmentsDropdown.some(
//                             //     (data) =>
//                             //       data.date
//                             //         .split(" ")[1]
//                             //         ?.includes(date.date.format("MM/DD/YYYY")) &&
//                             //       data.date.split(" ")[2]?.includes("2:00")
//                             //   )
//                             // }

//                             checked={
//                               date.is200Checked ||
//                               getBlockedDates.some(
//                                 (item) =>
//                                   item?.blocked_dates ===
//                                   date.date.format("MM/DD/YYYY") &&
//                                   item?.blocked_time === "2:00 PM"
//                               ) ||
//                               unblockDates.some(
//                                 (unblockItem) =>
//                                   unblockItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unblockItem?.blocked_time?.includes("2:00 PM")
//                               ) ||
//                               // appointmentsDropdown.some(
//                               //   (data) =>
//                               //     data.date
//                               //       .split(" ")[1]
//                               //       ?.includes(date.date.format("MM/DD/YYYY")) &&
//                               //     data.date.split(" ")[2]?.includes("9:30")
//                               // ) ||
//                               getReserveDates.some(
//                                 (item) =>
//                                   item?.blocked_dates ===
//                                   date.date.format("MM/DD/YYYY") &&
//                                   item?.blocked_time === "2:00 PM"
//                               ) ||
//                               unReserveDates.some(
//                                 (unReserveItem) =>
//                                   unReserveItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unReserveItem?.blocked_time?.includes("2:00 PM")
//                               ) ||
//                               unavailableDates.some(
//                                 (unavailableItem) =>
//                                   unavailableItem?.date?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unavailableItem?.time?.includes("2:00 PM")
//                               )
//                             }
//                             value={`2:00 PM|#|${date.date.format("MM/DD/YYYY")}`}
//                             onChange={(e) => handleRemoveDate(e, date, weekIndex, dayIndex, "2:00 PM")}

//                           // onChange={(e) => {
//                           //   if (
//                           //     getBlockedDates.some(
//                           //       (item) =>
//                           //         item.blocked_dates ===
//                           //         date.date.format("MM/DD/YYYY") &&
//                           //         item.blocked_time === "2:00 PM" &&
//                           //         item.is_Reserved === false
//                           //     ) ||
//                           //     getBlockedDates.some(
//                           //       (item) =>
//                           //         item.blocked_dates ===
//                           //         date.date.format("MM/DD/YYYY") &&
//                           //         item.blocked_time === "2:00 PM" &&
//                           //         item.is_Reserved === true
//                           //     )
//                           //   ) {
//                           //     // Call handleUnblock when the red checkbox is clicked
//                           //     handleUnblock(
//                           //       date.date.format("MM/DD/YYYY"),
//                           //       "2:00 PM"
//                           //     );
//                           //   } else {
//                           //     const updatedCheckboxData = [...calendarDates];
//                           //     if (e.target.checked) {
//                           //       updatedCheckboxData.push({
//                           //         blocked_dates: e.target.value.split("|#|")[1],
//                           //         blocked_time: e.target.value.split("|#|")[0],
//                           //       });
//                           //     } else {
//                           //       const indexToDelete =
//                           //         updatedCheckboxData.findIndex(
//                           //           (item) =>
//                           //             item.blocked_dates ===
//                           //             e.target.value.split("|#|")[1]
//                           //         );
//                           //       updatedCheckboxData.splice(indexToDelete, 1);
//                           //     }

//                           //     setCalendarDates(updatedCheckboxData);
//                           //   }
//                           //   const updatedWeeks = [...weeks];
//                           //   updatedWeeks[weekIndex][dayIndex].is200Checked =
//                           //     !date.is200Checked;
//                           //   setWeeks(updatedWeeks);
//                           // }}
//                           />
//                           2:00 PM
//                         </div>
//                       </td>
//                     ) : null
//                   )
//                 }
//                 )}
//               </tr>
//             ))}
//           </table> :
//           <table className="table">
//             <tr>
//               <th className="thInspect">
//                 All <br />
//                 <Grid>
//                   <input
//                     type="checkbox"
//                     checked={select930All}
//                     // onChange={() => handleSelectAllDates(weekM)}
//                     onChange={() => handleSelectAll("9:30 AM","2")}
//                     className="red-checkbox"
//                   />
//                   <span>9:30 AM</span>
//                 </Grid>
//                 <Grid>
//                   <input
//                     type="checkbox"
//                     checked={select200All}
//                     // onChange={() => handleSelectAllDates(weekM)}
//                     onChange={() => handleSelectAll("2:00 PM","2")}
//                     className="red-checkbox"
//                   />
//                   <span>2:00 PM</span>
//                 </Grid>
//               </th>
//               {isSecondInstance
//                 ? dayOfWeek.map((day, dayIndex) => (
//                   <th className="thInspect" key={day}>
//                     {day}
//                   </th>
//                 ))
//                 :
//                 daysOfWeek.map((day, dayIndex) =>
//                   getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
//                     <th className="thInspect" key={day}>
//                       {day}
//                     </th>
//                   ) : null
//                 )
//               }
//             </tr>
//             {weeks.map((week, weekIndex) => (
//               <tr key={weekIndex}>
//                 <td className="tdInspect">
//                   <Grid>
//                     <input
//                       type="checkbox"
//                       className="red-checkbox"
//                       checked={week.every((date) => date.is930Checked)}
//                       onChange={() => handleRowCheckbox(weekIndex, "9:30 AM")}
//                     />
//                     <span>9:30 AM</span>
//                   </Grid>
//                   <Grid>
//                     <input
//                       type="checkbox"
//                       className="red-checkbox"
//                       checked={week.every((date) => date.is200Checked)}
//                       onChange={() => handleRowCheckbox(weekIndex, "2:00 PM")}
//                     />
//                     <span>2:30 AM</span>
//                   </Grid>
//                 </td>
//                 {week.map((date, dayIndex) => {
//                   // console.log("datedatedatedate", date)
//                   return (
//                     getEnableWeekend || (dayIndex !== 0 && dayIndex !== 6) ? (
//                       <td className="tdInspect" key={dayIndex}>
//                         {date.date.format("D")}
//                         <div>
//                           <input
//                             type="checkbox"
//                             style={{
//                               accentColor: unblockDates && unblockDates.some(
//                                 (unblockItem) =>
//                                   unblockItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unblockItem?.blocked_time?.includes("9:30 AM")
//                               )
//                                 ? "green" :
//                                 blockDates.some(
//                                   (item) =>
//                                     item?.blocked_dates ===
//                                     date.date.format("MM/DD/YYYY") &&
//                                     item?.blocked_time === "9:30 AM"
//                                 )
//                                   ? "#e60000" :
//                                   reserveDate.some(
//                                     (item) =>
//                                       item?.blocked_dates ===
//                                       date.date.format("MM/DD/YYYY") &&
//                                       item?.blocked_time === "9:30 AM"
//                                   )
//                                     ? "rgba(155, 46, 239, 1)" :
//                                     unReserveDates.some(
//                                       (unReserveItem) =>
//                                         unReserveItem?.blocked_dates?.includes(
//                                           date.date.format("MM/DD/YYYY")
//                                         ) && unReserveItem?.blocked_time?.includes("9:30 AM")
//                                     ) && "rgba(155,46,239,1)"
//                               // unavailableDates.some(
//                               //   (unavailableItem) =>
//                               //     unavailableItem?.date?.includes(
//                               //       date.date.format("MM/DD/YYYY")
//                               //     ) && unavailableItem?.time?.includes("9:30 AM")
//                               // ) && "#e60000"

//                             }}
//                             value={`9:30 AM|#|${date.date.format("MM/DD/YYYY")}`}
//                             // disabled={blockDates?.some((item) =>
//                             //   item?.blocked_dates ===
//                             //   date.date.format("MM/DD/YYYY") &&
//                             //   item?.blocked_time === "9:30 AM")
//                             // }
//                             checked={
//                               date.is930Checked ||
//                               blockDates?.some((item) =>
//                                 item?.blocked_dates ===
//                                 date.date.format("MM/DD/YYYY") &&
//                                 item?.blocked_time === "9:30 AM") ||
//                               reserveDate?.some((item) =>
//                                 item?.blocked_dates ===
//                                 date.date.format("MM/DD/YYYY") &&
//                                 item?.blocked_time === "9:30 AM")
//                             }
//                             // checked={
//                             //   date.is930Checked ||
//                             //   getBlockedDates.some(
//                             //     (item) =>
//                             //       item?.blocked_dates ===
//                             //       date.date.format("MM/DD/YYYY") &&
//                             //       item?.blocked_time === "9:30 AM"
//                             //   ) ||
//                             //   unblockDates.some(
//                             //     (unblockItem) =>
//                             //       unblockItem?.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unblockItem?.blocked_time?.includes("9:30 AM")
//                             //   ) ||
//                             //   unReserveDates.some(
//                             //     (unReserveItem) =>
//                             //       unReserveItem?.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unReserveItem?.blocked_time?.includes("9:30 AM")
//                             //   ) ||
//                             //   // appointmentsDropdown.some(
//                             //   //   (data) =>
//                             //   //     data.date
//                             //   //       .split(" ")[1]
//                             //   //       ?.includes(date.date.format("MM/DD/YYYY")) &&
//                             //   //     data.date.split(" ")[2]?.includes("9:30")
//                             //   // ) ||
//                             //   getReserveDates.some(
//                             //     (item) =>
//                             //       item?.blocked_dates ===
//                             //       date.date.format("MM/DD/YYYY") &&
//                             //       item?.blocked_time === "9:30 AM"
//                             //   ) ||
//                             //   unavailableDates.some(
//                             //     (unavailableItem) =>
//                             //       unavailableItem?.date?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unavailableItem?.time?.includes("9:30 AM")
//                             //   )
//                             // }
//                             onChange={(e) => handleDates(e, date, weekIndex, dayIndex, "9:30 AM")}
//                           />
//                           9:30 AM
//                           <br />
//                           <input
//                             type="checkbox"
//                             style={{
//                               accentColor: unblockDates && unblockDates.some(
//                                 (unblockItem) =>
//                                   unblockItem?.blocked_dates?.includes(
//                                     date.date.format("MM/DD/YYYY")
//                                   ) && unblockItem?.blocked_time?.includes("2:00 PM")
//                               )
//                                 ? "green" :
//                                 blockDates.some(
//                                   (item) =>
//                                     item?.blocked_dates ===
//                                     date.date.format("MM/DD/YYYY") &&
//                                     item?.blocked_time === "2:00 PM"
//                                 )
//                                   ? "#e60000" :
//                                   reserveDate.some(
//                                     (item) =>
//                                       item?.blocked_dates ===
//                                       date.date.format("MM/DD/YYYY") &&
//                                       item?.blocked_time === "2:00 PM"
//                                   )
//                                     ? "rgba(155, 46, 239, 1)" :
//                                     unReserveDates.some(
//                                       (unReserveItem) =>
//                                         unReserveItem?.blocked_dates?.includes(
//                                           date.date.format("MM/DD/YYYY")
//                                         ) && unReserveItem?.blocked_time?.includes("9:30 AM")
//                                     ) && "rgba(155,46,239,1)"
//                               // unavailableDates.some(
//                               //   (unavailableItem) =>
//                               //     unavailableItem?.date?.includes(
//                               //       date.date.format("MM/DD/YYYY")
//                               //     ) && unavailableItem?.time?.includes("2:00 PM")
//                               // ) && "#e60000"
//                             }}
//                             checked={
//                               date.is200Checked ||
//                               blockDates?.some((item) =>
//                                 item?.blocked_dates ===
//                                 date.date.format("MM/DD/YYYY") &&
//                                 item?.blocked_time === "2:00 PM") ||
//                               reserveDate?.some((item) =>
//                                 item?.blocked_dates ===
//                                 date.date.format("MM/DD/YYYY") &&
//                                 item?.blocked_time === "2:00 PM")
//                             }
//                             // checked={
//                             //   date.is200Checked ||
//                             //   getBlockedDates.some(
//                             //     (item) =>
//                             //       item?.blocked_dates ===
//                             //       date.date.format("MM/DD/YYYY") &&
//                             //       item?.blocked_time === "2:00 PM"
//                             //   ) ||
//                             //   unblockDates.some(
//                             //     (unblockItem) =>
//                             //       unblockItem?.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unblockItem?.blocked_time?.includes("2:00 PM")
//                             //   ) ||
//                             //   // appointmentsDropdown.some(
//                             //   //   (data) =>
//                             //   //     data.date
//                             //   //       .split(" ")[1]
//                             //   //       ?.includes(date.date.format("MM/DD/YYYY")) &&
//                             //   //     data.date.split(" ")[2]?.includes("9:30")
//                             //   // ) ||
//                             //   getReserveDates.some(
//                             //     (item) =>
//                             //       item?.blocked_dates ===
//                             //       date.date.format("MM/DD/YYYY") &&
//                             //       item?.blocked_time === "2:00 PM"
//                             //   ) ||
//                             //   unReserveDates.some(
//                             //     (unReserveItem) =>
//                             //       unReserveItem?.blocked_dates?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unReserveItem?.blocked_time?.includes("2:00 PM")
//                             //   ) ||
//                             //   unavailableDates.some(
//                             //     (unavailableItem) =>
//                             //       unavailableItem?.date?.includes(
//                             //         date.date.format("MM/DD/YYYY")
//                             //       ) && unavailableItem?.time?.includes("2:00 PM")
//                             //   )
//                             // }
//                             value={`2:00 PM|#|${date.date.format("MM/DD/YYYY")}`}
//                             onChange={(e) => handleDates(e, date, weekIndex, dayIndex, "2:00 PM")}

//                           // onChange={(e) => {
//                           //   if (
//                           //     getBlockedDates.some(
//                           //       (item) =>
//                           //         item.blocked_dates ===
//                           //         date.date.format("MM/DD/YYYY") &&
//                           //         item.blocked_time === "2:00 PM" &&
//                           //         item.is_Reserved === false
//                           //     ) ||
//                           //     getBlockedDates.some(
//                           //       (item) =>
//                           //         item.blocked_dates ===
//                           //         date.date.format("MM/DD/YYYY") &&
//                           //         item.blocked_time === "2:00 PM" &&
//                           //         item.is_Reserved === true
//                           //     )
//                           //   ) {
//                           //     // Call handleUnblock when the red checkbox is clicked
//                           //     handleUnblock(
//                           //       date.date.format("MM/DD/YYYY"),
//                           //       "2:00 PM"
//                           //     );
//                           //   } else {
//                           //     const updatedCheckboxData = [...calendarDates];
//                           //     if (e.target.checked) {
//                           //       updatedCheckboxData.push({
//                           //         blocked_dates: e.target.value.split("|#|")[1],
//                           //         blocked_time: e.target.value.split("|#|")[0],
//                           //       });
//                           //     } else {
//                           //       const indexToDelete =
//                           //         updatedCheckboxData.findIndex(
//                           //           (item) =>
//                           //             item.blocked_dates ===
//                           //             e.target.value.split("|#|")[1]
//                           //         );
//                           //       updatedCheckboxData.splice(indexToDelete, 1);
//                           //     }

//                           //     setCalendarDates(updatedCheckboxData);
//                           //   }
//                           //   const updatedWeeks = [...weeks];
//                           //   updatedWeeks[weekIndex][dayIndex].is200Checked =
//                           //     !date.is200Checked;
//                           //   setWeeks(updatedWeeks);
//                           // }}
//                           />
//                           2:00 PM
//                         </div>
//                       </td>
//                     ) : null
//                   )
//                 }
//                 )}
//               </tr>
//             ))}
//           </table>}
//       </Grid>
//       {isSecondInstance ?
//         <Grid item xs={12} md={12} container spacing={2} sx={{ mt: 1 }}>
//           <Grid item xs={12} md={3} container>
//             <Button
//               sx={{
//                 border: "1px solid rgba(191, 10, 48, 1)",
//                 color: "rgba(255, 255, 255, 1)",
//                 borderRadius: "6px",
//                 padding: "10px!important",
//                 backgroundColor: "rgba(191, 10, 48, 1)",
//                 "&:hover": { backgroundColor: "rgba(191, 10, 48, 1)" },
//                 textTransform: "unset",
//                 width: "100%",
//               }}
//               onClick={() => onClick()}
//             >
//               Preset an appointment
//             </Button>
//           </Grid>
//           <Grid item xs={12} md={2} container>
//             <Button
//               sx={{
//                 border: "1px solid rgba(44, 181, 44, 1)",
//                 color: "rgba(44, 181, 44, 1)",
//                 borderRadius: "6px",
//                 padding: "10px!important",
//                 "&:hover": { backgroundColor: "rgba(44, 181, 44, 0.1)" },
//                 textTransform: "unset",
//                 width: "100%",
//               }}
//               onClick={unBlockDateId}
//             >
//               Unblock
//             </Button>
//           </Grid>
//           <Grid item xs={12} md={2} container>
//             <Button
//               sx={{
//                 border: "1px solid rgba(155, 46, 239, 1)",
//                 color: "rgba(155, 46, 239, 1)",
//                 borderRadius: "6px",
//                 padding: "10px!important",
//                 "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
//                 textTransform: "unset",
//                 width: "100%",
//               }}
//               onClick={handleunReserveDates}
//             >
//               UnReserve
//             </Button>
//           </Grid>
//         </Grid>
//         : (
//           <Grid item xs={12} md={12} container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12} md={2} container>
//               <Button
//                 sx={{
//                   border: "1px solid rgba(191, 10, 48, 1)",
//                   color: "rgba(255, 255, 255, 1)",
//                   borderRadius: "6px",
//                   padding: "10px!important",
//                   backgroundColor: "rgba(191, 10, 48, 1)",
//                   "&:hover": { backgroundColor: "rgba(191, 10, 48, 1)" },
//                   textTransform: "unset",
//                   width: "100%",
//                 }}
//                 onClick={handleSubmitDates}
//               >
//                 Block
//               </Button>

//             </Grid>

//             {/* <Grid item xs={12} md={2} container>
//               <Button
//                 sx={{
//                   border: "1px solid rgba(44, 181, 44, 1)",
//                   color: "rgba(44, 181, 44, 1)",
//                   borderRadius: "6px",
//                   padding: "10px!important",
//                   "&:hover": { backgroundColor: "rgba(44, 181, 44, 0.1)" },
//                   textTransform: "unset",
//                   width: "100%",
//                 }}
//                 onClick={unBlockDateId}
//               >
//                 Unblock
//               </Button>
//             </Grid> */}

//             <Grid item xs={12} md={2} container>
//               <Button
//                 sx={{
//                   border: "1px solid rgba(155, 46, 239, 1)",
//                   color: "rgba(255, 255, 255, 1)",
//                   borderRadius: "6px",
//                   padding: "10px!important",
//                   backgroundColor: "rgba(155, 46, 239, 1)",
//                   "&:hover": { backgroundColor: "rgba(155, 46, 239, 1)" },
//                   textTransform: "unset",
//                   width: "100%",
//                 }}
//                 onClick={handleReserveDates}
//               >
//                 Reserve
//               </Button>
//             </Grid>

//             {/* <Grid item xs={12} md={2} container>
//               <Button
//                 sx={{
//                   border: "1px solid rgba(155, 46, 239, 1)",
//                   color: "rgba(155, 46, 239, 1)",
//                   borderRadius: "6px",
//                   padding: "10px!important",
//                   "&:hover": { backgroundColor: "rgba(155, 46, 239, 0.1)" },
//                   textTransform: "unset",
//                   width: "100%",
//                 }}
//                 onClick={handleunReserveDates}
//               >
//                 UnReserve
//               </Button>
//             </Grid> */}
//           </Grid>
//         )}
//     </>
//   );
// }

// export default CalendarManagement;

