import React from "react";
import logo from "../images/logo.png";
import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const location = useLocation();
  const url = location.pathname
  return (
    <nav className="nav">
      <input type="checkbox" id="nav-check" />
      <div className="nav-header">
        <div className="nav-title">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="nav-btn">
        <label htmlFor="nav-check">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>

      <ul className="nav-list">
        <Link to="/jobDispatch">
          <li style={{ color: url === "/jobDispatch" && "#000" }}>
            Home
          </li>
        </Link>
        <Link to="/jobDispatch/project">
          <li style={{ color: url === "/jobDispatch/project" && "#000" }}>
            Projects

          </li>
        </Link>
        <Link to="/jobDispatch/inspection">
          <li style={{ color: url === "/jobDispatch/inspection" && "#000" }}>
            Manage Files (...)
          </li>
        </Link>
        {/* <li>
          <a href="#">Manage Files (...)</a>
        </li> */}
        <li>
          <a href="#" style={{ color: "rgba(255, 0, 0, 1)" }}>
            Log out
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
