import { Box, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import JSZip from 'jszip';
import axios from 'axios';

const InspectionHomeData = ({ jobData, search, isCreateProject }) => {

    const [isJobSheet, setJobSheet] = useState(false)
    const navigate = useNavigate()
    const handleCreateProject = (jobId) => {
        navigate(`/jobDispatch/inspection/${jobId}`)
    }

    // const createZipFile = async () => {
    //     for (const url of jobData.attachedDocuments) {
    //         try {
    //             const response = await axios.get(url, { responseType: 'arraybuffer' });
    //             console.log("response: ", response);
    //             // Extract the file name from the URL
    //             const fileName = url.split('/').pop();

    //             // Create a Blob from the array buffer
    //             const blob = new Blob([response.data], { type: response.headers['content-type'] });
    //             console.log("blob", blob);
    //             // Create a download link and trigger a click to download the image
    //             const link = document.createElement('a');
    //             link.href = window.URL.createObjectURL(blob);
    //             link.download = fileName;
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);

    //             console.log(`Image ${fileName} downloaded successfully.`);
    //         } catch (error) {
    //             console.error(`Error downloading image from ${url}:`, error);
    //         }
    //     }
    // }

    const createZipFile = async () => {
        const zip = new JSZip();

        // Fetch each image and add it to the zip
        const promises = jobData.attachedDocuments.map(async (url, index) => {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                zip.file(`image_${index + 1}.jpg`, blob);
            } catch (error) {
                console.error(`Error fetching ${url}:`, error);
            }
        });

        // Wait for all promises to resolve before generating the zip file
        await Promise.all(promises);

        // Generate the zip file
        zip.generateAsync({ type: 'blob' })
            .then((content) => {
                // Create a download link and trigger the download
                const downloadLink = document.createElement('a');
                const url = URL.createObjectURL(content);
                downloadLink.href = url;
                downloadLink.download = 'images.zip';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch((error) => {
                console.error('Error creating zip file:', error);
            });
    };

    return (
        <>
            <h3 style={{ color: "rgba(106, 96, 169, 1)", marginBottom: 0 }}>{jobData?.name}</h3>
            <h5 style={{ color: "rgba(106, 96, 169, 1)", marginTop: 2 }}>{jobData?.id}</h5>

            <Box
                sx={{
                    p: 2,
                    border: "1px solid #12111180",
                    display: "flex",
                    flexDirection: "column"
                    // borderBottom: "unset",
                }}
            >
                <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                    <span style={{ fontWeight: 400, fontSize: "18px" }}>
                        Date and Time:{" "}
                    </span>
                    {jobData?.date}
                </p>
                <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                    <span style={{ fontWeight: 400, fontSize: "18px" }}>
                        Inspection Site Address:{" "}
                    </span>
                    {jobData?.siteAddress}
                </p>
                <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                    <span style={{ fontWeight: 400, fontSize: "18px" }}>
                        Resource (Inspector Name):{" "}
                    </span>
                    {jobData?.inspectorName}
                </p>
                {isJobSheet &&
                    <>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Email:{" "}
                            </span>
                            {jobData?.email}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Person Site Number:{" "}
                            </span>
                            {jobData?.personOnSiteNumber}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Client Address:{" "}
                            </span>
                            {jobData?.clientAddress}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Client Category:{" "}
                            </span>
                            {jobData?.clientCategory}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Client Phone:{" "}
                            </span>
                            {jobData?.clientPhone}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Client Comment:{" "}
                            </span>
                            {jobData?.comments}
                        </p>
                        <p style={{ color: "rgba(106, 96, 169, 1)" }}>
                            <span style={{ fontWeight: 400, fontSize: "18px" }}>
                                Attached Documents:{" "}
                            </span>
                            <span
                                style={{
                                    color: " rgba(46, 105, 255, 1)",
                                    textDecoration: " underline",
                                    textDecorationColor: "rgba(46, 105, 255, 1)",
                                    borderRadius: "4px",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                                }}
                                onClick={() => createZipFile()}
                            >{"Download"}</span>
                        </p>
                    </>
                }
            </Box>
            <Grid item xs={12} md={8} container spacing={2}>
                {isCreateProject !== 1 && <Grid item >
                    <Button
                        sx={{
                            border: "1px solid rgba(46, 105, 255, 1)",
                            backgroundColor: " rgba(46, 105, 255, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            borderRadius: "4px",
                            padding: "unset",
                            paddingTop: "6px",
                            paddingBottom: "6px",
                            paddingRight: "18px",
                            paddingLeft: "18px",
                            "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                            textTransform: "unset",
                            mt: 2,
                        }}
                        // disabled={!search}
                        onClick={() => handleCreateProject(jobData.id)}
                    >
                        Create Project
                    </Button>
                </Grid>}
                <Grid item xs={6} md={6}>
                    <p
                        style={{
                            color: " rgba(46, 105, 255, 1)",
                            textDecoration: " underline",
                            textDecorationColor: "rgba(46, 105, 255, 1)",
                            borderRadius: "4px",
                            padding: "unset",
                            paddingTop: "6px",
                            paddingRight: "18px",
                            paddingLeft: "18px",
                            "&:hover": { backgroundColor: "rgba(46, 105, 255)" },
                            textTransform: "unset",
                            mt: 2,
                        }}
                        onClick={() => setJobSheet(!isJobSheet)}
                    >View Job Sheet</p>
                </Grid>
            </Grid>
        </>
    )
}

export default InspectionHomeData
