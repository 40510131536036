import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminHome from "./AdminHome";
import Login from "./Login";
import SetPassword from "../components/SetPassword";
import ForgotPassword from "../components/ForgetPassword";
import JobDispatch from "./JobDispatch";
import Profile from "./Profile";
import InspectionHome from "./InspectionHome";
import Project from "../components/Project";
import InspectionProject from "../components/InspectionProject";
import Agreement from "../components/Agreement";
import ProjectDetails from "../components/ProjectDetails";


function Main() {
  const token = localStorage.getItem("p_u_t");
  return (
    <>
      <Routes>
        {!token ? (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/password-set" element={<SetPassword />} />
            <Route path="/password-reset" element={<SetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to={"/"} />} />
          </>
        ) : (
          <>
            <Route path="/jobDispatch/inspection" element={<JobDispatch />} />
            <Route path="/jobDispatch/inspection/:id" element={<JobDispatch />} />
            <Route path="/jobDispatch/project" element={<ProjectDetails />} />
            <Route path="/jobDispatch" element={<InspectionHome />} />
            <Route path="/create-project/:projectId" element={<InspectionProject />} />
            <Route path="/*" element={<AdminHome />} />
            <Route path="/" element={<Navigate to={"/home"} />} />
            <Route path="/password-set" element={<SetPassword />} />
            <Route path="/password-reset" element={<SetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<Navigate to="/home" />} />
            {/* <Route path="*" element={<Navigate to={"/home"} />} /> */}
          </>
        )}
      </Routes>
    </>
  );
}

export default Main;
