import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SideBar from "../components/SideBar";
import Home from "../components/Home";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import logo from "../images/Amoldi App_logo.JPG";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cart from "../images/cart.png";
import mail from "../images/mail.png";
import userImg from "../images/user.png";
import { Button, Card, CardContent, Typography } from "@mui/material";
import Orders from "../components/Orders";
import Information from "../components/Information";
import ClientManagement from "../components/ClientManagement";
import InspectorManagement from "../components/InspectorManagement";
import UserManagement from "../components/UserManagement";
import PricingMatrix from "../components/PricingMatrix";
import ContactManagement from "../components/ContactMangement";
import { AppStorage } from "../utils/Context/AppContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Request from "../utils/Requests/Request";
import { showToast } from "../utils";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
// greeting message
const now = new Date();
const currentHour = now.getHours();
let greetingMessage = "";

if (currentHour >= 5 && currentHour < 12) {
  greetingMessage = "Good Morning!";
} else if (currentHour >= 12 && currentHour < 17) {
  greetingMessage = "Good Afternoon!";
} else {
  greetingMessage = "Good Evening!";
}
function AdminHome() {
  const location = useLocation();
  const AppContext = useContext(AppStorage);
  const navigate = useNavigate();
  // const permissionsString = AppContext?.userInfo.permissions || ""; // Ensure it's not undefined
  // const permissionsArray = permissionsString.split(",").map(permission => permission.trim());
  // Function to get the title based on the route
  const getTitle = () => {
    if (location.pathname === "/home") {
      return "Dashboard";
    } else if (location.pathname === "/order") {
      return "Order Management";
    } else if (location.pathname === "/fileManagement") {
      return "File Management";
    }
    else if (location.pathname === "/clientManagement") {
      return "Client Management";
    }
    else if (location.pathname === "/inspectorManagement") {
      return "Inspector Management";
    }
    else if (location.pathname === "/userManagement") {
      return "User Management";
    }
    else if (location.pathname === "/pricingMatrix") {
      return "Settings";
    }
    else if (location.pathname === "/contactManagement") {
      return "Contact Management";
    } else {
      return "Dashboard"
    }
  };
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openNotification, setNotification] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);


  function getMessages() {
    setLoading(true);
    Request.get(`/getMessages`)
      .then((result) => {
        setLoading(false);
        console.log("message Log", result.data.data);
        setNotifications(result.data.data)
      })
      .catch((err) => {
        setLoading(false);
        // console.log("Error: ", err);
        if (err.response.status == 401) {
          showToast("error", "Unauthorized user.");
        } else {
          showToast("error", "Something went wrong.");
        }
      });
  }
  console.log("notifications", notifications);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "#ffffff",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "block" }),
                "&:hover": {
                  backgroundColor: "unset",
                },
              }}
            >
              {/* <MenuIcon /> */}
              <img src={logo} alt="" style={{ width: 40, height: 40 }} />
            </IconButton>
            <h2 style={{ color: "rgba(21, 6, 45, 1)" }}>
              {getTitle()}
            </h2>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Box className="searchDiv">
                <FontAwesomeIcon icon={faSearch} className="iconSearch" />
                <input
                  className="input-field"
                  type="text"
                  placeholder="What are you looking for..."
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Cart} alt="" />
              </Box>
              <Box
                sx={{
                  backgroundColor: "rgba(243, 241, 246, 1)",
                  borderRadius: "50%",
                  padding: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
                onClick={() => {
                  getMessages();
                  setNotification(!openNotification)
                }}
              >
                <img src={mail} alt="" />
              </Box>
              {openNotification &&
                <Box style={{
                  height: "400%",
                  width: "20%",
                  position: "absolute",
                  color: "red",
                  backgroundColor: "#ffffff",
                  top: 'calc(100%)', // Adjust the distance from the first Box
                  left: "70%", // Align with the left edge of the first Box
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  padding: 10,
                  overflow: 'auto'
                }}
                >
                  {notifications.map((item, index) => {
                    return (
                      <Card
                        style={{
                          background: "rgba(255, 255, 255, 1)",
                          borderRadius: "12px",
                          marginBottom: 10
                        }}
                      >
                        <div style={{ padding: 5 }}>
                          <p style={{ fontSize: 15 }}>{item}</p>
                        </div>
                      </Card>
                    )
                  })}
                </Box>}
            </Box>
            <div className="vl"></div>
            <Box sx={{ display: "flex" }}>
              <p
                style={{
                  color: "black",
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
              >
                <span
                  style={{
                    color: "rgba(191, 10, 48, 1)",
                    fontSize: "12px",
                    textAlign: "right",
                    fontWeight: "500",
                  }}
                >
                  {greetingMessage}
                </span>
                {`${AppContext?.userInfo.firstName} ${AppContext?.userInfo.lastName}`}
              </p>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={() => navigate('/profile')}
                >
                  <img className="imgT" src={userImg} alt="N/A" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List onClick={handleDrawerClose} sx={{ paddingTop: "20px" }}>
          <SideBar open={handleDrawerOpen} />
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/order" element={<Orders />} />
          <Route path="/fileManagement" element={<Information />} />
          <Route path="/clientManagement" element={<ClientManagement />} />
          <Route path="/inspectorManagement" element={<InspectorManagement />} />
          <Route path="/userManagement" element={<UserManagement />} />
          <Route path="/pricingMatrix" element={<PricingMatrix />} />
          <Route path="/contactManagement" element={<ContactManagement />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Box>
    </Box >
  );
}

export default AdminHome;
