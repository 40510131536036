import { Button, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select';

const BuildingStructure = ({
    formData,
    setFormData,
    buildingData,
    main_index,
    getMaterial,
    materialDropdown,
    addMaterial
}) => {
    // console.log(
    //     "building stucture formData", formData
    // );
    const wrapperRef = useRef(null);
    const materialDropdownRef = useRef(null);
    const [openField, setOpenField] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // Clicked outside the wrapper, hide the field
                setOpenField(false);
            }
        };

        // Attach the event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: 60,
            // width: "300px",
            borderRadius: "6px",
            border: state.isFocused
                ? "1px solid rgba(155, 46, 239, 1)"
                : "1px solid rgba(223, 222, 225, 1)",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid rgba(155, 46, 239, 1)",
            },
        }),
        indicatorSeparator: () => ({ display: 'none' }), // Hide the indicator separator
        dropdownIndicator: () => ({ display: 'none' }), // Hide the dropdown indicator
    };

    const handleOpenFieldClick = (event) => {
        // Prevent the event from propagating to the document click listener
        event.stopPropagation();
    };
    return (
        <div> <p
            ref={wrapperRef}
            style={{
                marginTop: "6px",
                fontSize: "15px",
                color: "rgba(155, 46, 239, 1)",
                cursor: "pointer"
                // width:"90%"
            }}>
            {`Structure ${main_index + 1}`}
        </p>
            <Grid item xs={12} md={12} lg={12} container>
                <Grid xs={12} md={12} lg={6}>
                    <FormControl sx={{ mt: 1, width: "90%" }}>
                        <Select
                            displayEmpty
                            sx={{
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderRadius: "12px",
                                "& .MuiInputBase-formControl": {
                                    borderRadius: "10px",
                                    color: "rgba(155, 46, 239, 1) !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                },
                                color: "rgba(155, 46, 239, 1)",
                            }}
                            value={buildingData.buildingStructure}
                            onChange={(e) => {
                                let dupObj = { ...formData };
                                dupObj.structure[main_index].buildingStructure = e.target.value;
                                setFormData(dupObj);
                            }}
                        >
                            <MenuItem value={"Exterior"} disabled={formData.structure.filter(item => item.buildingStructure === "Exterior").length === 2}>Exterior</MenuItem>
                            <MenuItem value={"Interior"} disabled={formData.structure.some(item => item.buildingStructure === "Interior")}>Interior</MenuItem>
                        </Select>
                    </FormControl>
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                            // width:"90%"
                        }}
                    >
                        Building structure:
                    </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <FormControl sx={{ mt: 1, width: "90%" }}>
                        <Select
                            displayEmpty
                            sx={{
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderRadius: "12px",
                                "& .MuiInputBase-formControl": {
                                    borderRadius: "10px",
                                    color: "rgba(155, 46, 239, 1) !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(223, 222, 225, 1) !important",
                                },
                                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "rgba(155, 46, 239, 1) !important",
                                },
                                color: "rgba(155, 46, 239, 1)",
                            }}
                            value={buildingData.buildingMaterialType}
                            onChange={(e) => {
                                let dupObj = { ...formData };
                                dupObj.structure[main_index].buildingMaterialType = e.target.value;
                                setFormData(dupObj);
                            }}
                        >
                            {/* {console.log("formData.buildingStructure", formData.buildingStructure)} */}
                            {buildingData.buildingStructure === "Exterior" ?
                                <MenuItem value={"Roof"}>Roof</MenuItem> :
                                <MenuItem value={"Walls"}>Walls</MenuItem>
                            }
                            {buildingData.buildingStructure === "Exterior" && <MenuItem value={"Walls"}>Walls</MenuItem>}
                        </Select>
                    </FormControl>
                    <p
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                            // width:"90%"
                        }}
                    >
                        Building material types:
                    </p>
                </Grid>
                <Grid xs={12} md={12} lg={6}>
                    <FormControl sx={{ mt: 1, width: "90%" }}>
                        <ReactSelect
                            // arrowRenderer={null}
                            value={buildingData.others}
                            onChange={(e) => {
                                let dupObj = { ...formData };
                                dupObj.structure[main_index].others = e;
                                getMaterial(e)
                                setMenuOpen(false)
                                setFormData(dupObj);
                            }}
                            options={materialDropdown}
                            onInputChange={(inputValue, { action }) => {
                                if (action === 'input-change') {
                                    // Set menuIsOpen to true when the user starts typing
                                    setMenuOpen(true); // Assuming you have a state variable to manage menuIsOpen
                                }
                            }}
                            onBlur={() => setMenuOpen(false)}
                            isSearchable={true}
                            styles={customStyles}
                            placeholder="Material"
                            menuIsOpen={isMenuOpen}
                        />
                    </FormControl>
                    <p
                        ref={wrapperRef}
                        style={{
                            marginTop: "6px",
                            fontSize: "12px",
                            color: "rgba(155, 46, 239, 1)",
                            cursor: "pointer"
                            // width:"90%"
                        }}
                        onClick={(e) => {
                            setOpenField(!openField)
                            handleOpenFieldClick(e)
                        }}
                    >
                        + Material
                    </p>
                </Grid>

                {openField &&
                    <Grid
                        xs={12}
                        md={12}
                        sx={{ display: "flex", gap: "15px" }}
                        onClick={handleOpenFieldClick}
                    >
                        {/* <FormControl sx={{ mt: 1, width: "100%" }}> */}
                        <Grid xs={12} md={12} lg={6} style={{ width: "100%" }}>
                            <TextField
                                placeholder="Add material"
                                id="outlined-start-adornment"
                                sx={{
                                    width: "90%",
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    borderRadius: "12px",
                                    "& .MuiInputBase-formControl": {
                                        borderRadius: "10px",
                                        color: "rgba(155, 46, 239, 1) !important",
                                    },
                                    "& .MuiInputBase-formControl ::placeholder": {
                                        color: "rgba(180, 177, 185, 1) !important",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(223, 222, 225, 1) !important",
                                    },
                                    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "rgba(155, 46, 239, 1) !important",
                                    },
                                }}
                                value={buildingData.material}
                                onChange={(e) => {
                                    let dupObj = { ...formData };
                                    dupObj.structure[main_index].material = e.target.value;
                                    // addMaterial(e.target.value)
                                    setFormData(dupObj);
                                }}
                            />
                        </Grid>
                        <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                            <Button
                                sx={{
                                    border: "1px solid rgba(46, 105, 255, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    borderRadius: "4px",
                                    width: "100%",
                                    padding: "9px 0px",
                                    backgroundColor: "rgba(46, 105, 255, 1)",
                                    textTransform: "unset",
                                    fontWeight: "unset",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                                }}
                                onClick={() => addMaterial(buildingData.material)}
                            >
                                Add
                            </Button>
                        </Grid>
                        <Grid xs={2} md={2} lg={1} sx={{ pt: 1 }}>
                            <Button
                                sx={{
                                    border: "1px solid rgba(46, 105, 255, 1)",
                                    color: "rgba(255, 255, 255, 1)",
                                    borderRadius: "4px",
                                    width: "100%",
                                    padding: "9px 0px",
                                    backgroundColor: "rgba(46, 105, 255, 1)",
                                    textTransform: "unset",
                                    fontWeight: "unset",
                                    "&:hover": { backgroundColor: "rgba(46, 105, 255, 1)" },
                                }}
                                onClick={() => addMaterial(buildingData.material, true)}
                            >
                                Remove
                            </Button>
                        </Grid>
                        {/* </FormControl> */}
                        {/* <p
        style={{
          marginTop: "6px",
          fontSize: "12px",
          color: "rgba(155, 46, 239, 1)",
          // width:"90%"
        }}
        onClick={() => setOpenField(true)}
      >
        + Material
      </p> */}
                    </Grid>}
            </Grid></div>
    )
}

export default BuildingStructure;