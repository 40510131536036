import React, { useState } from "react";
import logo from "../images/logoDash-removebg.png";
import { showToast } from "../utils/index";
import Request from "../utils/Requests/Request";

function ForgotPassword() {
  const [email, setEmail] = useState("");

    function sendEmail(e) {
        e.preventDefault()
        if (email !== "" ) {
          Request.post("/forgotPassword", {
            email: email,
          })
            .then((err) => {
                // console.log(err);
                
              showToast("success", err?.data?.message);
            })
            .catch((err) => {
              // console.log("Error: ", err);
              showToast("error", err?.response?.data?.message);
            });
        } else {
          showToast("error", "Email Cannot be Blank");
        }
    }
  return (
    <div
      style={{
        background:"rgba(18, 23, 58, 1)",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "33vw",
          background: "rgb(0 2 57)",
          borderRadius:"12px",
          padding:"10px",
          boxShadow: "rgba(191, 10, 48, 0.1) 0px 10px 50px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <img src={logo} alt="" style={{ width: "15%" }} />
        </div>
        <h3 style={{color:"rgba(244, 247, 253, 1)", textAlign:"center"}}>Enter email to get a reset password link</h3>
        <form onSubmit={sendEmail}>
            <div className="txt_field">
                <input type="text" onChange={(event) => setEmail(event.target.value)}/>
                <span></span>
                <label>Email</label>
            </div>
            
            <input name="submit" type="Submit" value="Get Email" />
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
