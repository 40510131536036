import "./App.css";
import Main from './pages/Main';
import { AppContext } from './utils/Context/AppContext';
import { removeConsoleLogs } from "./utils/removeConsole";

function App() {
  const restoreConsole = removeConsoleLogs();
  return (
    <>
      <AppContext>
        <Main />
      </AppContext>
    </>
  );
}

export default App;
