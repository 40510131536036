import React, { useEffect, useState, createContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Request from '../Requests/Request';
import axios from "../Requests/axios"

const AppStorage = createContext()

const AppContext = ({ children }) => {
    const [userInfo, setUserInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [userAuthToken, setUserAuthToken] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        let userToken = localStorage.getItem('p_u_t');
        //   console.log("userToken",userToken);
        if (!!userToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;
            setUserAuthToken(userToken);
        }
    }, [])

    useEffect(() => {
        if (userAuthToken !== '') {
            setIsLoading(true);
            Request.get('/getUserDetails').then(result => {
                setIsLoading(false);
                setUserInfo(result.data['user']);
                localStorage.setItem('userInfo', JSON.stringify(result.data['user']))
            }).catch(err => {
                // console.log('Error: ', err);
                setIsLoading(false);
                if (err.response.status == 401) {
                    toast.error('Unauthorized user.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/');
                } else {
                    toast.error('Something went wrong.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate('/');
                }
            })
        } else {
            setUserInfo({})
        }
    }, [userAuthToken])

    function setUserToken(token) {
        setUserAuthToken(token);
        localStorage.setItem('p_u_t', token);
    }
    return (
        <AppStorage.Provider
            value={{
                userInfo,
                userAuthToken,
                setUserToken,
                isLoading,
                setIsLoading
            }}
        >
            {children}
        </AppStorage.Provider>
    );

}

export { AppStorage, AppContext }
